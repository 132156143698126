<mat-form-field class="abcdj-mat-form-field">
  <mat-label>{{label}}</mat-label>
  <mat-chip-list #chipList [multiple]="multiple">
    <mat-chip *ngFor="let value of selectedValues" [selectable]="!isDisabled" [removable]="!isDisabled"
      (removed)="remove(value)">
      {{value}}
      <mat-icon matChipRemove *ngIf="!isDisabled">cancel</mat-icon>
    </mat-chip>
    <input placeholder="{{placeholder}}" #autocompleteInput [formControl]="inputCtrl" [matAutocomplete]="auto"
      [disabled]="isDisabled" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let value of filteredValues$ | async" [value]="value">
      {{value}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
