import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, Optional, Output, ViewChild, inject } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxChange as MatCheckboxChange, MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { FieldValue, MetadataProvider } from '../../_models';
import { BaseComponent, EDIT_CONTEXT, EditContextType, I18N_CONTEXT, MessageSeverity } from '../../common';

@Component({
  selector: 'hdis-enum-picker',
  templateUrl: './enum-picker.component.html',
  styleUrls: ['./enum-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, FlexModule, MatLegacyButtonModule, MatIconModule, MatLegacyFormFieldModule, MatLegacyInputModule, ReactiveFormsModule, MatSlideToggleModule, NgFor, MatLegacyCheckboxModule, MatDividerModule, NgClass, ExtendedModule, AsyncPipe],
})
export class EnumPickerComponent extends BaseComponent {
  @Input()
    title: string;

  @Input()
    fieldName: string;

  @Input()
  get showCloseButton(): boolean { return this._showCloseButton; }

  set showCloseButton(value) { this._showCloseButton = coerceBooleanProperty(value); }

  _showCloseButton = false;

  @Input()
  get canShowInvalidValues(): boolean { return this._canShowInvalidValues; }

  set canShowInvalidValues(value) { this._canShowInvalidValues = coerceBooleanProperty(value); }

  _canShowInvalidValues = false;

  @Input('max')
  get maxSelection(): number { return this._maxSelection; }

  set maxSelection(value) { this._maxSelection = coerceNumberProperty(value); }

  _maxSelection: number;

  @Output()
  readonly selectionChange = new EventEmitter<any>();

  @Output()
  readonly opened = new EventEmitter<void>();

  @Output()
  readonly closed = new EventEmitter<void>();

  fieldsLoading: boolean;

  selected$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  available$: BehaviorSubject<FieldValue[]> = new BehaviorSubject([]);

  options$: Observable<{ value: string; visible: boolean }[]>;

  @ViewChild('filterInput') filterInput: ElementRef;

  filter = new UntypedFormControl('');

  showInvalidValues = false;

  isOpen = false;

  isReadonly = false;

  private snackbar = inject(MatSnackBar);

  constructor(
    private service: MetadataProvider,
    @Optional() @Inject(I18N_CONTEXT) public i18nContext: string,
    @Optional() @Inject(EDIT_CONTEXT) public editContext: EditContextType,
  ) {
    super();
    this.showCloseButton = editContext === 'panel';
    this.options$ =
      combineLatest([
        this.selected$,
        this.available$,
        this.filter.valueChanges.pipe(startWith('')),
      ]).pipe(
        map(([selected, available, autocomplete = '']) => {
          if (available.length) {
            const newOptions = available.map((option) => ({
              ...option,
              selected: selected.includes(option.value),
              visible: option.label.toLowerCase().includes(autocomplete.toLowerCase()),
            }));
            return newOptions;
          } if (this.maxSelection === 1) {
            return selected.map((val) => ({ value: val, visible: true, selected: true }));
          }
          return [];
        }),
      );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    console.log('destroy enum picker');
    this.closed.emit();
  }

  clickCheckbox(selectedValue: string, event: MatCheckboxChange) {
    const prevValue = this.selected$.value;
    let newSelected;
    if (event.checked) {
      newSelected = this.maxSelection === 1 ? [selectedValue] : [...prevValue, selectedValue].sort();
    } else {
      newSelected = this.selected$.value.filter((selected) => selected !== selectedValue);
    }

    if (this.maxSelection && newSelected.length > this.maxSelection) {
      // revert the element
      event.source.checked = !event.checked;
      this.snackbar.open(`${this.fieldName} allows max ${this.maxSelection} items`, '', { panelClass: [MessageSeverity.WARNING] });
    } else {
      this.setSelected(newSelected);
      this.selectionChange.emit(this.maxSelection === 1 ? newSelected[0] : newSelected);
    }
  }

  optionId(index, option) {
    return option.value;
  }

  open(fieldName: string, selected: string | string[]) {
    // first clean any possible dirty state by closing the previous picker
    this.close();

    // then updated the component props
    this.setSelected(selected);
    this.setField(fieldName);
    this.isOpen = true;

    // finally emit the `opened` event to let subscribers (usually the directive) to properly subscribe
    this.opened.emit();
  }

  close() {
    this.isOpen = false;
    this.closed.emit();
  }

  private setField(name: string) {
    this.fieldName = name;
    this.loadValues(this.showInvalidValues);
  }

  setSelected(selected: any) {
    let next: any[] = [];
    if (selected) next = Array.isArray(selected) ? selected : [selected];

    this.selected$.next(next);
  }

  loadValues(showInvalidValues: boolean) {
    this.available$.next([]);
    this.fieldsLoading = true;
    this.service.getFieldValues(this.fieldName, null, showInvalidValues).pipe(take(1)).subscribe((newValues) => {
      this.available$.next(newValues);
      this.fieldsLoading = false;
    });
  }
}
