// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  language: 'en',
  apiBaseUrl: '',
  assetsBaseUrl: '',
  streamBaseUrl: '',
  endpoints: {
    files: '/files',
    brands: '/brands',
    pools: '/pools',
    audio: '/audio',
    profiles: '/profiles',
    upload: '/songs/upload',
    user: '/users/me',
    bookmarks: '/users/me/bookmarks',
    uiPreferences: '/users/me/view-preferences',
    fields: '/_fields',
    fieldValues: '/_field-values',
    fieldStats: '/_stats',
    schedules: '/schedules',
    playlists: '/playlists',
    selections: '/selections',
    radioStations: '/radio-stations',
    compilations: '/compilations',
    inbox: '/inbox',
    players: '/players',
    devices: '/devices',
  },
  authentication: {
    issuer: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_21DlqBRX7',
    responseType: 'code',
    redirectUri: window.location.origin,
    clientId: '53dc76g2cfe6lhlh1vpacd1idq',
    scope: 'email profile openid aws.cognito.signin.user.admin',
    strictDiscoveryDocumentValidation: false,
  },
  platform: {
    upload: {
      maxConcurrentRequests: 1,
    },
    download: {
      maxConcurrentRequests: 5,
    },
  },

};
