import { isSameYear, format, isSameDay } from 'date-fns';

export const roundMs = (amount: number): number => Math.round(amount * 1000) / 1000;

export const formatDatePeriod = (from: Date, to: Date) => {
  if (from && to) {
    const dateFormat = (isSameYear(from, to)) ? 'dd MMMM' : 'dd MMMM yyyy';
    return isSameDay(from, to) ? format(from, dateFormat) : `${format(from, dateFormat)} -  ${format(to, dateFormat)}`;
  }
  return `${from ? format(from, 'dd MMMM yyyy') : 'N/A'} -  ${to ? format(to, 'dd MMMM yyyy') : 'N/A'}`;
};

export function parseIsoToDate(strDate: string): Date {
  const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?$/;
  if (typeof strDate === 'string' && isoDateFormat.test(strDate)) {
    return new Date(strDate);
  }
  return null;
}

export const FORMAT_DATE_TIME_LOCAL = "yyyy-MM-dd'T'HH:mm:ss.SSS";
export const FORMAT_DATE = 'yyyy-MM-dd';
export const FORMAT_TIME = 'HH:mm:ss';

export const FORMAT_DATE_TIME_COMPACT = 'dd/MM/yyyy HH:mm';
export const FORMAT_DATE_TIME = 'dd/MM/yyyy HH:mm:ss';
export const FORMAT_DATE_TIME_MS = 'dd/MM/yyyy HH:mm:ss.SSS';
export const FORMAT_DATE_COMPACT = 'dd/MM/yyyy';
export const FORMAT_DATE_COMPACT_DE = 'dd.MM.yyyy';
export const FORMAT_TIME_COMPACT = 'HH:mm';

export const FORMAT_TIME_MS = 'HH:mm:ss.SSS';
export const FORMAT_TIME_PLAYBACK = 'mm:ss.SSS';
