import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AudioPlayerWidgetComponent } from '../../audio-player/audio-player-widget/audio-player-widget.component';
import { AudioPlayerComponent } from '../../audio-player/component/audio-player.component';

@Component({
  selector: 'abcdj-grid-app-layout',
  templateUrl: './app-grid-layout.component.html',
  styleUrls: ['./app-grid-layout.component.scss'],
  standalone: true,
  imports: [RouterOutlet, AudioPlayerWidgetComponent, AudioPlayerComponent],
})
export class AppGridLayoutComponent {
}
