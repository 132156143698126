import { Injectable } from '@angular/core';
import { parseBlob } from 'music-metadata-browser';
import { BaseAudioMetadata } from '../track-upload/track-upload.interfaces';

@Injectable({ providedIn: 'root' })
export class FileService {
  async parseAudioFile(file: File): Promise<BaseAudioMetadata> {
    const t0 = new Date().getTime();
    console.debug(`Parsing ${file.name} of type ${file.type}`);
    const rawMetadata = await parseBlob(file);
    const t1 = new Date().getTime();
    const duration = t1 - t0;

    console.debug(`Completed parsing of ${file.name} in ${duration} ms`, rawMetadata);

    const { title, artist, album, year, isrc, label, copyright, media, picture } = rawMetadata.common;
    const artwork = picture?.[0] ? { format: picture[0].format, data: picture[0].data.toString('base64') } : null;
    const parsedMetadata: BaseAudioMetadata = { title, artist, album, releaseYear: year, isrc: isrc?.[0], label: label?.[0], publisher: copyright, source: media, artwork };

    // heardis specific tags
    if (rawMetadata.format.tagTypes.includes('vorbis')) {
      const nativeMetadata = rawMetadata?.native?.vorbis || [];
      if (!parsedMetadata.label) {
        const organization = nativeMetadata.find((tag) => tag.id === 'ORGANIZATION');
        parsedMetadata.label = organization?.value;
      }
      if (!parsedMetadata.source) {
        const source = nativeMetadata.find((tag) => tag.id === 'MEDIATYPE');
        parsedMetadata.source = source?.value;
      }
    }

    return parsedMetadata;
  }
}
