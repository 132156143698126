import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[hdisResizableDrawer]',
  standalone: true,
})
export class ResizableDrawerDirective implements AfterViewInit {
  dragging = false;

  containerElement;

  drawerElement;

  contentElement;

  isLeftDrawer = true;

  initialWidth;

  initialOffset = 0;

  defaultWidth = 0;

  @Input() resizableGrabWidth = 6;

  @Input() drawerMinWidth = 300;

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    const baseSelector = this.el.nativeElement.tagName === 'MAT-SIDENAV-CONTAINER' ? 'mat-sidenav' : 'mat-drawer';
    this.containerElement = this.el.nativeElement;
    this.drawerElement = this.el.nativeElement.querySelector(baseSelector);
    this.contentElement = this.el.nativeElement.querySelector(`${baseSelector}-content`);

    // start is default, so left could be both 'start' or empty
    this.isLeftDrawer = this.drawerElement.getAttribute('position') !== 'end';

    document.addEventListener('mousemove', this.mouseMoveG, true);
    document.addEventListener('mouseup', this.mouseUpG, true);
    this.drawerElement.addEventListener('dblclick', this.resetWidth, true);
    this.drawerElement.addEventListener('mousedown', this.mouseDown, true);
    this.drawerElement.addEventListener('mousemove', this.mouseMove, true);
  }

  preventGlobalMouseEvents() {
    document.body.style['pointer-events'] = 'none';
    this.drawerElement.style['pointer-events'] = 'auto';
  }

  restoreGlobalMouseEvents() {
    document.body.style['pointer-events'] = 'auto';
  }

  newWidth = (wid) => {
    const newWidth = Math.min(Math.max(this.drawerMinWidth, wid), this.containerElement.offsetWidth);
    this.drawerElement.style.width = `${newWidth}px`;
    this.contentElement.style[this.isLeftDrawer ? 'marginLeft' : 'marginRight'] = `${newWidth}px`;
  };

  mouseMoveG = (evt) => {
    if (!this.dragging) {
      return;
    }
    const newWidth = this.isLeftDrawer ? evt.clientX - this.initialOffset : this.initialWidth - (evt.clientX - this.initialOffset);
    this.newWidth(newWidth);
    evt.stopPropagation();
  };

  mouseUpG = (evt) => {
    if (!this.dragging) {
      return;
    }
    this.restoreGlobalMouseEvents();
    this.dragging = false;
    evt.stopPropagation();
  };

  resetWidth = (evt) => {
    // if we double click on the draggable border and it was ever moved, reset to default width
    if (this.inDragRegion(evt) && this.defaultWidth) {
      this.newWidth(this.defaultWidth);
    }
  };

  mouseDown = (evt) => {
    if (this.inDragRegion(evt)) {
      this.dragging = true;

      /*
      first time we move the drawer we store its width to be able to reset on double click.
      getting the size after init seems to be unreliable
      */
      if (!this.defaultWidth) this.defaultWidth = this.drawerElement.offsetWidth;

      /*
      store values before starting moving to properly calculate the new width when moving.
      usage of position relative and absolute by mat-drawer makes hard to get the right values in other ways
      */
      this.initialWidth = this.drawerElement.offsetWidth;
      this.initialOffset = this.isLeftDrawer ? evt.clientX - this.drawerElement.offsetWidth : evt.clientX;

      /*
      the following is needed to avoid weird effects due to mouse events triggered by other
      elements in the background while moving, however this interfere with double click for
      reset size so we comment it out for the time being
      */
      this.preventGlobalMouseEvents();
      // evt.stopPropagation();
    }
  };

  mouseMove = (evt) => {
    if (this.inDragRegion(evt) || this.dragging) {
      this.drawerElement.style.cursor = 'col-resize';
    } else {
      this.drawerElement.style.cursor = 'default';
    }
  };

  inDragRegion(evt) {
    // targetNode is mat-drawer since it is called only in callbacks of this.drawerElement
    if (this.isLeftDrawer) {
      // last n pixels where n is this.resizableGrabWidth
      return evt.offsetX > this.drawerElement.offsetWidth - this.resizableGrabWidth;
    }
    // first n pixels where n is this.resizableGrabWidth
    return evt.offsetX < this.resizableGrabWidth;
  }
}
