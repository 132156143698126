import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, Optional, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { AudioEditExplicitType, AudioEditType, TrackEditQuery } from '@heardis/api-contracts';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FieldValue } from '../../_models';
import { BaseComponent, EDIT_CONTEXT, EditContextType, I18N_CONTEXT } from '../../common';

type AssetFormType = {
  cutThreshold: number;
  cutStrictness:'must' | 'should';
  explicitThreshold: number;
  explicitStrictness: 'must' | 'should';
};

@Component({
  selector: 'hdis-asset-picker',
  templateUrl: './asset-picker.component.html',
  styleUrls: ['./asset-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, MatTabsModule, MatButtonToggleModule, MatSliderModule, MatRadioModule],
})
export class AssetPickerComponent extends BaseComponent {
  cutLevels: FieldValue[] = [
    { value: AudioEditType.STANDARD, label: AudioEditType.STANDARD },
    { value: AudioEditType.SHORT, label: AudioEditType.SHORT },
    { value: AudioEditType.RADIO, label: AudioEditType.RADIO },
  ];

  explicitLevels: FieldValue[] = [
    { value: null, label: 'explicit' },
    { value: AudioEditExplicitType.MILD, label: AudioEditExplicitType.MILD },
    { value: AudioEditExplicitType.CLEAN, label: AudioEditExplicitType.CLEAN },
  ];

  @Input() title: string;

  @Input()
  get showCloseButton(): boolean { return this._showCloseButton; }

  set showCloseButton(value) { this._showCloseButton = coerceBooleanProperty(value); }

  _showCloseButton = false;

  @Input()
  get canShowInvalidValues(): boolean { return this._canShowInvalidValues; }

  set canShowInvalidValues(value) { this._canShowInvalidValues = coerceBooleanProperty(value); }

  _canShowInvalidValues = false;

  @Input('max')
  get maxSelection(): number { return this._maxSelection; }

  set maxSelection(value) { this._maxSelection = coerceNumberProperty(value); }

  _maxSelection: number;

  @Output() readonly selectionChange = new EventEmitter<TrackEditQuery['trackEdits']>();

  @Output() readonly opened = new EventEmitter<void>();

  @Output() readonly closed = new EventEmitter<TrackEditQuery['trackEdits']>();

  selected$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  available$: BehaviorSubject<FieldValue[]> = new BehaviorSubject([]);

  options$: Observable<{ value: string; visible: boolean }[]>;

  @ViewChild('filterInput') filterInput: ElementRef;

  filter = new UntypedFormControl('');

  showInvalidValues = false;

  isOpen = false;

  isReadonly = false;

  private snackbar = inject(MatSnackBar);

  fb = inject(FormBuilder);

  defaultValue: AssetFormType = {
    cutThreshold: 1,
    cutStrictness: 'should',
    explicitThreshold: 2,
    explicitStrictness: 'must',
  };

  form = this.fb.group<AssetFormType>(this.defaultValue);

  formSubscription: Subscription;

  constructor(
    @Optional() @Inject(I18N_CONTEXT) public i18nContext: string,
    @Optional() @Inject(EDIT_CONTEXT) public editContext: EditContextType,
  ) {
    super();

    this.formSubscription = this.form.valueChanges.subscribe((value: AssetFormType) => this.selectionChange.emit(this.toTrackEditQuery(value)));
    this.showCloseButton = editContext === 'panel';
    this.options$ =
      combineLatest([
        this.selected$,
        this.available$,
        this.filter.valueChanges.pipe(startWith('')),
      ]).pipe(
        map(([selected, available, autocomplete = '']) => {
          if (available.length) {
            const newOptions = available.map((option) => ({
              ...option,
              selected: selected.includes(option.value),
              visible: option.label.toLowerCase().includes(autocomplete.toLowerCase()),
            }));
            return newOptions;
          } if (this.maxSelection === 1) {
            return selected.map((val) => ({ value: val, visible: true, selected: true }));
          }
          return [];
        }),
      );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    console.log('destroy asset picker');
    this.formSubscription.unsubscribe();
    this.closed.emit(this.getCurrentValue());
  }

  open(current: TrackEditQuery['trackEdits']) {
    // first clean any possible dirty state by closing the previous picker
    this.close();

    // then updated the component props
    this.setSelected(current);
    this.isOpen = true;

    // finally emit the `opened` event to let subscribers (usually the directive) to properly subscribe
    this.opened.emit();
  }

  setSelected(current: TrackEditQuery['trackEdits']) {
    const { cut: { threshold: cutThreshold, strictness: cutStrictness } = {}, explicit: { threshold: explicitThreshold, strictness: explicitStrictness } = {} } = current;
    const cutThresholdIndex = this.cutLevels.findIndex((level) => level.value === cutThreshold);
    const explicitThresholdIndex = this.explicitLevels.findIndex((level) => level.value === explicitThreshold);

    // merge with default
    const value = { ...this.defaultValue };
    if (cutThresholdIndex > -1) value.cutThreshold = cutThresholdIndex;
    if (cutStrictness) value.cutStrictness = cutStrictness;
    if (explicitThresholdIndex > -1) value.explicitThreshold = explicitThresholdIndex;
    if (explicitStrictness) value.explicitStrictness = explicitStrictness;

    this.form.patchValue(value, { emitEvent: false });
  }

  close() {
    this.isOpen = false;
    this.closed.emit(this.getCurrentValue());
  }

  private getCurrentValue() {
    return this.toTrackEditQuery(this.form.value as AssetFormType);
  }

  cutLabel = (index: number) => this.cutLevels.at(index).label;

  explicitLabel = (index: number) => this.explicitLevels.at(index).label;
  // setSelected(current: TrackEditQuery['trackEdits']) {

  //   let next: any[] = [];
  //   if (selected) next = Array.isArray(selected) ? selected : [selected];

  //   this.selected$.next(next);
  // }

  private toTrackEditQuery(value: AssetFormType): TrackEditQuery['trackEdits'] {
    return {
      cut: {
        threshold: this.cutLevels[value.cutThreshold].value,
        strictness: value.cutStrictness,
      },
      explicit: {
        threshold: this.explicitLevels[value.explicitThreshold].value,
        strictness: value.explicitStrictness,
      },
      whitelist: [],
      blacklist: [],
    };
  }
}
