import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AudioPlayerStoreEffects } from './audio-player.effects';
import { audioPlayerTrackReducer } from './audio-player.reducer';
import { featureKey } from './audio-player.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureKey, audioPlayerTrackReducer),
    EffectsModule.forFeature([AudioPlayerStoreEffects]),
  ],
  providers: [AudioPlayerStoreEffects],
})
export class AudioPlayerStoreModule { }
