/* eslint-disable no-underscore-dangle */
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { Observable, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { FieldValue } from '../_models';

export type ValueSelectorAutocompleteFn = (filter: string) => Observable<FieldValue[]>;

export type ValueSelectorSortType = 'ascending' | 'descending' | 'none';

@Component({
  selector: 'hdis-value-selector',
  templateUrl: './value-selector.component.html',
  styleUrls: ['./value-selector.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ValueSelectorComponent), multi: true },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatLegacyFormFieldModule, MatLegacyChipsModule, NgFor, NgIf, MatIconModule, MatLegacyInputModule, ReactiveFormsModule, MatLegacyAutocompleteModule, MatLegacyOptionModule, AsyncPipe],
})
export class ValueSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;

  @Input() hint: string;

  @Input('selectorMaxSelection')
  get maxSelection(): number { return this._maxSelection; }

  set maxSelection(value) { this._maxSelection = coerceNumberProperty(value); }

  _maxSelection: number;

  @Input('selectorAllowNew')
  get allowNew(): boolean { return this._allowNew; }

  set allowNew(value) { this._allowNew = coerceBooleanProperty(value); }

  _allowNew = false;

  @Input('selectorDebounceTime')
  get debounceTime(): number { return this._debounceTime; }

  set debounceTime(value) { this._debounceTime = coerceNumberProperty(value, 500); }

  _debounceTime = 500;

  @Input('selectorMinAutocompleteLength')
  get minAutocompleteLength(): number { return this._minAutocompleteLength; }

  set minAutocompleteLength(value) { this._minAutocompleteLength = coerceNumberProperty(value, 3); }

  _minAutocompleteLength = 3;

  @Input()
  get required(): boolean { return this._required; }

  set required(value) { this._required = coerceBooleanProperty(value); }

  _required = false;

  @Input() selectorSort: ValueSelectorSortType = 'none';

  @Input() selectorAutocompleteFn: ValueSelectorAutocompleteFn;

  @Output() selectorOnError = new EventEmitter<string>();

  // FORM VALUE ACCESSOR PROPS
  propagateFn: (_: any) => void;

  isDisabled: boolean;

  // MAT CHIPS PROPS
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  visible = true;

  isSelectable = true;

  isRemovable = true;

  selectedValues: string[] = [];

  // MAT AUTOCOMPLETE PROPS
  @ViewChild('autocompleteInput') autocompleteInput: ElementRef<HTMLInputElement>;

  inputControl = new UntypedFormControl();

  filteredOptions: Observable<FieldValue[]>;

  currentOptions: FieldValue[];

  isLoading = false;

  debounce = 500;

  constructor(
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.filteredOptions = this.inputControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(this.debounceTime), // debounce requests to backend while typing
        switchMap((value) => (value?.length >= this.minAutocompleteLength ? this.selectorAutocompleteFn(value || '') : of([]))),
        map((fieldValues) => fieldValues.slice(0, 50)), // keep a reasonable amount of value to not hit too much the performance
        tap((fieldValues) => { this.currentOptions = fieldValues; }),
      );
  }

  // NG VALUE ACCESSOR METHODS
  writeValue(values: any): void {
    const inputValues = values || [];
    // when maxSelection is set to 1, a single value is passed, otherwise an array is passed
    if (this.maxSelection === 1) {
      this.selectedValues = values ? [values] : [];
    } else {
      this.selectedValues = values || [];
    }
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  // MAT CHIPS METHODS
  /**
   * called when user add item from input text
   */
  add(event: MatChipInputEvent): void {
    const { value } = event;

    if (!this.maxSelection || this.selectedValues.length < this.maxSelection) {
      const newValue = (value || '').trim();

      if (this.allowNew || this.currentOptions.findIndex((opt) => opt.value === newValue || opt.label === newValue) !== -1) {
        // Add our value
        if (newValue) {
          this.updateSelectedValues(newValue);
        }

        // Reset the input value
        if (event.chipInput.inputElement) {
          // eslint-disable-next-line no-param-reassign
          event.chipInput.inputElement.value = '';
        }
        this.propagateFn((this.maxSelection === 1) ? this.selectedValues[0] : this.selectedValues);
      } else {
        this.selectorOnError.emit(`only existing values are allowed. Value '${newValue}' is not`);
      }
    } else {
      this.selectorOnError.emit(`max ${this.maxSelection} selected values allowed`);
    }
  }

  remove(value: string): void {
    const index = this.selectedValues.indexOf(value);

    if (index >= 0) {
      this.selectedValues.splice(index, 1);
    }
    this.propagateFn((this.maxSelection === 1) ? this.selectedValues[0] || null : this.selectedValues);
  }

  // MAT AUTOCOMPLETE METHODS
  displayFn(field: FieldValue): string {
    return field && field.label ? field.label : '';
  }

  /**
   * called when user add item from the autocomplete dropdown
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.maxSelection || this.selectedValues.length < this.maxSelection) {
      this.updateSelectedValues(event.option.viewValue);
      this.autocompleteInput.nativeElement.value = '';
      this.inputControl.setValue('');
      this.propagateFn((this.maxSelection === 1) ? this.selectedValues[0] : this.selectedValues);
    } else {
      this.selectorOnError.emit(`max ${this.maxSelection} selected values allowed`);
    }
  }

  updateSelectedValues(newValue: string) {
    const newValues = [...this.selectedValues, newValue];

    if (this.selectorSort === 'ascending') {
      this.selectedValues = newValues.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    } else if (this.selectorSort === 'descending') {
      this.selectedValues = newValues.sort((a, b) => b.toLowerCase().localeCompare(a.toLowerCase()));
    } else {
      this.selectedValues = newValues;
    }
  }
}
