import { RootStoreModule } from './root-store.module';
// import * as RootStoreSelectors from './selectors';
import * as RootStoreState from './state';
export * from './bookmarks';

export {
  RootStoreState,
  //  RootStoreSelectors,
  RootStoreModule
};
