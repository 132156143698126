import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, NgZone, OnDestroy, ViewChild } from '@angular/core';
import AudioPlayerEventTarget from '../audio-player-event-target';
import { AudioPlayerStatus } from '../audio-player.interfaces';
import { AudioPlayerService } from '../audio-player.service';

@Component({
  selector: 'hdis-audio-player-progress-bar',
  templateUrl: './audio-player-progress-bar.component.html',
  styleUrls: ['./audio-player-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AudioPlayerProgressBarComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class') class = 'hdis-audio-player-progress-bar';

  @Input() status: AudioPlayerStatus;

  get canPlay() { return this.status === AudioPlayerStatus.NOT_PLAYING || this.status === AudioPlayerStatus.PLAYING; }

  // audioRef: HTMLMediaElement;

  // playerRef: WaveSurfer;

  playerTarget: AudioPlayerEventTarget;

  progressListener;

  @ViewChild('progress') progressRef: ElementRef;

  constructor(
    public playerService: AudioPlayerService,
    private zone: NgZone,
  ) {
    this.playerTarget = playerService.getTarget();
  }

  private updateTargetProgress = (event: CustomEvent) => {
    this.progressRef.nativeElement.style.width = (event.detail.progress && event.detail.duration) ? `${(event.detail.progress / event.detail.duration) * 100}%` : '0%';
  };

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      /**
       * apparently there's no need to throttle as the user agent should already take care of this
       * as per MDN docs at https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/timeupdate_event
       */
      // this.progressListener = this.audioRef.addEventListener('timeupdate', this.updateProgress);
      // this.progressListener = this.playerRef.on('audioprocess', this.updateWavesurfProgress);

      this.playerTarget.addEventListener('progress', this.updateTargetProgress);
    });
  }

  ngOnDestroy(): void {
    // this.audioRef.removeEventListener('timeupdate', this.updateProgress);
    // this.audioRef.removeEventListener('audioprocess', this.updateWavesurfProgress);
    this.playerTarget.removeEventListener('progress', this.updateTargetProgress);
  }

  seek(event) {
    if (!this.canPlay) return;
    const current = event.offsetX;
    const total = event.currentTarget.clientWidth;
    const percent = Math.min(Math.max(0, current / total), 1);
    this.playerService.seekPercent(percent);
  }
}
