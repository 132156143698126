import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Audio, AudioBeatgridConfig, AudioEdit, AudioEditEffect, AudioEditRule, AudioEditStatus, AudioEditType } from '@heardis/api-contracts';
import { randomHexColor, randomId } from '@heardis/hdis-ui';
import { SongService } from '../../../_services/song.service';

@Injectable({
  providedIn: 'root',
})
export class TrackEditorFormService {
  constructor(
    private fb: UntypedFormBuilder,
    private songService: SongService,
  ) {}

  effectToForm(defaultValue?: AudioEditEffect) {
    const effectForm = this.fb.group(
      {
        type: this.fb.control(null),
        start: this.fb.control(null),
        end: this.fb.control(null),
        length: this.fb.control(null),
        beats: this.fb.control(null),
      },
      { validators: this.effectFormValidation },
    );

    if (defaultValue) effectForm.patchValue(defaultValue, { emitEvent: false });
    return effectForm;
  }

  ruleToForm(defaultValue?: Partial<AudioEditRule>) {
    const { preEffect, effect, postEffect } = defaultValue;
    return this.fb.group(
      {
        id: this.fb.control(defaultValue?.id || randomId(), [Validators.required]),
        label: this.fb.control(defaultValue?.label || 'rule', [Validators.required]),
        color: this.fb.control(defaultValue?.color || '#808080'),
        enabled: this.fb.control(defaultValue?.enabled ?? true),
        readonly: this.fb.control(defaultValue?.readonly ?? false),
        preEffect: this.effectToForm(preEffect),
        effect: this.effectToForm(effect),
        postEffect: this.effectToForm(postEffect),
      },
      { validators: this.ruleFormValidation },
    );
  }

  createEdit({ name, type, explicit, notes, rules }: Partial<AudioEdit>): AudioEdit {
    const id = randomId();
    return {
      id,
      type,
      explicit,
      status: AudioEditStatus.DRAFT,
      name: name || `edit ${id}`,
      rules: rules || [],
      notes,
    };
  }

  createRule({ label, enabled, color, readonly, preEffect, effect, postEffect }: Partial<AudioEditRule>): AudioEditRule {
    const id = randomId();
    return {
      id,
      label: label || `rule ${id}`,
      enabled: enabled ?? true,
      readonly: readonly ?? false,
      color: color || randomHexColor(),
      preEffect,
      effect,
      postEffect,
    };
  }

  editToForm(edit: AudioEdit) {
    // new or empty edits, add an empty rule for convenience
    const rulesForm = (edit.rules.length) ? edit.rules.map((rule) => this.ruleToForm(rule)) : [this.ruleToForm(this.createRule({ label: 'rule 1', color: '#808080' }))];
    return this.fb.group(
      {
        id: this.fb.control(edit.id),
        status: this.fb.control(edit.status),
        type: this.fb.control(edit.type),
        explicit: this.fb.control(edit.explicit),
        name: this.fb.control(edit.name, [Validators.required]),
        notes: this.fb.control(edit.notes),
        rules: this.fb.array(rulesForm, { validators: this.rulesFormValidation, updateOn: 'blur' }),
      },
      { validators: [this.editValidator] },
    );
  }

  editsToForm(edits: [string, AudioEdit][]) {
    return this.fb.group(
      edits
        .filter(([id, edit]) => edit) // ignore nullish edits
        .reduce((editControls, [currEditId, currEdit]) => ({
          ...editControls,
          [currEditId]: this.editToForm(currEdit),
        }), {}),
      { validators: this.editsFormValidation },
    );
  }

  createBeatgridSectionForm(beatgridSection: AudioBeatgridConfig) {
    const beatgridForm = this.fb.group({
      auto: this.fb.control(beatgridSection.auto),
      beats: this.fb.control(beatgridSection.beats),
      bpm: this.fb.control(beatgridSection.bpm),
      startTime: this.fb.control(beatgridSection.startTime),
      endTime: this.fb.control(beatgridSection.endTime),
      timeSignature: this.fb.control(beatgridSection.timeSignature),
      referenceDownbeat: this.fb.control(beatgridSection.referenceDownbeat),
    });
    return beatgridForm;
  }

  beatgridSectionsToForm(beatgridSections: AudioBeatgridConfig[]) {
    return this.fb.array(beatgridSections.map((beatgridSection) => this.createBeatgridSectionForm(beatgridSection)));
  }

  trackToForm(track: Audio) {
    const emptyEdit = this.createEdit({ name: 'Standard', type: AudioEditType.STANDARD });
    const edits: [string, AudioEdit][] = track.editor?.edits ? Object.entries(track.editor.edits) : [[emptyEdit.id, emptyEdit]];
    // either map the existing track edits or create an empty one for convenience
    const editsForm = this.editsToForm(edits);
    const beatgridConfig = this.songService.getTrackBeatgridConfig(track);
    const beatgridForm = this.beatgridSectionsToForm(beatgridConfig);

    return this.fb.group(
      {
        edits: editsForm,
        beatgrid: beatgridForm,
      },
    );
  }

  /** @TODO Lukas: This should probably been splitted in multiple validators */
  effectFormValidation(effect: UntypedFormGroup): ValidationErrors | null {
    const { start, end, length } = effect.getRawValue();
    const trackStart = 0;
    const tracklength = 3600; // 60 Min.

    if (start === null || end === null) return null;

    if (start > end) return { startHigherThanEnd: { value: start - end } };
    if (start < trackStart) return { startLowerThanTrackStart: { value: start } };
    if (start > tracklength) return { startHigherThanTracklength: { value: start - tracklength } };

    if (end < start) return { endLowerThanStart: { value: start - end } };
    if (end < trackStart) return { endLowerThanTrackStart: { value: end } };
    if (end > tracklength) return { endHigherThanTracklength: { value: end - tracklength } };

    if (length === null) return null;
    if (end - start !== length) return { durationLengthWrong: { value: end - start } };
    /** @TODO need to do a check on the beats length */

    return null;
  }

  /** @TOASK Don't know if necessary. If the form is already handled properly. */
  ruleFormValidation(rule: UntypedFormGroup): ValidationErrors | null {
    const { preEffect, effect, postEffect }: AudioEditRule = rule.getRawValue();

    const checkIfEffectIsEdited = (editEffect: AudioEditEffect) => {
      const { type, ...effectValues } = editEffect;
      return Object.values(effectValues).some((effectValue) => effectValue !== null);
    };
    const preEffectIsEdited = checkIfEffectIsEdited(preEffect);
    const effectIsEdited = checkIfEffectIsEdited(effect);
    const postEffectIsEdited = checkIfEffectIsEdited(postEffect);

    if (preEffectIsEdited && effectIsEdited && preEffect.end !== effect.start) return { fadeoutEndDontMatchCutStart: { value: Math.abs(effect.start - preEffect.end) } };
    if (postEffectIsEdited && effectIsEdited && postEffect.start !== effect.end) return { fadeinStartDontMatchCutEnd: { value: Math.abs(postEffect.start - effect.end) } };
    if (preEffectIsEdited && postEffectIsEdited && !effectIsEdited) return { allEditedExceptCut: true };

    return null;
  }

  rulesFormValidation(rules: UntypedFormArray): ValidationErrors | null {
    /**
     * @TODO @Lukas this is not ready yet.
     * It doesn't make sense to compare only the the current with the previous. (only if they are always ordered)
     * @TOASK @Stefano there is a 'setError' function on a control.
     * Is this something I can use? Because then I can set the error on a specific rule.
     */
    // const rulesValue = rules.value;
    // const errors = [];

    // rulesValue.reduce((previousRule, currentRule, index) => {
    //   if (!previousRule) return null;
    //   const { effects: previousEffects, label: previousLabel } = previousRule;
    //   const { effects: currentEffects, label: currentLabel } = currentRule;

    //   const currentEffectsErrors = currentEffects.some((currentEffect) => {
    //     const { start: currentEffectStart, end: currentEffectEnd } = currentEffect;
    //     const [prevFadeout, prevCut, prevFadein] = previousEffects;

    //     if (currentEffectStart === null) return false;
    //     if (prevFadeout.end !== null && prevFadeout.start !== null) {
    //       if (currentEffectStart < prevFadeout.end && currentEffectStart > prevFadeout.start) return true;
    //       if (currentEffectEnd < prevFadeout.end && currentEffectEnd > prevFadeout.start) return true;
    //     }
    //     if (prevCut.end !== null && prevCut.start !== null) {
    //       if (currentEffectStart < prevCut.end && currentEffectStart > prevCut.start) return true;
    //       if (currentEffectEnd < prevCut.end && currentEffectStart > prevCut.start) return true;
    //     }
    //     if (prevFadein.end !== null && prevFadein.start !== null) {
    //       if (currentEffectStart < prevFadein.end && currentEffectStart > prevFadein.start) return true;
    //       if (currentEffectEnd < prevFadein.end && currentEffectEnd > prevFadein.start) return true;
    //     }

    //     return false;
    //   });

    //   if (currentEffectsErrors) {
    //     errors.push({ currentRuleIndex: index + 1, message: `${currentLabel} has overlapping effects with ${previousLabel}` });
    //   }

    //   return currentRule;
    // });

    // if (errors.length) return { rulesOverlap: errors };
    return null;
  }

  /** @TODO can definitly be improved */
  editsFormValidation(edits: UntypedFormGroup): ValidationErrors | null {
    const allEditsControls = Object.entries(edits.controls).map(([_key, value]) => value);
    const allEditsValues = Object.entries(edits.value).map(([key, value]: [key: string, value: AudioEdit]) => ({ id: key, name: value.name }));

    function tagsAlreadyInUse(currentEdit, otherEditsTags) {
      if (!currentEdit.length || !otherEditsTags.length) return false;
      if (currentEdit.length !== otherEditsTags.length) return false;

      return currentEdit.every((tag) => otherEditsTags.includes(tag));
    }

    const comparisonResult = allEditsControls.map((editControl) => {
      const { id, tags } = editControl.value;
      const editsToCompare = allEditsValues.filter((editValues) => editValues.id !== id);
      const comparison = editsToCompare?.map((editToCompare) => (tagsAlreadyInUse([], tags) ? editToCompare.id : false)).filter(Boolean);

      if (comparison.length) {
        /** @TOASK I am not sure if it is the right place to set an error here */
        const tagsAreAlreadyInUse = { errorMessage: `Edit tags are already in use in edits: ${comparison}`, id, editsWithSameTags: [...comparison] };
        editControl.setErrors({ tagsAreAlreadyInUse });
        return tagsAreAlreadyInUse;
      }
      editControl.setErrors(null);
      return null;
    });

    if (comparisonResult.filter(Boolean).length) return { tagsAreAlreadyInUse: { editsWithError: comparisonResult } };

    return null;
  }

  editValidator = (edit: UntypedFormGroup): ValidationErrors | null => ((edit.value.explicit && !edit.value.type) ? { explicitMustDefineType: true } : null);
}
