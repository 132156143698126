import { State, key, NavigationPagination } from './state';
import { State as AppState } from '../state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectFeature = createFeatureSelector<State>(key);

export const getPagination = (currentId: string) => createSelector(
  selectFeature,
  (state: State) => {
    const pagination: NavigationPagination = { current: null, previous: null, next: null };
    const currIndex = state.collection.indexOf(currentId);
    if (currIndex !== -1) {
      pagination.current = currentId;
      if (currIndex > 0) pagination.previous = state.collection[currIndex - 1];
      if (currIndex < state.collection.length - 1) pagination.next = state.collection[currIndex + 1];
    }
    return pagination;
  });

export const getNext = createSelector(
  selectFeature,
  (state: State) => state.currentIndex < state.collection.length - 1 ? state.collection[state.currentIndex + 1] : ''
);
