<div fxLayout="column" fxFlexFill class="enum-picker-wrapper padded-content" *ngIf="isOpen">
  <h3 *ngIf="title || showCloseButton">
    <button mat-icon-button *ngIf="showCloseButton" (click)="close()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    {{title}}
  </h3>
  <mat-form-field fxFlex="none" class="values-quick-filter">
    <input #filterInput matInput placeholder="filter values" [formControl]="filter">
    <mat-slide-toggle *ngIf="canShowInvalidValues" [color]="accent" (change)="loadValues($event.checked)" matSuffix>
      show invalid values</mat-slide-toggle>
  </mat-form-field>
  <ng-container *ngIf="maxSelection !== 1">
    <div fxFlex="0 1 auto" fxLayout="row wrap" fxLayoutAlign=" start" class="checkbox-container"
      *ngIf="selected$ | async as selectedValues">
      <mat-checkbox *ngFor="let selectedValue of selectedValues trackBy selectedValue" color="primary"
        [disabled]="isReadonly" [value]="selectedValue" [checked]="true" [title]="selectedValue"
        (change)="clickCheckbox(selectedValue, {checked: false})">
        {{selectedValue}}
      </mat-checkbox>
      <span *ngIf="(maxSelection === 1 && !selectedValues) || (maxSelection !== 1 && !selectedValues.length)">No
        selected value</span>
    </div>
    <mat-divider></mat-divider>
  </ng-container>
  <div fxFlex="1 1 auto" fxLayout="row wrap" fxLayoutAlign=" start" class="checkbox-container scrollable-content"
    *ngIf="options$ | async as options">
    <mat-checkbox *ngFor="let option of options trackBy optionId" [disabled]="isReadonly"
      [color]="maxSelection !== 1 ? 'accent' : 'primary'" [checked]="option.selected"
      [ngClass]="{'hidden': !option.visible}" [title]="option.value" (change)="clickCheckbox(option.value, $event)">
      {{option.label}}
    </mat-checkbox>
    <!-- TODO length is not reduced. should filter on visible prop -->
    <span *ngIf="!options.length && !fieldsLoading">No available values
      {{filter.value && 'matching the filter'}}
    </span>
    <span *ngIf="!options.length && fieldsLoading">Loading fields... </span>
  </div>
</div>
