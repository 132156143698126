<div fxLayout="column" fxFlexFill class="wheel-picker-wrapper padded-content">
  <h3>
    <button *ngIf="title && showCloseButton" mat-flat-button (click)="close()">
      <mat-icon>chevron_left</mat-icon>
      {{title}}
    </button>
    <button *ngIf="!title && showCloseButton" mat-icon-button (click)="close()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <ng-container *ngIf="title && !showCloseButton">
      {{title}}
    </ng-container>
  </h3>
  <div fxFlex="auto">
    <canvas #wheel></canvas>
  </div>
</div>
