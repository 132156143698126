<ng-container>
  <mat-form-field (click)="datepicker.open()">
    <mat-label>{{dateLabel}}</mat-label>
    <input matInput [(ngModel)]="currentDate" (ngModelChange)="onChange()" placeholder="date" [matDatepicker]="datepicker" [min]="minDate" [max]="maxDate" [disabled]="readonly">
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field *ngIf="!hideTime">
    <mat-label>{{timeLabel}}</mat-label>
    <input matInput [(ngModel)]="currentTime" (ngModelChange)="onChange()" placeholder="time" type="time" [matAutocomplete]="startTime" [disabled]="readonly">
    <mat-autocomplete #startTime="matAutocomplete">
      <mat-option value="00:00">00:00</mat-option>
      <mat-option value="00:30">00:30</mat-option>
      <mat-option value="01:00">01:00</mat-option>
      <mat-option value="01:30">01:30</mat-option>
      <mat-option value="02:00">02:00</mat-option>
      <mat-option value="02:30">02:30</mat-option>
      <mat-option value="03:00">03:00</mat-option>
      <mat-option value="03:30">03:30</mat-option>
      <mat-option value="04:00">04:00</mat-option>
      <mat-option value="04:30">04:30</mat-option>
      <mat-option value="05:00">05:00</mat-option>
      <mat-option value="05:30">05:30</mat-option>
      <mat-option value="06:00">06:00</mat-option>
      <mat-option value="06:30">06:30</mat-option>
      <mat-option value="07:00">07:00</mat-option>
      <mat-option value="07:30">07:30</mat-option>
      <mat-option value="08:00">08:00</mat-option>
      <mat-option value="08:30">08:30</mat-option>
      <mat-option value="09:00">09:00</mat-option>
      <mat-option value="09:30">09:30</mat-option>
      <mat-option value="10:00">10:00</mat-option>
      <mat-option value="10:30">10:30</mat-option>
      <mat-option value="11:00">11:00</mat-option>
      <mat-option value="11:30">11:30</mat-option>
      <mat-option value="12:00">12:00</mat-option>
      <mat-option value="12:30">12:30</mat-option>
      <mat-option value="13:00">13:00</mat-option>
      <mat-option value="13:30">13:30</mat-option>
      <mat-option value="14:00">14:00</mat-option>
      <mat-option value="14:30">14:30</mat-option>
      <mat-option value="15:00">15:00</mat-option>
      <mat-option value="15:30">15:30</mat-option>
      <mat-option value="16:00">16:00</mat-option>
      <mat-option value="16:30">16:30</mat-option>
      <mat-option value="17:00">17:00</mat-option>
      <mat-option value="17:30">17:30</mat-option>
      <mat-option value="18:00">18:00</mat-option>
      <mat-option value="18:30">18:30</mat-option>
      <mat-option value="19:00">19:00</mat-option>
      <mat-option value="19:30">19:30</mat-option>
      <mat-option value="20:00">20:00</mat-option>
      <mat-option value="20:30">20:30</mat-option>
      <mat-option value="21:00">21:00</mat-option>
      <mat-option value="21:30">21:30</mat-option>
      <mat-option value="22:00">22:00</mat-option>
      <mat-option value="22:30">22:30</mat-option>
      <mat-option value="23:00">23:00</mat-option>
      <mat-option value="23:30">23:30</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>
