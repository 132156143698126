import { Pipe, PipeTransform } from '@angular/core';
import { ColDef } from '@ag-grid-community/core';
import { ICON_CELL_WIDTH, PREFIX_INTERNAL_COLUMN, RowSelectionMode } from '../table.interfaces';

/**
 * @deprecated columnDefs are now included via gridOptions object instead of via template.
 * Refer to the method in initColumnDefinitions in `template.component.ts`
 */
@Pipe({
  name: 'columnDefinitions',
  standalone: true,
})
export class ColumnDefinitionsPipe implements PipeTransform {
  transform(columns: ColDef[], selectionMode: RowSelectionMode, localize = true): ColDef[] {
    if (!columns?.length) return [];

    const definitions: ColDef[] = [];
    if (this.hasCheckbox(selectionMode)) {
      definitions.push({
        headerName: '',
        // disable 'select all' header checkbox because of ux inconsistency due to infinite scroll loading
        field: `${PREFIX_INTERNAL_COLUMN}CHECKBOX_SELECTION`,
        headerCheckboxSelection: false,
        checkboxSelection: true,
        resizable: false,
        suppressMovable: true,
        lockPosition: true,
        lockVisible: true,
        suppressSizeToFit: true,
        width: ICON_CELL_WIDTH,
        filter: false,
        cellClass: 'hdis-table__selection-checkbox-cell',
        headerClass: 'hdis-table__header-empty-cell',
      });
    }
    return [...definitions, ...columns];
  }

  private hasCheckbox(selectionMode: RowSelectionMode) {
    return selectionMode !== 'none';
  }
}
