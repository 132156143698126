import { RequestRange, RequestSort } from '@heardis/api-contracts';
import { Observable } from 'rxjs';
import { MetadataProvider } from './metadata-provider';
import { Response } from './response';

/**
 * Defines the contract for getting meta-data for entities.
 */
export abstract class EntityProvider<T> extends MetadataProvider {
  abstract getEntityRoute(entityId: string): string[];

  abstract getEntity(entityId: string, params?: { [s: string]: string }): Observable<T>;

  abstract searchEntities(filter: any, range?: RequestRange, sort?: RequestSort[]): Observable<Response<T>>;
}
