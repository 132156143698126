<div class="hdis-player-waveform-toolbar" *ngIf="waveform.mode === AudioPlayerWaveformViewMode.EXTENDED">
  <div class="hdis-player-waveform-toolbar-group">
    <div>
      playback speed
      <mat-slider min="0.1" max="3" step="0.1" color="primary">
        <input matSliderThumb [value]="playbackRate" (dragEnd)="changePlaybackRate($event.value)" #playbackRateInput>
      </mat-slider>
      {{playbackRateInput.value}}x
      <button mat-button (click)="changePlaybackRate(1)">reset</button>
    </div>

    &nbsp;

    <div>
      zoom
      <mat-slider [min]="zoomMin" [max]="zoomMax" [step]="zoomStep" color="primary">
        <input matSliderThumb [value]="waveform.zoom" (dragEnd)="changeZoom($event.value)" #zoomInput>
      </mat-slider>
      {{zoomInput.value}}x
      <button mat-button (click)="changeZoom(1)">reset</button>
    </div>

    &nbsp;

    <button mat-button (click)="setAutoscroll(!waveform.autoscroll)">
      <mat-icon [color]="waveform.autoscroll ? 'primary' : 'accent'">lock</mat-icon>
      autoscroll
    </button>

    <button mat-button (click)="copyCursorPositionToClipboard()">
      <mat-icon>content_paste</mat-icon>
      copy cursor position
    </button>
  </div>

  <div *ngIf="editor.isVisible" class="hdis-player-waveform-toolbar-group" >
    <button mat-button (click)="setSnapToGrid(!editor.snapToGrid)">
      <mat-icon [color]="editor.snapToGrid ? 'primary' : 'accent'">view_week</mat-icon>
      snap to grid
    </button>

    <button mat-button (click)="setPrelistening(!editor.prelistening)">
      <mat-icon [color]="editor.prelistening ? 'primary' : 'accent'">dynamic_form</mat-icon>
      prelistening
    </button>
  </div>
</div>

<div class="hdis-player-progress-bar">
  <div #beatgridEl class="hdis-player-beatgrid"></div>
  <div #waveformEl class="hdis-player-waveform" tabindex="0" (keydown)="onKeyDown($event)"  (scroll)="onScroll($event)"></div>
  <div #timelineEl class="hdis-player-timeline"></div>
  <div #minimapEl class="hdis-player-minimap"></div>
  <mat-progress-bar *ngIf="trackStatus === 'loading' || waveform.status === 'loading'" mode="buffer" [bufferValue]="bufferSize$ | async" class="hdis-player-loading-bar" />
</div>
