<input type="file" #fileInput style="display: none" (change)="onFileAdded()" />
<mat-form-field [floatLabel]="floatLabel">
  <mat-label>{{label}}</mat-label>
  <input class="input-placeholder" type="text" [placeholder]="placeholder" matInput [(ngModel)]="assetPath"
    (ngModelChange)="inputChange($event)">
  <span matSuffix>
    <mat-progress-spinner *ngIf="uploading && progress" mode="determinate" [value]="progress" diameter="20">
    </mat-progress-spinner>
    <mat-icon *ngIf="error" [matTooltip]="uploadMessage">error_outline</mat-icon>
    <button mat-icon-button (click)="addFile()">
      <mat-icon>upload</mat-icon>
    </button>
  </span>
  <mat-hint>max. 2 MB, formats: jpg, jpeg, png, gif, heif</mat-hint>
</mat-form-field>
