const hueToRgb = (t1, t2, hue) => {
  if (hue < 0) hue += 6;
  if (hue >= 6) hue -= 6;
  if (hue < 1) return (t2 - t1) * hue + t1;
  if (hue < 3) return t2;
  if (hue < 4) return (t2 - t1) * (4 - hue) + t1;
  return t1;
};
const hslToRgb = (initialHue, sat, light) => {
  let t2;
  const hue = initialHue / 60;
  if (light <= 0.5) {
    t2 = light * (sat + 1);
  } else {
    t2 = light + sat - (light * sat);
  }
  const t1 = light * 2 - t2;
  const r = hueToRgb(t1, t2, hue + 2) * 255;
  const g = hueToRgb(t1, t2, hue) * 255;
  const b = hueToRgb(t1, t2, hue - 2) * 255;
  return { r, g, b };
};

export const colorPalette = (size: number, saturation = 0.5, light = 0.9): string[] => {
  const radius = 360 / size;
  return Array(size).fill('').map((value, index) => {
    const rgb = hslToRgb(index * radius, saturation, light);
    return `rgb(${Math.round(rgb.r)}, ${Math.round(rgb.g)}, ${Math.round(rgb.b)})`;
  });
};

export const randomHexColor = () => `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0').toUpperCase()}`;
