/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { setPlayerTracklistVisibility } from '../../_state/view-preferences/view-preferences.actions';
import { AudioPlayerTracklistComponent } from '../audio-player-tracklist/audio-player-tracklist.component';
import { AudioPlayerMode, AudioPlayerStatus, AudioPlayerTrack, AudioPlayerTracklist } from '../audio-player.interfaces';
import { AudioPlayerService } from '../audio-player.service';
import { playNextTrack, playPreviousTrack, reloadCurrentTrack, setWaveformVisibility } from '../store/audio-player.actions';

@Component({
  selector: 'abcdj-audio-player-playback-controls',
  templateUrl: './audio-player-playback-controls.component.html',
  styleUrls: ['./audio-player-playback-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatMenuModule, MatIconModule, AudioPlayerTracklistComponent],
})
export class AudioPlayerPlaybackControlsComponent {
  @Input() status: AudioPlayerStatus;

  @Input() tracklist: AudioPlayerTracklist;

  @Input() currentTrack: AudioPlayerTrack;

  @Input() mode: AudioPlayerMode;

  private playerService = inject(AudioPlayerService);

  private store = inject(Store);

  AudioPlayerStatus = AudioPlayerStatus;

  AudioPlayerMode = AudioPlayerMode;

  get canInteract() { return this.status === AudioPlayerStatus.PLAYING || this.status === AudioPlayerStatus.NOT_PLAYING || this.status === AudioPlayerStatus.ERROR; }

  // if in editor mode, or track is not loaded, waveform cant be toggled
  get canToggleWaveform() { return this.mode !== AudioPlayerMode.EDITOR && this.status !== AudioPlayerStatus.EMPTY; }
  /**
     * triggered everytime angular checks the component view.
     * in this case, if this.zone.runOutsideAngular works as expected, we should not see
     * this console.log when the progress bar is updated (outside of angular) but only
     * when the user interacts with the controls
     */
  // ngAfterViewChecked(): void {
  //   console.log('checked the view');
  // }

  setWaveformVisibility = (makeVisible: boolean) => this.store.dispatch(setWaveformVisibility({ isVisible: makeVisible }));

  setTracklistVisibility = (makeVisible: boolean) => this.store.dispatch(setPlayerTracklistVisibility({ isVisible: makeVisible }));

  next = () => this.store.dispatch(playNextTrack());

  previous = () => this.store.dispatch(playPreviousTrack());

  togglePlayback = ($event: Event, currStatus: AudioPlayerStatus) => {
    switch (currStatus) {
      case AudioPlayerStatus.NOT_PLAYING:
        this.playerService.play();
        break;
      case AudioPlayerStatus.PLAYING:
        this.playerService.pause();
        break;
      case AudioPlayerStatus.ERROR:
        this.store.dispatch(reloadCurrentTrack());
        break;
      case AudioPlayerStatus.EMPTY:
      case AudioPlayerStatus.FATAL_ERROR:
      default:
        $event.preventDefault();
        break;
    }
  };
}
