import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';

@Component({
  selector: 'hdis-chip-picker',
  templateUrl: './chip-picker.component.html',
  styleUrls: ['./chip-picker.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ChipPickerComponent, multi: true }],
  standalone: true,
  imports: [MatLegacyChipsModule, NgFor],
})
export class ChipPickerComponent implements ControlValueAccessor {
  @Input() options: string[] = [];

  selectedOptions: boolean[] = [];

  selected: string[] = [];

  isDisabled = false;

  propagateFn: Function;

  writeValue(selected: string[]): void {
    this.selectedOptions = this.options.map((option) => selected.includes(option));
    this.selected = selected;
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  toggleSelection(index: number) {
    if (this.isDisabled) return;
    this.selectedOptions[index] = !this.selectedOptions[index];
    const selectedValues = this.options.filter((option, i) => this.selectedOptions[i]);
    this.propagateFn(selectedValues);
  }
}
