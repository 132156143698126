<h4 mat-dialog-title>{{title}}</h4>
<button class="close-drawer-button" mat-icon-button (click)="dialogRef.close()" tabindex="-1">
  <mat-icon>close</mat-icon>
</button>

<mat-dialog-content>
  <hdis-multi-upload fxFlex="auto" [endpoint]="endpoint" (uploadCompleted)="onUploadCompleted()">
  </hdis-multi-upload>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" (click)="dialogRef.close()">finish</button>
</mat-dialog-actions>
