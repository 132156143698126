import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UploadObjectStatus, UploadProgress } from './upload.interfaces';
import { adapter, featureKey, UploadState } from './upload.reducer';

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectFeatureState = createFeatureSelector<UploadState>(featureKey);

export const selectFiles = createSelector(
  selectFeatureState,
  selectEntities,
);
export const selectFile = (id: string) => createSelector(
  selectFiles,
  (files) => files[id],
);

export const selectAllFiles = createSelector(
  selectFeatureState,
  selectAll,
);

export const selectUploadableFiles = createSelector(
  selectAllFiles,
  (files) => files.filter((file) => file.status === UploadObjectStatus.Ready || file.status === UploadObjectStatus.Failed),
);

export const selectUploadingFiles = createSelector(
  selectAllFiles,
  (files) => files.filter((file) => file.status === UploadObjectStatus.Requested || file.status === UploadObjectStatus.Started),
);

export const isUploading = createSelector(
  selectUploadingFiles,
  (files) => files.length > 0,
);

export const selectOverallProgress = createSelector(
  selectUploadingFiles,
  (files) => {
    if (files.length) {
      return files.reduce(
        (overall, file) => {
          const updatedOverall = { ...overall };
          switch (file.status) {
            case UploadObjectStatus.Requested: {
              updatedOverall.total += file.file.size;
              break;
            }
            case UploadObjectStatus.Started: {
              updatedOverall.uploaded += file.uploaded;
              updatedOverall.total += file.file.size;
              break;
            }
            case UploadObjectStatus.Failed:
            case UploadObjectStatus.Completed: {
              updatedOverall.uploaded += file.file.size;
              updatedOverall.total += file.file.size;
              break;
            }
            default:
              console.warn(`unhandled upload progress status ${file.status}`);
          }
          return updatedOverall;
        },
        { uploaded: 0, total: 0 } as UploadProgress,
      );
    }
    return null;
  },
);
