import { ColDef } from '@ag-grid-community/core';
import { Field, FieldType } from '@heardis/api-contracts';
import { Observable } from 'rxjs';

/**
 * Defines the contract for getting meta-data for entities.
 */
export abstract class MetadataProvider {
  /**
   * Get the fields that can be used to search for entities. Tipically it's the published fields with some metafields for advanced querying.
   * It is used for example to get the pool tracks, profile tracks etc...
   */
  abstract getQueryFields(): Observable<Field[]>;

  /**
   * Get the available fields for the context-entity.
   */
  abstract getEntityFields(withMetaFields: boolean): Observable<Field[]>;

  /**
   * Get the available values for a given field.
   * @param fieldName
   */
  abstract getFieldValues(fieldName: string, keyword?: string, withInvalid?: boolean): Observable<FieldValue[]>;

  /**
   * Get the available conditions grouped by field type
   * @param fieldName
   */
  abstract getQueryConditions(): Observable<LocalizedFieldCondition[]>;

  /**
   * Get the array of available columns for tables showing the context-entity
   * @param fieldName
   */
  abstract getTableColumns(): Observable<ColDef[]>;
}

export interface FieldValue<T = any> {
  value: T;
  label: string;
}

/** @todo move to @heardis/api-contracts as the available rule types should be defined by the backend, while the frontend should only decorate them UI-wise with labels, tooltips etc, as defined in LocalizedFieldCondition */
export interface FieldCondition {
  id: string;
  type: string;
  // used to provide more information about the scope of the rule (e.g. global vs day vs time events in plg)
  icon?: string;
  criteria: {
    // the name of the field
    name?: string;
    // the base type of the field
    type?: FieldType;
    // field can have value only from a known list of values
    isEnum?: boolean;
    // can have multiple values
    isMulti?: boolean;
    // the field has no persisted counterpart (usually for advanced queries such as allStyles, referencePool etc...)
    isMeta?: boolean;
  }[];
}

export interface LocalizedFieldCondition extends FieldCondition {
  label: string;
  icon?: string;
  tooltip?: string;
}
