<ng-container [formGroup]="form">
  <div formArrayName="items" *ngFor="let timezoneForm of timezonesForm.controls; index as i">
    <ng-container [formGroupName]="i">
      <button *ngIf="timezonesForm.controls.length > 1" mat-icon-button class="abcdj-button"
        (click)="removeTimezone(i)">
        <mat-icon>{{Icons.ACTION_REMOVE}}</mat-icon>
      </button>

      <button mat-icon-button class="abcdj-button" (click)="toggleFavorite(i)"
        matTooltip="default url will not include the slug">
        <mat-icon>{{timezoneForm.get('isDefault').value ? Icons.ACTION_FAVORITE_SET : Icons.ACTION_FAVORITE_UNSET }}
        </mat-icon>
      </button>

      <stream-timezone-item fxFlex [formGroup]="timezoneForm"></stream-timezone-item>
    </ng-container>
  </div>
  <button mat-flat-button (click)="addTimezone()">
    <mat-icon inline>{{Icons.ACTION_ADD}}</mat-icon>
    add timezone
  </button>
</ng-container>
