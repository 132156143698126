<mat-form-field floatLabel="always">
  <mat-label>{{label}}</mat-label>
  <ng-container *ngIf="isBulk; then bulkEdit else singleEdit"></ng-container>

  <ng-template #singleEdit>
    <input type="text" matInput [placeholder]="placeholder" [formControl]="ctrl" [readonly]="readonly">
  </ng-template>

  <ng-template #bulkEdit>
    <input type="text" matInput [placeholder]="placeholder" [formControl]="ctrl" [matAutocomplete]="nameAutocomplete"
      [readonly]="readonly">
    <mat-autocomplete #nameAutocomplete="matAutocomplete" [displayWith]="optionLabel">
      <mat-option *ngFor="let option of bulkDefaultOptions" [value]="option.value">
        {{option.label}}
      </mat-option>
      <mat-option *ngFor="let option of options" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </ng-template>
</mat-form-field>
