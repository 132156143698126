import { AudioBeatgrid, AudioEditEffect, AudioEditRule, AudioPreset } from '@heardis/api-contracts';

export enum AudioPlayerMode {
  WIDGET = 'widget', // only visible in sidebar
  STANDARD = 'standard', // sidebar + compact waveform
  EXTENDED = 'extended', // sidebar + navigable waveform
  EDITOR = 'editor', // sidebar + navigable + editable waveform
}

export enum AudioPlayerTracklistViewMode {
  FLOATING = 'floating',
}

export enum AudioPlayerTrackStatus {
  /** stinks! this is not associated to tracks, but used by selectors as default */
  EMPTY = 'empty',

  /** reference to track was added */
  INIT = 'init',

  /** basic track info was loaded */
  INFO = 'info',

  /** track is being hydrated, fetching data from backend */
  LOADING = 'loading',

  /** track is hydrated and ready to play */
  READY = 'ready',

  /** some error while loading the track */
  ERROR = 'error',
}

export enum AudioPlayerTrackAssetType {
  /** use the source file */
  ORIGINAL = 'original',

  /** use the standard edit if available, fallback to original */
  STANDARD = 'standard',

  RADIO = 'radio',

  SHORT = 'short',

  /** could not identify the type */
  UNKNOWN = 'unknown',
}

export type AudioPlayerTrackAsset = {
  // asset id
  id: string;
  type:AudioPlayerTrackAssetType;
  beatgrid: AudioBeatgrid;
};

export enum AudioPlayerTrackSource {
  TRACK = 'track',
  INBOX = 'inbox',
}

export type AudioPlayerTrackInfo = {
  source: AudioPlayerTrackSource;
  id: string;
  title: string;
  artist: string;
  route: string[];
  assets: AudioPlayerTrackAsset[];
};

export type AudioPlayerTrackError = {
  id: string;
  error: unknown;
};

export type AudioPlayerTrackInit = AudioPlayerTrackInfo & {
  status: AudioPlayerTrackStatus.INIT;
};

export type AudioPlayerTrackReady = AudioPlayerTrackInfo & {
  status: AudioPlayerTrackStatus.READY;
};

export type AudioPlayerTrackFailed = AudioPlayerTrackInfo & {
  status: AudioPlayerTrackStatus.ERROR;
  error: unknown;
};

export type AudioPlayerTrack = AudioPlayerTrackInit | AudioPlayerTrackReady | AudioPlayerTrackFailed;

export interface AudioPlayerTracklist {
  isVisible: boolean;
  mode: AudioPlayerTracklistViewMode;
  hasPrevious: boolean;
  hasNext: boolean;
  history: AudioPlayerTrack[];
  next: AudioPlayerTrack[];
  queue: AudioPlayerTrack[];
}
export enum AudioPlayerFileStatus {
  EMPTY = 'empty',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
}

export enum AudioPlayerStatus {
  EMPTY = 'empty', // no track available
  LOADING = 'loading', // no track available
  NOT_PLAYING = 'notPlaying', // no track available
  PLAYING = 'playing', // no track available
  ERROR = 'error', // failed somewhere but can be recovered
  FATAL_ERROR = 'fatalError', // failed somewhere but can be recovered
}

export type AudioPlayerPlaybackStatus = 'init' | 'playing' | 'paused' | 'stopped';

export enum AudioPlayerWaveformViewMode {
  STANDARD = 'standard',
  EXTENDED = 'extended',
}

export type AudioPlayerWaveformStatus = 'init' | 'loading' | 'loaded' | 'error';
export type AudioPlayerWaveformSize = 'standard' | 'extended';

export interface AudioPlayerEditorEnabledEvent {
  type:'enabled';
}
export interface AudioPlayerEditorDisabledEvent {
  type:'disabled';
}
export interface AudioPlayerEditorNewRuleEvent {
  type:'newRule';
  preEffect?: AudioEditEffect;
  effect?: AudioEditEffect;
  postEffect?:AudioEditEffect;
}

export interface AudioPlayerEditorUpdateRuleEvent {
  type:'updateRule';
  rule: AudioEditRule;
}

export interface AudioPlayerEditorRemoveRuleEvent {
  type:'removeRule';
  rule: AudioEditRule;
}

export type AudioPlayerEditorEvent = AudioPlayerEditorEnabledEvent | AudioPlayerEditorDisabledEvent | AudioPlayerEditorNewRuleEvent | AudioPlayerEditorUpdateRuleEvent | AudioPlayerEditorRemoveRuleEvent;

export type AudioPlayerTrackFileViewModel = {
  source: AudioPlayerTrackSource;
  status: AudioPlayerTrackStatus;
  type: AudioPlayerTrackAssetType;
  trackId: string;
  fileId:string;
  format: AudioPreset;
  beatgrid: AudioBeatgrid;
  autoplay: boolean;
};

export type AudioPlayerWidgetViewModel = {
  status: AudioPlayerStatus;
  tracklist: AudioPlayerTracklist;
  currentTrack: AudioPlayerTrack;
  currentAsset: AudioPlayerTrackAsset;
  mode: AudioPlayerMode;
};
