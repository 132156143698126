import { ColDef } from '@ag-grid-community/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApproveInboxAudioRequestBody, ApproveInboxAudioResponseBody, ArchiveObjectInfo, AudioType, BulkUpdateInboxAudioMetadataRequestBody, BulkUpdateInboxAudioMetadataResponseBody, Field, FieldType, InboxArchiveImportResponseBody, InboxArchiveSearchResponseBody, InboxAudio, InboxAudioMetadata, InboxObject, OrQuery, Resource, Scope } from '@heardis/api-contracts';
import { FieldValue, LocalizedFieldCondition, MetadataProvider } from '@heardis/hdis-ui';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, catchError, combineLatest, map, of, shareReplay, take } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../_services/auth.service';
import { SongService } from '../../../_services/song.service';
import { AudioPlayerTrack, AudioPlayerTrackAssetType, AudioPlayerTrackInfo, AudioPlayerTrackSource, AudioPlayerTrackStatus } from '../../../audio-player/audio-player.interfaces';
import { BulkUpdateInboxAudioMetadataResult } from '../inbox.interfaces';

@Injectable({ providedIn: 'root' })
export class InboxService implements MetadataProvider {
  /**
   * Service should be on generic INBOX_OBJECT and then optionally deal with subtypes
   * (e.g. in permissions users may be enabled for tracks but disabled for future types)
   * However at the momen we deal only with Inbox Tracks so we keep it simple
   */
  resource = Resource.INBOX_TRACK;

  matchesCache: Record<string, Observable<string>> = {};

  baseUrl = environment.apiBaseUrl + environment.endpoints.inbox;

  constructor(
    private i18n: TranslocoService,
    private http: HttpClient,
    private sService: SongService,
    private authService: AuthService,
  ) {
  }

  public getAudioUploadEndpoint(): string {
    return `${this.baseUrl}/audio/_upload`;
  }

  public getEntityFields(): Observable<Field[]> {
    const fileFields: Field[] = [
      { name: 'filetype', type: FieldType.STRING, isEnum: true },
      { name: 'path', type: FieldType.STRING, isSortable: true },
      { name: 'lastUpdate', type: FieldType.DATE, isSortable: true },
      { name: 'owner', type: FieldType.STRING, isSortable: true },
    ];
    const audioFields: Field[] = [
      { name: 'title', type: FieldType.STRING, isSortable: true },
      { name: 'artist', type: FieldType.STRING, isSortable: true },
      { name: 'album', type: FieldType.STRING, isSortable: true },
      { name: 'isrc', type: FieldType.STRING, isSortable: true },
      { name: 'label', type: FieldType.STRING, isSortable: true },
      { name: 'publisher', type: FieldType.STRING, isSortable: true },
      { name: 'source', type: FieldType.STRING, isSortable: true },
      { name: 'type', type: FieldType.STRING, isSortable: true },
    ];
    return of([
      ...fileFields,
      ...audioFields,
    ]).pipe(
      shareReplay(1),
    );
  }

  public getQueryFields(): Observable<Field[]> {
    return this.getEntityFields();
  }

  getFieldValues(fieldName: string, keyword?: string, withInvalid?: boolean): Observable<FieldValue<any>[]> {
    let values: any[];
    switch (fieldName) {
      case 'filetype':
        values = [{ value: 'audio', label: 'audio' }];
        break;
      default:
        break;
    }
    return of(values);
  }

  getQueryConditions(): Observable<LocalizedFieldCondition[]> {
    throw new Error('Method not implemented.');
  }

  getTableColumns(): Observable<ColDef[]> {
    const fileDefs: ColDef[] = [
      { field: 'filetype', colId: 'filetype', headerName: this.i18n.translate('library.inbox.props.filetype') },
      { field: 'path', colId: 'path', headerName: this.i18n.translate('library.inbox.props.path') },
      { field: 'lastUpdate', colId: 'lastUpdate', type: ['dateColumn'], headerName: this.i18n.translate('library.inbox.props.lastUpdate') },
      { field: 'owner', colId: 'owner', headerName: this.i18n.translate('library.inbox.props.owner') },
    ];
    const audioDefs: ColDef[] = [
      { field: 'artwork', colId: 'artwork', headerName: this.i18n.translate('entities.track.metadata.artwork') },
      { field: 'title', colId: 'title', headerName: this.i18n.translate('entities.track.metadata.title') },
      { field: 'artist', colId: 'artist', headerName: this.i18n.translate('entities.track.metadata.artist') },
      { field: 'album', colId: 'album', headerName: this.i18n.translate('entities.track.metadata.album') },
      { field: 'releaseyear', colId: 'releaseyear', headerName: this.i18n.translate('entities.track.metadata.releaseYear') },
      { field: 'isrc', colId: 'isrc', headerName: this.i18n.translate('entities.track.metadata.ids.isrc') },
      { field: 'label', colId: 'label', headerName: this.i18n.translate('entities.track.metadata.label') },
      { field: 'publisher', colId: 'publisher', headerName: this.i18n.translate('entities.track.metadata.publisher') },
      { field: 'source', colId: 'source', headerName: this.i18n.translate('entities.track.metadata.source') },
      { field: 'md5', colId: 'md5', type: ['entityColumn'], cellRendererParams: { fetchFn: this.checkExistingTrack }, headerName: this.i18n.translate('library.inbox.props.track.matchingTracks') },
      { field: 'type', colId: 'type', headerName: this.i18n.translate('library.inbox.props.type') },
    ];
    return of([
      ...fileDefs,
      ...audioDefs,
    ]).pipe(
      shareReplay(1),
    );
  }

  handleResponse(res): InboxAudio {
    if (res?.lastUpdate) res.lastUpdate = new Date(res.lastUpdate);
    return <InboxAudio>res;
  }

  public listFiles(): Observable<InboxAudio[]> {
    return this.http.get(`${this.baseUrl}/audio`).pipe(
      map((files: any[]) => files.map((file) => this.handleResponse(file))),
      take(1),
    );
  }

  public updateAudioFilesMetadata(files: InboxAudio[], changes: Partial<InboxAudioMetadata>): Observable<BulkUpdateInboxAudioMetadataResult> {
    const payload: BulkUpdateInboxAudioMetadataRequestBody = {
      ids: files.map((file) => file.path),
      changes,
    };
    return this.http.patch<BulkUpdateInboxAudioMetadataResponseBody>(`${this.baseUrl}/audio`, payload).pipe(
      take(1),
      map((response) => {
        const hasErrors = response.some((fileChanged) => fileChanged.status === 'rejected');

        return {
          hasErrors,
          files: response.map((fileChanged, index) => {
            if (fileChanged.status === 'fulfilled') {
              return {
                success: true,
                input: files[index],
                output: fileChanged.value,
              };
            }
            return {
              success: false,
              input: files[index],
              output: files[index],
              error: fileChanged.reason,
            };
          }),
        };
      }),
    );
  }

  public approveFiles(files: ApproveInboxAudioRequestBody) {
    return this.http.post<ApproveInboxAudioResponseBody>(`${this.baseUrl}/audio/_approve`, files).pipe(
      take(1),
    );
  }

  public rejectFiles(files: InboxObject[]) {
    return this.http.post<ApproveInboxAudioResponseBody>(`${this.baseUrl}/audio/_reject`, files).pipe(
      take(1),
    );
  }

  public claimFiles(files: ApproveInboxAudioRequestBody) {
    return this.http.post<ApproveInboxAudioResponseBody>(`${this.baseUrl}/audio/_claim`, files).pipe(
      take(1),
    );
  }

  checkExistingTrack = (md5: string, row: InboxAudio): Observable<string> => {
    const key = `${md5}-${row.title}-${row.artist}`;

    if (!this.matchesCache[key]) {
      const filter: OrQuery = {
        or: [
          { equals: { _id: md5 } },
          {
            and: [
              { contains: { 'metadata.title': row.title } },
              { contains: { 'metadata.artist': row.artist } },
            ],
          },
        ],
      };
      this.matchesCache[key] = this.sService.searchEntities(filter, { offset: 0, limit: 100 }).pipe(
        map((results) => {
          let retValue = 'no matches';
          const exactMatch = results.content.find((track) => track._id === md5);
          if (exactMatch) {
            retValue = `match: ${exactMatch.metadata.artist} - ${exactMatch.metadata.title}`;
          } else if (results.totalElements) {
            retValue = `${results.totalElements} candidates: ${results.content.map((track) => ` ${track.metadata.artist} - ${track.metadata.title}`).join(', ')}`;
          }
          return retValue;
        }),
        shareReplay(),
      );
    }

    return this.matchesCache[key];
  };

  loadPlayerTrack(track: AudioPlayerTrack): Observable<AudioPlayerTrackInfo> {
    return combineLatest([
      this.getTrackFileUrl(track.id),
      this.getInboxTrackArtworkUrl(track.id, true),
    ]).pipe(
      map(([audioUrl, artworkUrl]) => ({ ...track, audioUrl, artworkUrl })),
    );
  }

  getTrackFileUrl(path: string): Observable<string> {
    // Passing through User service
    const songRequestStreamUrl = `${this.baseUrl}/audio/_stream`;
    const params = new HttpParams({ fromObject: { path, file: 'audio' } });
    return this.http.get<{ url: string }>(songRequestStreamUrl, { params }).pipe(
      map((urlResponse) => urlResponse.url),
      take(1),
    );
  }

  getInboxTrackArtworkUrl(path: string, canFail: boolean): Observable<string> {
    // Passing through User service
    const songRequestStreamUrl = `${this.baseUrl}/audio/_stream`;
    const params = new HttpParams({ fromObject: { path, file: 'artwork' } });
    return this.http.get<{ url: string }>(songRequestStreamUrl, { params }).pipe(
      map((urlResponse) => urlResponse.url),
      take(1),
      catchError((err) => {
        console.log('unable to fetch inbox track artwork url', err);
        if (canFail) return of(null);
        throw err;
      }),
    );
  }

  mapToAudioPlayerTrack = (track: InboxAudio): AudioPlayerTrack => ({
    status: AudioPlayerTrackStatus.INIT,
    source: AudioPlayerTrackSource.INBOX,
    id: track.path,
    route: ['/library/inbox'],
    artist: track.artist,
    title: track.title,
    assets: [{ id: null, type: AudioPlayerTrackAssetType.ORIGINAL, beatgrid: [] }],
  });

  canCreate = (entity?: InboxObject): boolean => this.authService.hasPermission(this.resource, Scope.CREATE);

  canRead = (entity?: InboxObject): boolean => {
    if (this.authService.hasPermission(this.resource, Scope.READ)) {
      return true;
    }
    if (entity) {
      const username = this.authService.getUsername();
      if (this.authService.hasPermission(this.resource, Scope.READ_OWN) && entity.owner === username) {
        return true;
      }
    } else if (this.authService.hasPermission(this.resource, Scope.READ_OWN)) {
      return true;
    }

    return false;
  };

  canEdit = (entity?: InboxObject): boolean => {
    if (this.authService.hasPermission(this.resource, Scope.EDIT)) {
      return true;
    }
    if (entity) {
      const username = this.authService.getUsername();
      if (this.authService.hasPermission(this.resource, Scope.EDIT_OWN) && entity.owner === username) {
        return true;
      }
    } else if (this.authService.hasPermission(this.resource, Scope.EDIT_OWN)) {
      return true;
    }

    return false;
  };

  canDelete = (entity?: InboxObject): boolean => {
    if (this.authService.hasPermission(this.resource, Scope.DELETE)) {
      return true;
    }
    if (entity) {
      const username = this.authService.getUsername();
      if (this.authService.hasPermission(this.resource, Scope.DELETE_OWN) && entity.owner === username) {
        return true;
      }
    } else if (this.authService.hasPermission(this.resource, Scope.DELETE_OWN)) {
      return true;
    }

    return false;
  };

  archiveSearchTracks = (keyword: string): Observable<ArchiveObjectInfo[]> => this.http.post<InboxArchiveSearchResponseBody>(`${this.baseUrl}/audio/archive/_search`, { path: keyword }).pipe(
    take(1),
    map((response) => response.content),
  );

  archiveImportTracks = (keys: string[]): Observable<string[]> => this.http.post<InboxArchiveImportResponseBody>(`${this.baseUrl}/audio/archive/_import`, { files: keys }).pipe(
    take(1),
    map((response) => response.objects.map((obj) => obj.Key)),
  );
}
