<div fxLayout="column" fxFlexFill>
  <full-calendar fxFlex class="padded-content" #calendar [options]="calendarOptions">
    <ng-template #eventContent let-arg>
      <div *ngIf="arg.timeText" class="hdis-calevent-time">{{ arg.timeText }}</div>
      <div class="hdis-calevent-info ellipsis">
        <span class="hdis-calevent-title">{{ arg.event.title }}</span>
        <span *ngFor="let info of (arg.event.extendedProps.info || [])" class="hdis-calevent-subtitle">{{ info }}</span>
      </div>
    </ng-template>
  </full-calendar>
</div>
