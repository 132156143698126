<mat-form-field class="abcdj-mat-form-field" floatLabel="always">
  <mat-label>{{label}}</mat-label>
  <input type="text" [placeholder]="placeholder" matInput [matAutocomplete]="auto" [formControl]="selected"
  name="selected" [required]="required">
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of options$ | async" [value]="option">
      {{option}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
