import { NgFor, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';

export enum SelectionDialogType {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export interface SelectionDialogOption<T> {
  value: T;
  label: string;
  checked?: boolean;
}

export interface SelectionDialogParams<T> {
  title: string;
  type: SelectionDialogType;
  options: SelectionDialogOption<T>[];
  message?: string;
  subMessage?: string;
  confirm?: string;
  cancel?: string;
}

export interface SelectionDialogData<T> {
  submit: boolean;
  values?: T[];
}

@Component({
  selector: 'hdis-selection-dialog',
  templateUrl: './selection-dialog.component.html',
  styleUrls: ['./selection-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, MatButtonModule, MatIconModule, MatDialogModule, MatRadioModule, FlexModule, ReactiveFormsModule, FormsModule, NgFor, MatCheckboxModule],
})
export class SelectionDialogComponent<T extends string | number | symbol> {
  data: SelectionDialogParams<T>;

  singleChoice: boolean;

  localSingleValue: T;

  localMultiValue: Record<T, boolean>;

  constructor(
    public dialogRef: MatDialogRef<SelectionDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) data: SelectionDialogParams<T>,
  ) {
    this.data = {
      confirm: 'confirm',
      cancel: 'cancel',
      ...data,
    };

    if (this.data.type === SelectionDialogType.SINGLE) {
      this.singleChoice = true;
      this.localSingleValue = this.data.options.find((opt) => opt.checked).value;
    }
  }

  close(submit: boolean) {
    const values = this.singleChoice ? [this.localSingleValue] : this.data.options.filter((opt) => opt.checked).map((opt) => opt.value);
    const retValue: SelectionDialogData<T> = { submit, values };
    this.dialogRef.close(retValue);
  }
}
