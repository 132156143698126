import { NgModule } from '@angular/core';
import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, TranslocoModule, translocoConfig } from '@ngneat/transloco';
import { environment } from '../../environments/environment';
import { HttpLoader } from './http.loader';

@NgModule({
  imports: [TranslocoModule],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [
          { id: 'en', label: 'english' },
          { id: 'de', label: 'deutsch' },
          { id: 'it', label: 'italiano' },
        ],
        defaultLang: 'en',
        fallbackLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        flatten: {
          aot: environment.production,
        },
        missingHandler: {
          useFallbackTranslation: true,
          logMissingKey: !environment.production,
        },
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: HttpLoader },
  ],
})
export class TranslocoRootModule { }
