import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { catchError, forkJoin, Observable, of } from 'rxjs';
import { AsyncPipe } from '@angular/common';

interface EntityCellRendererParams extends ICellRendererParams {
  fetchFn: (cellValue, rowValue) => Observable<string>;
}

@Component({
  selector: 'hdis-entity-renderer',
  templateUrl: './entity-renderer.component.html',
  styleUrls: ['./entity-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe],
})
export class EntityCellRendererComponent implements AgRendererComponent {
  label: Observable<string | string[]>;

  agInit(params: EntityCellRendererParams): void {
    if (params.value) {
      // params.value could be single value or array of values, so we wrap in an array and then flatten it to ensure 1-level array
      const rowValue = params.data;
      const requests = [params.value].flatMap((cellValue) => params.fetchFn(cellValue, rowValue).pipe(
        catchError((err) => {
          console.warn(`unable to retrieve label for entity ${cellValue}`, err);
          return of(cellValue);
        }),
      ));
      this.label = forkJoin(requests);
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}
