<ng-container *ngIf="config$ | async as config">
  <div class="player-wrapper view-{{config.mode}}">
    <div class="hdis-player-size-controls" *ngIf="config.mode !== AudioPlayerMode.WIDGET">
      <button *ngIf="config.waveform.mode === AudioPlayerWaveformViewMode.STANDARD" mat-icon-button aria-label="expand player" (click)="setExtendedWaveform()" [disabled]="config.mode === AudioPlayerMode.EDITOR">
        <mat-icon>expand_less</mat-icon>
      </button>
      <button *ngIf="config.waveform.mode === AudioPlayerWaveformViewMode.EXTENDED" mat-icon-button aria-label="shrink player" (click)="setStandardWaveform()" [disabled]="config.mode === AudioPlayerMode.EDITOR">
        <mat-icon>expand_more</mat-icon>
      </button>
    </div>

    <hdis-audio-player-progress-counter type="elapsed" *ngIf="config.mode !== AudioPlayerMode.WIDGET"></hdis-audio-player-progress-counter>

    <hdis-player-waveform class="hdis-player-progress" [trackStatus]="config.trackStatus" [playbackRate]="config.playbackRate" [waveform]="config.waveform" [editor]="config.editor"></hdis-player-waveform>

    <hdis-audio-player-progress-counter type="remaining" *ngIf="config.mode !== AudioPlayerMode.WIDGET"></hdis-audio-player-progress-counter>
  </div>
</ng-container>
