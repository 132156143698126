<mat-menu #menu="matMenu">
  <!-- needed lazy load for proper positioning when table component has change detection onPush -->
  <ng-template matMenuContent>
    <ng-container *ngIf="!branch">
      <button mat-menu-item (click)="reset($event)">reset columns</button>
      <mat-divider></mat-divider>
    </ng-container>
    <ng-container *ngFor="let column of columns | headerDefinitions : branch">

      <div mat-menu-item *ngIf="!column.hasChildren" (click)="toggle($event, column.id, !column.selected)">
        <mat-pseudo-checkbox [state]="column.selected ? 'checked' : 'unchecked'" color="primary"></mat-pseudo-checkbox>
        {{ column.label }}
      </div>

      <ng-container *ngIf="column.hasChildren">
        <div mat-menu-item [matMenuTriggerFor]="subMenu.menu">
          <mat-pseudo-checkbox
            [state]="column.hasVisibleChildren && column.hasNotVisibleChildren ? 'indeterminate' : column.selected ? 'checked' : 'unchecked'"
            color="primary" (click)="toggle($event, column.id, !column.selected)"></mat-pseudo-checkbox>
          {{ column.label }}
        </div>
        <hdis-table-header-menu #subMenu [columns]="columns" [branch]="branch + column.id + '.'"
          (toggleColumns)="toggleColumns.emit($event)" (resetColumns)="resetColumns.emit()">
        </hdis-table-header-menu>
      </ng-container>

    </ng-container>
  </ng-template>
</mat-menu>
