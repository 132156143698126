import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Component({
    selector: 'hdis-chip-autocomplete',
    templateUrl: './chip-autocomplete.component.html',
    styleUrls: ['./chip-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChipAutocompleteComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [MatLegacyFormFieldModule, MatLegacyChipsModule, NgFor, NgIf, MatIconModule, ReactiveFormsModule, MatLegacyAutocompleteModule, MatLegacyOptionModule, AsyncPipe]
})
export class ChipAutocompleteComponent implements ControlValueAccessor {
  @Input() label: string;

  @Input() placeholder: string;

  @Input()
  get multiple(): boolean { return this._multiple; }

  set multiple(value) { this._multiple = coerceBooleanProperty(value); }

  _multiple = false;

  propagateFn: Function;

  @Input() availableValues: string[] = [];

  isDisabled = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  inputCtrl = new UntypedFormControl();

  filteredValues$: Observable<string[]>;

  filteredValues: string[] = [];

  selectedValues: string[] = [];

  @ViewChild('autocompleteInput') autocompleteInput: ElementRef<HTMLInputElement>;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
    this.filteredValues$ = this.inputCtrl.valueChanges.pipe(
      startWith(null),
      map((timezone: string | null) => {
        if (timezone) {
          this.filteredValues = this._filter(timezone);
          return this.filteredValues;
        }
        this.filteredValues = [];
        return this.availableValues.slice();
      }),
    );
  }

  writeValue(values: string[]): void {
    this.selectedValues = values || [];
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    isDisabled ? this.inputCtrl.disable() : this.inputCtrl.enable();
  }

  add(event: MatChipInputEvent): void {
    let value = (event.value || '').trim();

    if (this.filteredValues.length === 1) value = this.filteredValues[0];

    // check if the entered value is valid and not selected yet
    if (value && this.availableValues.includes(value) && !this.selectedValues.includes(value)) {
      // Add value to selection and clear the input element
      // this.selectedValues.push(value);
      this.setSelected([...this.selectedValues, value]);
      event.chipInput?.clear();
      this.inputCtrl.setValue(null);
    } else if (!value) {
      // in material example it clear regardless if it adds or not to the selection.
      // we want to add only if it is one valid value, but clean if no value is added.
      // Should not be necessary but you never know
      // Clear the input value
      event.chipInput?.clear();
      this.inputCtrl.setValue(null);
    }
  }

  remove(timezone: string): void {
    const index = this.selectedValues.indexOf(timezone);

    if (index >= 0) {
      this.setSelected(this.selectedValues.filter((item, i) => i !== index));
      // this.selectedValues.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedValues.includes(event.option.viewValue)) { this.setSelected([...this.selectedValues, event.option.viewValue]); }
    // this.selectedValues.push(event.option.viewValue);

    this.autocompleteInput.nativeElement.value = '';
    this.inputCtrl.setValue(null);
  }

  private setSelected(values: string[]) {
    this.selectedValues = values;
    this.propagateFn?.(values);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.availableValues.filter((timezone) => timezone.toLowerCase().includes(filterValue));
  }
}
