import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, inject } from '@angular/core';
import * as dragDrop from 'drag-drop';

@Component({
  standalone: true,
  selector: 'hdis-file-drag-drop',
  templateUrl: './file-drag-drop.component.html',
  styleUrls: ['./file-drag-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDragDropComponent implements AfterContentInit, OnDestroy {
  private cleanup: () => void;

  private elementRef = inject(ElementRef);

  private zone = inject(NgZone);

  public dragging = true;

  @Input()
  get multiple(): boolean { return this._multiple; }

  set multiple(value) { this._multiple = coerceBooleanProperty(value); }

  _multiple = false;

  @Input()
  get disabled(): boolean { return this._disabled; }

  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this.toggleDragAndDrop();
  }

  _disabled = false;

  @Input() accept: string[];

  @Output() public emitDropFiles: EventEmitter<File[]> = new EventEmitter<File[]>();

  @Output() public emitEnterFiles: EventEmitter<File[]> = new EventEmitter<File[]>();

  @Output() public emitOverFiles: EventEmitter<File[]> = new EventEmitter<File[]>();

  @Output() public emitLeaveFiles: EventEmitter<File[]> = new EventEmitter<File[]>();

  @Output() public emitSelectFiles: EventEmitter<File[]> = new EventEmitter<File[]>();

  @Output() public emitDropText: EventEmitter<string> = new EventEmitter<string>();

  private emitFileEvent(emitter: EventEmitter<File[] | string>, type: string, files: File[] | string) {
    console.debug(`drag/drop:${type} event`);
    this.zone.run(() => {
      emitter.emit(files);
    });
  }

  ngAfterContentInit() {
    this.toggleDragAndDrop();
  }

  public async handleFileSelected(event) {
    this.emitFileEvent(this.emitSelectFiles, 'select', [...event.target.files]);
  }

  ngOnDestroy() {
    this.disableDragAndDrop();
  }

  toggleDragAndDrop() {
    if (this.disabled) {
      this.disableDragAndDrop();
    } else {
      this.enableDragAndDrop();
    }
  }

  enableDragAndDrop() {
    if (this.cleanup) {
      console.log('already initialized, skip');
      return;
    }
    const e = this.elementRef.nativeElement;
    const divDrop = e.childNodes[0];
    this.cleanup = dragDrop(divDrop, {
      onDrop: (files, pos, fileList, directories) => {
        console.log(files, pos, fileList, directories);
        const filteredFiles = this.accept ? files.filter(({ name, type }) => {
          if (this.accept.includes(type)) return true;

          console.warn(`ignore file ${name} due to invalid type ${type}. Accepted types are: ${this.accept}`);
          return false;
        }) : files;
        this.emitFileEvent(this.emitDropFiles, 'drop', filteredFiles);
      },

      onDragEnter: (event) => {
        this.dragging = true;
        this.emitFileEvent(this.emitEnterFiles, 'enter', event);
      },
      onDragOver: (event) => {
        this.emitFileEvent(this.emitOverFiles, 'over', event);
      },
      onDragLeave: (event) => {
        this.dragging = false;
        this.emitFileEvent(this.emitLeaveFiles, 'leave', event);
      },
      /** Currently disabled but might be useful in the future if we have integration with other platforms for automatic download or something like that */
      onDropText: (text: string) => {
        this.emitFileEvent(this.emitDropText, 'text', text);
      },
    });
  }

  disableDragAndDrop() {
    this.cleanup?.();
    this.cleanup = null;
  }
}
