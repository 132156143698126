<div class="padded-content-xs">
  <ng-container *ngIf="track else infoPlaceholder">
    <a [routerLink]="track.route" class="title">{{track.title}}</a>
    <a [routerLink]="track.route" class="artist">{{track.artist}}</a>
  </ng-container>

  <ng-template #infoPlaceholder>
    <span class="title">select a track to start playing</span>
    <span class="artist">---</span>
  </ng-template>
</div>
