import { AudioPreset } from '@heardis/api-contracts';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { AudioPlayerFileStatus, AudioPlayerPlaybackStatus, AudioPlayerTrack, AudioPlayerTrackAssetType, AudioPlayerTrackStatus, AudioPlayerTracklistViewMode, AudioPlayerWaveformStatus, AudioPlayerWaveformViewMode } from '../audio-player.interfaces';

export const featureKey = 'player';

export type AudioPlayerFileState = {
  status: AudioPlayerFileStatus;
};

export type AudioPlayerPlaybackState = {
  status: AudioPlayerPlaybackStatus;
  rate: number;
  autoplay: boolean;
  autonext: boolean;
};

export type AudioPlayerWaveformState = {
  isVisible: boolean;
  status: AudioPlayerWaveformStatus;
  mode: AudioPlayerWaveformViewMode;
  zoom: number;
  autoscroll: boolean;
  minimap: boolean;
};

export type AudioPlayerEditorState = {
  isVisible: boolean;
  snapToGrid: boolean;
  prelistening: boolean;
};

export type AudioPlayerTracklistState = {
  isVisible: boolean;
  mode: AudioPlayerTracklistViewMode;
};

export type AudioPlayerState = EntityState<AudioPlayerTrack> & {
  currentId: string;
  queue: string[];
  next: string[];
  history: string[];

  format: AudioPreset;

  edit: AudioPlayerTrackAssetType;

  file: AudioPlayerFileState;

  playback: AudioPlayerPlaybackState;

  waveform: AudioPlayerWaveformState;

  editor: AudioPlayerEditorState;

  tracklist: AudioPlayerTracklistState;
};

export const adapter: EntityAdapter<AudioPlayerTrack> = createEntityAdapter<AudioPlayerTrack>();

export const initialState: AudioPlayerState = adapter.getInitialState({
  currentId: null,
  history: [],
  next: [],
  queue: [],

  format: AudioPreset.PREVIEW,

  edit: AudioPlayerTrackAssetType.STANDARD,

  file: {
    status: AudioPlayerFileStatus.EMPTY,
  },

  playback: {
    rate: 1,
    status: 'init',
    autoplay: true,
    autonext: true,
  },

  waveform: {
    isVisible: false,
    status: 'init',
    mode: AudioPlayerWaveformViewMode.STANDARD,
    zoom: 1,
    autoscroll: false,
    minimap: false,
  },

  editor: {
    isVisible: false,
    snapToGrid: false,
    prelistening: true,
  },

  tracklist: {
    isVisible: false,
    mode: AudioPlayerTracklistViewMode.FLOATING,
  },
  // ids: [],
  // entities: {},
  // additional entity state properties
  // selectedDayIds: [],
  // includeClosingTimes: true
});

export type AudioPlayerViewModel = {
  playbackRate: number;
  waveform: AudioPlayerWaveformState;
  editor: AudioPlayerEditorState;
  trackStatus: AudioPlayerTrackStatus;
};
