<div fxLayout="column" fxFlexFill class="pair-picker-wrapper padded-content" *ngIf="isOpen">
  <h3 *ngIf="title || showCloseButton">
    <button mat-icon-button *ngIf="showCloseButton" (click)="close()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    {{title}}
  </h3>
  <ng-container *ngFor="let pair of options$ | async; index as i;">
    <mat-button-toggle-group [value]="pair.currentValue" (change)="onSelectionChange($event, i)">
      <mat-button-toggle class="button-value" [value]="pair.leftValue">{{pair.leftLabel}}</mat-button-toggle>
      <mat-button-toggle class="button-reset" value="">
        <mat-icon>remove</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle class="button-value" [value]="pair.rightValue">{{pair.rightLabel}}</mat-button-toggle>
    </mat-button-toggle-group>
  </ng-container>
</div>
