import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '../../common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Component({
    selector: 'hdis-bpm-field',
    templateUrl: './bpm-field.component.html',
    styleUrls: ['./bpm-field.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BpmFieldComponent), multi: true },
    ],
    standalone: true,
    imports: [MatLegacyFormFieldModule, MatLegacyInputModule, ReactiveFormsModule, MatTooltipModule, MatLegacyButtonModule, MatIconModule]
})
export class BpmFieldComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  @HostBinding('class') class = 'hdis-bpm-field';

  @Input() label = 'BPM';

  @Input() tooltipMessage = 'you can calculate the BPM by selecting this field and hitting the spacebar repeatedly';

  @Input() disabledTooltipMessage = 'you can change the BPM in the beatgrid section of the track editor';

  @Input() required = false;

  @Input()
  get readonly(): boolean { return this._readonly; }

  set readonly(value) { this._readonly = coerceBooleanProperty(value); }

  _readonly: boolean;

  initValue: number;

  disabled: boolean;

  bpmControl: UntypedFormControl = new UntypedFormControl();

  prevTime = 0;

  countClicks = 0;

  currentTime = 0;

  bpm = 0;

  timeDifference = 0;

  bpmTotal = 0;

  bpmFinal = 0;

  propagateFn: (newValue: unknown) => void;

  writeValue(obj: any): void {
    this.initValue = obj;
    this.bpmControl.reset(this.initValue, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    (isDisabled) ? this.bpmControl.disable({ emitEvent: false }) : this.bpmControl.enable({ emitEvent: false });
  }

  ngOnInit(): void {
    this.bpmControl.valueChanges
      .pipe(
        // debounceTime(200),
        takeUntil(this.unsubscribe),
      )
      .subscribe((newValue) => {
        this.propagateFn(newValue);
      });
  }

  count = (evt?: MouseEvent) => {
    evt?.stopPropagation();
    if (this.prevTime === 0) {
      this.prevTime = Date.now();
    } else if ((Date.now() - this.prevTime) > 2200) {
      this.reset();
    } else {
      this.currentTime = Date.now();
      this.timeDifference = this.currentTime - this.prevTime;
      this.prevTime = this.currentTime;
      this.bpm = 60 / this.timeDifference;
      this.bpmTotal += this.bpm;
      this.countClicks += 1;
      this.bpmFinal = (this.bpmTotal / this.countClicks) * 1000;

      this.bpmControl.patchValue(this.bpmFinal.toFixed(2));
      this.bpmControl.markAsDirty();
    }
  };

  reset = () => {
    this.resetCounter();
    this.bpmControl.reset(this.initValue);
  };

  private resetCounter() {
    this.prevTime = 0;
    this.bpm = 0;
    this.bpmTotal = 0;
    this.bpmFinal = 0;
    this.countClicks = 0;
  }

  doubleBPM = () => {
    this.bpmControl.patchValue(+(this.bpmControl.value * 2).toFixed(2));
    this.bpmControl.markAsDirty();
  };

  halveBPM = () => {
    this.bpmControl.patchValue(+(this.bpmControl.value / 2).toFixed(2));
    this.bpmControl.markAsDirty();
  };

  roundBPM = () => {
    this.bpmControl.patchValue(Math.round(this.bpmControl.value));
    this.bpmControl.markAsDirty();
  };
}
