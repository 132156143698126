<mat-icon [style.color]="iconColor" [title]="iconTitle" (mouseenter)="onEnter($event)" (mouseleave)="onLeave($event)" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  {{ icon }}
</mat-icon>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="overlayPositions" [cdkConnectedOverlayPush]="true">
  <div class="thumbnail-cell mat-elevation-z10">
    <img [src]="imageUrl$ | async | withAuth : placeholderUrl" [title]="params.label" (error)="onImgError($event)" />
  </div>
</ng-template>
