/* eslint-disable class-methods-use-this */
import { AudioBeatgrid, AudioBeats } from '@heardis/api-contracts';
import { PluginDefinition, PluginParams, WaveSurferPlugin } from 'wavesurfer.js/types/plugin';

export type BeatgridPluginParams = PluginParams & {
  downbeatHeight: number;
  downbeatColor: string;
  beatHeight: number;
  beatColor: string;
  timeSignature: number;
  sharpLines: boolean;
  zoomDebounce: boolean;
  offset: number;
};

/**
 * Adds a beatgrid to the waveform.
 *
 * Inspired by https://github.com/katspaugh/wavesurfer.js/blob/master/src/plugin/timeline/index.js
 * and https://github.com/katspaugh/wavesurfer.js/blob/master/src/plugin/markers/index.js
 *
 * @author stefano miccoli
 */
export default class BeatgridPlugin implements WaveSurferPlugin {
  container;

  wrapper;

  drawer;

  wavesurfer;

  pixelRatio;

  maxCanvasWidth;

  maxCanvasElementWidth;

  params;

  util;

  canvases: HTMLCanvasElement[];

  beatgrid: AudioBeatgrid;

  beats: AudioBeats;

  /**
     * Beatgrid plugin definition factory
     *
     * This function must be used to create a plugin definition which can be
     * used by wavesurfer to correctly instantiate the plugin.
     *
     * @param  {BeatgridPluginParams} params parameters use to initialise the plugin
     */
  static create(params: Partial<BeatgridPluginParams>): PluginDefinition {
    return {
      name: 'beatgrid',
      deferInit: params && params.deferInit ? params.deferInit : false,
      params,
      staticProps: {
        loadBeatgrid(beats: AudioBeatgrid) {
          if (!this.initialisedPluginList.beatgrid) {
            this.initPlugin('beatgrid');
          }
          return this.beatgrid.load(beats);
        },
        clearBeatgrid() {
          this.beatgrid?.clear();
        },
        getBeatgrid(onlyBeats = false): AudioBeatgrid {
          return onlyBeats ? this.beatgrid?.beats : this.beatgrid?.beatgrid;
        },
        hasBeatgrid(): boolean {
          return this.beatgrid?.beatgrid?.length > 0;
        },
      },
      instance: BeatgridPlugin,
    };
  }

  // event handlers
  private onZoom: () => void;

  private onScroll = () => {
    if (this.wrapper && this.drawer.wrapper) {
      this.wrapper.scrollLeft = this.drawer.wrapper.scrollLeft;
    }
  };

  private onRedraw = () => this.render();

  private onReady = () => {
    const ws = this.wavesurfer;
    this.drawer = ws.drawer;
    this.pixelRatio = ws.drawer.params.pixelRatio;
    this.maxCanvasWidth = ws.drawer.maxCanvasWidth || ws.drawer.width;
    this.maxCanvasElementWidth = ws.drawer.maxCanvasElementWidth || Math.round(this.maxCanvasWidth / this.pixelRatio);

    // add listeners
    ws.drawer.wrapper.addEventListener('scroll', this.onScroll);
    ws.on('redraw', this.onRedraw);
    ws.on('zoom', this.onZoom);

    this.render();
  };

  private onWrapperClick = (e) => {
    e.preventDefault();
    const relX = 'offsetX' in e ? e.offsetX : e.layerX;
    // this.fireEvent('click', relX / this.wrapper.scrollWidth || 0);
  };

  private isBeatVisible(pxPerBeat: number): boolean {
    // if we have less than 4px per beat the beatgrid would be too cramped so we skip rendering regular beats (and show only downbeats)
    return (pxPerBeat > 4);
  }

  /**
     * Creates an instance of BeatgridPlugin.
     *
     * You probably want to use BeatgridPlugin.create()
     *
     * @param {BeatgridPluginParams} params Plugin parameters
     * @param {object} ws Wavesurfer instance
     */
  constructor(params, ws) {
    this.container =
            typeof params.container === 'string' ?
              document.querySelector(params.container) :
              params.container;

    if (!this.container) {
      throw new Error('No container for wavesurfer beatgrid');
    }

    this.wavesurfer = ws;
    this.util = ws.util;
    this.params = {
      downbeatHeight: 20,
      beatHeight: 15,
      downbeatColor: '#000',
      beatColor: '#888',
      timeSignature: 4,
      sharpLines: true,
      zoomDebounce: false,
      offset: 0,
      ...params,
    };

    this.canvases = [];
    this.wrapper = null;
    this.drawer = null;
    this.pixelRatio = null;
    this.maxCanvasWidth = null;
    this.maxCanvasElementWidth = null;
    /**
     * This event handler has to be in the constructor function because it
     * relies on the debounce function which is only available after
     * instantiationes
     *
     * Use a debounced function if `params.zoomDebounce` is defined
     */
    this.onZoom = this.params.zoomDebounce ?
      this.wavesurfer.util.debounce(
        () => this.render(),
        this.params.zoomDebounce,
      ) :
      () => this.render();
  }

  /**
     * Initialisation function used by the plugin API
     */
  init() {
    // Check if ws is ready
    if (this.wavesurfer.isReady) {
      this.onReady();
    } else {
      this.wavesurfer.once('ready', this.onReady);
    }
  }

  /**
     * Destroy function used by the plugin API
     */
  destroy() {
    // this.unAll();
    this.wavesurfer.un('redraw', this.onRedraw);
    this.wavesurfer.un('zoom', this.onZoom);
    this.wavesurfer.un('ready', this.onReady);
    this.wavesurfer.drawer.wrapper.removeEventListener('scroll', this.onScroll);

    if (this.wrapper && this.wrapper.parentNode) {
      this.wrapper.removeEventListener('click', this.onWrapperClick);
      this.wrapper.parentNode.removeChild(this.wrapper);
      this.wrapper = null;
    }
  }

  /**
     * Create a beatgrid element to wrap the canvases drawn by this plugin
     *
     */
  createWrapper() {
    const wsParams = this.wavesurfer.params;
    this.container.innerHTML = '';
    this.wrapper = this.container.appendChild(
      document.createElement('beatgrid'),
    );
    this.util.style(this.wrapper, {
      display: 'block',
      position: 'relative',
      userSelect: 'none',
      webkitUserSelect: 'none',
      height: `${this.params.downbeatHeight}px`,
    });

    if (wsParams.fillParent || wsParams.scrollParent) {
      this.util.style(this.wrapper, {
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'hidden',
      });
    }

    this.wrapper.addEventListener('click', this.onWrapperClick);
  }

  /**
     * Render the beatgrid (also updates the already rendered beatgrid)
     *
     */
  render() {
    if (!this.wrapper) {
      this.createWrapper();
    }
    this.updateCanvases();
    this.updateCanvasesPositioning();
    this.renderCanvases();
  }

  /**
     * Add new beatgrid canvas
     *
     */
  addCanvas() {
    const canvas = this.wrapper.appendChild(
      document.createElement('canvas'),
    );
    this.canvases.push(canvas);
    this.util.style(canvas, {
      position: 'absolute',
      zIndex: 4,
    });
  }

  /**
     * Remove beatgrid canvas
     *
     */
  removeCanvas() {
    const canvas = this.canvases.pop();
    canvas.parentElement.removeChild(canvas);
  }

  /**
     * Make sure the correct of beatgrid canvas elements exist and are cached in
     * this.canvases
     *
     */
  updateCanvases() {
    const totalWidth = Math.round(this.drawer.wrapper.scrollWidth);
    const requiredCanvases = Math.ceil(totalWidth / this.maxCanvasElementWidth);

    while (this.canvases.length < requiredCanvases) {
      this.addCanvas();
    }

    while (this.canvases.length > requiredCanvases) {
      this.removeCanvas();
    }
  }

  /**
     * Update the dimensions and positioning style for all the beatgrid canvases
     *
     */
  updateCanvasesPositioning() {
    // cache length for performance
    const canvasesLength = this.canvases.length;
    this.canvases.forEach((canvas, i) => {
      // canvas width is the max element width, or if it is the last the
      // required width
      const canvasWidth = i === canvasesLength - 1 ?
        this.drawer.wrapper.scrollWidth - this.maxCanvasElementWidth * (canvasesLength - 1) :
        this.maxCanvasElementWidth;
      // set dimensions and style
      this.canvases[i].width = canvasWidth * this.pixelRatio;
      // on certain pixel ratios the canvas appears cut off at the bottom,
      // therefore leave 1px extra
      this.canvases[i].height = (this.params.downbeatHeight + 1) * this.pixelRatio;
      this.util.style(canvas, {
        width: `${canvasWidth}px`,
        height: `${this.params.downbeatHeight}px`,
        left: `${i * this.maxCanvasElementWidth}px`,
      });
    });
  }

  /**
     * Render the beatgrid labels and notches
     *
     */
  renderCanvases() {
    if (!this.beatgrid?.length) return;

    const wsParams = this.wavesurfer.params;
    const width = wsParams.fillParent && !wsParams.scrollParent ? this.drawer.getWidth() : this.drawer.wrapper.scrollWidth * wsParams.pixelRatio;
    const downbeatHeight = this.params.downbeatHeight * this.pixelRatio;
    const beatHeight = this.params.beatHeight * this.pixelRatio;
    const estPixelsPerBeat = width / this.beatgrid.length;

    const ratio = width / this.wavesurfer.backend.getDuration();
    // build an array of beat indexes and pixel positions, this is then used multiple times below
    const positioning = this.beatgrid.map(([timestamp, isDownbeat]) => ([isDownbeat, ratio * timestamp]));

    // iterate over each position
    const renderPositions = (cb) => {
      positioning.forEach((pos) => {
        cb(pos[0], pos[1]);
      });
    };

    // render downbeats
    this.setFillStyles(this.params.downbeatColor);
    renderPositions((isDownbeat, pxPosition) => {
      if (isDownbeat) {
        this.fillRect(pxPosition, 0, 1, downbeatHeight);
      }
    });

    // render the other beats
    this.setFillStyles(this.params.beatColor);
    renderPositions((isDownbeat, pxPosition) => {
      if (!isDownbeat && this.isBeatVisible(estPixelsPerBeat)) {
        this.fillRect(pxPosition, 0, 1, beatHeight);
      }
    });
  }

  /**
     * Set the canvas fill style
     *
     * @param {DOMString|CanvasGradient|CanvasPattern} fillStyle Fill style to
     * use
     */
  setFillStyles(fillStyle) {
    this.canvases.forEach((canvas) => {
      const context = canvas.getContext('2d');
      if (context) {
        context.fillStyle = fillStyle;
      }
    });
  }

  /**
   * Draw a rectangle on the canvases
   *
   * (it figures out the offset for each canvas)
   */
  fillRect(x: number, y: number, width: number, height: number) {
    this.canvases.forEach((canvas, i) => {
      const leftOffset = i * this.maxCanvasWidth;

      const intersection = {
        x1: Math.max(x, i * this.maxCanvasWidth),
        y1: y,
        x2: Math.min(x + width, i * this.maxCanvasWidth + canvas.width),
        y2: y + height,
      };

      if (intersection.x1 < intersection.x2) {
        const context = canvas.getContext('2d');
        if (context) {
          // workaround to avoid antialiasing of canvas lines across pixels that would look meh
          if (this.params.sharpLines) {
            context.fillRect(
              Math.round(intersection.x1 - leftOffset),
              Math.round(intersection.y1),
              Math.round(intersection.x2 - intersection.x1),
              Math.round(intersection.y2 - intersection.y1),
            );
          } else {
            context.fillRect(
              intersection.x1 - leftOffset,
              intersection.y1,
              intersection.x2 - intersection.x1,
              intersection.y2 - intersection.y1,
            );
          }
        }
      }
    });
  }

  load(beats: AudioBeatgrid) {
    console.debug('[beatgrid] load', beats);
    this.beatgrid = [...beats];
    this.beats = beats.map(([beat]) => beat);
    // The first invokation of load() might be triggered before the waveform is initialized.
    // so we skip rendering if no drawer is initialized yet and rely on the render() invokation
    // when the onReady finishes
    if (this.drawer) this.render();
  }

  clear() {
    console.debug('[beatgrid] clear');
    this.beatgrid = [];
    this.beats = [];
    if (this.drawer) this.render();
  }
}
