import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotAuthorizedComponent } from './_global/not-authorized/not-authorized.component';
import { NotFoundComponent } from './_global/not-found/not-found.component';
import { ServiceUnavailableComponent } from './_global/service-unavailable/service-unavailable.component';
import { AppGridLayoutComponent } from './_layouts/app-grid-layout/app-grid-layout.component';
import { ErrorLayoutComponent } from './_layouts/error-layout/error-layout.component';

const routes: Routes = [
  {
    path: '',
    // canActivateChild: [
    //   // AuthGuard
    //   // AuthenticationGuard
    // ],
    component: AppGridLayoutComponent,
    children: [
      { path: 'customers', loadChildren: () => import('./customers/customers.module').then((m) => m.CustomersModule) },
      { path: 'library', loadChildren: () => import('./library/library.module').then((m) => m.LibraryModule) },
      { path: 'scheduling', loadChildren: () => import('./scheduling/scheduling.module').then((m) => m.SchedulingModule) },
      { path: 'fleet', loadChildren: () => import('./fleet/fleet.module').then((m) => m.FleetModule) },
      { path: 'streaming', loadChildren: () => import('./streaming/streaming.module').then((m) => m.StreamingModule) },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule) },
      /*
      DJ-339: when user clicks on logout and then on login, he is redirected to "404 not found" page.
      This is due to the fact that cognito redirects to {redirecturi}#id_token=....
      Ideally we should specify a redirecturi that already contains the initial route. In this case the router would not override it as
      specified at https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/routing-with-the-hashstrategy.html.

      However, since we are very unlucky, we can't use LocationPathStrategy (urls without fragments) due to S3/cloudfront way of routing,
      and we can't set a redirecturi with a predefined fragment due to cognito limitation.
      */
      { path: 'id_token', redirectTo: '/library', pathMatch: 'full' },
      { path: '', redirectTo: '/library', pathMatch: 'full' },
    ],
  },
  {
    path: 'error',
    component: ErrorLayoutComponent,
    children: [
      { path: 'not-found', component: NotFoundComponent },
      { path: 'not-authorized', component: NotAuthorizedComponent },
      { path: 'service-unavailable', component: ServiceUnavailableComponent },
    ],
  },
  { path: '**', redirectTo: '/error/not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
