/* eslint-disable @typescript-eslint/ban-types */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl, ValidationErrors, Validator } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { map, Observable, startWith } from 'rxjs';
import { timezones } from '../timezone-picker.types';

@Component({
  selector: 'hdis-timezone-autocomplete',
  templateUrl: './timezone-autocomplete.component.html',
  styleUrls: ['./timezone-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimezoneAutocompleteComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => TimezoneAutocompleteComponent), multi: true },
  ],
})
export class TimezoneAutocompleteComponent implements OnInit, ControlValueAccessor, Validator {
  options = timezones;

  @Input() label = 'timezone';

  @Input() placeholder = 'select timezone';

  @Input() required = false;

  propagateFn: Function;

  selected = new UntypedFormControl();

  options$: Observable<string[]>;

  ngOnInit() {
    this.options$ = this.selected.valueChanges.pipe(
      startWith(''),
      map((value) => {
        // value is object of type Entity, emit only if user changed
        if (this.selected.dirty) this.propagateFn(value);
        return this._filter(value);
      }),
    );
  }

  writeValue(obj: any): void {
    this.selected.reset(obj);
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.required) return null;
    return (control?.value?._id) ? null : { timezone: 'invalid' };
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }
}
