<ng-container *ngIf="config$ | async as config">

  <!-- <button class="edit-selector" mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button> -->
  <button class="edit-selector" mat-raised-button [matMenuTriggerFor]="menu" *ngIf="config.mode !== AudioPlayerMode.EDITOR" [disabled]="!config.currentTrack">
    {{config.currentAsset?.type || 'original'}}
  </button>

  <button class="edit-selector" mat-raised-button *ngIf="config.mode === AudioPlayerMode.EDITOR">
    editor
  </button>

  <mat-menu #menu="matMenu">
    <button [class.selected]="config.currentAsset?.type === asset.type" mat-menu-item *ngFor="let asset of config.currentTrack?.assets" (click)="changeEdit(asset)" matTooltip="edit id: {{asset.id}}" [matTooltipDisabled]="!asset.id" matTooltipShowDelay="500">
      <!-- <mat-icon>dialpad</mat-icon> -->
      <span >{{asset.type}}</span>
    </button>
  </mat-menu>


  <abcdj-audio-player-artwork [track]="config.currentTrack"></abcdj-audio-player-artwork>

  <hdis-audio-player-progress-bar *ngIf="!!config.currentTrack && config.mode === AudioPlayerMode.WIDGET" [track]="config.currentTrack" [status]="config.status"></hdis-audio-player-progress-bar>

  <abcdj-audio-player-playback-controls [status]="config.status" [track]="config.currentTrack" [tracklist]="config.tracklist" [mode]="config.mode">
  </abcdj-audio-player-playback-controls>

  <abcdj-audio-player-track-details class="hdis-player-playback-info" [track]="config.currentTrack">
  </abcdj-audio-player-track-details>
</ng-container>
