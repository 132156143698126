<!-- <amplify-auth-container>
  <amplify-authenticator>
    <amplify-sign-in header-text="ABC_DJ login" slot="sign-in" hide-sign-up></amplify-sign-in>
  </amplify-authenticator>
</amplify-auth-container>

<ng-container *ngIf="isAuthenticated$ | async">
  <router-outlet *ngIf="isLoaded$ | async; else loadingTpl">
  </router-outlet>
  <ng-template #loadingTpl>
    <div class="centering">
      Loading user data...
    </div>
  </ng-template>
</ng-container>
-->



<!--
 <amplify-authenticator>
  <ng-template
    amplifySlot="authenticated"
    let-user="user"
    let-signOut="signOut"
  >
    <h1>Welcome {{ user.username }}!</h1>
    <button (click)="signOut()">Sign Out</button>
  </ng-template>
</amplify-authenticator> -->

<!--
route: {{authenticator.route }}

<ng-container *ngIf="authenticator.authStatus === 'configuring'">
  Loading authentication...
</ng-container>

<ng-container *ngIf="authenticator.authStatus === 'authenticated'">
  <router-outlet> </router-outlet>
</ng-container>

<ng-container *ngIf="authenticator.authStatus === 'unauthenticated'">
  <amplify-authenticator></amplify-authenticator>
</ng-container>
-->

<amplify-authenticator [hideSignUp]="true">

  <ng-template amplifySlot="header">
    <div style="padding: var(--amplify-space-large); text-align: center">
      <img class="amplify-image" alt="Amplify logo" src="/assets/img/logo-abc_dj_256.png" />
    </div>
  </ng-template>


  <!-- <ng-template amplifySlot="sign-in-header">
    <h3>ABC_DJ login</h3>
  </ng-template> -->


  <!-- <ng-template amplifySlot="sign-in-footer">
    <div style="text-align: center">
      <button (click)="authenticator.toResetPassword()" class="amplify-button amplify-field-group__control"
        data-size="small" data-variation="link" type="button" style="font-weight: normal">
        Reset Password
      </button>

    </div>
  </ng-template> -->

  <ng-template amplifySlot="authenticated">
    <abcdj-authenticated>
      <router-outlet></router-outlet>
    </abcdj-authenticated>
  </ng-template>
</amplify-authenticator>
