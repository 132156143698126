export const groupBy = function (arr, criteria) {
  return arr.reduce((obj, item) => {
    // Check if the criteria is a function to run on the item or a property of it
    const key = typeof criteria === 'function' ? criteria(item) : item[criteria];

    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }

    // Push the value to the object
    obj[key].push(item);

    // Return the object to the next item in the loop
    return obj;
  }, {});
};

/** given an ordered array of numbers (ascending) find the values that is closest (or matching) the needle */
export const getClosestItemIndex = (haystack: number[], needle: number) => {
  if (needle > haystack[haystack.length - 1]) return haystack.length - 1;

  if (needle < haystack[0]) return 0;

  let left = 0;
  let right = haystack.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    if (haystack[mid] === needle) {
      return mid;
    }
    if (haystack[mid] < needle) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  // needle is not in the array, choose the closest value
  return needle - haystack[right] < haystack[left] - needle ? right : left;
};

export const getClosestItem = (haystack: number[], needle: number) => {
  const index = getClosestItemIndex(haystack, needle);
  return haystack[index];
};
