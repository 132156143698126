import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AudioPlayerTrack } from '../audio-player.interfaces';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: 'abcdj-audio-player-track-details',
    templateUrl: './audio-player-track-details.component.html',
    styleUrls: ['./audio-player-track-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, RouterLink]
})
export class AudioPlayerTrackDetailsComponent {
  @Input() track: AudioPlayerTrack;
}
