<mat-form-field>
  <mat-label *ngIf="label">{{label}}</mat-label>
  <input matInput [placeholder]="placeholder" [formControl]="spotifyIdControl" autocomplete="off" [required]="required"
    name="spotify">
  <a mat-icon-button matSuffix href="https://open.spotify.com/track/{{spotifyIdControl.value}}" target="_blank"
    [disabled]="spotifyIdControl.invalid || !spotifyIdControl.value">
    <mat-icon>launch</mat-icon>
  </a>
  <mat-error *ngIf="spotifyIdControl.errors?.['forbiddenUrl']">
    {{ spotifyIdControl.errors?.['forbiddenUrl'] }}
  </mat-error>
</mat-form-field>
