import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { hierarchy, InternSet, scaleOrdinal, schemeTableau10, select, treemap } from 'd3';
import { debounceTime, fromEvent, takeUntil, tap } from 'rxjs';
import { BaseComponent } from '../../common';
import { TreemapChartData } from '../chart-interfaces';
import { NgIf } from '@angular/common';
import { ComponentResizeDirective } from '../../component-resize/component-resize.directive';

@Component({
    selector: 'hdis-treemap-chart',
    templateUrl: 'treemap-chart.component.html',
    styleUrls: ['treemap-chart.component.scss'],
    standalone: true,
    imports: [ComponentResizeDirective, NgIf]
})
export class TreemapChartComponent extends BaseComponent implements OnInit {
  loading = true;

  error = false;

  @Input() public data: TreemapChartData;

  @ViewChild('chart', { static: true }) private chartElement: ElementRef;

  @ViewChild('wrapper', { static: true }) private chartContainer: ElementRef;

  private svg;

  private width: number;

  private height: number;

  ngOnInit(): void {
    this.svg = select(this.chartContainer.nativeElement);
    this.setSize();
    this.renderChart();

    fromEvent(window, 'resize').pipe(
      debounceTime(500), // emits once, then ignores subsequent emissions for 300ms, repeat...
      tap((event) => this.onResize(event)),
      takeUntil(this.unsubscribe),
    ).subscribe();
  }

  onResize(event: any) {
    console.log('onResize', event);
    this.setSize();
    this.cleanChart();
    this.renderChart();
  }

  setSize() {
    this.width = this.chartContainer.nativeElement.clientWidth;
    this.height = this.chartContainer.nativeElement.clientHeight;
    console.log(`new area ${this.width}x${this.height}`);
  }

  cleanChart = () => {
    // empty the chart
    console.log('clean chart by removing all items');
    this.svg.selectAll('*').remove();
  };

  renderChart = () => {
    this.loading = true;
    const colors = schemeTableau10;

    const root = hierarchy(this.data);
    root.sum((data) => data.value);
    root.sort((a, b) => b.value - a.value);

    const map = treemap<TreemapChartData>()
      // .tile(d3.treemapBinary)
      .size([this.width, this.height])
      .round(true)(root);
    const leaves = map.leaves();

    // Careful, copy/paste !!! Should be rewritten !!!
    const group = (d, n) => n.ancestors().slice(-2)[0].data.name;
    let zDomain;
    const G = group === null ? null : map.leaves().map((d) => group(d.data, d));
    zDomain = G;
    zDomain = new InternSet(zDomain);
    const color = group === null ? null : scaleOrdinal(zDomain, colors);
    // Careful, copy/paste !!! Should be rewritten !!!

    console.log(`render in area ${this.width}x${this.height}`);
    this.svg = select(this.chartElement.nativeElement)
      .attr('viewBox', `0 0 ${this.width} ${this.height}`)
      .attr('width', this.width)
      .attr('height', this.height)
      .style('background-color', '#EEE');

    this.svg.selectAll('rect')
      .data(leaves)
      .enter()
      .append('rect')
      .attr('x', (d) => d.x0)
      .attr('y', (d) => d.y0)
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
      .style('stroke', 'black')
      .style('fill', (d, i, j) => color(G[i]))
      .attr('class', 'rectangle');

    this.svg
      .selectAll('text')
      .data(leaves)
      .enter()
      .append('text')
      .attr('x', (d) => d.x0 + 3)
      .attr('y', (d) => d.y0 + 10)
      .text((d, i) => `${d.data.name} ${d.data?.value}`)
      .attr('font-size', '10px')
      .attr('fill', 'white');
    this.loading = false;
  };
}
