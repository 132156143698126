export class CustomError extends Error {
  __proto__: Error;

  constructor (
    public message: string,
    public details?: any
  ) {
    super(message);
    this.name = new.target.name;
    // see https://github.com/Microsoft/TypeScript/issues/13965 for this mess...
    const trueProto = new.target.prototype;
    this.__proto__ = trueProto;
  }
}
