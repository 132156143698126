import { Pipe, PipeTransform } from '@angular/core';
import { Lrc } from '@heardis/api-contracts';

@Pipe({
  name: 'timestampLyrics',
  standalone: true,
})
export class TimestampLyricsPipe implements PipeTransform {
  transform(value: Lrc[], ...args: unknown[]): string {
    return value?.map((lrc) => (lrc.line ? `${lrc.timestamp} - ${lrc.line}` : '')).join('\n') || '';
  }
}
