
<ng-container *ngFor="let step of steps; let isLast=last">
  <a class="breadcrumb" mat-button [routerLink]="step.link ? step.link : []" [class.last-chunk]="isLast">
    <span class="prefix" *ngIf="step.prefix">{{step.prefix}}: </span>

    <mat-icon *ngIf="step.icon" [fontIcon]="step.icon" />

    {{step.label}}

    <span class="suffix" *ngIf="step.suffix">{{step.suffix}}</span>
  </a>

  <mat-icon class="separator" inline *ngIf="!isLast">arrow_right</mat-icon>
</ng-container>
