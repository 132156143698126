import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { Observable } from 'rxjs';
import { debounceTime, startWith, switchMap, tap } from 'rxjs/operators';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { FieldValue } from '../_models';

interface WeightedFieldValue extends FieldValue {
  weight: number;
}

@Component({
  selector: 'hdis-enum-autocomplete',
  templateUrl: './enum-autocomplete.component.html',
  styleUrls: ['./enum-autocomplete.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EnumAutocompleteComponent), multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatLegacyFormFieldModule, MatLegacyChipsModule, NgFor, NgIf, MatIconModule, ReactiveFormsModule, MatLegacyAutocompleteModule, MatLegacyOptionModule, AsyncPipe],
})
export class EnumAutocompleteComponent implements OnChanges, ControlValueAccessor {
  @Input() fetchValuesFn: Function;

  @Input() label = 'values';

  propagateFn: Function;

  readonly = false;

  visible = true;

  selectable = true;

  removable = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  autocompleteControl = new UntypedFormControl();

  filteredOptions: Observable<FieldValue[]>;

  currentOptions: FieldValue[];

  selectedValues: WeightedFieldValue[];

  @ViewChild('fieldInput') fieldInput: ElementRef<HTMLInputElement>;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fetchValuesFn) {
      this.filteredOptions = this.autocompleteControl.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
        switchMap((value) => {
          /*
          when user writes in autocomplete input incoming value is string.
          when user selects an item from the dropdown the incoming value is FieldValue.
          */
          if (value) {
            return this.fetchValuesFn(value) as Observable<FieldValue[]>;
          }
          // this.addValue(value);
          return (this.fetchValuesFn() as Observable<FieldValue[]>).pipe(tap((values) => { this.currentOptions = values; }));
        }),
      );
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.readonly = isDisabled;
    if (isDisabled) {
      this.autocompleteControl.disable({ emitEvent: false });
    } else {
      this.autocompleteControl.enable({ emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  writeValue(formValues: [string, number][]): void {
    this.selectedValues = formValues.map((value) => {
      const option = this.currentOptions?.find((opt) => opt.value === value[0]);
      return {
        value: value[0],
        label: option?.label || value[0],
        weight: Math.floor(value[1] * 100),
      };
    });

    this.currentOptions?.filter((fieldValue) => formValues.includes(fieldValue.value)) || formValues.map((formValue) => ({ value: formValue, label: formValue }));
  }

  registerOnTouched(fn: any): void {
    console.debug('Method registerOnTouched not implemented.');
  }

  typeValue(event: MatChipInputEvent): void {
    const value = event.value.trim().toLowerCase();
    const match = this.currentOptions.find((field) => field.label === value);
    if (match) {
      this.addValue(match);
    } else {
      console.debug('no match for ', value);
    }
  }

  selectValue(event: MatAutocompleteSelectedEvent): void {
    this.addValue(event.option.value);
  }

  addValue(field: FieldValue) {
    this.selectedValues.push({
      ...field,
      weight: 0,
    });
    this.fieldInput.nativeElement.value = '';
    this.autocompleteControl.setValue(null);
    this.propagateFn(this.selectedValues.map((selectedField) => this.mapFormValue(selectedField)));
  }

  removeValue(field: FieldValue): void {
    const index = this.selectedValues.findIndex((selValue) => selValue.value === field.value);

    if (index >= 0) {
      this.selectedValues.splice(index, 1);
      this.propagateFn(this.selectedValues.map((selectedField) => this.mapFormValue(selectedField)));
    }
  }

  changeRatio($e, index) {
    this.selectedValues[index].weight = Number($e.target.value);
    this.propagateFn(this.selectedValues.map((selectedField) => this.mapFormValue(selectedField)));
  }

  mapFormValue(formValue: WeightedFieldValue): [string, number] {
    return [formValue.value, formValue.weight / 100];
  }
}
