import { ViewPreferences } from '@heardis/api-contracts';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { key, State } from './view-preferences.state';

export const getViewPreferences = createFeatureSelector<State>(key);

export const getTablesViewPreferences = createSelector(
  getViewPreferences,
  (preferences: ViewPreferences) => preferences.tables,
);

export const getTableViewPreference = (tableViewId: string) => createSelector(
  getTablesViewPreferences,
  (tables: any) => tables[tableViewId],
);

export const getUserLanguage = createSelector(
  getViewPreferences,
  (preferences: ViewPreferences) => preferences.language,
);

export const getSidebarBookmarksVisibility = createSelector(
  getViewPreferences,
  (preferences: ViewPreferences) => preferences.sidebar.bookmarks,
);
