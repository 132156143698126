import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ConnectedPosition, CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { WithAuthPipe } from '../../../pipes/with-auth/with-auth.pipe';

export interface ThumbnailCellRendererParams extends ICellRendererParams {
  icon?: string;
  label: string;
  hasImage: (row) => boolean;
  placeholderUrl: string;
  getImageUrl: (row) => Observable<string>;
}

@Component({
  selector: 'hdis-thumbnail-cell',
  templateUrl: './thumbnail-renderer.component.html',
  styleUrls: ['./thumbnail-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, CdkOverlayOrigin, CdkConnectedOverlay, WithAuthPipe, AsyncPipe],
})
export class ThumbnailCellRendererComponent implements AgRendererComponent {
  hasImage: boolean;

  isOpen: boolean;

  icon: string;

  iconTitle: string;

  iconColor: string;

  imageUrl$: Observable<string>;

  label: string;

  params: ThumbnailCellRendererParams;

  placeholderUrl: string;

  overlayPositions: ConnectedPosition[] = [
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
  ];

  agInit(params: ThumbnailCellRendererParams): void {
    this.params = params;
    if (params.data) {
      this.icon = params.data.icon || 'panorama';
      this.hasImage = params.hasImage ? params.hasImage(params.data) : true;
      this.placeholderUrl = params.placeholderUrl || 'assets/img/logo-abc_dj_1024.png';

      if (this.hasImage) {
        this.iconColor = 'inherit';
        this.iconTitle = '';
        if (params.getImageUrl) this.imageUrl$ = this.params.getImageUrl(params.data);
      } else {
        this.iconColor = '#ccc';
        this.iconTitle = 'image unavailable';
      }
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  onImgError(event) {
    // eslint-disable-next-line no-param-reassign
    event.target.src = this.placeholderUrl;
  }

  onEnter(event: MouseEvent) {
    if (!this.hasImage) return;
    /**
     * The first time the user enters the thumbnail we ask for the image url.
     * We do it here and not in agInit because if the url includes a temporary token (such as for track artworks)
     * and the user leaves the page open for a while, the preloaded token may expire and the image url may not be valid anymore
     */
    // first time get the image url (we get the image url when the user needs it to ensure that i)
    this.isOpen = true;
  }

  onLeave(event: MouseEvent) {
    if (!this.hasImage) return;
    this.isOpen = false;
  }
}
