<mat-form-field [floatLabel]="floatLabel">
  <mat-label>{{label}}</mat-label>
  <div class="color-input-wrapper">
    <input matInput [(ngModel)]="color" (ngModelChange)="inputChange($event)" [disabled]="isDisabled"
      [placeholder]="placeholder" />
    <div class="color-preview-icon-wrapper">
      <div *ngIf="color" class="color-preview" [style.backgroundColor]="color"></div>
      <mat-icon matSuffix [colorPicker]="color" [cpDisabled]="isDisabled"
        (colorPickerChange)="colorPickerChange($event)">
        palette</mat-icon>
    </div>
  </div>
</mat-form-field>
