import { createAction, props } from '@ngrx/store';
import { AudioPlayerTrack, AudioPlayerTrackAssetType, AudioPlayerTrackError, AudioPlayerTrackInfo } from '../audio-player.interfaces';

/** tracklist */
export const addTracksToNext = createAction('[Audio Player] add tracks to next', props<{ tracks: AudioPlayerTrack[] }>());
export const addTracksToQueue = createAction('[Audio Player] add tracks to queue', props<{ tracks: AudioPlayerTrack[] }>());
export const addTracksToNextAndPlay = createAction('[Audio Player] add tracks to next and play', props<{ tracks: AudioPlayerTrack[] }>());
export const cleanQueue = createAction('[Audio Player] clean queue');
export const cleanNext = createAction('[Audio Player] clean next');
export const playNextTrack = createAction('[Audio Player] play next track');
export const playPreviousTrack = createAction('[Audio Player] play previous track');
export const playTrackFromNext = createAction('[Audio Player] play track from play-next queue', props<{ index: number }>());
export const playTrackFromQueue = createAction('[Audio Player] play track from queue', props<{ index: number }>());

export const currentTrackInfoRequested = createAction('[Player/API] request current track info');
export const currentTrackInfoCached = createAction('[Player/API] load track info cached');
export const currentTrackInfoLoaded = createAction('[Player/API] load track info success', props<{ track: AudioPlayerTrackInfo }>());
export const currentTrackInfoFailed = createAction('[Player/API] load track info failure', props<{ track: AudioPlayerTrackError } >());
export const currentTrackInfoReady = createAction('[Player/API] send track to player');
export const currentTrackEditApproved = createAction('[Track Form] current track edit approved');

export const loadTrackFile = createAction('[Player/API] load track file');
export const loadTrackFileSuccess = createAction('[Player/API] load track file success');
export const loadTrackFileFailure = createAction('[Player/API] load track file error');
export const reloadCurrentTrack = createAction('[Player] reload track file');

/** playback controls */
export const setPlaybackRate = createAction('[Audio Player] set playback rate', props<{ rate: number }>());
export const playbackStarted = createAction('[Audio Player] played track');
export const playbackPaused = createAction('[Audio Player] paused track');
export const playbackStopped = createAction('[Audio Player] stopped track');
export const setPlaybackAutoplay = createAction('[Audio Player] set playback autoplay', props<{ autoplay: boolean }>());
export const setPreferredEdit = createAction('[Audio Player] set preferred edit', props<{ edit: AudioPlayerTrackAssetType }>());
// export const setVolume = createAction('[Audio Player] set volume', props<{ volume: number }>());

/** waveform */
export const setWaveformVisibility = createAction('[Audio Player] toggle waveform', props<{ isVisible: boolean }>());
export const waveformLoaded = createAction('[Audio Player] loaded track waveform');
export const showExtendedWaveform = createAction('[Audio Player] set waveform extended mode');
export const showStandardWaveform = createAction('[Audio Player] set waveform standard mode');
export const setWaveformZoom = createAction('[Audio Player] set waveform zoom', props<{ zoom: number }>());
export const setWaveformAutoscroll = createAction('[Audio Player] set waveform autoscroll', props<{ autoscroll: boolean }>());

/** editor */
export const prelisteningEnabled = createAction('[Audio Player] enabled editor prelistening');
export const prelisteningDisabled = createAction('[Audio Player] disabled editor prelistening');
export const snapToGridEnabled = createAction('[Audio Player] enabled editor snap-to-grid');
export const snapToGridDisabled = createAction('[Audio Player] disabled editor snap-to-grid');

export const trackEditorOpened = createAction('[Track Page] track editor opened', props<{ track: AudioPlayerTrack }>());
export const trackEditorClosed = createAction('[Track Page] track editor closed');

/** integration */
