import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { AudioPlayerArtworkComponent } from '../audio-player-artwork/audio-player-artwork.component';
import { AudioPlayerPlaybackControlsComponent } from '../audio-player-playback-controls/audio-player-playback-controls.component';
import { AudioPlayerProgressBarComponent } from '../audio-player-progress-bar/audio-player-progress-bar.component';
import { AudioPlayerTrackDetailsComponent } from '../audio-player-track-details/audio-player-track-details.component';
import { AudioPlayerMode, AudioPlayerTrackAsset, AudioPlayerWidgetViewModel } from '../audio-player.interfaces';
import { setPreferredEdit } from '../store/audio-player.actions';
import { selectPlayerWidgetViewModel } from '../store/audio-player.selectors';

@Component({
  selector: 'hdis-audio-player-widget',
  templateUrl: './audio-player-widget.component.html',
  styleUrls: ['./audio-player-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    AsyncPipe,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    AudioPlayerArtworkComponent,
    AudioPlayerProgressBarComponent,
    AudioPlayerPlaybackControlsComponent,
    AudioPlayerTrackDetailsComponent,
  ],
})
export class AudioPlayerWidgetComponent {
  AudioPlayerMode = AudioPlayerMode;

  private store = inject(Store);

  config$: Observable<AudioPlayerWidgetViewModel> = this.store.select(selectPlayerWidgetViewModel);

  changeEdit(asset: AudioPlayerTrackAsset) {
    this.store.dispatch(setPreferredEdit({ edit: asset.type }));
  }
}
