import { inject, Injectable } from '@angular/core';
import { Field, FieldType, Playlist, PlaylistExportJob, PlaylistStatus, PlaylistTrack, RequestRange, RequestSort, Resource } from '@heardis/api-contracts';
import { Icons } from '@heardis/hdis-ui';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Response } from '../_models/response';
import { EntityService } from './entity.service';
import { ProfileService } from './profile.service';
import { ScheduleService } from './schedule.service';

@Injectable({ providedIn: 'root' })
export class PlaylistService extends EntityService<Playlist> {
  baseUrl = `${environment.apiBaseUrl}${environment.endpoints.playlists}`;

  resource = Resource.PLAYLIST;

  entityId = 'playlist';

  prService = inject(ProfileService);

  scService = inject(ScheduleService);

  getEntityRoute(entityId: string): string[] {
    return ['/scheduling/playlists', entityId];
  }

  getPlaylistDays(playlist: Playlist): Date[] {
    const startDate = new Date(playlist.startDate);
    const endDate = new Date(playlist.endDate);
    let cursorDate = new Date(startDate);
    const days: Date[] = [];
    while (differenceInCalendarDays(endDate, cursorDate) >= 0) {
      days.push(cursorDate);
      cursorDate = addDays(cursorDate, 1);
    }
    return days;
  }

  getEntries(playlistId: string, range?: RequestRange, sort?: RequestSort[]): Observable<Response<PlaylistTrack>> {
    const url = `${this.baseUrl}/${playlistId}/entries`;
    const queryParams = this.parseQueryParams(range, sort);
    return this.http.get<any>(url, { params: queryParams }).pipe(
      map((res) => {
        const tracks = res.data as PlaylistTrack[];
        const retValue: Response<PlaylistTrack> = {
          totalElements: res.meta.total,
          isFirst: range.offset === 0, // untested
          isLast: ((range.offset + tracks.length) === res.meta.total), // untested
          content: tracks,
        };
        return retValue;
      }),
      take(1),
    );
  }

  getEntryFields(): Observable<Field[]> {
    return of([
      { name: '_id', type: FieldType.STRING, isSortable: false },
      { name: 'trackId', type: FieldType.STRING, isSortable: false },
      { name: 'startTime', type: FieldType.DATE, isSortable: false },
      { name: 'endTime', type: FieldType.DATE, isSortable: false },
      { name: 'trackTitle', type: FieldType.STRING, isSortable: false },
      { name: 'trackArtist', type: FieldType.STRING, isSortable: false },
      { name: 'trackDuration', type: FieldType.NUMBER, isSortable: false },
    ]);
  }

  getSchedulePlaylists(scheduleId: string, range?: RequestRange, sort?: RequestSort[]): Observable<Response<Playlist>> {
    return this.scService.getSchedulePlaylists(scheduleId, range, sort);
  }

  publishPlaylist(playlistId: string, revId: number) {
    return this.changeEntityStatus(playlistId, revId, PlaylistStatus.PUBLISHED);
  }

  generatePlaylist(playlistId: string) {
    const url = `${this.baseUrl}/${playlistId}/_generate`;
    return this.http.post<void>(url, {})
      .pipe(take(1));
  }

  exportPlaylist(playlistId: string, fileId: string, formatId: string, infoPrefix?: string, days?: string[]) {
    const url = `${this.baseUrl}/${playlistId}/_export`;
    return this.http.post<void>(url, { fileId, formatId, infoPrefix, days })
      .pipe(take(1));
  }

  getExportJobs(playlistId: string, fileId: string, range?: RequestRange, sort?: RequestSort[]): Observable<Response<PlaylistExportJob>> {
    const url = `${this.baseUrl}/${playlistId}/jobs`;
    let queryParams = this.parseQueryParams(range, sort);
    queryParams = queryParams.set('fileId', fileId);
    return this.http.get<PlaylistExportJob[]>(url, { params: queryParams, observe: 'response' })
      .pipe(map(this.handleResponseList), take(1));
  }

  getTableColumns() {
    return super.getTableColumns().pipe(
      map((columns) => columns.map((colDef) => {
        if (colDef.field === 'status') {
          return {
            ...colDef,
            headerValueGetter: () => '',
            type: [...colDef.type, 'iconColumn'],
            cellRendererParams: {
              icons: {
                [PlaylistStatus.DELETED]: Icons.STATUS_DELETED,
                [PlaylistStatus.DRAFT]: Icons.STATUS_DRAFT,
                [PlaylistStatus.READY]: Icons.STATUS_PENDING,
                [PlaylistStatus.PROCESSING]: Icons.STATUS_PROCESSING,
                [PlaylistStatus.ERROR]: Icons.STATUS_ERROR,
                [PlaylistStatus.PROCESSED]: Icons.STATUS_PROCESSED,
                [PlaylistStatus.PUBLISHED]: Icons.STATUS_PUBLISHED,
              },
              labels: {
                [PlaylistStatus.DELETED]: this.i18n.translate('entities.playlist.status.values.imported'),
                [PlaylistStatus.DRAFT]: this.i18n.translate('entities.playlist.status.values.draft'),
                [PlaylistStatus.ERROR]: this.i18n.translate('entities.playlist.status.values.deleted'),
                [PlaylistStatus.PROCESSED]: this.i18n.translate('entities.playlist.status.values.processed'),
                [PlaylistStatus.PROCESSING]: this.i18n.translate('entities.playlist.status.values.processing'),
                [PlaylistStatus.PUBLISHED]: this.i18n.translate('entities.playlist.status.values.published'),
                [PlaylistStatus.READY]: this.i18n.translate('entities.playlist.status.values.ready'),
              },
            },
          };
        } if (colDef.field === 'profileId') {
          return {
            ...colDef,
            type: ['entityColumn'],
            cellRendererParams: {
              fetchFn: this.getProfileLabel,
            },
          };
        } if (colDef.field === 'scheduleId') {
          return {
            ...colDef,
            type: ['entityColumn'],
            cellRendererParams: {
              fetchFn: this.getScheduleLabel,
            },
          };
        }
        return colDef;
      })),
    );
  }

  getProfileLabel = (profileId: string): Observable<string> => this.prService.getEntityLabel(profileId);

  getScheduleLabel = (profileId: string): Observable<string> => this.scService.getEntityLabel(profileId);
}
