import { HttpClient, HttpContext, HttpContextToken, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UploadResponse } from '@heardis/api-contracts';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, shareReplay } from 'rxjs/operators';
import { FileUploadResult, FileUploadStatus, MultiFileUploadConfig, MultiFileUploadResult } from './multi-upload.interfaces';

/** @TODO move this to the error.interceptor once the app/lib responsibilities are sorted out and we can import this token in this service */
export const SHOULD_UNAUTHORIZED_LOGOUT = new HttpContextToken<boolean>(() => true);

@Injectable({
  providedIn: 'root',
})
export class MultiUploadService {
  constructor(
    private http: HttpClient,
  ) { }

  public rawUploadFile(endpoint: string, file: File): Observable<HttpEvent<any>> {
    // create a new multipart-form for every file
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);

    // create a http-post request and pass the form
    // tell it to report the upload progress
    const reqConfig = new HttpRequest('POST', endpoint, formData, {
      reportProgress: true,
      context: new HttpContext().set(SHOULD_UNAUTHORIZED_LOGOUT, false),
    });

    // send the http-request and subscribe for progress-updates
    return this.http.request(reqConfig);
  }

  public uploadFile<T>(endpoint: string, file: File): Observable<FileUploadResult<T>> {
    const uploadStatus: FileUploadResult<T> = { status: FileUploadStatus.PENDING, total: file.size, loaded: 0, progress: 0, message: null, response: null };
    // send the http-request and subscribe for progress-updates
    return this.rawUploadFile(endpoint, file).pipe(
      filter(({ type }) => type === HttpEventType.Sent || type === HttpEventType.UploadProgress || type === HttpEventType.Response),
      map((event) => {
        // eslint-disable-next-line default-case
        switch (event.type) {
          case HttpEventType.Sent: {
            uploadStatus.status = FileUploadStatus.UPLOADING;
            break;
          }
          case HttpEventType.UploadProgress: {
            uploadStatus.loaded = event.loaded;
            break;
          }
          // it's a duplicate of HttpEventType.Response and generate unnecessary emissions
          // case HttpEventType.ResponseHeader: {
          //   if (event.status === 200) {
          //     uploadStatus.status = FileUploadStatus.COMPLETED;
          //     uploadStatus.loaded = file.size;
          //   } else {
          //     uploadStatus.status = FileUploadStatus.FAILED;
          //     uploadStatus.loaded = file.size;
          //     uploadStatus.message = event.statusText;
          //   }
          //   break;
          // }
          case HttpEventType.Response: {
            if (event.status === 200) {
              const [uploadResult] = event.body as PromiseSettledResult<T>[];
              if (uploadResult.status === 'fulfilled') {
                uploadStatus.status = FileUploadStatus.COMPLETED;
                uploadStatus.loaded = file.size;
                uploadStatus.response = uploadResult.value;
              } else if (uploadResult.status === 'rejected') {
                uploadStatus.status = FileUploadStatus.FAILED;
                uploadStatus.loaded = file.size;
                uploadStatus.message = uploadResult.reason;
              }
            } else {
              uploadStatus.status = FileUploadStatus.FAILED;
              uploadStatus.loaded = file.size;
              uploadStatus.message = event.statusText;
            }
            break;
          }
        }
        return uploadStatus;
      }),
      catchError((error) => of({ status: FileUploadStatus.FAILED, total: file.size, message: error.status })), // Close the progress-stream if we get an answer form the API
      // tap({
      //   next: status => console.log('next: ', file, status),
      //   error: err => console.error('error:', file, err),
      //   complete: () => console.log('complete:', file),
      // }),
      shareReplay(),
    );
  }

  public uploadFiles(endpoint: string, files: File[], userConfig?: Partial<MultiFileUploadConfig>): Observable<MultiFileUploadResult<UploadResponse>> {
    const config: MultiFileUploadConfig = {
      ...userConfig,
      maxConcurrency: 3,
    };
    const totalSize = files.reduce((acc, curr) => (acc + curr.size), 0);

    const uploadStatus: MultiFileUploadResult<UploadResponse> = {
      total: { status: FileUploadStatus.PENDING, total: totalSize },
      files: files.map((file) => ({ status: FileUploadStatus.PENDING, total: file.size })),
    };

    let prevFilesLoaded = 0;
    let countFilesLoaded = 0;

    const filesTotal = from(files).pipe(
      mergeMap(
        (file, index) => this.uploadFile<UploadResponse>(endpoint, file).pipe(map((fileUploadResult) => ({ fileUploadResult, index }))),
        config.maxConcurrency,
      ),
      map(({ fileUploadResult, index }) => {
        uploadStatus.files[index] = fileUploadResult;

        const { status, loaded, total } = fileUploadResult;

        if (status === FileUploadStatus.UPLOADING) {
          const totalLoaded = prevFilesLoaded + loaded;
          uploadStatus.total = {
            ...uploadStatus.total,
            status: FileUploadStatus.UPLOADING,
            loaded: totalLoaded,
            progress: Math.round((totalLoaded / totalSize) * 100),
          };
        } else if (status === FileUploadStatus.COMPLETED) {
          prevFilesLoaded += total;
          countFilesLoaded += 1;

          if (countFilesLoaded === files.length && uploadStatus.total.status !== FileUploadStatus.FAILED) {
            uploadStatus.total = {
              ...uploadStatus.total,
              status: FileUploadStatus.COMPLETED,
            };
          }
        } else if (status === FileUploadStatus.FAILED) {
          prevFilesLoaded += total;
          countFilesLoaded += 1;
          uploadStatus.total = {
            ...uploadStatus.total,
            status: FileUploadStatus.FAILED,
          };
        }

        return uploadStatus;
      }),
    );

    return filesTotal;
  }
}
