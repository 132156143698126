import { Injectable } from '@angular/core';
import { GetObjectCommand, GetObjectCommandInput, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { S3Location } from '@heardis/api-contracts';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(
    private authService: AuthService,
  ) { }

  async getObjectUrl(location: S3Location, filename?: string): Promise<string> {
    const params: GetObjectCommandInput = { ...location };
    if (filename) params.ResponseContentDisposition = `attachment; filename ="${filename}"`;
    const command = new GetObjectCommand(params);
    const client = await this.getClient();
    return getSignedUrl(client, command, { expiresIn: 900 });
  }

  async getRawObject(location: S3Location): Promise<string> {
    const client = await this.getClient();
    const command = new GetObjectCommand(location);
    return client.send(command)
      .then((data) => (new Response(data.Body as ReadableStream, {}).text()));
  }

  async getObject<T>(location: S3Location): Promise<T> {
    const client = await this.getClient();
    const command = new GetObjectCommand(location);
    return client.send(command)
      .then((data) => new Response(data.Body as ReadableStream, {}).json())
      .catch((err) => {
        console.error('unable to download file', err);
        throw err;
      });
  }

  async downloadObject(location: S3Location, filename?: string) {
    /** @todo use filehelpers.downloadFromUrl */
    const downloadLink = document.createElement('a');
    downloadLink.href = await this.getObjectUrl(location, filename);
    // downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  locationToString(location: S3Location): string {
    return `s3://${location.Bucket}/${location.Key}`;
  }

  locationFromString(url: string): S3Location {
    const tokens = url.split('/');
    const protocol = tokens.shift();
    if (protocol !== 's3:') throw new Error(`invalid resource url ${url}`);
    tokens.shift(); // remove empty item from s3://

    return {
      Bucket: tokens.shift(),
      Key: tokens.join('/'),
    };
  }

  /**
   * @deprecated not tested, not working, do not use, get the presigned url instead
   * @param location
   * @param filename
   */
  private downloadBlob(blob, filename) {
    // const url = URL.createObjectURL(blob.Body);
    // const url = URL.createObjectURL(new Blob(blob.Body, { type: "application/zip" }))
    const url = URL.createObjectURL(new Blob(blob.Body, { type: 'application/zip' }));
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  private getClient(): Promise<S3Client> {
    // Initialize the Amazon Cognito credentials provider
    // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-integrating-user-pools-with-identity-pools.html
    return this.authService.getAWSCredentials()
      .then((credentials) => new S3Client({ apiVersion: '2006-03-01', region: environment.aws.region, credentials }));
  }

  // Storage.configure({
  //   bucket: environment.aws.schedulesBucketName,
  //   region: 'eu-west-1',
  //   customPrefix: {
  //     public: ''
  //   }
  // })
  // return Storage.get(fileKey, {
  //   download: true,
  //   contentType: 'application/json'
  // })
  //   .then((blob: any) => blob.Body.text())
  //   .then(content => JSON.parse(content) as PlaylistTrack[])
  //   .catch(err => {
  //     console.error('unable to fetch playlist file', err);
  //     throw new Error(`unable to fetch playlist s3://${environment.aws.schedulesBucketName}/${fileKey}`)
  //   })
}
