import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Audio, ImagePreset } from '@heardis/api-contracts';
import { WithAuthPipe } from 'libs/hdis-ui/src/lib/pipes/with-auth/with-auth.pipe';
import { SongService } from '../../../_services/song.service';
import { TrackArtworkByIdPipe } from '../../pipes/track-artwork-by-id.pipe';
import { TrackArtworkPipe } from '../../pipes/track-artwork.pipe';

@Component({
  selector: 'abcdj-track-artwork',
  templateUrl: './track-artwork.component.html',
  styleUrls: ['./track-artwork.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TrackArtworkByIdPipe, TrackArtworkPipe, AsyncPipe, WithAuthPipe, NgIf],
})
export class TrackArtworkComponent {
  @Input() format: ImagePreset;

  @Input() track: Audio;

  @Input() trackId: string;

  @Input() label: string;

  @Input() placeholderUrl = 'assets/img/no-artwork.png';

  trackService = inject(SongService);
}
