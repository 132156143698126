import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AudioPlayerStoreModule } from '../audio-player/store';
import { UploadStoreModule } from '../library/inbox/_state/upload';
import { PlaylistDayStoreModule } from '../scheduling/_state/playlist-day/playlist-day-store.module';
import { BookmarksStoreModule } from './bookmarks';
import { NavigationStoreModule } from './navigation';
import { UserStoreModule } from './user';
import { ViewPreferencesStoreModule } from './view-preferences';

@NgModule({
  imports: [
    CommonModule,
    UserStoreModule,
    UploadStoreModule,
    BookmarksStoreModule,
    ViewPreferencesStoreModule,
    NavigationStoreModule,
    AudioPlayerStoreModule,
    PlaylistDayStoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
  ],
  declarations: [],
})
export class RootStoreModule { }
