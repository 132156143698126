import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { slugify } from '@heardis/hdis-ui';
import { map, Observable, startWith } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { timezones } from '../timezone-picker.types';

@Component({
  selector: 'stream-timezone-item',
  templateUrl: './timezone-item.component.html',
  styleUrls: ['./timezone-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule, FlexLayoutModule],
})
export class TimezoneItemComponent implements OnInit {
  options = timezones;

  filteredOptions: Observable<string[]>;

  @Input() formGroup: UntypedFormGroup;

  ngOnInit() {
    this.filteredOptions = this.formGroup.get('code').valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value)),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.formGroup.get('slug').setValue(slugify(event.option.value.split('/')[1]));
  }
}
