import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'mlm-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss'],
  standalone: true,
  imports: [TranslocoModule, MatButtonModule],
})
export class ServiceUnavailableComponent {
}
