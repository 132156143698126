import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { AudioPlayerTrack } from '../audio-player.interfaces';
import { cleanNext, cleanQueue, playTrackFromNext, playTrackFromQueue } from '../store/audio-player.actions';

@Component({
  selector: 'abcdj-audio-player-tracklist',
  templateUrl: './audio-player-tracklist.component.html',
  styleUrls: ['./audio-player-tracklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatListModule, MatButtonModule, NgIf, MatMenuModule, NgFor, MatDividerModule],
})
export class AudioPlayerTracklistComponent {
  @Input() current: AudioPlayerTrack;

  @Input() next: AudioPlayerTrack[];

  @Input() queue: AudioPlayerTrack[];

  store = inject(Store);

  getTrackId = (track: AudioPlayerTrack) => track.id;

  loadFromNext = (index: number) => this.store.dispatch(playTrackFromNext({ index }));

  loadFromQueue = (index: number) => this.store.dispatch(playTrackFromQueue({ index }));

  cleanQueue = () => this.store.dispatch(cleanQueue());

  cleanNext = () => this.store.dispatch(cleanNext());
}
