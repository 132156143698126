import { createSelector } from '@ngrx/store';
import { State, key } from './bookmarks.state';
import { State as AppState } from '../state';

export const selectFeature = (state: AppState) => state[key];

export const getBookmarks = createSelector(
  selectFeature,
  (state: State) => state,
);

export const getBookmark = (bookmarkId: string) => createSelector(
  selectFeature,
  (state: State) => state[bookmarkId],
);
