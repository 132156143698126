import * as UserStoreState from './user/user.state';
import * as BookmarksStoreState from './bookmarks/bookmarks.state';
import * as NavigationStoreState from './navigation/state';
import * as ViewPreferencesStoreState from './view-preferences/view-preferences.state';
import * as AudioPlayerStoreState from '../audio-player/store/audio-player.state';

export interface State {
  [UserStoreState.key]: UserStoreState.State;
  [BookmarksStoreState.key]: BookmarksStoreState.State;
  [ViewPreferencesStoreState.key]: ViewPreferencesStoreState.State;
  [NavigationStoreState.key]: NavigationStoreState.State;
  [AudioPlayerStoreState.featureKey]: AudioPlayerStoreState.AudioPlayerState;
}
