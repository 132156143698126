<mat-form-field (click)="openOverlay()" #trigger
  [ngClass]="{'hdis-enum-picker-form-field': true, 'disabled': readonly}">
  <mat-label>{{label}}</mat-label>
  <input matInput *ngIf="inline" [value]="formattedValue" readonly>
  <textarea matInput *ngIf="!inline" cdkOverlayOrigin #trigger="cdkOverlayOrigin" cdkTextareaAutosize
    #autosize="cdkTextareaAutosize" disabled>{{formattedValue}}</textarea>
</mat-form-field>

<!-- <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  cdkConnectedOverlayPanelClass="hdis-enum-picker-form-field-panel" [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayPush]="true" [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayFlexibleDimensions]="false" (backdropClick)="closeOverlay()">

  <hdis-enum-picker #enumPicker> </hdis-enum-picker>
</ng-template> -->
