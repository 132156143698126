import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { isValidUrl } from '../../common';

@Component({
  standalone: true,
  selector: 'hdis-spotify-field',
  templateUrl: './spotify-field.component.html',
  styleUrls: ['./spotify-field.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SpotifyFormFieldComponent), multi: true },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
  ],
})
export class SpotifyFormFieldComponent implements OnInit, ControlValueAccessor {
  @Input() label = 'Spotify ID';

  @Input() placeholder = 'enter a valid track URL or ID...';

  @Input() required = false;

  initValue: string;

  spotifyIdControl: UntypedFormControl = new UntypedFormControl('', this.spotifyIdValidator());

  propagateFn: (newValue: string) => void;

  writeValue(obj: any): void {
    this.initValue = obj;
    this.spotifyIdControl.reset(this.initValue, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.spotifyIdControl.disable({ emitEvent: false });
    }
  }

  ngOnInit(): void {
    this.spotifyIdControl.valueChanges.subscribe((newValue) => {
      const spotifyId = this.getSpotifyIdFromUrl(newValue);
      this.propagateFn(spotifyId || newValue);
    });
  }

  spotifyIdValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isUrl = isValidUrl(control.value);
      if (isUrl) {
        if (control.value?.search(/spotify/gi) < 0) return { forbiddenUrl: 'Invalid url' };
        if (control.value?.search(/artist/gi) >= 0) return { forbiddenUrl: 'Invalid artist url' };
        if (control.value?.search(/album/gi) >= 0) return { forbiddenUrl: 'Invalid album url' };
        if (control.value?.search(/track/gi) < 0) return { forbiddenUrl: 'Invalid url' };
      }
      return null;
    };
  }

  getSpotifyIdFromUrl(newValue: string): string {
    if (newValue.search(/track/gi) < 0) return null;
    const { pathname } = new URL(newValue);
    const [spotifyId] = pathname.match(/([^:|/]+)?$/);
    if (spotifyId) this.spotifyIdControl.patchValue(spotifyId);
    return spotifyId;
  }
}
