import { ColDef } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { Field, FieldType, PlaylistTrack, Resource, Scope } from '@heardis/api-contracts';
import { FieldValue, LocalizedFieldCondition, mapFieldsToColumnDefinitions, MetadataProvider } from '@heardis/hdis-ui';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../_services/auth.service';
import { PoolService } from '../../_services/pool.service';
import { SongService } from '../../_services/song.service';
import { StorageService } from '../../_services/storage.service';
import { getUserLanguage } from '../../_state/view-preferences/view-preferences.selectors';

@Injectable({ providedIn: 'root' })
export class PlaylistTracksService implements MetadataProvider {
  entityId = 'playlist.track';

  playlistTrackfields: Field[] = [
    { name: '_id', type: FieldType.STRING, isSortable: true },
    { name: 'start', type: FieldType.DATE, isSortable: true },
    { name: 'end', type: FieldType.DATE, isSortable: true },
    { name: 'duration', type: FieldType.STRING, isSortable: true },
    { name: 'file', type: FieldType.OBJECT, isSortable: true, isSearchable: true },
    { name: 'poolIds', type: FieldType.STRING, isSearchable: false, isSortable: false },
    { name: '_facets', type: FieldType.STRING, isSearchable: false, isSortable: false },
    { name: 'ts', type: FieldType.STRING, isSearchable: false, isSortable: false },
  ];

  constructor(
    private sService: SongService,
    private pService: PoolService,
    private storage: StorageService,
    private i18n: TranslocoService,
    private store: Store,
    private authService: AuthService,
  ) {
  }

  getFieldValues(fieldName: string): Observable<FieldValue[]> {
    return this.sService.getFieldValues(fieldName);
  }

  getQueryConditions(): Observable<LocalizedFieldCondition[]> {
    return of([]);
  }

  public getEntityFields(): Observable<Field[]> {
    return combineLatest([
      of(this.playlistTrackfields),
      // playlist tracks only include track metadata and source info, so we exclude other fields
      this.sService.getEntityFields().pipe(map((fields) => fields.filter((field) => this.isAudioMetadata(field.name)))),
    ]).pipe(
      // it's actually not all the source meta
      map(([playlistTrackFields, trackFields]) => (
        [...playlistTrackFields, ...trackFields].sort((a, b) => {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;
          return 0;
        })
      )),
    );
  }

  public getQueryFields(): Observable<Field[]> {
    return combineLatest([
      of(this.playlistTrackfields),
      this.sService.getQueryFields().pipe(map((fields) => fields.filter((field) => this.isAudioMetadata(field.name)))),
    ]).pipe(
      // it's actually not all the source meta
      map(([playlistTrackFields, trackFields]) => (
        [...playlistTrackFields, ...trackFields].sort((a, b) => {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;
          return 0;
        })
      )),
    );
  }

  getTableColumns(): Observable<ColDef[]> {
    const playlistTrackColumns$ = this.store.select(getUserLanguage).pipe(
      map((lang) => {
        const columns = mapFieldsToColumnDefinitions(this.playlistTrackfields);
        return columns.map((column) => {
          const parsedColumn: ColDef = {
            ...column,
            headerName: this.i18n.translate(`entities.${this.entityId}.${column.headerName}`),
          };
          if (column.field === 'poolIds') {
            parsedColumn.type = ['entityColumn'];
            parsedColumn.cellRendererParams = {
              fetchFn: this.getPoolLabel,
            };
          }
          if (column.field === 'file') {
            /**
             * if we wanted to keep the file object as the underlying value of the cell we would have to define:
             * - a valueFormatter to show the name of the file
             * - a comparator to allow sorting (on client-side mode of course)
             * - a filterValueGetter to allow filtering (on client-side mode of course)
             */
            // parsedColumn.valueFormatter = ({ value: file }: { value: PlaylistTrackFile }) => ((file.type === 'edit' ? file.name : file.type) || 'source');
            // parsedColumn.comparator = (fileA:PlaylistTrackFile, fileB: PlaylistTrackFile) => (fileA.type === 'edit' ? fileA.name : 'source').localeCompare(fileB.type === 'edit' ? fileB.name : 'source');
            // parsedColumn.filterValueGetter = (params) => ((params.getValue('file').type === 'edit' ? params.getValue('file').name : params.getValue('file').type) || 'source');

            /**
             * however, this case is fairly simple so we just convert the object to a string, and get sorting and filtering for free (inherited from the default aggrid column)
             */
            parsedColumn.valueGetter = (params) => ((params.data.file.type === 'edit' ? params.data.file.name : params.data.file.type) || 'source');

            /**
             * if we wanted the label to link to the track details page, on the editor tab, with the edit selected (that would be mindblowing)
             * we would need a custom cell renderer to handle the cell as an angular component and benefit from the router ant other stuff
             */
          }
          return parsedColumn;
        });
      }),
    );

    return combineLatest([
      playlistTrackColumns$,
      this.sService.getTableColumns().pipe(map((columns) => columns.filter((column) => this.isAudioMetadata(column.field)))),
    ]).pipe(
      map(([playlistTrackColumns, trackColumns]) => ([...playlistTrackColumns, ...trackColumns].sort((a, b) => {
        if (a.headerName > b.headerName) return 1;
        if (b.headerName > a.headerName) return -1;
        return 0;
      })
      )),
    );
  }

  public getPlaylistFile(playlistId: string, fileId: string, day?: string): Promise<PlaylistTrack[]> {
    const fileKey = day ? `${playlistId}/${fileId}/${day}.json` : `${playlistId}/${fileId}.json`;
    return this.storage.getObject<PlaylistTrack[]>({
      Bucket: environment.aws.schedulesBucketName,
      Key: fileKey,
    }).catch((err) => {
      console.error('unable to fetch playlist file', err);
      throw new Error(`unable to fetch playlist s3://${environment.aws.schedulesBucketName}/${fileKey}`);
    });
  }

  getPlaylistTracks = (playlistId: string, fileId: string, day: string): Observable<PlaylistTrack[]> => from(this.getPlaylistFile(playlistId, fileId, day));

  getPlaylistFiles = (playlistId: string, fileId: string, days: Date[]): Observable<PlaylistTrack[][]> => forkJoin(days.map((day) => from(this.getPlaylistFile(playlistId, fileId, format(day, 'yyyy-MM-dd')))));

  private isAudioMetadata(fieldName) {
    return fieldName.startsWith('metadata.') || fieldName.startsWith('source.');
  }

  getPoolLabel = (poolId: string): Observable<string> => ((poolId === 'whitelist') ? of('whitelist') : this.pService.getEntityLabel(poolId));

  canRead = () => this.authService.hasPermission(Resource.TRACK, Scope.READ);
}
