export const unCamelCase = (str: string) => str
// insert a space between lower & upper
  .replace(/([a-z])([A-Z])/g, '$1 $2')
// space before last upper in a sequence followed by lower
  .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
// uppercase the first character
  .replace(/^./, (innerString) => innerString.toUpperCase());

export const toKebabCase = (str: string): string => str
  .replace(/[\s_]+|([a-z0-9])(?=[A-Z])/g, '$1-')
  .toLowerCase();

export const convertToHumanReadable = (fieldName: string): string => {
  const fieldNameSplitted = fieldName.split('.');
  let label: string;
  if (fieldNameSplitted.length === 3) {
    label = `${unCamelCase(fieldNameSplitted[1])}: ${unCamelCase(fieldNameSplitted[2])}`;
  } else {
    const lastItem = fieldNameSplitted.pop();
    label = lastItem ? unCamelCase(lastItem) : 'N/A';
  }
  return label;
};

export const randomId = (length = 12): string => {
  const idLength = Math.max(0, Math.min(length, 12));
  return Math.random().toString(36).substring(2, idLength);
};

export const slugify = (string: string, separator = '-') => string
  .toString()
  .normalize('NFD') // split an accented letter in the base letter and the acent
  .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
  .toLowerCase()
  .trim()
  .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
  .replace(/\s+/g, separator);

export const isValidUrl = (url: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};
