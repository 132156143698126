import { ColumnState } from '@ag-grid-community/core';
import { ViewPreferences } from '@heardis/api-contracts';
import { createAction, props } from '@ngrx/store';
import { State } from './view-preferences.state';

/** persistence actions */
export const initUserPreferences = createAction(
  '[ViewPreferences] Init user view preferences',
  props<{ viewPreferences: ViewPreferences }>(),
);
export const persistViewPreferencesSuccess = createAction(
  '[ViewPreferences] Persist View Preferences Success',
  props<{ viewPreferences: State }>(),
);
export const persistViewPreferencesFailure = createAction(
  '[ViewPreferences] Persist View Preferences Failure',
  props<{ error: Error }>(),
);

/** table preferences */
export const updateTableViewPreferences = createAction(
  '[ViewPreferences] Update table view preferences',
  props<{ tableId: string; tableState: ColumnState[] }>(),
);
export const resetTableViewPreferences = createAction(
  '[ViewPreferences] Reset table view preferences',
  props<{ tableId: string }>(),
);
export const resetTablesViewPreferences = createAction(
  '[ViewPreferences] Reset tables view preferences',
);

/** language preferences */
export const setLanguage = createAction(
  '[ViewPreferences] Update UI language',
  props<{ langId: string }>(),
);

/** sidebar preferences */
export const showSidebarBookmarks = createAction(
  '[ViewPreferences] Show bookmarks in sidebar',
);
export const hideSidebarBookmarks = createAction(
  '[ViewPreferences] Hide bookmarks in sidebar',
);

/** player preferences */
export const setPlayerArtworkVisibility = createAction(
  '[ViewPreferences] Set player artwork visibility',
  props<{ isVisible: boolean }>(),
);
export const setPlayerTracklistVisibility = createAction(
  '[ViewPreferences] Set player tracklist visibility',
  props<{ isVisible: boolean }>(),
);
