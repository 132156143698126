import { createReducer, on } from '@ngrx/store';
import { hideSidebarBookmarks, initUserPreferences, resetTablesViewPreferences, resetTableViewPreferences, setLanguage, setPlayerArtworkVisibility, setPlayerTracklistVisibility, showSidebarBookmarks, updateTableViewPreferences } from './view-preferences.actions';
import { initialState, State } from './view-preferences.state';

export const reducer = createReducer(
  initialState,

  on(initUserPreferences, (state: State, { viewPreferences }) => ({
    ...state,
    ...(viewPreferences.language ? { language: viewPreferences.language } : {}),
    ...(viewPreferences.tables ? { tables: viewPreferences.tables } : {}),
    ...(viewPreferences.sidebar ? { sidebar: viewPreferences.sidebar } : {}),
  })),

  on(updateTableViewPreferences, (state: State, { tableId, tableState }) => ({
    ...state,
    tables: {
      ...state.tables,
      [tableId]: tableState,
    },
  })),
  on(resetTableViewPreferences, (state: State, { tableId }) => {
    const { [tableId]: currTable, ...otherTables } = state.tables;
    return {
      ...state,
      tables: otherTables,
    };
  }),
  on(resetTablesViewPreferences, (state: State) => ({
    ...state,
    tables: null,
  })),

  on(setLanguage, (state: State, { langId }) => ({
    ...state,
    language: langId,
  })),

  on(showSidebarBookmarks, (state: State) => ({
    ...state,
    sidebar: {
      ...state.sidebar,
      bookmarks: true,
    },
  })),
  on(hideSidebarBookmarks, (state: State) => ({
    ...state,
    sidebar: {
      ...state.sidebar,
      bookmarks: false,
    },
  })),
);
