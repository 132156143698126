<h4 mat-dialog-title *ngIf="data.title">{{data.title}}</h4>
<button class="close-drawer-button" mat-icon-button (click)="dialogRef.close()" tabindex="-1">
  <mat-icon>close</mat-icon>
</button>

<mat-dialog-content>
  <p *ngIf="data.message" style="font-size: large; font-weight:bold">{{data.message}}</p>
  <p *ngIf="data.subMessage" [innerHtml]="data.subMessage"></p>
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="data.type === TYPE.CONFIRM">
  <button mat-flat-button (click)="dialogRef.close(false)">{{data.cancel}}</button>
  <button mat-flat-button color="primary" (click)="dialogRef.close(true)">{{data.confirm}}</button>
</mat-dialog-actions>

<mat-dialog-actions align="end" *ngIf="data.type === TYPE.ACKNOWLEDGE">
  <button mat-flat-button color="primary" class="abcdj-button"
    (click)="dialogRef.close(true)">{{data.acknowledge}}</button>
</mat-dialog-actions>

<mat-dialog-actions align="end" *ngIf="data.type === TYPE.CHOOSE">
  <button mat-flat-button (click)="dialogRef.close(false)">{{data.cancel}}</button>
  <button mat-flat-button *ngFor="let opt of data.options | keyvalue" color="primary" class="abcdj-button"
    (click)="dialogRef.close(opt.key)">{{opt.value}}</button>
</mat-dialog-actions>
