import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UserService } from '../../_services/user.service';
import { loadBookmarks } from '../bookmarks/bookmarks.actions';
import { initUserPreferences } from '../view-preferences/view-preferences.actions';
import * as featureActions from './user.actions';

@Injectable()
export class UserStoreEffects {
  constructor(
    private uService: UserService,
    private actions$: Actions,
  ) { }

  loadUserInfo$ = createEffect(() => this.actions$.pipe(
    ofType(featureActions.initUser),
    distinctUntilChanged(),
    switchMap(() => this.uService.fetchUserData()),
    switchMap(({ viewPreferences, bookmarks }) => ([
      featureActions.setUserInfo({ success: true }),
      initUserPreferences({ viewPreferences }),
      loadBookmarks({ bookmarks })
    ])),
    catchError(() => of(featureActions.setUserInfo({ success: false })))
  ))
}
