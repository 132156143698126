import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const DEPLOYMENT_PROVIDER = new InjectionToken<DeploymentProvider>('DEPLOYMENT_PROVIDER');
export interface DeploymentProvider {
  /**
   * given the deployment id, returnes the list of versions for the correspondent artifact (e.g. static website)
   * @param deploymentId
   */
  getArtifactVersions(deploymentId: string, unstable: boolean): Observable<string[]>;
}
