import { Injectable } from '@angular/core';
import { FileUploadResult, FileUploadStatus, MultiUploadService } from '@heardis/hdis-ui';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { InboxService } from 'apps/mlm/src/app/library/inbox/_services/inbox.service';
import { environment } from 'apps/mlm/src/environments/environment';
import { filter, from, map, mergeMap, of, switchMap } from 'rxjs';
import { UploadResponse } from '@heardis/api-contracts';
import { FileService } from '../../_services/file.service';
import * as featureActions from './upload.actions';
import { UploadState } from './upload.reducer';
import { selectFile, selectUploadableFiles } from './upload.selectors';

const MAX_CONCURRENT_REQUESTS = environment.platform.upload.maxConcurrentRequests;
const MAX_CONCURRENT_PREPROCESSING = 1;

@Injectable()
export class UploadStoreEffects {
  constructor(
    private iService: InboxService,
    private fService: FileService,
    private muService: MultiUploadService,
    private actions$: Actions,
    private store$: Store<UploadState>,
  ) {
  }

  addMusicFile$ = createEffect(() => this.actions$.pipe(
    ofType(featureActions.addFile),
    mergeMap(
      (action) => {
        if (action.file.type.startsWith('audio/')) {
          return from(this.fService.parseAudioFile(action.file)).pipe(
            map((metadata) => featureActions.annotateFile({ id: action.id, metadata })),
          );
        }
        return of(featureActions.annotateFile({ id: action.id, metadata: {} }));
      },
      MAX_CONCURRENT_PREPROCESSING,
    ),
  ));

  uploadAllRequest$ = createEffect(() => this.actions$.pipe(
    ofType(featureActions.uploadAllFiles),
    concatLatestFrom((action) => this.store$.select(selectUploadableFiles)),
    switchMap(([action, uploadObjects]) => {
      const actions = uploadObjects.map((uploadObject) => featureActions.uploadFile({ id: uploadObject.id }));
      return actions;
    }),
  ));

  uploadRequest$ = createEffect(() => this.actions$.pipe(
    ofType(featureActions.uploadFile),
    concatLatestFrom((action) => this.store$.select(selectFile(action.id))),
    // concatMap enqueue multiple emissions (i.e. upload requests), be cautious with backpressure, maybe want to move to mergeMap with a reasonable concurrency
    mergeMap(
      ([action, uploadObject]) => {
        const upload$ = this.muService.uploadFile<UploadResponse>(this.iService.getAudioUploadEndpoint(), uploadObject.file).pipe(
          filter((status) => !!status),
          map((status) => this.getActionFromUploadStatus(action.id, status)),
        );
        return upload$;
      },
      MAX_CONCURRENT_REQUESTS,
    ),
  ));

  private getActionFromUploadStatus(id: string, { status, loaded, message }: FileUploadResult<UploadResponse>) {
    // eslint-disable-next-line default-case
    switch (status) {
      case FileUploadStatus.UPLOADING: {
        return featureActions.uploadFileProgress({ id, uploaded: loaded });
      }
      case FileUploadStatus.COMPLETED: {
        return featureActions.uploadFileSuccess({ id });
      }
      case FileUploadStatus.FAILED: {
        return featureActions.uploadFileFailure({ id, error: message });
      }
      default:
        return null;
    }
  }
}
