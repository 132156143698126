export const removeEmpty = (obj) => Object.entries(obj).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {});

export const getPropertyByPath = (object: any, path: string[], defaultValue?: any) => {
  const recur = (object, path) => {
    if (object === undefined) {
      return defaultValue;
    }
    if (path.length === 0) {
      return object;
    }
    return recur(object[path[0]], path.slice(1));
  };
  return recur(object, path);
};
