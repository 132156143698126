import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { ConnectedPosition, Overlay, OverlayConfig, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ComponentRef, forwardRef, Inject, Injector, Input, OnChanges, OnInit, Optional, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyFormField as MatFormField, MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';
import { I18N_CONTEXT } from '../../common';
import { EnumPickerComponent } from '../picker/enum-picker.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'hdis-enum-picker-form-field',
    templateUrl: './enum-picker-form-field.component.html',
    styleUrls: ['./enum-picker-form-field.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EnumPickerFormFieldComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [MatLegacyFormFieldModule, NgClass, ExtendedModule, NgIf, MatLegacyInputModule, TextFieldModule, CdkOverlayOrigin]
})
export class EnumPickerFormFieldComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() fieldName: string;

  @Input() separator: string = ', ';

  @Input() label: string = 'value';

  @Input()
  get inline(): boolean {
    return this._inline;
  }
  set inline(value) {
    this._inline = coerceBooleanProperty(value);
  }
  _inline: boolean = false;

  @Input('max')
  get maxSelection(): number {
    return this._maxSelection;
  }
  set maxSelection(value) {
    this._maxSelection = coerceNumberProperty(value);
  }
  _maxSelection: number;

  @Input()
  get canShowInvalidValues(): boolean {
    return this._canShowInvalidValues;
  }
  set canShowInvalidValues(value) {
    this._canShowInvalidValues = coerceBooleanProperty(value);
  }
  _canShowInvalidValues: boolean = false;

  @ViewChild('trigger') trigger: MatFormField;

  readonly: boolean = false;
  isActive: boolean = false;

  value: any;
  formattedValue: string;

  propagateFn: Function;

  picker: EnumPickerComponent;
  release$: Observable<any>;

  overlayPositions: ConnectedPosition[] = [
    // { originY: "bottom", overlayY: "top", originX: "center", overlayX: "center" },
    // { originY: "top", overlayY: "bottom", originX: "center", overlayX: "center" },
    { originX: "start", originY: "bottom", overlayX: "start", overlayY: "top", },
    { originX: "start", originY: "top", overlayX: "start", overlayY: "bottom", },
    { originX: "center", originY: "bottom", overlayX: "center", overlayY: "top", },
    { originX: "center", originY: "top", overlayX: "center", overlayY: "bottom", },
    { originX: "end", originY: "bottom", overlayX: "end", overlayY: "top", },
    { originX: "end", originY: "top", overlayX: "end", overlayY: "bottom", },
    // { originX: "start", originY: "center", overlayX: "end", overlayY: "center", },
    // { originY: "bottom", overlayY: "top", originX: "center", overlayX: "end" },
    // { originY: "top", overlayY: "bottom", originX: "center", overlayX: "end" },
  ]

  options$: Observable<{ value: string, visible: boolean }[]>

  constructor(
    private injector: Injector,
    private overlay: Overlay,
    private i18n: TranslocoService,
    @Optional() @Inject(I18N_CONTEXT) public i18nContext: string,
  ) {
  }

  writeValue(value: any): void {
    console.debug(`[enum-picker-form-field] for ${this.fieldName} `, { active: this.isActive }, value);
    this.value = value;
    this.formatValue();

    if (this.isActive && this.picker)
      this.picker.setSelected(this.value)
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState(isDisabled: boolean) {
    this.readonly = isDisabled
  }
  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.fieldName) {
      // const values$ = .pipe(shareReplay(1))
    }
  }

  private formatValue() {

    let translated;

    if (Array.isArray(this.value)) {
      translated = this.i18n.translate<string[]>(this.value.map(key => (`${this.i18nContext}${this.fieldName}.values.${key}`))).join(this.separator);
    } else {
      translated = this.i18n.translate<string>(`${this.i18nContext}${this.fieldName}.values.${this.value}`);
    }
    // If it's used within a `MatFormField`, we should set it through the property so it can go
    // through change detection.
    this.formattedValue = translated;
    if (this.trigger) {
      this.trigger._control.value = translated;
    }
  }

  openOverlay() {
    console.debug(`open overlay for ${this.fieldName}`);

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: 'hdis-enum-picker-form-field-panel',

      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.trigger.getConnectedOverlayOrigin())
        .withPositions(this.overlayPositions)
        .withFlexibleDimensions()
        .withGrowAfterOpen()
        .withPush()
    });

    // Returns an OverlayRef (which is a PortalHost)
    const overlayRef = this.overlay.create(overlayConfig);

    // Subscribe to a stream that emits when the backdrop was clicked
    overlayRef.backdropClick().subscribe(_ => overlayRef.dispose());

    // Create ComponentPortal that can be attached to a PortalHost
    const filePreviewPortal = new ComponentPortal(EnumPickerComponent, null, this.injector);

    // Attach ComponentPortal to PortalHost
    const containerRef: ComponentRef<EnumPickerComponent> = overlayRef.attach(filePreviewPortal);
    this.picker = containerRef.instance;
    this.picker.showCloseButton = false;
    this.picker.isReadonly = this.readonly;
    this.picker.maxSelection = this.maxSelection;
    this.picker.canShowInvalidValues = this.canShowInvalidValues;

    this.picker.opened
      .pipe(first())
      .subscribe(() => {
        console.debug(`[enum-picker-form-field] subscribe for ${this.fieldName}`);
        this.isActive = true;
        this.release$ = containerRef.instance.closed
          .pipe(tap(() => {
            console.debug(`[enum-picker-form-field] unsubscribe for ${this.fieldName}`);
            this.isActive = false;
          }));
        this.picker.selectionChange
          .pipe(takeUntil(this.release$)).subscribe(newValue => {
            console.debug(`[enum-picker-form-field] new value for ${this.fieldName}`, newValue);
            this.propagateFn(newValue)
            this.writeValue(newValue)
          });
      })

    this.picker.open(this.fieldName, this.value);

  }
}
