import { Component, OnInit, ChangeDetectionStrategy, Input, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ReactiveFormsModule } from '@angular/forms';
import { RequestRange, RequestSort } from '@heardis/api-contracts';
import { debounceTime, Observable, startWith, switchMap, tap } from 'rxjs';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { Response } from '../_models';

export interface AutocompleteOption {
  id: string;
  value: any;
  label: string;
}

export type AutocompleteFunction = (term: string, range?: RequestRange, sort?: RequestSort[]) => Observable<Response<AutocompleteOption>>;

@Component({
  selector: 'hdis-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AutocompleteComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => AutocompleteComponent), multi: true },
  ],
  standalone: true,
  imports: [MatLegacyFormFieldModule, MatLegacyInputModule, ReactiveFormsModule, MatLegacyAutocompleteModule, NgIf, NgFor, MatLegacyOptionModule, AsyncPipe],
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor, Validator {
  @Input()
    label = 'autocomplete';

  @Input()
    placeholder = 'select value...';

  @Input()
    loading = false;

  @Input()
    required = false;

  @Input()
    autocompleteFn: AutocompleteFunction;

  propagateFn: Function;

  selected = new UntypedFormControl();

  options$: Observable<Response<AutocompleteOption>>;

  ngOnInit(): void {
    this.options$ = this.selected.valueChanges.pipe(
      debounceTime(200),
      startWith(''),
      switchMap((inputValue) => {
        /*
        when user writes in autocomplete input incoming value is string.
        when user selects an item from the dropdown the incoming value is on Entity object.
        here we want to update the async request for available entities only when the user types something
        */
        let keyword; let
          emitValue = null;
        if (typeof inputValue === 'string') {
          keyword = inputValue;
        } else {
          emitValue = inputValue.value;
        }

        // value is object of type Entity, emit only if user changed
        if (this.selected.dirty) this.propagateFn(emitValue);

        return this.autocompleteFn(keyword, { offset: 0, limit: 100 }, [{ field: 'name', order: 'asc' }]);
      }),
      tap((values) => console.log(values)),
    );
  }

  writeValue(obj: any): void {
    this.selected.reset(obj);
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.selected.disable() : this.selected.enable();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return (control?.value?._id) ? null : { entity: 'invalid' };
  }

  displayFn = (option: AutocompleteOption) => ((this.loading) ? 'Loading...' : option?.label);
}
