<div class="app-wrapper">
  <div class="app-header">
    <router-outlet name="header"></router-outlet>
  </div>

  <div class="app-sidebar">
    <div class="app-sidebar-sections">
      <router-outlet name="sidebar"></router-outlet>
    </div>
    <hdis-audio-player-widget></hdis-audio-player-widget>
  </div>

  <div class="app-main">
    <div class="app-content">
      <router-outlet></router-outlet>
    </div>
    <hdis-audio-player></hdis-audio-player>
  </div>
</div>
