import { Identifiable } from '../common';
import { ContextMenuItem } from '../context-menu/context-menu.interfaces';

export const PREFIX_INTERNAL_COLUMN = 'INTERNAL_';

export interface ValueFormatterParams {
  data: any;
  value?: any;
}

export type RowDetailsMode = 'none' | 'panel' | 'page';
export type RowSelectionMode = 'none' | 'single' | 'multiple';
export type RowSelectionType = 'checkbox' | 'row';
export type RowModelType = 'infinite' | 'clientSide';

export type ValueFormatter = (params: ValueFormatterParams) => string;

export interface GetRowsResult<T> {
  rows: T[];
  totalCount?: number;
}

/*
 * CELL-RENDERERS
 */

export interface BoundContextMenuItem<T extends Identifiable> extends ContextMenuItem<T> {
  boundAction?: () => any;
}

export const ICON_CELL_WIDTH = 60;

/*
 * FILTERS
 */
/**
 * Used for the multiselect filter to provide select-candiates.
 */
export { FilterSelectItem } from './filters/multivalue-filter/multivalue-filter.component';

export interface StatsConfig {
  type: string;
  [key: string]: unknown;
}

export interface ChordsStatsConfig extends StatsConfig {
  type: 'chords';
  field: string;
}
