import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as featureActions from './upload.actions';
import { UploadObject, UploadObjectStatus } from './upload.interfaces';

export const featureKey = 'upload';

export type UploadState = EntityState<UploadObject>;

export const adapter: EntityAdapter<UploadObject> = createEntityAdapter<UploadObject>();

export const initialState: UploadState = adapter.getInitialState({
  // ids: [],
  // entities: {},
  // additional entity state properties
  // selectedDayIds: [],
  // includeClosingTimes: true
});

export const dayReducer = createReducer(
  initialState,
  on(featureActions.addFile, (state, { id, file, status }) => {
    const record: UploadObject = {
      id,
      file,
      status: status || UploadObjectStatus.Pending,
      uploaded: null,
      error: null,
      metadata: null,
    };
    return adapter.upsertOne(record, state);
  }),
  on(featureActions.annotateFile, (state, { id, metadata }) => {
    const updatePayload: Update<UploadObject> = {
      id,
      changes: {
        metadata,
        status: UploadObjectStatus.Ready,
      },
    };
    return adapter.updateOne(updatePayload, state);
  }),
  on(featureActions.removeFile, (state, { id }) => adapter.removeOne(id, state)),
  on(featureActions.removeAllFiles, (state) => adapter.removeAll(state)),
  on(featureActions.removeUploadedFiles, (state) => adapter.removeMany((object: UploadObject) => object.status === UploadObjectStatus.Completed, state)),
  on(featureActions.uploadFile, (state, { id }) => {
    const updatePayload: Update<UploadObject> = {
      id,
      changes: { status: UploadObjectStatus.Requested, uploaded: 0, error: null },
    };
    return adapter.updateOne(updatePayload, state);
  }),

  on(featureActions.uploadFileProgress, (state, { id, uploaded }) => {
    const updatePayload: Update<UploadObject> = {
      id,
      changes: { status: UploadObjectStatus.Started, uploaded },
    };
    return adapter.updateOne(updatePayload, state);
  }),

  on(featureActions.uploadFileSuccess, (state, { id }) => {
    const updatePayload: Update<UploadObject> = {
      id,
      changes: { status: UploadObjectStatus.Completed },
    };
    return adapter.updateOne(updatePayload, state);
  }),

  on(featureActions.uploadFileFailure, (state, { id, error }) => {
    const updatePayload: Update<UploadObject> = {
      id,
      changes: { status: UploadObjectStatus.Failed, error },
    };
    return adapter.updateOne(updatePayload, state);
  }),
);
