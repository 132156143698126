import { ColDef } from '@ag-grid-community/core';
import { FORMAT_DATE_TIME_COMPACT, FORMAT_TIME_MS } from '../common';
import { arrayCommaFormatter, dateFormatter } from './renderers/formatters';
import { ICON_CELL_WIDTH } from './table.interfaces';

const columnTypes: Record<string, ColDef> = {
  stringColumn: {
    filter: 'agTextColumnFilter',
  },
  numberColumn: {
    filter: 'agNumberColumnFilter',
    initialWidth: 120,
  },
  booleanColumn: {
    type: 'text',
    width: 100,
    resizable: false,
    valueFormatter: (params) => {
      if (params.value === true) { return 'yes'; }
      if (params.value === false) { return 'no'; }
      return '';
    },
  },
  iconColumn: {
    resizable: false,
    width: ICON_CELL_WIDTH,
    suppressSizeToFit: true,
    cellRenderer: 'iconCellRenderer',
  },
  thumbnailColumn: {
    filter: false,
    sortable: false,
    resizable: false,
    width: 100,
    suppressSizeToFit: true,
    cellRenderer: 'thumbnailCellRenderer',
  },
  trendColumn: {
    filter: false,
    sortable: false,
    cellRenderer: 'trendCellRenderer',
  },
  entityColumn: {
    filter: false,
    sortable: false,
    cellRenderer: 'entityCellRenderer',
  },
  dateColumn: {
    initialWidth: 150,
    valueFormatter: (params) => dateFormatter(params.value, FORMAT_DATE_TIME_COMPACT, 'N/A'),
    filter: 'agDateColumnFilter',
    // add extra parameters for the date filter
    filterParams: {
      defaultOption: 'inRange',
      filterOptions: ['equals', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
      // provide comparator function
      comparator(filterLocalDateAtMidnight, cellValue) {
        const dateAsString = cellValue;

        if (dateAsString == null) {
          return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        const dateParts = dateAsString.split('/');
        const day = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[0]);
        const cellDate = new Date(year, month, day);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    },
  },
  timeColumn: {
    maxWidth: 150,
    filter: 'agDateColumnFilter',
    valueFormatter: (params) => dateFormatter(params.value, FORMAT_TIME_MS),
    // add extra parameters for the date filter
    filterParams: {
      defaultOption: 'inRange',
      filterOptions: ['equals', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
      // provide comparator function
      comparator(filterLocalDateAtMidnight, cellValue) {
        const dateAsString = cellValue;

        if (dateAsString == null) {
          return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        const dateParts = dateAsString.split('/');
        const day = Number(dateParts[2]);
        const month = Number(dateParts[1]) - 1;
        const year = Number(dateParts[0]);
        const cellDate = new Date(year, month, day);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
    },
  },
  enumColumn: {
    filter: 'hdisMultiValueFilter',
  },
  multiValueColumn: {
    valueFormatter: arrayCommaFormatter,
  },
  sortableColumn: {
    sortable: true,
  },
};

export default columnTypes;
