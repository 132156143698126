<mat-form-field [class.disabled]="disabled">
  <input matInput type="number" step="0.10" [formControl]="bpmControl" name="bpm" [placeholder]="label"
    (keyup.space)="count()" [matTooltip]="disabled ? disabledTooltipMessage : tooltipMessage"
    matTooltipShowDelay="300" autocomplete="off" [required]="required" [readonly]="readonly">
  <button [disabled]="disabled || readonly || !bpmControl.dirty" mat-icon-button matSuffix (click)="reset()">
    <mat-icon>settings_backup_restore</mat-icon>
  </button>
  <button [disabled]="disabled || readonly" mat-icon-button matSuffix (click)="roundBPM()">
    <mat-icon>close_fullscreen</mat-icon>
  </button>
  <button [disabled]="disabled || readonly" mat-icon-button matSuffix (click)="halveBPM()" class="text-button">
    &#189;<small>x</small>
  </button>
  <button [disabled]="disabled || readonly" mat-icon-button matSuffix (click)="doubleBPM()" class="text-button">
    2<small>x</small>
  </button>
  <button [disabled]="disabled || readonly" mat-icon-button matSuffix (click)="count($event)">
    <mat-icon>av_timer</mat-icon>
  </button>
</mat-form-field>
