import { ColDef } from '@ag-grid-community/core';
import { Field, FieldType } from '@heardis/api-contracts';

/**
 * Does the mapping from Field metadata returned by the backend to TableColumnDefs.
 *
 * @param fields
 * @param columnDefProcessor Allows to customize the created TableColumnDefs.
 */
export const mapFieldsToColumnDefinitions = (fields: Field[]): ColDef[] => fields.map((field) => {
  const types: string[] = [`${field.type}Column`];

  if (field.isMulti) types.push('multiValueColumn');
  if (field.isEnum) types.push('enumColumn');
  if (field.isSortable) types.push('sortableColumn');

  const columnDefinition: ColDef = {
    headerName: field.name,
    field: field.name,
    type: types,
  };

  switch (field.type) {
    // case 'time':
    case FieldType.STRING:
    case FieldType.NUMBER:
    case FieldType.DATE:
    case FieldType.BOOLEAN:
    case FieldType.OBJECT:
      return columnDefinition;
      break;
    default:
      console.error(`Failed mapping field of type [${field.type}] to column-definition.`);
      return {
        headerName: field.name,
        field: field.name,
        type: ['stringColumn'],
      };
  }
});
