<div class="error-page" *transloco="let t">
  <div class="error-wrapper">
    <div class="error-message">
      <h1>Ooooops!</h1>
      <h2>{{ t('errors.notFound.title') }}</h2>
    </div>
    <button mat-flat-button color="primary" class="abcdj-button" routerLink="/">{{ t('errors.common.toHome') }}</button>
    <button mat-flat-button color="primary" class="abcdj-button" (click)="location.back()">{{ t('errors.common.back')
      }}</button>
  </div>
</div>
