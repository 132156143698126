import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageSeverity } from '@heardis/hdis-ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { UserService } from '../../_services/user.service';
import * as featureActions from './bookmarks.actions';

@Injectable()
export class BookmarksStoreEffects {
  private snackbar = inject(MatSnackBar);

  constructor(
    private uService: UserService,
    private actions$: Actions,
  ) { }

  updateBookmarks$ = createEffect(() => this.actions$.pipe(
    ofType(featureActions.addBookmark),
    mergeMap(({ bookmark }) => this.uService.addBookmark(bookmark)
      .pipe(
        map((bookmarks) => featureActions.loadBookmarks({ bookmarks })),
        catchError((err) => {
          this.snackbar.open('Unable to add the bookmark, please try again', '', { panelClass: [MessageSeverity.ERROR] });
          console.error('unable to add bookmarks', err);
          return EMPTY;
        }),
      )),
  ));

  removeBookmarks$ = createEffect(() => this.actions$.pipe(
    ofType(featureActions.removeBookmark),
    mergeMap(({ bookmarkId }) => this.uService.removeBookmark(bookmarkId)
      .pipe(
        map((bookmarks) => featureActions.loadBookmarks({ bookmarks })),
        catchError((err) => {
          this.snackbar.open('Unable to remove the bookmark, please try again', '', { panelClass: [MessageSeverity.ERROR] });
          console.error('unable to remove bookmarks', err);
          return EMPTY;
        }),
      )),
  ));
}
