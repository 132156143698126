<div class="hdis-table" fxLayout="column" fxFlexFill>
  <div class="header-container" *ngIf="title || showQuickFilter" fxFlex="none" fxLayout="row">
    <div *ngIf="title" class="table-title" fxFlex="auto">
      <h2>{{ title }}</h2>
    </div>

    <button *ngIf="datasource.statsEnabled" mat-icon-button (click)="toggleView()">
      <mat-icon>{{view === 'table' ? 'insights' : 'table_chart'}}</mat-icon>
    </button>

    <div *ngIf="showQuickFilter" class="quick-search" fxFlex="none">
      <mat-form-field>
        <mat-icon matPrefix> search </mat-icon>
        <input #quickSearch matInput placeholder="{{ quickFilterPlaceholder }}" [(ngModel)]="quickSearchInput"
          (ngModelChange)="this.quickSearchUpdate.next($event)">
      </mat-form-field>
    </div>
  </div>

  <ng-container *ngIf="view === 'table'">
    <ng-container *ngIf="columns else missingColumnsTpl">
      <ag-grid-angular class="ag-theme-material" [gridOptions]="gridOptions">
      </ag-grid-angular>
    </ng-container>
    <ng-template #missingColumnsTpl>
      missing column definitions and/or initial state
    </ng-template>
  </ng-container>
  <ng-container *ngIf="view === 'chart'">
    <ng-container *ngIf="stats$ | async as stats; else loadingStats">
      <hdis-chord-chart fxFlex="auto" [data]="stats"></hdis-chord-chart>
    </ng-container>
    <ng-template #loadingStats>
      Loading stats...
    </ng-template>
  </ng-container>
</div>

<!-- column-selection menu -->

<div style="visibility: hidden; position: fixed" [style.left]="headerContextMenuPosition.x"
  [style.top]="headerContextMenuPosition.y" [matMenuTriggerFor]="headerContextMenu.menu" #headerContextMenuTrigger>
</div>

<hdis-table-header-menu #headerContextMenu [columns]="headerColumns" (resetColumns)="resetColumns()"
  (toggleColumns)="toggleColumns($event)">
</hdis-table-header-menu>

<ng-content></ng-content>
