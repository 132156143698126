/**
 * Defines objects that can be uniquely identified across components of the platform
 */
export interface Identifiable {
  _id: string;
}

export enum MessageSeverity {
  INFO = 'info-alert',
  SUCCESS = 'success-alert',
  WARNING = 'warning-alert',
  ERROR = 'error-alert',
}
