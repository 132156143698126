import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
  langs = {};

  constructor(private http: HttpClient) { }

  getTranslation(langPath: string) {
    console.debug(`i18n: loading file for ${langPath}`);
    if (!this.langs[langPath]) {
      this.langs[langPath] = this.http.get<Translation>(`/assets/i18n/${langPath}.json`).pipe(shareReplay());
    }
    return this.langs[langPath];
  }
}
