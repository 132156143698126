import { CustomError } from './custom-error';

export class NotImplementedError extends CustomError {
  constructor(message: string, details?: any) {
    super(message, details);
  }
}

export class NotFoundError extends CustomError {
  constructor(message: string, details?: any) {
    super(message, details);
  }
}

export class NotAuthenticatedError extends CustomError {
  constructor(message: string, details?: any) {
    super(message, details);
  }
}

/**
 * Error thrown in case an expectation could not be fulfilled.
 */
export class IllegalStateError extends CustomError {
  constructor(message: string, details?: any) {
    super(message, details);
  }
}

/**
 * Generic error thrown in case something went wrong on the backend side.
 */
export class BackendError extends CustomError {
  constructor(message: string, details?: any) {
    super(message, details);
  }
}

export class PersistenceError extends CustomError {
  constructor(message: string, details?: any) {
    super(message, details);
  }
}

/**
 * May be thrown in case a user cancelled a workflow.
 */
export class UserCancellationError extends CustomError {
  constructor(message: string, details?: any) {
    super(message, details);
  }
}
