/* eslint-disable @angular-eslint/no-host-metadata-property */
import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { formatNumber, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, Input, LOCALE_ID, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormField as MatFormField, MatLegacyFormFieldControl as MatFormFieldControl, MAT_LEGACY_FORM_FIELD as MAT_FORM_FIELD } from '@angular/material/legacy-form-field';
import { Subject } from 'rxjs';
import { randomId } from '../../common';

@Component({
    selector: 'hdis-time-field',
    templateUrl: './time-field.component.html',
    styleUrls: ['./time-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        // { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TrackEditorDurationFormFieldComponent), multi: true },
        { provide: MatFormFieldControl, useExisting: TimeFieldComponent }
    ],
    standalone: true,
    imports: [ReactiveFormsModule, NgIf]
})
export class TimeFieldComponent implements ControlValueAccessor, MatFormFieldControl<number>, OnDestroy {
  @ViewChild('minutes') minutesInput: HTMLInputElement;

  @ViewChild('seconds') secondsInput: HTMLInputElement;

  @ViewChild('milliseconds') millisecondsInput: HTMLInputElement;

  minutesValues = { maxLength: 2, maxValue: 60 };

  secondsValues = { maxLength: 2, maxValue: 60 };

  millisecondsValues = { maxLength: 3, maxValue: 1000 };

  parts: UntypedFormGroup;

  stateChanges = new Subject<void>();

  focused = false;

  touched = false;

  @HostBinding('id') id = `time-field-${randomId()}`;

  onChange = (_: any) => {};

  onTouched = () => {};

  get empty() {
    const { value: { minutes, seconds, milliseconds } } = this.parts;
    return !minutes && !seconds && !milliseconds;
  }

  @HostBinding('class.floating-label') get shouldLabelFloat() { return this.focused || !this.empty; }

  @Input() units = [];

  @Input()
  get placeholder(): string { return this._placeholder; }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get readonly(): boolean { return this._readonly; }

  set readonly(value: BooleanInput) { this._readonly = coerceBooleanProperty(value); }

  private _readonly = false;

  @Input()
  get value(): number | null {
    if (this.parts.valid) {
      const { minutes, seconds, milliseconds } = this.parts.value;
      return minutes * 60 + seconds + milliseconds / 1000;
    }
    return null;
  }

  set value(duration: number | null) {
    if (duration === null) return;
    const minutes = formatNumber(Math.floor(duration / 60), this.locale, '2.0-0');
    const seconds = formatNumber(Math.floor(duration % 60), this.locale, '2.0-0');
    const milliseconds = formatNumber(Math.round((duration % 1) * 1000), this.locale, '3.0-0');
    this.parts.setValue({ minutes, seconds, milliseconds });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  constructor(
    formBuilder: UntypedFormBuilder,
    @Inject(LOCALE_ID) public locale: string,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    this.parts = formBuilder.group({
      minutes: [null, [Validators.maxLength(this.minutesValues.maxLength)]],
      seconds: [null, [Validators.maxLength(this.secondsValues.maxLength)]],
      milliseconds: [null, [Validators.maxLength(this.millisecondsValues.maxLength)]],
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(currElement: HTMLInputElement, prevElement: HTMLInputElement): void {
    if (!currElement || !prevElement) return;
    if (currElement.value.length) return;

    this._focusMonitor.focusVia(prevElement, 'program');
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.input-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {}

  writeValue(duration: number | null): void {
    this.value = duration;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // when focusing on one part, select it to make it closer to the native ux and easier for the user to replace double digits numbers
  handleInputFocusIn(event: FocusEvent) {
    (event.target as HTMLInputElement).select();
  }

  // when leaving one part, if it is value somehow, ensure that the other parts are set to 00 or 000
  handleInputFocusOut(event: FocusEvent) {
    if ((event.target as HTMLInputElement).value) {
      const { minutes, seconds, milliseconds } = this.parts.value;
      this.parts.setValue({
        minutes: formatNumber(minutes || 0, this.locale, '2.0-0'),
        seconds: formatNumber(seconds || 0, this.locale, '2.0-0'),
        milliseconds: formatNumber(milliseconds || 0, this.locale, '3.0-0'),
      });
    }
  }

  handleInput(): void {
    /** @todo should probably listen to valueChanges of this.parts to make it more consistent */
    this.onChange(this.value);
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = Number(event.clipboardData.getData('text'));
    this.value = clipboardData;
    this.onChange(clipboardData);

    /**
     * @TOASK @Stefano as you added both they were causing problems as they apply 10 times the input together.
     * After I commented those 2 lines out everything worked as expected again. Also the handleMaxInput.
     * The solution above with the onChange and the asigning of the value is now working as expected.
     * this.value = clipboardData;
     * this.handleInput();
    */
  }
}
