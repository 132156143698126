import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NavigationStoreEffects } from './effects';
import { reducer } from './reducer';
import { key } from './state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(key, reducer),
    EffectsModule.forFeature([NavigationStoreEffects])
  ],
  providers: [NavigationStoreEffects]
})
export class NavigationStoreModule { }
