import { formatDate } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, Input, LOCALE_ID, NgZone, OnDestroy, ViewChild } from '@angular/core';
import AudioPlayerEventTarget from '../audio-player-event-target';
import { AudioPlayerService } from '../audio-player.service';

@Component({
  selector: 'hdis-audio-player-progress-counter',
  templateUrl: './audio-player-progress-counter.component.html',
  styleUrls: ['./audio-player-progress-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class AudioPlayerProgressCounterComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class') class = 'hdis-audio-player-progress-counter';

  @Input() type: 'elapsed' | 'remaining' = 'elapsed';

  playerTarget: AudioPlayerEventTarget;

  @ViewChild('time') elementRef: ElementRef;

  constructor(
    private zone: NgZone,
    private playerService: AudioPlayerService,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.playerTarget = this.playerService.getTarget();
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      /**
       * apparently there's no need to throttle as the user agent should already take care of this
       * as per MDN docs at https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/timeupdate_event
       */
      this.playerTarget.addEventListener('progress', this.updateProgress);
    });
  }

  ngOnDestroy(): void {
    this.playerTarget.removeEventListener('progress', this.updateProgress);
  }

  private updateProgress = (event) => {
    const { progress, duration } = event.detail;
    if (Number.isNaN(progress) || Number.isNaN(duration)) return;
    const total = formatDate(duration * 1000, 'mm:ss', this.locale);
    const partial = this.type === 'elapsed' ? formatDate(progress * 1000, 'mm:ss', this.locale) : `-${formatDate((duration - progress) * 1000, 'mm:ss', this.locale)}`;
    this.elementRef.nativeElement.innerHTML = `${partial} ${total}`;
  };
}
