import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserStoreEffects } from './user.effects';
import { reducer } from './user.reducer';
import { key } from './user.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(key, reducer),
    EffectsModule.forFeature([UserStoreEffects])
  ],
  providers: [UserStoreEffects]
})
export class UserStoreModule { }
