import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { Icons } from '../common';

export interface Breadcrumb {
  icon?: Icons;
  prefix?: string;
  label: string;
  link?: string | string[];
  suffix?: string;
}

@Component({
  selector: 'hdis-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule, MatTooltipModule],
})
export class BreadcrumbsComponent {
  @Input() steps: Breadcrumb[];
}
