import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageSeverity } from '@heardis/hdis-ui';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { bufferTime, catchError, distinctUntilChanged, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { UserService } from '../../_services/user.service';
import { State } from '../state';
import * as featureActions from './view-preferences.actions';
import { getUserLanguage, getViewPreferences } from './view-preferences.selectors';

@Injectable()
export class ViewPreferencesStoreEffects {
  private snackbar = inject(MatSnackBar);

  constructor(
    private uService: UserService,
    private langService: TranslocoService,
    private actions$: Actions,
    private store$: Store<State>,
  ) { }

  persistViewPreferences$ = createEffect(() => this.actions$.pipe(
    ofType(
      featureActions.updateTableViewPreferences,
      featureActions.resetTableViewPreferences,
      featureActions.resetTablesViewPreferences,
      featureActions.setLanguage,
      featureActions.showSidebarBookmarks,
      featureActions.hideSidebarBookmarks,

      featureActions.setPlayerArtworkVisibility,
      featureActions.setPlayerTracklistVisibility,
    ),
    bufferTime(10000), // buffer actions that may occur in a fixed timeframe and perform only when request to the backend
    filter((buffer) => buffer.length > 0), // dont go further if no events occurred
    withLatestFrom(this.store$.select(getViewPreferences).pipe(distinctUntilChanged())),
    mergeMap(
      // TODO filter preferences to be persisted depending on the types of the actions collected
      ([actions, preferences]) => this.uService.updateUserPreferences(preferences)
        .pipe(
          map((userPrefs) => featureActions.persistViewPreferencesSuccess({ viewPreferences: userPrefs })),
          catchError((err) => {
            this.snackbar.open('Unable to update the view preferences on the server, will try again later', '', { panelClass: [MessageSeverity.ERROR] });
            console.error('unable to update the view preferences', err);
            return of(featureActions.persistViewPreferencesFailure({ error: err }));
          }),
        ),

    ),
  ));

  setCurrentLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(
      featureActions.initUserPreferences,
      featureActions.setLanguage,
    ),
    withLatestFrom(this.store$.select(getUserLanguage).pipe(distinctUntilChanged())),
    map(([action, language]) => this.langService.setActiveLang(language)),
  ), { dispatch: false });
}
