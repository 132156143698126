<div fxLayout="column" fxFlexFill>
  <div fxFlex="none" class="padded-content">
    <mat-form-field>
      <input matInput placeholder="filter values..." [(ngModel)]="filterInput" autocomplete="off">
      <button *ngIf="filterInput" mat-icon-button matSuffix title="clear search" (click)="filterInput=''">
        <mat-icon>clear</mat-icon>
      </button>
      <button mat-icon-button matSuffix title="reset filter" matSuffix mat-icon-button (click)="reset()">
        <mat-icon>search_off</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div fxFlex="auto" class="scrollable-content">
    <div mat-menu-item *ngFor="let element of elements; let i = index; trackBy: trackBy"
      [class.hidden]="!isVisible(element, filterInput)" (click)="toggle(i)">
      <mat-pseudo-checkbox [state]="element.checked ? 'checked' : 'unchecked'" color="primary"></mat-pseudo-checkbox>
      {{ element.label }}
    </div>
  </div>
</div>
