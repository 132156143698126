<div fxLayout="column" fxFlexFill>
  <input type="file" #file style="display: none" (change)="onFilesAdded()" multiple />
  <h3 fxFlex="none" *ngIf="title">{{title}}</h3>

  <button [disabled]="uploading" mat-flat-button color="primary" (click)="addFiles()">Add Files</button>

  <mat-progress-bar *ngIf="uploadInfos$ | async as uploadInfo" mode="buffer" [bufferValue]="uploadInfo.total.progress"
    class="hdis-player-loading-bar">
  </mat-progress-bar>

  <mat-list dense fxFlex="auto" class="scrollable-content">
    <mat-list-item *ngFor="let fileInfo of files; let i = index">
      <div mat-line fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="50">
          <button mat-icon-button color="primary" (click)="removeFile(i)">
            <mat-icon>clear</mat-icon>
          </button>
          {{fileInfo.name}}
        </div>

        <div fxFlex="50" class="upload-info">
          <mat-icon *ngIf="!uploadInfos?.files?.[i] || uploadInfos?.files?.[i]?.status === 'pending'">
            hourglass_empty</mat-icon>

          <mat-progress-spinner
            *ngIf="uploadInfos?.files?.[i]?.status === 'uploading' && uploadInfos?.files?.[i]?.progress !== 100"
            mode="determinate" [value]="uploadInfos?.files?.[i]?.progress" diameter="24">
          </mat-progress-spinner>
          <mat-progress-spinner
            *ngIf="uploadInfos?.files?.[i]?.status === 'uploading' && uploadInfos?.files?.[i]?.progress === 100"
            mode="indeterminate" diameter="24">
          </mat-progress-spinner>

          <button *ngIf="uploadInfos?.files?.[i]?.status === 'failed'" mat-icon-button color="primary"
            (click)="uploadFile(i)">
            <mat-icon>autorenew</mat-icon>
          </button>

          <mat-icon *ngIf="uploadInfos?.files?.[i]?.status === 'completed'">check_circle_outline</mat-icon>
          <mat-icon *ngIf="uploadInfos?.files?.[i]?.status === 'failed'"
            [matTooltip]="uploadInfos?.files?.[i]?.message">
            error_outline</mat-icon>
        </div>
      </div>
    </mat-list-item>
  </mat-list>

  <button [disabled]="!files.length || uploading" mat-flat-button color="primary" (click)="uploadFiles()">Upload Files</button>
</div>
