import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observable, of, take, takeUntil } from 'rxjs';
import { BaseComponent, Identifiable } from '../../common';
import { CTX_SEPARATOR, ContextMenuActionEvent, ContextMenuItem } from '../context-menu.interfaces';

@Component({
  standalone: true,
  selector: 'hdis-context-menu-toolbar',
  templateUrl: './context-menu-toolbar.component.html',
  styleUrls: ['./context-menu-toolbar.component.scss'],
  imports: [CommonModule, FlexLayoutModule, MatDividerModule, MatTooltipModule, MatButtonModule, MatIconModule],
})
export class ContextMenuToolbarComponent<T extends Identifiable> extends BaseComponent {
  CTX_SEPARATOR = CTX_SEPARATOR;

  @Input() activeRow: T;

  @Input() selectedRows: T[] = [];

  @Input() allRows: T[] = [];

  @Input() items: ContextMenuItem<T>[];

  @Input() baseUrl = '';

  @Input()
  get showLabels(): boolean { return this._showLabels; }

  set showLabels(value) { this._showLabels = coerceBooleanProperty(value); }

  _showLabels: boolean;

  @Output() onAction = new EventEmitter<ContextMenuActionEvent>();

  triggerMenuAction(menuItem: ContextMenuItem<T>) {
    if (!menuItem.handleAction) return;
    const actionReturnValue = menuItem.handleAction(this.activeRow, this.selectedRows, this.allRows);

    const action$ = actionReturnValue instanceof Observable ? actionReturnValue : of(actionReturnValue);

    action$.pipe(
      take(1),
      takeUntil(this.unsubscribe),
    ).subscribe((retValue) => {
      const eventData = { id: menuItem.id, data: retValue };
      console.debug('context menu event from toolbar', eventData);
      this.onAction.emit(eventData);
    });
  }
}
