import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../_services/auth.service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.assetsBaseUrl)) {
      return from(this.authService.getAccessToken()).pipe(
        switchMap((jwt: string) => (jwt ? next.handle(request.clone({ setHeaders: { Authorization: `Bearer ${jwt}` } })) : next.handle(request))),
      );
    }

    if (request.url.startsWith(environment.apiBaseUrl)) {
      return from(this.authService.getAccessToken()).pipe(
        switchMap((jwt: string) => (jwt ? next.handle(request.clone({ setHeaders: { Authorization: `Bearer ${jwt}` } })) : next.handle(request))),
      );
    }

    return next.handle(request);
  }
}
