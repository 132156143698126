<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let value of selectedValues; let i = index" [selectable]="selectable" [removable]="removable"
      (removed)="removeValue(value)">
      {{value.label}}:
      <input class="chip-weight mat-input-element" type="number" [value]="value.weight"
        (change)="changeRatio($event, i)" min="0" max="100" step="5">
      %
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input placeholder="add value..." #fieldInput [formControl]="autocompleteControl" [matAutocomplete]="auto"
      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="typeValue($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="selectValue($event)">
    <mat-option *ngFor="let opt of filteredOptions | async" [value]="opt">
      {{opt.label}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
