import { createReducer, on } from '@ngrx/store';
import { AudioPlayerFileStatus, AudioPlayerPlaybackStatus, AudioPlayerTrackStatus, AudioPlayerWaveformStatus, AudioPlayerWaveformViewMode } from '../audio-player.interfaces';
import * as featureActions from './audio-player.actions';
import { AudioPlayerState, adapter, initialState } from './audio-player.state';

export const audioPlayerTrackReducer = createReducer(
  initialState,

  /** tracks */
  on(featureActions.addTracksToNextAndPlay, (state, { tracks }) => {
    const [currentId, ...next] = tracks.map((track) => track.id);
    return adapter.addMany(tracks, { ...state, currentId, next });
  }),
  on(featureActions.addTracksToNext, (state, { tracks }) => {
    const newTrackIds = tracks.map((track) => track.id);
    const next = [...state.next, ...newTrackIds];
    return adapter.addMany(tracks, { ...state, next });
  }),
  on(featureActions.addTracksToQueue, (state, { tracks }) => {
    const newTrackIds = tracks.map((track) => track.id);
    const queue = [...state.queue, ...newTrackIds];
    return adapter.addMany(tracks, { ...state, queue });
  }),
  on(featureActions.cleanQueue, (state) => ({ ...state, queue: [] })),
  on(featureActions.cleanNext, (state) => ({ ...state, next: [] })),
  on(featureActions.playNextTrack, (state) => {
    let changes: Partial<AudioPlayerState>;
    const history = [...state.history, state.currentId];
    if (state.queue.length) {
      const [currentId, ...queue] = state.queue;
      changes = { currentId, queue };
    } else if (state.next.length) {
      const [currentId, ...next] = state.next;
      changes = { currentId, next };
    } else {
      changes = { currentId: null };
    }
    return { ...state, ...changes, history };
  }),
  on(featureActions.playPreviousTrack, (state) => {
    const history = [...state.history];
    const currentId = history.pop();
    return { ...state, currentId, history };
  }),
  on(featureActions.playTrackFromNext, (state, { index }) => {
    const history = [...state.history, state.currentId];
    const next = state.next.slice(index);
    const currentId = next.shift();
    return { ...state, currentId, next, history };
  }),
  on(featureActions.playTrackFromQueue, (state, { index }) => {
    const history = [...state.history, state.currentId];
    const queue = state.queue.slice(index);
    const currentId = queue.shift();
    return { ...state, currentId, queue, history };
  }),

  on(featureActions.currentTrackInfoLoaded, (state, { track }) => {
    const { id, ...changes } = track;
    return adapter.updateOne({ id, changes: { ...changes, status: AudioPlayerTrackStatus.READY } }, state);
  }),

  on(featureActions.currentTrackInfoFailed, (state, { track }) => {
    const { id, ...changes } = track;
    return adapter.updateOne({ id, changes: { status: AudioPlayerTrackStatus.ERROR } }, state);
  }),

  /** playback controls */
  on(featureActions.setPlaybackRate, (state, { rate }) => {
    const playback = { ...state.playback };
    return { ...state, playback: { ...playback, rate } };
  }),

  on(featureActions.playbackStarted, (state) => ({ ...state, playback: { ...state.playback, status: 'playing' as AudioPlayerPlaybackStatus } })),
  on(featureActions.playbackPaused, (state) => ({ ...state, playback: { ...state.playback, status: 'paused' as AudioPlayerPlaybackStatus } })),
  on(featureActions.playbackStopped, (state) => ({ ...state, playback: { ...state.playback, status: 'stopped' as AudioPlayerPlaybackStatus } })),

  on(featureActions.setPlaybackAutoplay, (state, { autoplay }) => {
    const playback = { ...state.playback };
    return { ...state, playback: { ...playback, autoplay } };
  }),

  on(featureActions.loadTrackFile, (state) => ({ ...state, file: { ...state.file, status: AudioPlayerFileStatus.LOADING } })),
  on(featureActions.loadTrackFileSuccess, (state) => ({ ...state, file: { ...state.file, status: AudioPlayerFileStatus.LOADED } })),
  on(featureActions.loadTrackFileFailure, (state) => ({ ...state, file: { ...state.file, status: AudioPlayerFileStatus.ERROR } })),

  /** waveform */
  on(featureActions.waveformLoaded, (state) => ({ ...state, waveform: { ...state.waveform, status: 'loaded' as AudioPlayerWaveformStatus } })),
  on(featureActions.setWaveformVisibility, (state, { isVisible }) => {
    const waveform = { ...state.waveform };
    return { ...state, waveform: { ...waveform, isVisible } };
  }),
  on(featureActions.showStandardWaveform, (state) => ({ ...state, waveform: { ...state.waveform, mode: AudioPlayerWaveformViewMode.STANDARD } })),
  on(featureActions.showExtendedWaveform, (state) => ({ ...state, waveform: { ...state.waveform, mode: AudioPlayerWaveformViewMode.EXTENDED } })),
  on(featureActions.setWaveformZoom, (state, { zoom }) => {
    const waveform = { ...state.waveform };
    return { ...state, waveform: { ...waveform, zoom } };
  }),
  on(featureActions.setWaveformAutoscroll, (state, { autoscroll }) => {
    const waveform = { ...state.waveform };
    return { ...state, waveform: { ...waveform, autoscroll } };
  }),

  on(featureActions.setPreferredEdit, (state, { edit }) => ({ ...state, edit })),

  /** editor */
  on(featureActions.prelisteningEnabled, (state) => ({ ...state, editor: { ...state.editor, prelistening: true } })),
  on(featureActions.prelisteningDisabled, (state) => ({ ...state, editor: { ...state.editor, prelistening: false } })),
  on(featureActions.snapToGridEnabled, (state) => ({ ...state, editor: { ...state.editor, snapToGrid: true } })),
  on(featureActions.snapToGridDisabled, (state) => ({ ...state, editor: { ...state.editor, snapToGrid: false } })),

  on(featureActions.trackEditorOpened, (state, { track }) => adapter.addOne(track, {
    ...state,
    currentId: track.id,
    editor: {
      ...state.editor,
      isVisible: true,
      prelistening: true,
      snapToGrid: false,
    },
  })),

  on(featureActions.trackEditorClosed, (state) => ({
    ...state,
    editor: {
      ...state.editor,
      isVisible: false,
    },
  })),

);
