import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as featureActions from './playlist-day.actions';
import { PlaylistDay } from './playlist-day.types';

export const featureKey = 'playlistDays';

export const toPlaylistDayId = (pId: string, eId: string, day: string): string => `${pId}_${eId}_${day}`;
export const fromPlaylistDayId = (id: string): { pId: string; eId: string; day: string } => {
  const [pId, eId, day] = id.split('_');
  return { pId, eId, day };
};

export interface PlaylistDayState extends EntityState<PlaylistDay> {
  selectedDayIds: string[];
  includeClosingTimes: boolean;
}

export function selectPlaylistDayId({ pId, eId, day }: PlaylistDay): string {
  return toPlaylistDayId(pId, eId, day);
}

export function sortByName(a: PlaylistDay, b: PlaylistDay): number {
  return a.pId.localeCompare(b.pId) || a.eId.localeCompare(b.eId) || a.day.localeCompare(b.day);
}

export const adapter: EntityAdapter<PlaylistDay> = createEntityAdapter<PlaylistDay>({
  selectId: selectPlaylistDayId,
  sortComparer: sortByName,
});

export const initialState: PlaylistDayState = adapter.getInitialState({
  // additional entity state properties
  selectedDayIds: [],
  includeClosingTimes: true,
});

export const dayReducer = createReducer(
  initialState,
  on(featureActions.addPlaylistDay, (state, { day }) => adapter.addOne(day, state)),
  on(featureActions.setPlaylistDay, (state, { day }) => adapter.setOne(day, state)),
  on(featureActions.upsertPlaylistDay, (state, { day }) => adapter.upsertOne(day, state)),
  on(featureActions.addPlaylistDays, (state, { days }) => adapter.addMany(days, state)),
  on(featureActions.upsertPlaylistDays, (state, { days }) => adapter.upsertMany(days, state)),
  on(featureActions.updatePlaylistDay, (state, { update }) => adapter.updateOne(update, state)),
  on(featureActions.updatePlaylistDays, (state, { updates }) => adapter.updateMany(updates, state)),
  on(featureActions.mapPlaylistDay, (state, { entityMap }) => adapter.mapOne(entityMap, state)),
  on(featureActions.mapPlaylistDays, (state, { entityMap }) => adapter.map(entityMap, state)),
  on(featureActions.deletePlaylistDay, (state, { id }) => adapter.removeOne(id, state)),
  on(featureActions.deletePlaylistDays, (state, { ids }) => adapter.removeMany(ids, state)),
  on(featureActions.deletePlaylistDaysByPredicate, (state, { predicate }) => adapter.removeMany(predicate, state)),
  on(featureActions.loadPlaylistDays, (state, { days }) => adapter.setAll(days, state)),
  on(featureActions.setPlaylistDays, (state, { days }) => adapter.setMany(days, state)),
  on(featureActions.clearPlaylistDays, (state) => adapter.removeAll({ ...state, selectedUserId: null })),

  on(featureActions.selectPlaylistDays, (state, { ids }) => ({
    ...state,
    selectedDayIds: ids,
  })),

  on(featureActions.toggleClosingTimesVisibility, (state) => ({
    ...state,
    includeClosingTimes: !state.includeClosingTimes,
  })),
  on(featureActions.setClosingTimesVisibility, (state, { visible }) => ({
    ...state,
    includeClosingTimes: visible,
  })),

  on(featureActions.fetchPlaylistDay, (state, { id }) => {
    const day = fromPlaylistDayId(id);
    return adapter.upsertOne({
      ...day,
      status: 'LOADING',
      tracks: [],
    }, state);
  }),

  on(featureActions.fetchPlaylistDaySuccess, (state, { id, tracks }) => {
    const day: PlaylistDay = {
      ...fromPlaylistDayId(id),
      tracks,
      status: 'LOADED',
    };
    return adapter.upsertOne(day, state);
  }),

  on(featureActions.fetchPlaylistDayFailure, (state, { id, error }) => {
    const day: PlaylistDay = {
      ...fromPlaylistDayId(id),
      tracks: [],
      status: 'ERROR',
    };
    return adapter.upsertOne(day, state);
  }),
);
