import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UploadResponse } from '@heardis/api-contracts';
import { MultiUploadService } from '../multi-upload.service';

@Component({
  selector: 'hdis-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ImageUploadComponent), multi: true },
  ],
  standalone: true,
  imports: [MatLegacyFormFieldModule, MatLegacyInputModule, ReactiveFormsModule, FormsModule, NgIf, MatLegacyProgressSpinnerModule, MatIconModule, MatTooltipModule, MatLegacyButtonModule],
})
export class ImageUploadComponent implements ControlValueAccessor {
  @Input() floatLabel = 'auto';

  @Input() label = 'upload image';

  @Input() placeholder = 'upload image';

  @Input() endpoint: string;

  @Output() uploaded = new EventEmitter<string>();

  @Input()
  get disabled(): boolean { return this._disabled; }

  set disabled(value) { this._disabled = coerceBooleanProperty(value); }

  _disabled = false;

  @ViewChild('fileInput') fileInput;

  public file: File;

  propagateFn: (newValue: string) => void;

  assetPath: string;

  progress: number;

  canBeClosed = true;

  showCancelButton = true;

  uploading = false;

  uploadSuccessful = false;

  error = false;

  uploadMessage = '';

  constructor(
    private uploadService: MultiUploadService,
    private cdr: ChangeDetectorRef,
  ) { }

  writeValue(value: string): void {
    this.assetPath = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  addFile() {
    this.fileInput.nativeElement.click();
  }

  onFileAdded() {
    const { files } = this.fileInput.nativeElement;
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        this.file = files[key];
        break;
      }
    }
    this.uploadFile();
  }

  onUploadCompleted(destinationPath: string) {
    this.assetPath = destinationPath;
    this.uploaded.emit(this.assetPath);
    this.propagateFn?.(this.assetPath);
    this.cdr.markForCheck();
  }

  inputChange(newValue: string) {
    this.uploaded.emit(this.assetPath);
    this.propagateFn?.(newValue);
  }

  uploadFile() {
    if (!this.endpoint) throw Error('no upload endpoint specified');

    this.error = false;
    // set the component state to "uploading"
    this.uploading = true;
    // The dialog should not be closed while uploading
    this.canBeClosed = false;
    // Hide the cancel-button
    this.showCancelButton = false;

    const uploadInfo = this.uploadService.uploadFile<UploadResponse>(this.endpoint, this.file);

    uploadInfo.subscribe(({ progress, message, status, response }) => {
      if (status === 'uploading') {
        this.progress = progress;
      }

      if (status === 'failed') {
        this.error = true;
        this.uploadSuccessful = false;
        this.uploading = false;
        this.uploadMessage = `failed due to: ${message}`;
      }

      if (status === 'completed') {
        // ... the dialog can be closed again...
        this.canBeClosed = true;
        // ... the upload was successful...
        this.uploadSuccessful = true;
        // ... and the component is no longer uploading
        this.uploading = false;
        this.uploadMessage = 'upload completed';

        console.debug('[image-upload] upload finished', response.destination);
        this.onUploadCompleted(response.destination);
      }

      this.cdr.markForCheck();
    });
  }
}
