import { Identifiable } from '../common';

export const CTX_SEPARATOR = 'ctx.divider';

/** compatible with state/navigation/NavigationPagination */
export interface ContextMenuNavigation {
  current: string;
  previous: string;
  next: string;
}

export interface ContextMenuItem<T extends Identifiable> {
  id: string;
  tooltip?: string;
  label?: string;
  icon?: string;
  routerLink?: string[];
  isVisible?: ContextMenuTestFn<T>;
  isDisabled?: ContextMenuTestFn<T>;
  handleAction?: ContextMenuActionFn<T>;
}

export interface ContextMenuActionEvent { id: string; data?: any }
export type ContextMenuActionCallback = (event: ContextMenuActionEvent) => void;
export type ContextMenuTestFn<T> = (activeRow: T, selectedRows: T[], rows: T[]) => boolean;
export type ContextMenuActionFn<T> = (activeRow: T, selectedRows: T[], rows: T[], callback?: ContextMenuActionCallback) => any;
