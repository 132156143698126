import { Pipe, PipeTransform } from '@angular/core';

const formats = {
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
};

@Pipe({
  name: 'bytes',
  standalone: true,
})
export class BytesPipe implements PipeTransform {
  transform(size: number, format = 'MB') {
    const amount = formats[format];
    return `${(size / amount).toFixed(2)}${format}`;
  }
}
