export enum FileUploadStatus {
  PENDING = 'pending',
  UPLOADING = 'uploading',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export interface FileUploadResult<T> {
  status: FileUploadStatus;
  progress?: number;
  loaded?: number;
  total?: number;
  message?: string;
  response?: T;
}

export interface MultiFileUploadResult<T> {
  total: FileUploadResult<T>;
  files: FileUploadResult<T>[];
}

export interface MultiFileUploadConfig {
  maxConcurrency: number;
}
