import { createReducer, on } from '@ngrx/store';
import { initUser, resetUser, setUserInfo } from './user.actions';
import { initialState, State } from './user.state';

export const reducer = createReducer(
  initialState,

  on(initUser, (state: State, { username, roles }) => ({
    ...state,
    username,
    roles,
    infoStatus: 'init'
  })),

  on(resetUser, (state: State) => ({
    ...initialState,
  })),

  on(setUserInfo, (state: State, { success }) => ({
    ...state,
    infoStatus: success ? 'success' : 'failed'
  })),
);
