import { Component, OnInit, ChangeDetectionStrategy, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ReactiveFormsModule } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs';
import { BaseComponent } from '../../common';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';

@Component({
    selector: 'hdis-css-size-field',
    templateUrl: './css-size-field.component.html',
    styleUrls: ['./css-size-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CssSizeFieldComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => CssSizeFieldComponent), multi: true },
    ],
    standalone: true,
    imports: [MatLegacyFormFieldModule, MatLegacyInputModule, ReactiveFormsModule]
})
export class CssSizeFieldComponent extends BaseComponent implements ControlValueAccessor, Validator {
  @Input() label: string;

  @Input() placeholder: string;

  @Input() units = ['px'];

  currentUnit = 'px';

  ctrl = new UntypedFormControl();

  propagateFn: Function;

  constructor() {
    super();
    this.ctrl.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe),
      )
      .subscribe((value) => {
        this.propagateFn?.(this.addUnitToSize(value));
      });
  }

  writeValue(value: string): void {
    this.ctrl.reset(this.removeUnitFromSize(value));
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.ctrl.disable() : this.ctrl.enable();
  }

  registerOnValidatorChange?(fn: () => void): void {
    // throw new Error('Method not implemented.');
  }

  validate(control: AbstractControl): ValidationErrors {
    // throw new Error('Method not implemented.');
    return {};
  }

  private addUnitToSize(size: number): string {
    if (!size) return;
    return `${size}px`;
  }

  private removeUnitFromSize(size: string): number {
    if (!size) return;
    return Number(size.replace(/[^0-9\.]+/g, ''));
  }
}
