import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { PlaylistService } from '../../../_services/playlist.service';
import { PlaylistTracksService } from '../../_services/playlist-tracks.service';
import * as featureActions from './playlist-day.actions';
import { fromPlaylistDayId, PlaylistDayState } from './playlist-day.reducer';
import { selectPlaylistDayEntities } from './playlist-day.selectors';

const MAX_CONCURRENT_REQUESTS = 5;

@Injectable()
export class PlaylistDayStoreEffects {
  constructor(
    private pService: PlaylistService,
    private tService: PlaylistTracksService,
    private actions$: Actions,
    private store$: Store<PlaylistDayState>,
  ) { }

  ensurePlaylistDays$ = createEffect(() => this.actions$.pipe(
    ofType(
      featureActions.selectPlaylistDays,
    ),
    withLatestFrom(
      this.store$.select(selectPlaylistDayEntities),
    ),
    // filter(([actions, playlistDays]) => !!playlistId && !!executionId && !!days.length),
    mergeMap(([{ ids }, playlistDays]) => ids
      .filter((id) => {
        const doesntExists = !playlistDays[id];
        const failed = playlistDays[id]?.status === 'ERROR';
        return !playlistDays[id] || playlistDays[id].status === 'ERROR';
      }) // process only the days that were never processed or that failed
      .map((id) => featureActions.fetchPlaylistDay({ id }))),
  ));

  fetchPlaylistDay$ = createEffect(() => this.actions$.pipe(
    ofType(featureActions.fetchPlaylistDay),
    mergeMap(
      ({ id }) => {
        const { pId, eId, day } = fromPlaylistDayId(id);
        return this.tService.getPlaylistTracks(pId, eId, day).pipe(
          map((tracks) => featureActions.fetchPlaylistDaySuccess({ id, tracks })),
          catchError((error) => of(featureActions.fetchPlaylistDayFailure({ id, error }))),
        );
      },
      MAX_CONCURRENT_REQUESTS,
    ),
  ));
}
