export class ComponentResizeEvent {
  public current: DOMRectReadOnly;
  public previous?: DOMRectReadOnly;
  public pristine: boolean;

  public constructor(newDom: DOMRectReadOnly, oldDom: DOMRectReadOnly | undefined) {
    this.current = newDom;
    this.previous = oldDom;
    this.pristine = oldDom == null;
  }
}
