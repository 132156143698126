/* eslint-disable prefer-destructuring */
/* eslint-disable no-multi-assign */
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { AudioMood } from '@heardis/api-contracts';
import { TranslocoService } from '@ngneat/transloco';
// TODO rmeove chart.js in favor of D3
import { NgIf } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Chart, ChartConfiguration, ChartDataSets } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { draw } from 'patternomaly';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { FieldValue, MetadataProvider } from '../../_models';
import { BaseComponent, EDIT_CONTEXT, EditContextType, I18N_CONTEXT } from '../../common';

@Component({
  selector: 'hdis-wheel-picker',
  templateUrl: './wheel-picker.component.html',
  styleUrls: ['./wheel-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, NgIf, MatButtonModule, MatIconModule],
})
export class WheelPickerComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() title: string;

  @Output() readonly selectionChange = new EventEmitter<any>();

  @Output() readonly opened = new EventEmitter<void>();

  @Output() readonly closed = new EventEmitter<void>();

  @ViewChild('wheel', { static: true }) canvas: ElementRef;

  fieldsLoading: boolean;

  selected$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  available$: BehaviorSubject<ChartDataSets[]> = new BehaviorSubject([]);

  options$: Observable<{ value: string; visible: boolean }[]>;

  datasets: ChartDataSets[];

  isOpen = false;

  isReadonly = false;

  multi = true;

  fieldName: string;

  showCloseButton = false;

  private chart: Chart;

  private chartState: ChartConfiguration = {
    type: 'pie',
    data: {
      datasets: [],
    },
    plugins: [ChartDataLabels],
    options: {
      tooltips: {
        enabled: false,
      },
      // FIXME hardcorded rotation for 12 items (1/2 PI to start from top + 1/12 PI to have the first item aligned on the y-axis)
      rotation: -0.583 * Math.PI,
      animation: {
        animateRotate: false,
        animateScale: false,
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      plugins: {
        datalabels: {
          font: {
            size: 15,
            family: "Roboto, 'Helvetica Neue', sans-serif",
            weight: 400,
          },
          color: (context) => (context.dataset as any).labelColor[context.dataIndex],
          // backgroundColor: context => {
          //   return (context.dataset as any).labelBackgroundColor[context.dataIndex];
          // },
          // borderColor: context => {
          //   return (context.dataset as any).labelBackgroundColor[context.dataIndex];
          // },
          // borderRadius: 3,
          // borderWidth: 1,
          // padding: 2,
          // textStrokeWidth: 1,
          // textStrokeColor: context => {
          //   return (context.dataset as any).labelBackgroundColor[context.dataIndex];
          // },
          textShadowBlur: 20,
          textShadowColor: (context) => (context.dataset as any).labelBackgroundColor[context.dataIndex],
          formatter: (value, context) => (context.dataset as any).labels[context.dataIndex],
          rotation: (context) => {
            const ctx = (context.dataset as any);
            return ctx.labelRotation?.[context.dataIndex] || 0;
          },
        },
      },
      onClick: (evt, item) => {
        const selectedValue: any = item && item[0];
        console.log(item);
        if (selectedValue) {
          const value = selectedValue._chart.config.data.datasets[selectedValue._datasetIndex].values[selectedValue._index];
          this.toggleValue(value);
        }
      },
    },
  };

  private palette = {
    //    deep orange amber     lime        green     cyan        indigo
    100: ['#ffccbc', '#ffecb3', '#f0f4c3', '#c8e6c9', '#b2ebf2', '#c5cae9', '#ffccbc', '#ffecb3', '#f0f4c3', '#c8e6c9', '#b2ebf2', '#c5cae9'],
    200: ['#ffab91', '#ffe082', '#e6ee9c', '#a5d6a7', '#80deea', '#9fa8da', '#ffab91', '#ffe082', '#e6ee9c', '#a5d6a7', '#80deea', '#9fa8da'],
    300: ['#ff8a65', '#ffd54f', '#dce775', '#81c784', '#4dd0e1', '#9900ff', '#ff8a65', '#ffd54f', '#dce775', '#81c784', '#4dd0e1', '#9900ff'],
    400: ['#ff7043', '#ffca28', '#d4e157', '#66bb6a', '#26c6da', '#5c6bc0', '#ff7043', '#ffca28', '#d4e157', '#66bb6a', '#26c6da', '#5c6bc0'],
    500: ['#ff5722', '#ffc107', '#cddc39', '#4caf50', '#00bcd4', '#3f51b5', '#ff5722', '#ffc107', '#cddc39', '#4caf50', '#00bcd4', '#3f51b5'],
    800: ['#d84315', '#ff8f00', '#9e9d24', '#2e7d32', '#00838f', '#283593', '#d84315', '#ff8f00', '#9e9d24', '#2e7d32', '#00838f', '#283593'],
  };

  constructor(
    private service: MetadataProvider,
    @Optional() @Inject(I18N_CONTEXT) public i18nContext: string,
    @Optional() @Inject(EDIT_CONTEXT) public editContext: EditContextType,
    private i18n: TranslocoService,
  ) {
    super();
    this.showCloseButton = editContext === 'panel';
  }

  ngOnInit() {
    this.initWheel();
    combineLatest(
      this.selected$,
      this.available$,
    ).pipe(
      takeUntil(this.unsubscribe),
    ).subscribe(([selected, available]) => {
      this.updateWheel(available, selected);
    });
  }

  ngOnDestroy() {
    console.log('destroy enum picker');
    this.closed.emit();
  }

  open(fieldName: string, selected: string[]) {
    // first clean any possible dirty state by closing the previous picker
    this.close();

    // then updated the component props
    this.setSelected(selected);
    this.setField(fieldName);
    this.isOpen = true;

    // finally emit the `opened` event to let subscribers (usually the directive) to properly subscribe
    this.opened.emit();
  }

  close() {
    this.isOpen = false;
    this.closed.emit();
  }

  private initWheel() {
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.chart = new Chart(ctx, this.chartState);
  }

  private updateWheel(datasets: ChartDataSets[], selected: string[]) {
    console.log('update view of wheel-picker');
    const newDatasets = datasets.map((dataset) => ({
      ...dataset,
      backgroundColor: ((dataset as any).values as Array<string>).map((value, index) => {
        const bgColor = dataset.backgroundColor && dataset.backgroundColor[index];
        // return selected.includes(value) ? this.palette[800][index] : dataset.backgroundColor && dataset.backgroundColor[index];
        return selected?.includes(value) ? draw('zigzag', bgColor) : bgColor;
      }),
    }));

    this.chartState.data = { ...this.chartState.data, datasets: newDatasets };
    this.chart.update();
  }

  private setField(name: string) {
    this.fieldName = name;
    this.available$.next([]);
    this.fieldsLoading = true;
    this.service.getFieldValues(name).pipe(
      take(1),
      map((values) => this.mapFieldValuesToDataset(values)),
    ).subscribe((newValues) => {
      this.available$.next(newValues);
      this.fieldsLoading = false;
    });
  }

  setSelected(selected: string[]) {
    this.selected$.next(selected || []);
  }

  toggleValue(value: string) {
    const newValues = this.selected$.value;
    const index = newValues.indexOf(value);
    if (index === -1) {
      // select
      newValues.push(value);
    } else {
      // deselect
      newValues.splice(index, 1);
    }
    console.debug(`new wheel selection: ${newValues}`);
    this.setSelected(newValues);
    this.selectionChange.emit(newValues);
  }

  private mapFieldValuesToDataset(values: FieldValue[]): ChartDataSets[] {
    if (this.fieldName === 'metadata.moods') {
      const highMoods = [AudioMood.euphoric, AudioMood.sexy, AudioMood.courageous, AudioMood['loving-friendly'], AudioMood.nice, AudioMood.optimistic, AudioMood.miserable, AudioMood.frigid, AudioMood.fearful, AudioMood['hateful-aggressive'], AudioMood.naughty, AudioMood.pessimistic];
      const midMoods = [AudioMood.happy, AudioMood.romantic, AudioMood.confident, AudioMood['loving-friendly'], AudioMood.humble, AudioMood.together, AudioMood.sad, AudioMood['contemplative-deep'], AudioMood.doubtful, AudioMood['hateful-aggressive'], AudioMood.arrogant, AudioMood.lonely];
      const lowMoods: AudioMood[] = [AudioMood.sweet, AudioMood.romantic, AudioMood.confident, AudioMood.grateful, AudioMood.lighthearted, AudioMood.bitter, AudioMood['contemplative-deep'], AudioMood.doubtful, AudioMood.angry, AudioMood.melancholic];

      // original colors from Robin
      // const highColors = ["#4a86e8", "#ff00ff", "#00ff00", "#e06666", "#00ffff", "#9900ff", "#4a86e8", "#ff00ff", "#00ff00", "#e06666", "#00ffff", "#9900ff"];
      // const midColors = ["#6d9eeb", "#c27ba0", "#93c47d", "#e06666", "#76a5af", "#76a5af", "#6d9eeb", "#c27ba0", "#93c47d", "#e06666", "#76a5af", "#76a5af"];
      // const lowColors = ["#a4c2f4", "#c27ba0", "#93c47d", "#ea9999", "#a2c4c9", "#a2c4c9", "#a4c2f4", "#c27ba0", "#93c47d", "#ea9999", "#a2c4c9", "#a2c4c9"];

      // material colors deep orange - amber-     lime -     green -     cyan -  indigo

      const highColors = [...this.palette[300]];
      const midColors = [...this.palette[200]];
      const lowColors = [...this.palette[100]];

      const midLabels: string[] = [...midMoods];
      midLabels[3] = midLabels[9] = ''; // skip label friendly-aggressive at second level

      const lowLabels: string[] = [...lowMoods];
      lowLabels[1] = lowLabels[2] = lowLabels[6] = lowLabels[7] = ''; // skip label romantic-confident-contemplative/deep-doubtful at third level

      // join values from different levels assigning same color
      midColors[1] = midColors[7] = lowColors[1] = lowColors[7]; // romantic / contemplative/deep
      midColors[2] = midColors[8] = lowColors[2] = lowColors[8]; // confident / doubtful
      midColors[3] = midColors[9] = highColors[3] = highColors[9]; // friendly-loving / hateful-aggressive

      // handle edge case with adjacent values on same level having same color
      // lowColors[4] = lowColors[9] = "#cfd8dc" // lighthearted / melancholic USING special blue gray 100
      midColors[4] = midColors[10] = '#b0bec5';
      midColors[5] = midColors[11] = '#90a4ae';

      // handle edge case with arcs in second layer having same value and same color: lonely / together
      lowColors.splice(11, 1);
      lowColors.splice(5, 1);
      lowColors[4] = lowColors[9] = '#cfd8dc'; // together - lonely USING special blue gray 200

      return [
        {
          data: Array(highMoods.length).fill(1),
          backgroundColor: highColors,
          values: highMoods,
          labels: this.i18n.translate(highMoods.map((key) => (`${this.i18nContext}${this.fieldName}.values.${key}`))),
          labelColor: [...Array(6).fill('#ffffff'), ...Array(6).fill('#000000')],
          labelBackgroundColor: [...Array(6).fill('#000000'), ...Array(6).fill('#ffffff')],
          labelRotation: [-90, -60, -30, 0, 30, 60, -90, -60, -30, 0, 30, 60],
        } as any,
        {
          data: Array(midMoods.length).fill(1),
          backgroundColor: midColors,
          values: midMoods,
          labels: this.i18n.translate(midLabels.map((key) => (`${this.i18nContext}${this.fieldName}.values.${key}`))),
          labelColor: [...Array(6).fill('#ffffff'), ...Array(6).fill('#000000')],
          labelBackgroundColor: [...Array(6).fill('#000000'), ...Array(6).fill('#ffffff')],
          labelRotation: [-90, -60, -30, 0, 30, 60, -90, -60, -30, 0, 30, 60],
        } as any,
        {
          data: [1, 1, 1, 1, 2, 1, 1, 1, 1, 2],
          backgroundColor: lowColors,
          values: lowMoods,
          labels: this.i18n.translate(lowLabels.map((key) => (`${this.i18nContext}${this.fieldName}.values.${key}`))),
          labelColor: [...Array(5).fill('#ffffff'), ...Array(5).fill('#000000')],
          labelBackgroundColor: [...Array(5).fill('#000000'), ...Array(5).fill('#ffffff')],
          labelRotation: [-90, -60, -30, 0, 45, -90, -60, -30, 0, 45],
        } as any,
      ];
    }
    console.error(`field ${this.fieldName} not supported. Only metadata.moods is supported`);
    return [];
  }
}
