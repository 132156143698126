import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions, CustomButtonInput, DateSelectArg, DateUnselectArg, EventApi, EventClickArg, EventDropArg, EventInput, EventMountArg } from '@fullcalendar/core';
import { asRoughMs } from '@fullcalendar/core/internal';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DropArg, EventLeaveArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule';
import timegridPlugin from '@fullcalendar/timegrid';
import { addMilliseconds, format } from 'date-fns';
import { NgIf, NgFor } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { colorPalette, FORMAT_DATE_TIME_LOCAL } from '../../common';
import { CalendarEventType, HdisCalendarAction, HdisCalendarEvent } from '../calendar.interfaces';

@Component({
  selector: 'hdis-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, FullCalendarModule, NgIf, NgFor],
})
export class CalendarComponent implements OnInit {
  @Input() minDate: string;

  @Input() maxDate: string;

  @Input() defaultDate: string;

  /** @todo see https://fullcalendar.io/docs/upgrading-from-v5#angular-specific-changes and move events assignment from ts to html with async pipe */
  @Input()
  get events(): HdisCalendarEvent[] { return this._events; }

  set events(events: HdisCalendarEvent[]) {
    this._events = events;
    this.eventsMap = events.reduce((acc, curr) => { acc[curr.id] = curr; return acc; }, {});
    this.eventsPalette = colorPalette(this._events.length, 0.45, 0.85);
    this.timeEvents = this.denormalizeEvents(events, false);
    this.globalEvents = this.denormalizeEvents(events, true);
    // if calendar was initialized already, reload the events
    this.reloadEvents();
  }

  _events: HdisCalendarEvent[] = [];

  eventsMap: Record<string, HdisCalendarEvent> = {};

  timeEvents: EventInput[] = [];

  globalEvents: EventInput[] = [];

  eventsPalette: string[];

  @Input()
  get readOnly(): boolean { return this._readOnly; }

  set readOnly(value) { this._readOnly = coerceBooleanProperty(value); }

  _readOnly = false;

  @Input()
  get actions(): HdisCalendarAction[] { return this._actions; }

  set actions(actions: HdisCalendarAction[]) { this._actions = actions; }

  _actions: HdisCalendarAction[] = [];

  @Output() calendarAction = new EventEmitter<{ action: string; source: MouseEvent | KeyboardEvent }>();

  @Output() eventRequested = new EventEmitter<{ destination: CalendarEventType }>();

  @Output() eventSelected = new EventEmitter<{ event: HdisCalendarEvent; occurrence: CalendarEventType; source: MouseEvent }>();

  @Output() eventMoved = new EventEmitter<{ event: HdisCalendarEvent; occurrence: CalendarEventType; destination: CalendarEventType }>();

  @Output() eventAction = new EventEmitter<{ event: HdisCalendarEvent; occurrence: CalendarEventType; action: string; source: MouseEvent | KeyboardEvent }>();

  // references the #calendar in the template
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  calendarOptions: CalendarOptions;

  ngOnInit(): void {
    this.calendarOptions = {
      plugins: [
        dayGridPlugin,
        interactionPlugin,
        listPlugin,
        rrulePlugin,
        timegridPlugin,
      ],
      height: '100%',
      customButtons: this.actions.reduce((acc, curr) => {
        acc[curr.id] = {
          text: curr.label,
          icon: curr.icon,
          click: (ev: MouseEvent, element: HTMLElement) => this.calendarAction.emit({ action: curr.id, source: ev }),
        } as CustomButtonInput;
        return acc;
      }, {}),
      headerToolbar: {
        left: this.actions.map((action) => action.id).join(','),
        center: 'title',
        right: 'prev,today,next dayGridMonth,timeGridWeek,timeGridDay,listWeek',
      },
      initialView: 'timeGridWeek',
      titleFormat: { year: 'numeric', month: 'long', day: '2-digit' },
      slotLabelFormat: { hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false },
      eventTimeFormat: { hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false },
      firstDay: 1,
      initialDate: this.defaultDate || this.minDate,
      validRange: {
        start: this.minDate,
        end: this.maxDate,
      },
      selectable: !this.readOnly,
      droppable: !this.readOnly,
      editable: !this.readOnly,
      eventStartEditable: !this.readOnly,
      eventResizableFromStart: !this.readOnly,
      eventDurationEditable: !this.readOnly,
      unselectAuto: false, // prevent the selection to disappear while interacting with the event editor form
      allDayMaintainDuration: true,
      eventSources: [
        {
          id: 'timeslots',

          events: (fetchInfo, successCallback, failureCallback) => {
            successCallback(this.events.map(this.hdisToCalendarEvent));
          },
          // eventDataTransform: this.hdisToCalendarEvent
        },
      ],
      eventClick: this.onSelectEvent,
      select: this.onSelectRange,
      unselect: this.onClearSelectRange,
      eventResize: this.onResizeEvent,
      eventDrop: this.onMoveEvent,
      drop: this.onMoveFromGlobalEvent,
      eventLeave: this.onMoveToGlobalEvent,
      eventDidMount: this.onEventRendered,
    };
  }

  reloadEvents = () => {
    this.calendarComponent?.getApi()?.unselect();
    this.calendarComponent?.getApi()?.refetchEvents();
  };

  private denormalizeEvents(events: HdisCalendarEvent[], global: boolean): EventInput[] {
    return events
      .filter((event) => ((event.type === 'global') === global))
      .map(this.hdisToCalendarEvent);
  }

  hdisToCalendarEvent = (event: HdisCalendarEvent, index: number): EventInput => {
    let calEvent: EventInput;
    const baseEvent: EventInput = {
      id: event.id,
      groupId: event.id,
      title: event.label,
      textColor: 'black',
      color: event.color || this.eventsPalette[index],
      // source: event.source,
      extendedProps: {
        hdisId: event.id,
        type: event.type,
        actions: event.actions,
        info: event.info,
      },
    };

    if (event.type === 'global') {
      calEvent = {
        ...baseEvent,
      };
    } else {
      calEvent = {
        ...baseEvent,
        allDay: (event.type === 'day'),
        start: event.start,
        end: event.end,
      };
    }

    if (event.recurrence) {
      /// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // ENSURE THAT THIS IS INLINE WITH WHAT IS DEFINED IN PLG BACKEND SERVICE TO AVOID UNEXPECTED RESULTS IN PLAYLIST GENERATION //
      /// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      const { type, rule: deprecatedRule, count, exclude, until, weekDays } = event.recurrence;

      const eventTime = event.start.split('T').pop();
      // since it is just a difference between two dates we dont care about the timezone
      const duration = (event.start && event.end) ? new Date(event.end).getTime() - new Date(event.start).getTime() : null;

      const calRecurrence: any = {}; // should be RRuleInput, but @fullcalendar/rrule does not export it
      // oddly enough, FullCalendar expect a lowercase representation of the Frequency enum from RRule.
      // So basically, Frequency.DAILY turns out to be (and be stored in abcdj) as 3, while FullCalendar
      // wants it to be 'daily'. So we get the enum key based on the value

      if (count) calRecurrence.count = count;
      if (until) calRecurrence.until = `${until.split('T').shift()}T${eventTime}`; // string date in format yyyy-MM-dd or yyyyMMdd as stated at https://fullcalendar.io/docs/rrule-plugin

      const excludedDates = (exclude?.length) ? exclude.map((date) => `${date.split('T').shift()}T${eventTime}`) : [];
      console.debug('[calendar] excluded dates', exclude, excludedDates);

      calRecurrence.dtstart = event.start; // string date in format yyyy-MM-dd'T'HH:mm:ss

      // there might be old events that did not define the type explicitly but still had a rrule definition.
      // in that case we ensure that type is custom
      const recurrenceType = type || 'custom';

      switch (recurrenceType) {
        case 'daily':
          calRecurrence.freq = 'daily';
          break;
        case 'weekly':
          calRecurrence.freq = 'weekly';
          break;
        case 'workdays':
          calRecurrence.freq = 'weekly';
          calRecurrence.byweekday = ['mo', 'tu', 'we', 'th', 'fr'];
          break;
        case 'weekends':
          calRecurrence.freq = 'weekly';
          calRecurrence.byweekday = ['sa', 'su'];
          break;
        case 'custom': {
          let byweekday = [];

          if (weekDays) {
            byweekday = weekDays;
          } else if (deprecatedRule?.byweekday) {
            byweekday = deprecatedRule.byweekday;
          }

          // for reasons still unknown, byweekday may come as a string if only one item is selected.
          // this was also the behaviour with the old deprecate rule. So we just ensure that we work
          // with string[] otherwise the calendar may break
          if (typeof byweekday === 'string') byweekday = [byweekday];

          // if no week day is specified, the event is considered not recurrent
          if (byweekday.length) {
            calRecurrence.freq = 'weekly';
            calRecurrence.byweekday = byweekday.map((weekDay) => weekDay.toLowerCase());
          } else {
            calRecurrence.freq = 'daily';
            calRecurrence.count = 1;
          }
          break;
        }
        default:
          throw new Error(`recurrence type ${type} not recognized`);
      }

      calEvent = {
        ...calEvent,
        duration,
        rrule: {
          ...calRecurrence,
        },
        exdate: excludedDates,
      };
    }

    return calEvent;
  };

  private calendarEventToOccurrence(event: EventApi): CalendarEventType {
    return {
      type: event.allDay ? 'day' : 'time',
      start: format(event.start, FORMAT_DATE_TIME_LOCAL),
      end: event.end && format(event.end, FORMAT_DATE_TIME_LOCAL),
    };
  }

  onSelectEvent = (selectionInfo: EventClickArg) => {
    const event = this.eventsMap[selectionInfo.event.extendedProps.hdisId];
    const occurrence = this.calendarEventToOccurrence(selectionInfo.event);
    this.eventSelected.emit({ event, occurrence, source: selectionInfo.jsEvent });
    console.debug('[calendar] clicked on event', { output: { event, occurrence, source: selectionInfo.jsEvent }, selectionInfo });
  };

  onSelectRange = (selectionInfo: DateSelectArg) => {
    if (this.readOnly) {
      console.warn('[calendar] attempted range selection for a read-only calendar');
    } else {
      const retValue: CalendarEventType = {
        type: selectionInfo.allDay ? 'day' : 'time',
        start: format(selectionInfo.start, FORMAT_DATE_TIME_LOCAL),
        end: format(selectionInfo.end, FORMAT_DATE_TIME_LOCAL),
      };
      this.eventRequested.emit({ destination: retValue });
      console.debug('[calendar] selected a range of days or timeslots', { output: { destination: retValue }, selectionInfo });
    }
  };

  onClearSelectRange = (selectionInfo: DateUnselectArg) => {
    console.debug('[calendar] cleared selection of a range of days or timeslots', selectionInfo);
  };

  onResizeEvent = (selectionInfo: EventResizeDoneArg) => {
    const event = this.eventsMap[selectionInfo.event.extendedProps.hdisId];
    const occurrence = this.calendarEventToOccurrence(selectionInfo.event);
    const startDelta = asRoughMs(selectionInfo.startDelta);
    const endDelta = asRoughMs(selectionInfo.endDelta);
    // const event: HdisCalendarEvent = this.calendarToHdisEvent(selectionInfo.event);
    const destination: CalendarEventType = {
      start: format(addMilliseconds(new Date(event.start), startDelta), FORMAT_DATE_TIME_LOCAL),
      end: format(addMilliseconds(new Date(event.end), endDelta), FORMAT_DATE_TIME_LOCAL),
      type: selectionInfo.event.allDay ? 'day' : 'time',
    };
    this.eventMoved.emit({ event, occurrence, destination });
    console.debug('[calendar] resized event', { output: { event, destination }, selectionInfo });
  };

  onMoveEvent = (selectionInfo: EventDropArg) => {
    const event = this.eventsMap[selectionInfo.event.extendedProps.hdisId];
    const occurrence = this.calendarEventToOccurrence(selectionInfo.event);
    const delta = asRoughMs(selectionInfo.delta);
    let destination: CalendarEventType;

    if (selectionInfo.event.allDay === selectionInfo.oldEvent.allDay) {
      destination = {
        start: format(addMilliseconds(new Date(event.start), delta), FORMAT_DATE_TIME_LOCAL),
        end: format(addMilliseconds(new Date(event.end), delta), FORMAT_DATE_TIME_LOCAL),
        type: selectionInfo.event.allDay ? 'day' : 'time',
      };
    } else {
      // moved from/to allDay
      destination = {
        start: selectionInfo.event.startStr.split('+')[0],
        end: selectionInfo.event.endStr.split('+')[0],
        type: selectionInfo.event.allDay ? 'day' : 'time',
      };
    }
    this.eventMoved.emit({ event, occurrence, destination });
    console.debug('[calendar] moved event', { output: { event, destination }, selectionInfo });
  };

  onMoveFromGlobalEvent = (selectionInfo: DropArg) => {
    console.debug('[calendar] moved global event in calendar', selectionInfo);
    // this.eventSelected.emit({ event: selectionInfo.event, source: selectionInfo.jsEvent })
  };

  onMoveToGlobalEvent = (selectionInfo: EventLeaveArg) => {
    console.debug('[calendar] moved calendar event in global', selectionInfo);
    // this.eventSelected.emit({ event: selectionInfo.event, source: selectionInfo.jsEvent })
  };

  onEventRendered = (eventInfo: EventMountArg) => {
    /**
     * @fixme https://github.com/fullcalendar/fullcalendar/issues/7191
     * known issue, the eventInfo.el is not the one added to the DOM and so the action icons are not visible in the daygrid mode
     */
    if (eventInfo.view.type !== 'listWeek') {
      const event = this.eventsMap[eventInfo.event.extendedProps.hdisId];
      const occurrence = this.calendarEventToOccurrence(eventInfo.event);

      eventInfo.event.extendedProps.actions?.forEach((action: HdisCalendarAction) => {
        const actionIcon = document.createElement('span');
        actionIcon.className = `material-icons hdis-event-action hdis-event-${action.id}-action`;
        actionIcon.innerHTML = action.icon || action.id;
        actionIcon.onclick = (evt) => {
          evt.stopPropagation();
          this.eventAction.emit({ event, occurrence, action: action.id, source: evt });
          console.debug('[calendar] event action', { output: { event, occurrence, action: action.id, source: evt }, eventInfo });
        };
        eventInfo.el.appendChild(actionIcon);
      });
    }
  };
}
