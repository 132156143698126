import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UploadStoreEffects } from './upload.effects';
import { dayReducer, featureKey } from './upload.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureKey, dayReducer),
    EffectsModule.forFeature([UploadStoreEffects]),
  ],
  providers: [UploadStoreEffects],
})
export class UploadStoreModule { }
