import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { ICredentials } from '@aws-amplify/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Resource, RoleCapabilities, Scope } from '@heardis/api-contracts';
import { Store } from '@ngrx/store';
import { Observable, from, map, tap } from 'rxjs';
import { State } from '../_state/state';
import { getUserInfo } from '../_state/user/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  roles: Set<string>;

  capabilities: Set<string>;

  constructor(
    private store: Store<State>,
    private http: HttpClient,
    private auth: AuthenticatorService,
  ) {
    // For debugging purposes, in case you wanted to listen to any even
    // Hub.listen(/.*/, (data) => {
    //   console.log(`[auth] listen all messages: ${data.payload.event}`, data.payload.data)
    // })

    this.capabilities = new Set();
    this.roles = new Set();

    this.store.select(getUserInfo).subscribe((userInfo) => {
      this.roles = new Set(userInfo.roles);
      this.capabilities = userInfo.roles
        .filter((roleName) => RoleCapabilities[roleName])
        .reduce((acc, curr) => {
          Object.entries(RoleCapabilities[curr] as { [res in Resource]: Scope[] }).map(([resource, scopes]) => scopes.map((scope) => acc.add(`${resource}.${scope}`)));
          return acc;
        }, new Set<string>());

      console.log('[auth] init user capabilities', this.roles, this.capabilities);
    });
  }

  logout(): Promise<void> {
    return Auth.signOut({ global: true })
      .then(() => console.debug('[auth] logout'))
      .catch((err) => console.error('[auth] logout error', err));
  }

  getAccessToken(): Promise<string> {
    return Auth.currentSession()
      .then((session) => session?.getAccessToken()?.getJwtToken())
      .catch((err) => { console.error('[auth] access token error', err); throw err; });
  }

  /** temporary workaround for using with the player that expects an observable until we implement te service worker or we migrate the inbox to the cdn too */
  wrapUrlWithAuth(url: string): Observable<string> {
    return from(this.getAccessToken()).pipe(
      tap(() => console.debug(`[auth] append jwt to query string: ${url}`)),
      map((token) => `${url}?token=${token}`),
    );
  }

  getAWSCredentials(): Promise<ICredentials> {
    return Auth.currentUserCredentials();
  }

  isLoggedIn(): Promise<boolean> {
    return Auth.currentAuthenticatedUser()
      .then(() => true)
      .catch(() => false);
  }

  /**
   * get username for the current user based on the information stored in the jwt
   */
  getUsername(): string {
    return this.auth.user.username;
  }

  /**
   * Check if the user has a role
   * This method should be the last resort and used only for temporary checks, as permissions
   * associated to roles may change over time.
   * @param resource
   * @param scope
   */
  hasRole(role: string) {
    return this.roles.has(role);
  }

  /**
   * Check if the user can perform an action on a resource
   * @param resource
   * @param scope
   */
  hasPermission(resource: string, scope?: string) {
    // return true;
    if (scope) {
      return this.capabilities.has(`${resource}.${scope}`);
    }
    return [...this.capabilities].some((cap) => cap.startsWith(resource));
  }

  featureFlag(feature: string) {
    switch (feature) {
      // case 'trackEditor':
      //   return this.hasRole(UserRole.SUPERUSER) || this.hasRole(UserRole.MUSIC_CONSULTANT);
      // case 'publishEdit':
      //   return this.hasRole(UserRole.SUPERUSER);
      default:
        return false;
    }
  }
}
