/* eslint-disable no-plusplus */
import { AudioEditRule } from '@heardis/api-contracts';
import { getClosestItem, roundMs } from '@heardis/hdis-ui';
import '@material/mwc-icon-button';
import { Observer } from 'wavesurfer.js/src/util';
import { WaveSurferPlugin } from 'wavesurfer.js/types/plugin';
import { EditorRule } from './editor-rule';
import { EditorSelector } from './editor-selector';
import { EditorPluginEvent, EditorPluginEventType, EditorPluginParams, EditorRuleInitParams, EditorRuleParams, EditorSelectorInitParams, Interval } from './interfaces';

/**
 * TODO figure out whether we really need debounce package:
 * using RegionsPlugin (that inspired this plugin) there's no issue, probably because `debounce` is a devDependency of wavesurfer and then is bundled.
 * When activating this plugin, an error is triggered because of issing debounce package. Probably something in the util is using it.
 *
 * to reproduce:
 * 1) remove debounce package from the deps
 * 2) start angular dev server
 * 3) enjoy the error when building
 */
export default class EditorPlugin extends Observer implements WaveSurferPlugin {
  enabled: boolean;

  params: EditorPluginParams;

  wavesurfer: any;

  util: any;

  maxRules: any;

  rulesMinLength: any;

  _onBackendCreated: () => void;

  wrapper: any;

  orientation: any;

  defaultEdgeScrollWidth: number;

  list: Record<string, EditorRule>;

  prelistening = false;

  snapToGrid = false;

  readonly = false;

  _onReady: () => void;

  selector: EditorSelector;

  dragStart: { progress: number; time: number };

  // minimum duration in seconds to consider it as a drag
  slop: number;

  static create(params: EditorPluginParams) {
    return {
      name: 'editor',
      deferInit: params && params.deferInit ? params.deferInit : false,
      params,
      staticProps: {
        enableEditor() {
          if (!this.initialisedPluginList.editor) this.initPlugin('editor');
          return this.editor.enable();
        },
        disableEditor() {
          if (!this.initialisedPluginList.editor) return;
          this.editor.disable();
        },
        updateEditorParams(newParams: Partial<EditorPluginParams>) {
          if (!this.initialisedPluginList.editor) this.initPlugin('editor');
          return this.editor.updateParams(newParams);
        },
        isEditorEnabled(): boolean {
          if (!this.initialisedPluginList.editor) return false;
          return this.editor.isEnabled();
        },
        setEditorReadonly(readonly: boolean) {
          if (!this.initialisedPluginList.editor) this.initPlugin('editor');
          return this.editor.setReadonly(readonly);
        },
        setEditorRules(rules: AudioEditRule[], ruleParams: EditorRuleParams) {
          if (!this.initialisedPluginList.editor) this.initPlugin('editor');
          return this.editor.setRules(rules, ruleParams);
        },
        addRule(rule: AudioEditRule, ruleParams: EditorRuleParams) {
          if (!this.initialisedPluginList.editor) {
            this.initPlugin('editor');
          }
          return this.editor.add(rule, ruleParams);
        },

        clearRules() {
          this.editor?.clear();
        },

        enableDragSelection(options) {
          if (!this.initialisedPluginList.editor) {
            this.initPlugin('editor');
          }
          this.editor.enableDragSelection(options);
        },

        disableDragSelection() {
          this.editor.disableDragSelection();
        },
      },
      instance: EditorPlugin,
    };
  }

  constructor(params: EditorPluginParams, ws) {
    super();
    this.params = params;
    this.wavesurfer = ws;
    this.util = {
      ...ws.util,
      getRuleSnapToGridValue: (value) => this.getRuleSnapToGridValue(value, params),

      /**
       * IMPORTANT!
       * The waveform and any other plugins that use canvas are sensitive to pixel ratio, because canvases are like images, they have their own
       * size and can be squeezed or stretched via CSS.
       *
       * If pixelRatio != 1 (and by default wavesurfer uses window.devicePixelRatio that is affected by both OS display scale and browser zoom),
       * the canvas is first rendered in it's full size and then squeezed to fit the HTML based on the pixelRatio.
       * Therefore, for plugins that render canvases (timeline, beatgrid...) and want to align to the waveform, it makes sense to base their
       * login on the original waveform canvas size and then adapt to the pixelRatio, with something like
       * const width = wsParams.fillParent && !wsParams.scrollParent ? this.drawer.getWidth() : this.drawer.wrapper.scrollWidth * wsParams.pixelRatio;
       *
       * However, plugins that render html elements like this editor what is more relevant is the current size of the html elements of the canvas and
       * its container. Then the options are 2:
       * 1) you want the full size of the waveform (for example to get the pixelsPerSecond ratio): drawer.width / wsParams.pixelRatio or drawer.wrapper.scrollWidth
       * 2) you want the size of the viewport of the waveform (for example to get the pxPerSec at 1x to apply a zoom multiplier): drawer.wrapper.clientWidth
       *
       * Remember that Drawer is not an html element but a MultiCanvas object, and that drawer.width is not the size of the drawer HTML element but the original size of the canvas
       * that when used should account for the pixelRatio
       */
      getPixelsPerSecond: () => this.wavesurfer.drawer.width / this.wavesurfer.params.pixelRatio / this.wavesurfer.getDuration(),

      createIconButton: (icon: string, attrs?:[string, string][]) => {
        const button = document.createElement('mwc-icon-button');
        button.setAttribute('icon', icon);
        attrs?.forEach(([key, value]) => button.setAttribute(key, value));
        return button;
      },
      isSnapToGridEnabled: () => this.shouldSnapToGrid(),
      getCursorTime: (e) => {
        const progress = this.wavesurfer.drawer.handleEvent(e);
        const current = { progress, time: roundMs(progress * this.wavesurfer.getDuration()), position: e.clientX };
        console.log('[cursor] ', current);
        return current;
      },
      getValidTimestamp: (timestamp: number): number => {
        if (!this.shouldSnapToGrid()) return roundMs(timestamp);
        return getClosestItem([0, ...this.wavesurfer.getBeatgrid(true), this.wavesurfer.getDuration()], timestamp);
      },
    };

    this.slop = params.slop || 0.001; // if move less than 0.1% ignore
    this.maxRules = params.maxRules;
    this.rulesMinLength = params.rulesMinLength || null;

    this.updateParams(params);

    // turn the plugin instance into an observer
    const observerPrototypeKeys = Object.getOwnPropertyNames(
      this.util.Observer.prototype,
    );
    observerPrototypeKeys.forEach((key) => {
      EditorRule.prototype[key] = this.util.Observer.prototype[key];
      EditorSelector.prototype[key] = this.util.Observer.prototype[key];
    });
    this.wavesurfer.EditorRule = EditorRule;
    this.wavesurfer.EditorSelector = EditorSelector;

    // By default, scroll the container if the user drags a rule
    // within 5% (based on its initial size) of its edge
    const scrollWidthProportion = 0.05;
    this._onBackendCreated = () => {
      this.wrapper = this.wavesurfer.drawer.wrapper;
      this.orientation = this.wavesurfer.drawer.orientation;
      this.defaultEdgeScrollWidth = this.wrapper.clientWidth * scrollWidthProportion;
      if (this.params.rules) {
        this.params.rules.forEach((rule) => {
          this.add(rule, this.params.defaultRuleParams);
        });
      }
    };

    // Id-based hash of rules
    this.list = {};
    this._onReady = () => {
      this.wrapper = this.wavesurfer.drawer.wrapper;

      // Skip support for fancy parameters that add a ton of complexity and no value to our current case.
      // if at any time support is needed, have a look to Region and other plugins for insipiration
      if (this.wavesurfer.drawer.params.vertical) throw Error('Editor plugin does not support vertival waveforms');
      if (this.wavesurfer.params.splitChannels) throw Error('Editor plugin does not support split channel waveforms');

      if (this.params.dragSelection) {
        this.enableDragSelection(this.params);
      }
      Object.keys(this.list).forEach((id) => {
        this.list[id].render();
      });

      if (!this.selector) {
        const selectorParams: EditorSelectorInitParams = {
          minLength: this.rulesMinLength,
          start: null,
          end: null,
          color: '#666666',
          // then override props from rule params
          // ...params,
          // and finally define with that depends on the size at runtime
          edgeScrollWidth: this.params.edgeScrollWidth || this.defaultEdgeScrollWidth,
        };

        this.selector = new this.wavesurfer.EditorSelector(selectorParams, this.util, this.wavesurfer);
      }
    };
  }

  init() {
    // Check if ws is ready
    if (this.wavesurfer.isReady) {
      this._onBackendCreated();
      this._onReady();
    } else {
      this.wavesurfer.once('ready', this._onReady);
      this.wavesurfer.once('backend-created', this._onBackendCreated);
    }
  }

  destroy() {
    this.wavesurfer.un('ready', this._onReady);
    this.wavesurfer.un('backend-created', this._onBackendCreated);
    // Disabling `rule-removed' because destroying the plugin calls
    // the Rule.remove() method that is also used to remove rules based
    // on user input. This can cause confusion since teardown is not a
    // user event, but would emit `rule-removed` as if it was.
    this.wavesurfer.setDisabledEventEmissions(['rule-removed']);
    this.disableDragSelection();
    this.clear();
  }

  updateParams(params: Partial<EditorPluginParams>) {
    if (params.snapToGrid !== null) this.snapToGrid = params.snapToGrid;
    if (params.prelistening !== null) this.prelistening = params.prelistening;
    console.debug('[audio-player] update editor config', { snapToGrid: this.snapToGrid, prelistening: this.prelistening });
  }

  setReadonly(readonly) {
    if (readonly === this.readonly) return;
    this.readonly = readonly;
    (readonly) ? this.disableDragSelection() : this.enableDragSelection(this.params);
  }

  /**
   * check to see if adding a new rule would exceed maxRules
   * @return {boolean} whether we should proceed and create a rule
   * @private
   */
  wouldExceedMaxRules() {
    return (
      this.maxRules && Object.keys(this.list).length >= this.maxRules
    );
  }

  /** simple method that reload all the rules */
  setRules(rules: AudioEditRule[], params: EditorRuleParams) {
    this.clear();
    (rules || [])
      .filter((rule) => rule.enabled)
      .forEach((rule) => this.add(rule, params));
  }

  add(rule: AudioEditRule, params: EditorRuleParams): EditorRule {
    if (this.wouldExceedMaxRules()) {
      return null;
    }

    const ruleParams: EditorRuleInitParams = {
      minLength: this.rulesMinLength,
      // then override props from rule params
      ...params,
      // and finally define with that depends on the size at runtime
      edgeScrollWidth: this.params.edgeScrollWidth || this.defaultEdgeScrollWidth,
    };

    const editorRule = new this.wavesurfer.EditorRule(rule, ruleParams, this.util, this.wavesurfer);

    this.list[editorRule.id] = editorRule;

    editorRule.on('removed', () => {
      delete this.list[editorRule.id];
    });

    return editorRule;
  }

  /**
   * Remove all rules and the selector
   */
  clear() {
    this.selector?.hide();
    Object.keys(this.list).forEach((id) => {
      this.list[id].remove();
    });
  }

  onDragging = (e) => {
    const { progress, time: targetTime } = this.util.getCursorTime(e);
    if (Math.abs(progress - this.dragStart.progress) < this.slop) {
      this.selector.hide();
      return;
    }
    console.debug(`[editor] keep dragging from ${this.dragStart.time} to ${targetTime}`);
    if (!this.selector.isVisible()) this.selector.show();
    const selectorTIming: Interval = {
      start: Math.min(this.dragStart.time, targetTime),
      end: Math.max(this.dragStart.time, targetTime),
    };
    this.selector.renderPreview(selectorTIming);
  };

  onDragStart = (e) => {
    if (this.readonly) return;
    // to prevent event on the waveform while interacting with rules and selector.
    // workaround until we find a better way to stop propagation on parents while retaining the click event on child
    // (action buttons and handles would not work otherwise)
    if (e.currentTarget !== e.target) return;
    // if (this.selector.isVisible()) return
    this.dragStart = this.util.getCursorTime(e);
    console.debug('[editor] start dragging in ', this.dragStart);
    e.target.addEventListener('pointermove', this.onDragging);
    e.target.setPointerCapture(e.pointerId);
  };

  onDragEnd = (e) => {
    if (this.readonly) return;
    // see at onDragStart
    const { progress, time: targetTime } = this.util.getCursorTime(e);

    if (e.currentTarget !== e.target) return;
    e.target.removeEventListener('pointermove', this.onDragging);
    e.target.releasePointerCapture(e.pointerId);

    if (Math.abs(progress - this.dragStart.progress) < this.slop) {
      this.dragStart = null;
      this.selector.hide();
      return;
    }
    console.debug('[editor] stop dragging in ', progress);
    const targetDelta = targetTime - this.dragStart.time;
    const selectorTIming: Interval = {
      start: Math.min(this.dragStart.time, targetTime),
      end: Math.max(this.dragStart.time, targetTime),
    };
    this.selector.updateSelector(selectorTIming);
    this.selector.render();
    this.dragStart = null;
  };

  enableDragSelection(params) {
    this.disableDragSelection();

    const slop = params.slop || 2;
    const { container } = this.wavesurfer.drawer;
    const scroll = params.scroll !== false && this.wavesurfer.params.scrollParent;
    const scrollSpeed = params.scrollSpeed || 1;
    const scrollThreshold = params.scrollThreshold || 10;
    let drag;
    let duration = this.wavesurfer.getDuration();
    let maxScroll;
    let selection;
    let start;
    let touchId;
    let pxMove = 0;
    let scrollDirection;
    let wrapperRect;

    // Scroll when the user is dragging within the threshold
    const edgeScroll = (e) => {
      if (!selection || !scrollDirection) {
        return;
      }

      // Update scroll position
      let scrollLeft =
              this.wrapper.scrollLeft + scrollSpeed * scrollDirection;
      scrollLeft = Math.min(maxScroll, Math.max(0, scrollLeft));
      this.wrapper.scrollLeft = scrollLeft;

      // Update range
      const end = this.wavesurfer.drawer.handleEvent(e);
      selection.update({
        start: Math.min(end * duration, start * duration),
        end: Math.max(end * duration, start * duration),
      }, null);

      // Check that there is more to scroll and repeat
      if (scrollLeft < maxScroll && scrollLeft > 0) {
        window.requestAnimationFrame(() => {
          edgeScroll(e);
        });
      }
    };

    const eventDown = (e) => {
      if (e.touches && e.touches.length > 1) {
        return;
      }
      duration = this.wavesurfer.getDuration();
      touchId = e.targetTouches ? e.targetTouches[0].identifier : null;

      // Store for scroll calculations
      maxScroll = this.wrapper.scrollWidth - this.wrapper.clientWidth;
      wrapperRect = this.wrapper.getBoundingClientRect();

      drag = true;
      start = this.wavesurfer.drawer.handleEvent(e, true);
      selection = null;
      scrollDirection = null;
    };

    this.wrapper.addEventListener('pointerdown', this.onDragStart);
    this.wrapper.addEventListener('pointerup', this.onDragEnd);

    this.on('disable-drag-selection', () => {
      this.wrapper.removeEventListener('pointerdown', this.onDragStart);
      this.wrapper.removeEventListener('pointerup', this.onDragEnd);
    });

    const eventUp = (e) => {
      if (e.touches && e.touches.length > 1) {
        return;
      }

      drag = false;
      pxMove = 0;
      scrollDirection = null;

      if (selection) {
        this.util.preventClick();
        selection.fireEvent('update-end', e);
        this.wavesurfer.fireEvent('rule-update-end', selection, e);
      }

      selection = null;
    };
    this.wrapper.addEventListener('mouseleave', eventUp);
    this.wrapper.addEventListener('mouseup', eventUp);
    this.wrapper.addEventListener('touchend', eventUp);

    document.body.addEventListener('mouseup', eventUp);
    document.body.addEventListener('touchend', eventUp);
    this.on('disable-drag-selection', () => {
      document.body.removeEventListener('mouseup', eventUp);
      document.body.removeEventListener('touchend', eventUp);
      this.wrapper.removeEventListener('touchend', eventUp);
      this.wrapper.removeEventListener('mouseup', eventUp);
      this.wrapper.removeEventListener('mouseleave', eventUp);
    });

    const eventMove = (event) => {
      if (!drag) {
        return;
      }
      if (++pxMove <= slop) {
        return;
      }

      if (event.touches && event.touches.length > 1) {
        return;
      }
      if (event.targetTouches && event.targetTouches[0].identifier !== touchId) {
        return;
      }

      // auto-create a rule during mouse drag, unless rule-count would exceed "maxRules"
      // if (!selection) {
      //   selection = this.select(params || {});
      //   if (!selection) {
      //     return;
      //   }
      // }

      const end = this.wavesurfer.drawer.handleEvent(event);
      const startUpdate = this.wavesurfer.editor.util.getRuleSnapToGridValue(start * duration);
      const endUpdate = this.wavesurfer.editor.util.getRuleSnapToGridValue(end * duration);
      selection.update({
        start: Math.min(endUpdate, startUpdate),
        end: Math.max(endUpdate, startUpdate),
      }, null);

      // If scrolling is enabled
      if (scroll && container.clientWidth < this.wrapper.scrollWidth) {
        // Check threshold based on mouse
        const x = event.clientX - wrapperRect.left;
        if (x <= scrollThreshold) {
          scrollDirection = -1;
        } else if (x >= wrapperRect.right - scrollThreshold) {
          scrollDirection = 1;
        } else {
          scrollDirection = null;
        }
        if (scrollDirection) edgeScroll(event);
      }
    };
    this.wrapper.addEventListener('mousemove', eventMove);
    this.wrapper.addEventListener('touchmove', eventMove);
    this.on('disable-drag-selection', () => {
      this.wrapper.removeEventListener('touchmove', eventMove);
      this.wrapper.removeEventListener('mousemove', eventMove);
    });
    // stefano: unnecessary as it is already passed to the Rule constructor
    // this.wavesurfer.on('rule-created', (newRule: EditorRule) => {
    //   if (this.rulesMinLength) {
    //     newRule.minLength = this.rulesMinLength;
    //   }
    // });
  }

  // on(arg0: string, arg1: () => void) {
  //   throw new Error("Method not implemented.");
  // }

  disableDragSelection() {
    this.fireEvent('disable-drag-selection');
  }
  // fireEvent(arg0: string) {
  //   throw new Error("Method not implemented.");
  // }

  /**
   * Match the value to the grid, if required
   *
   * If the rules plugin params have a snapToGridInterval set, return the
   * value matching the nearest grid interval. If no snapToGridInterval is set,
   * the passed value will be returned without modification.
   *
   * @param {number} value the value to snap to the grid, if needed
   * @param {Object} params the editor plugin params
   * @returns {number} value
   */
  getRuleSnapToGridValue(value, params) {
    if (params.snapToGridInterval) {
      // the rules should snap to a grid
      const offset = params.snapToGridOffset || 0;
      return (Math.round((value - offset) / params.snapToGridInterval) * params.snapToGridInterval + offset);
    }

    // no snap-to-grid
    return value;
  }

  dispatchEvent(type: EditorPluginEventType, props?: any) {
    this.fireEvent(type, props);
    const event: EditorPluginEvent = { type, props };
    this.wavesurfer.fireEvent('editor', event);
  }

  /** @todo make it smarter by really enabling the plugin rather than showing its visible elements */
  enable() {
    this.enabled = true;
    if (this.params.dragSelection) this.enableDragSelection(this.params);
    this.dispatchEvent('enabled');
  }

  disable() {
    this.enabled = false;
    this.disableDragSelection();
    this.clear();
    this.dispatchEvent('disabled');
  }

  isEnabled() {
    return this.enabled;
  }

  shouldSnapToGrid() {
    return this.snapToGrid && this.wavesurfer.hasBeatgrid();
  }
}
