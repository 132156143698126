<div role="group" class="input-container"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField?.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)"
     >
    <input class="input-element input-minutes {{units.includes('minutes') ? 'visible' : 'hidden'}}"
         formControlName="minutes"
         type="number"
         (focusin)="handleInputFocusIn($event)"
         (focusout)="handleInputFocusOut($event)"
         (input)="handleInput()"
         (paste)="onPaste($event)"
         #minutes
         placeholder="--"
         [readonly]="readonly">
    <span *ngIf="units.includes('minutes')">:</span>
    <input class="input-element input-seconds {{units.includes('seconds') ? 'visible' : 'hidden'}}"
         formControlName="seconds"
         type="number"
         (focusin)="handleInputFocusIn($event)"
         (focusout)="handleInputFocusOut($event)"
         (input)="handleInput()"
         (paste)="onPaste($event)"
         (keyup.backspace)="units.includes('minutes') && autoFocusPrev(seconds, minutes)"
         placeholder="--"
         #seconds
         [readonly]="readonly">
    <span *ngIf="units.includes('milliseconds')">.</span>
    <input class="input-element input-milliseconds {{units.includes('milliseconds') ? 'visible' : 'hidden'}}"
         formControlName="milliseconds"
         type="number"
         (focusin)="handleInputFocusIn($event)"
         (focusout)="handleInputFocusOut($event)"
         (input)="handleInput()"
         (paste)="onPaste($event)"
         (keyup.backspace)="units.includes('seconds') && autoFocusPrev(milliseconds, seconds)"
         placeholder="---"
         #milliseconds
         [readonly]="readonly">
</div>
