/* eslint-disable max-len */
import { AudioEditEffect, AudioEditEffectType, AudioEditRule } from '@heardis/api-contracts';
import { Icons } from '@heardis/hdis-ui';
import '@material/mwc-icon-button';
import { Observer } from 'wavesurfer.js/src/util';
import { EditorSelectorEvent, EditorSelectorEventType, EditorSelectorHandleType, EditorSelectorInitParams, Interval } from './interfaces';

type ResizeTargetEffect = { handleType: 'start' | 'end' };

export class EditorSelector extends Observer {
  wavesurfer: WaveSurfer;

  wrapper: any;

  util: any;

  style: any;

  editorUtil: any;

  id: any;

  resize: boolean;

  drag: boolean;

  timing: Interval;

  isResizing: boolean;

  dragStartTime: number;

  resizeStartTime: number;

  resizeTarget: ResizeTargetEffect;

  draggingStartPosition: number;

  loop: boolean;

  color: any;

  attributes: any;

  maxLength: any;

  minLength: any;

  scroll: any;

  scrollSpeed: any;

  scrollThreshold: any;

  preventContextMenu: boolean;

  ruleHeight: string;

  marginTop: string;

  edgeScrollWidth: any;

  selectorEl: any;

  firedIn: boolean;

  firedOut: boolean;

  firedEvent: AudioEditEffectType;

  preButtonEl: HTMLButtonElement;

  effectButtonEl: HTMLButtonElement;

  postButtonEl: HTMLButtonElement;

  dragButtonEl: HTMLElement;

  playButtonEl: HTMLElement;

  deleteButtonEl: HTMLElement;

  lockButtonEl: HTMLElement;

  loopButtonEl: HTMLElement;

  rule: AudioEditRule;

  firstEffect: AudioEditEffect;

  lastEffect: AudioEditEffect;

  preEffectEl: HTMLElement;

  effectEl: HTMLElement;

  postEffectEl: HTMLElement;

  actionButtonsEl: HTMLElement;

  mouseDownTime: number;

  visible: boolean;

  constructor(params: EditorSelectorInitParams, editorUtils, ws) {
    super();

    this.wavesurfer = ws;
    this.wrapper = ws.drawer.wrapper;
    this.util = ws.util;
    this.style = this.util.style;
    this.editorUtil = editorUtils;

    this.timing = { start: params.start, end: params.end };

    this.id = ws.util.getId();
    this.drag = params.drag === undefined ? true : Boolean(params.drag);
    // reflect resize and drag state of rule for rule-updated listener
    this.isResizing = false;
    this.loop = Boolean(params.loop);
    this.color = params.color || '#666666';
    this.attributes = params.attributes || {};

    this.maxLength = params.maxLength;
    // It assumes the minLength parameter value, or the rulesMinLength parameter value, if the first one not provided
    this.minLength = params.minLength;

    this.scroll = params.scroll !== false && ws.params.scrollParent;
    this.scrollSpeed = params.scrollSpeed || 1;
    this.scrollThreshold = params.scrollThreshold || 10;
    // Determines whether the context menu is prevented from being opened.
    this.preventContextMenu = params.preventContextMenu === undefined ? false : Boolean(params.preventContextMenu);

    this.ruleHeight = '100%';
    this.marginTop = '0px';

    this.edgeScrollWidth = params.edgeScrollWidth;
    this.initSelector();
    this.wavesurfer.on('zoom', this.render);
    this.wavesurfer.on('redraw', this.render);
    this.dispatchEvent('created');
  }

  dispatchEvent(type: EditorSelectorEventType, props?: any) {
    this.fireEvent(type, props);
    const event: EditorSelectorEvent = { ctx: 'selector', type, selection: this, props };
    this.wavesurfer.fireEvent('editor', event);
  }

  /* Update rule params. */
  update(params, eventParams) {
    if (params.loop != null) {
      this.loop = Boolean(params.loop);
    }
    if (params.color != null) {
      this.color = params.color;
    }
    if (params.drag != null) {
      this.drag = Boolean(params.drag);
    }
    if (params.maxLength != null) {
      this.maxLength = Number(params.maxLength);
    }
    if (params.minLength != null) {
      this.minLength = Number(params.minLength);
    }
    if (params.attributes != null) {
      this.attributes = params.attributes;
    }
  }

  /* Remove a single rule. */
  remove() {
    if (this.selectorEl) {
      this.wrapper.removeChild(this.selectorEl);
      this.selectorEl = null;
      this.wavesurfer.un('zoom', this.render);
      this.wavesurfer.un('redraw', this.render);
      this.dispatchEvent('removed');
    }
  }

  /**
   * Play the audio rule.
   * @param {number} start Optional offset to start playing at
   */
  play(start) {
    if (!this.firstEffect || !this.lastEffect) return;
    this.setLoop(false);
    const s = start || this.firstEffect.start;
    this.wavesurfer.play(s, this.lastEffect.end);
    this.dispatchEvent('play');
  }

  fadein(currentTime, startTime, endTime) {
    const startVolume = 0;
    const endVolume = 1;
    const progress = (currentTime - startTime) / (endTime - startTime);
    const volume = startVolume + (endVolume - startVolume) * progress;
    this.wavesurfer.setVolume(Math.min(Math.max(volume, 0), 1));
  }

  fadeout(currentTime, startTime, endTime) {
    const startVolume = 1;
    const endVolume = 0;
    const progress = (currentTime - startTime) / (endTime - startTime);
    const volume = startVolume - (startVolume - endVolume) * progress;
    this.wavesurfer.setVolume(Math.min(Math.max(volume, 0), 1));
  }

  /**
   * Play the audio rule in a loop.
   * @param {number} start Optional offset to start playing at
   * */
  playLoop(start) {
    this.play(start);
    this.setLoop(true);
  }

  /**
   * Set looping on/off.
   * @param {boolean} loop True if should play in loop
   */
  setLoop(loop) {
    this.loop = loop;
  }

  resizing = (e) => {

  };

  onResizing = (e) => {
    // you may think we could just use the target time, as we are addressing only one timestamp and not ranges like in drag
    // however the issue is that the mouse position is still slightly offset from the effect border
    const { time: targetTime } = this.editorUtil.getCursorTime(e);
    const targetDelta = targetTime - this.resizeStartTime;
    const newTiming = this.resizeSelector(this.timing, targetDelta);
    console.debug(`[selector] resizing for a delta of ${targetDelta}s`, newTiming);
    this.renderPreview(newTiming);
  };

  onResizeStart = (e) => {
    e.target.addEventListener('pointermove', this.onResizing);
    e.target.setPointerCapture(e.pointerId);
    const { time: targetTime } = this.editorUtil.getCursorTime(e);
    this.resizeStartTime = targetTime;
    this.resizeTarget = { handleType: e.target.getAttribute('handle-type') };
    console.debug(`[selector] start resizing ${this.resizeTarget.handleType}`, this.timing);
  };

  onResizeEnd = (e) => {
    e.target.removeEventListener('pointermove', this.onResizing);
    e.target.releasePointerCapture(e.pointerId);

    const { time: targetTime } = this.editorUtil.getCursorTime(e);
    const targetDelta = targetTime - this.resizeStartTime;
    const newTiming = this.resizeSelector(this.timing, targetDelta);
    this.updateSelector(newTiming);
    this.render();
    this.dispatchEvent('updated');
    console.debug(`[selector] stop resizing ${this.resizeTarget.handleType}`, this.timing);
    this.resizeStartTime = null;
    this.resizeTarget = null;
  };

  private resizeSelector = (previousTiming: Interval, targetDeltaTime: number): Interval => {
    const deltaTime = targetDeltaTime;
    const { handleType } = this.resizeTarget;
    const newTiming: Interval = structuredClone(previousTiming);

    // ensure that resizing the effect does not push the rule outside of the track
    // and that an effect cant be resized to lower than 0
    const minTimestamp = 0;
    const maxTimestamp = this.wavesurfer.getDuration();
    let targetTimestamp = Math.max(minTimestamp, Math.min(this.resizeStartTime + deltaTime, maxTimestamp));

    // and that it sticks to the beatgrid if enabled
    targetTimestamp = this.editorUtil.getValidTimestamp(this.resizeStartTime + deltaTime);

    newTiming[handleType] = targetTimestamp;

    return newTiming;
  };

  private createResizeHandle = (position: 'start' | 'end') => {
    const handle = document.createElement('handle');
    handle.setAttribute('handle-type', position);
    handle.addEventListener('pointerdown', this.onResizeStart);
    handle.addEventListener('pointerup', this.onResizeEnd);
    return handle;
  };

  /* Render a rule as a DOM element. */
  private initSelector() {
    this.selectorEl = this.wrapper.appendChild(document.createElement('selector'));
    /** This tabIndex is necessary to make the HTML Element focusable */
    this.selectorEl.tabIndex = 0;
    this.selectorEl.setAttribute('data-id', this.id);
    if (this.resize) this.selectorEl.setAttribute('is-resizable', '');

    Object.entries(this.attributes).forEach(([name, value]) => {
      this.selectorEl.setAttribute(`data-rule-${name}`, value);
    });

    this.style(this.selectorEl, {
      position: 'absolute',
      zIndex: 3,
      height: this.ruleHeight,
      top: this.marginTop,
      color: this.color,
      backgroundColor: `${this.color}99`,
    });

    const preHandle = this.createResizeHandle('start');
    this.selectorEl.appendChild(preHandle);

    const postHandle = this.createResizeHandle('end');
    this.selectorEl.appendChild(postHandle);

    /* Action buttons to play, loop, lock and delete the rule */
    this.actionButtonsEl = this.selectorEl.appendChild(this.createActionButtonsElements());

    this.hide();
    this.render();
    this.bindEvents();
  }

  onDragging = (e) => {
    const { time: targetTime } = this.editorUtil.getCursorTime(e);
    const targetDelta = targetTime - this.dragStartTime;

    console.debug(`[selector] keep dragging from ${this.dragStartTime} to ${targetTime} for a delta of ${targetDelta}s`);

    const newTiming = this.move(this.timing, targetDelta);

    this.renderPreview(newTiming);
  };

  onDragStart = (e) => {
    console.debug('[selector] start dragging in ', e.clientX);
    e.target.addEventListener('pointermove', this.onDragging);
    e.target.setPointerCapture(e.pointerId);
    const { time: targetTime } = this.editorUtil.getCursorTime(e);
    this.dragStartTime = targetTime;
  };

  onDragEnd = (e) => {
    console.debug('[selector] stop dragging in ', e.clientX);
    e.target.removeEventListener('pointermove', this.onDragging);
    e.target.releasePointerCapture(e.pointerId);
    const { time: targetTime } = this.editorUtil.getCursorTime(e);
    const targetDelta = targetTime - this.dragStartTime;

    const newTiming = this.move(this.timing, targetDelta);
    this.updateSelector(newTiming);
    this.render();
    this.dispatchEvent('updated');
    this.dragStartTime = null;
  };

  private moveInterval = ({ start, end }: Interval, deltaTime: number): Interval => ({
    start: this.editorUtil.getValidTimestamp(start + deltaTime),
    end: this.editorUtil.getValidTimestamp(end + deltaTime),
  });

  private move = (previousTiming: Interval, targetDeltaTime: number): Interval => {
    console.debug(`[selector] attempt to move of ${targetDeltaTime}s from ${previousTiming.start}-${previousTiming.end}`);
    let deltaTime = targetDeltaTime;
    const maxEnd = this.wavesurfer.getDuration();

    // ensure that the rule cant move outside of track duration
    if (previousTiming.end + deltaTime > maxEnd) deltaTime = maxEnd - previousTiming.end;
    if (previousTiming.start + deltaTime < 0) deltaTime = previousTiming.start * -1;
    console.debug(`[selector] move of ${deltaTime}s from ${previousTiming.start}-${previousTiming.end}`);

    const newTiming: Interval = this.moveInterval(previousTiming, deltaTime);

    return newTiming;
  };

  createActionButtonsElements() {
    const actionButtonsElement = document.createElement('action-buttons');

    const dragButton = this.editorUtil.createIconButton(Icons.EDITOR_ACTION_DRAG, [['action', 'drag']]);
    this.dragButtonEl = actionButtonsElement.appendChild(dragButton);
    this.dragButtonEl.addEventListener('pointerdown', this.onDragStart);
    this.dragButtonEl.addEventListener('pointerup', this.onDragEnd);

    const createFadeoutButtonEl = actionButtonsElement.appendChild(this.editorUtil.createIconButton(Icons.EDITOR_EFFECT_FADEOUT, [['action', 'fade-out']]));
    // createFadeoutButtonEl.addEventListener('pointerdown', (e: PointerEvent) => {
    //   // e.stopImmediatePropagation()
    // })
    createFadeoutButtonEl.addEventListener('click', (e) => {
      e.stopPropagation();
      this.dispatchEvent('create', { preEffect: { type: AudioEditEffectType.FADEOUT, ...this.timing } });
    });

    const createCutButtonEl = actionButtonsElement.appendChild(this.editorUtil.createIconButton(Icons.EDITOR_EFFECT_CUT, [['action', 'cut']]));
    createCutButtonEl.addEventListener('click', (e) => {
      e.stopPropagation();
      this.dispatchEvent('create', { effect: { type: AudioEditEffectType.CUT, ...this.timing } });
    });

    const createFadeinButtonEl = actionButtonsElement.appendChild(this.editorUtil.createIconButton(Icons.EDITOR_EFFECT_FADEIN, [['action', 'fade-in']]));
    createFadeinButtonEl.addEventListener('click', (e) => {
      e.stopPropagation();
      this.dispatchEvent('create', { postEffect: { type: AudioEditEffectType.FADEIN, ...this.timing } });
    });

    const cancelButtonEl = actionButtonsElement.appendChild(this.editorUtil.createIconButton(Icons.EDITOR_ACTION_REMOVE, [['action', 'cancel']]));
    cancelButtonEl.addEventListener('click', (e) => {
      e.stopPropagation();
      this.hide();
    });

    return actionButtonsElement;
  }

  /**
   * @TODO @TOCHECK These keyboard event codes should come from the user preferences and have a default.
   * Add shortcuts for adding the effects. Should share the triggered events with the buttons. */
  onKeyDown = (event: KeyboardEvent) => {
    switch (event.code) {
      case 'Delete':
        this.dispatchEvent('remove', event);
        this.remove();
        break;
      case 'Space':
        if (event.shiftKey) {
          this.playLoop(this.firstEffect.start);
        } else {
          this.play(this.firstEffect.start);
        }
        break;
      default:
    }
  };

  /* Bind DOM events. */
  bindEvents() {
    const { preventContextMenu } = this;

    this.selectorEl.addEventListener('mouseenter', (e) => {
      this.dispatchEvent('mouse-enter', e);
    });

    this.selectorEl.addEventListener('mouseleave', (e) => {
      this.dispatchEvent('mouse-leave', e);
    });

    this.selectorEl.addEventListener('click', (e) => {
      console.debug('[selector] click');
      e.preventDefault();
      this.dispatchEvent('click', e);
    });

    this.selectorEl.addEventListener('dblclick', (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.dispatchEvent('double-click', e);
    });

    this.selectorEl.addEventListener('contextmenu', (e) => {
      if (preventContextMenu) {
        e.preventDefault();
      }
      this.dispatchEvent('context-menu', e);
    });

    this.selectorEl.addEventListener('keydown', this.onKeyDown);

    /* Drag or resize on mousemove. */
    if (this.drag || this.resize) {
      this.bindDragEvents();
    }
  }

  bindDragEvents() {
    const { container } = this.wavesurfer.drawer;
    const { scrollSpeed } = this;
    const { scrollThreshold } = this;
    let startTime;
    let touchId;
    let drag;
    let maxScroll;
    let resize: EditorSelectorHandleType;
    let updated = false;
    let scrollDirection;
    let wrapperRect;
    let ruleLeftHalfTime;
    let ruleRightHalfTime;

    // Scroll when the user is dragging within the threshold
    const edgeScroll = (event) => {
      const duration = this.wavesurfer.getDuration();
      if (!scrollDirection || (!drag && !resize)) {
        return;
      }

      const x = event.clientX;
      let distanceBetweenCursorAndWrapperEdge = 0;
      let ruleHalfTimeWidth = 0;
      const adjustment = 0;

      // Get the currently selected time according to the mouse position
      const { time: targetTime } = this.editorUtil.getCursorTime(event);
      const time = this.editorUtil.getRuleSnapToGridValue(targetTime);

      if (drag) {
        // Considering the point of contact with the rule while edgescrolling
        if (scrollDirection === -1) {
          ruleHalfTimeWidth = ruleLeftHalfTime * this.wavesurfer.params.minPxPerSec;
          distanceBetweenCursorAndWrapperEdge = x - wrapperRect.left;
        } else {
          ruleHalfTimeWidth = ruleRightHalfTime * this.wavesurfer.params.minPxPerSec;
          distanceBetweenCursorAndWrapperEdge = wrapperRect.right - x;
        }
      } else {
        // Considering minLength while edgescroll
        let { minLength } = this;
        if (!minLength) {
          minLength = 0;
        }

        // if (resize === 'start') {
        //   if (time > this.lastEffect.end - minLength) {
        //     time = this.lastEffect.end - minLength;
        //     adjustment = scrollSpeed * scrollDirection;
        //   }

        //   if (time < 0) {
        //     time = 0;
        //   }
        // } else if (resize === 'end') {
        //   if (time < this.firstEffect.start + minLength) {
        //     time = this.firstEffect.start + minLength;
        //     adjustment = scrollSpeed * scrollDirection;
        //   }

        //   if (time > duration) {
        //     time = duration;
        //   }
        // }
      }

      // Don't edgescroll if rule has reached min or max limit
      const wrapperScrollLeft = this.wrapper.scrollLeft;

      if (scrollDirection === -1) {
        if (Math.round(wrapperScrollLeft) === 0) {
          return;
        }

        if (Math.round(wrapperScrollLeft - ruleHalfTimeWidth + distanceBetweenCursorAndWrapperEdge) <= 0) {
          return;
        }
      } else {
        if (Math.round(wrapperScrollLeft) === maxScroll) {
          return;
        }

        if (Math.round(wrapperScrollLeft + ruleHalfTimeWidth - distanceBetweenCursorAndWrapperEdge) >= maxScroll) {
          return;
        }
      }

      // Update scroll position
      let scrollLeft = wrapperScrollLeft - adjustment + scrollSpeed * scrollDirection;

      if (scrollDirection === -1) {
        const calculatedLeft = Math.max(0 + ruleHalfTimeWidth - distanceBetweenCursorAndWrapperEdge, scrollLeft);
        scrollLeft = calculatedLeft;
        this.wrapper.scrollLeft = scrollLeft;
      } else {
        const calculatedRight = Math.min(maxScroll - ruleHalfTimeWidth + distanceBetweenCursorAndWrapperEdge, scrollLeft);
        scrollLeft = calculatedRight;
        this.wrapper.scrollLeft = scrollLeft;
      }
      const delta = time - this.mouseDownTime;

      // Continue dragging or resizing
      // this.onResize(delta, resize);

      // Repeat
      window.requestAnimationFrame(() => {
        edgeScroll(event);
      });
    };

    const onDown = (event) => {
      const duration = this.wavesurfer.getDuration();
      if (event.touches && event.touches.length > 1) {
        return;
      }
      touchId = event.targetTouches ? event.targetTouches[0].identifier : null;

      // stop the event propagation, if this rule is resizable or draggable
      // and the event is therefore handled here.
      if (this.drag || this.resize) {
        event.stopPropagation();
      }

      // Store the selected startTime we begun dragging or resizing
      const { time: targetTime } = this.editorUtil.getCursorTime(event);
      startTime = this.editorUtil.getRuleSnapToGridValue(targetTime);
      this.mouseDownTime = startTime;

      // Store the selected point of contact when we begin dragging
      ruleLeftHalfTime = startTime - this.firstEffect.start;
      ruleRightHalfTime = this.lastEffect.end - startTime;

      // Store for scroll calculations
      maxScroll = this.wrapper.scrollWidth - this.wrapper.clientWidth;

      wrapperRect = this.wrapper.getBoundingClientRect();

      this.isResizing = false;
      if (event.target.tagName.toLowerCase() === 'handle') {
        this.isResizing = true;
        resize = (event.target as HTMLElement).dataset.type as EditorSelectorHandleType;
      } else {
        resize = undefined;
      }
    };
    const onUp = (event) => {
      if (event.touches && event.touches.length > 1) {
        return;
      }

      if (drag || resize) {
        this.isResizing = false;
        drag = false;
        scrollDirection = null;
        resize = undefined;
      }

      if (updated) {
        updated = false;
        this.util.preventClick();
        this.dispatchEvent('updated', event);
      }
    };
    const onMove = (event) => {
      const duration = this.wavesurfer.getDuration();

      if (event.touches && event.touches.length > 1) {
        return;
      }
      if (event.targetTouches && event.targetTouches[0].identifier !== touchId) {
        return;
      }
      if (!drag && !resize) {
        return;
      }

      const oldTime = startTime;
      const { time: targetTime } = this.editorUtil.getCursorTime(event);
      let time = this.editorUtil.getRuleSnapToGridValue(targetTime);

      if (drag) {
        // To maintain relative cursor start point while dragging
        const maxEnd = this.wavesurfer.getDuration();
        if (time > maxEnd - ruleRightHalfTime) {
          time = maxEnd - ruleRightHalfTime;
        }

        if (time - ruleLeftHalfTime < 0) {
          time = ruleLeftHalfTime;
        }
      }

      if (resize) {
        // To maintain relative cursor start point while resizing
        // we have to handle for minLength
        if (!this.minLength) {
          this.minLength = 0;
        }

        if (time < 0) {
          time = 0;
        }

        if (time > duration) {
          time = duration;
        }
      }

      const delta = time - this.mouseDownTime;

      if (
        this.scroll && container.clientWidth < this.wrapper.scrollWidth
      ) {
        // Triggering edgescroll from within edgeScrollWidth
        const x = event.clientX;

        // Check direction
        if (x < wrapperRect.left + this.edgeScrollWidth) {
          scrollDirection = -1;
        } else if (x > wrapperRect.right - this.edgeScrollWidth) {
          scrollDirection = 1;
        } else {
          scrollDirection = null;
        }

        if (scrollDirection) {
          edgeScroll(event);
        }
      }
    };

    // this.selectorEl.addEventListener('mousedown', onDown);
    // this.selectorEl.addEventListener('touchstart', onDown);
    // this.selectorEl.addEventListener('mousemove', this.dragRule);
    // this.selectorEl.addEventListener('touchmove', onMove, { passive: false });

    // document.body.addEventListener('mousemove', onMove);
    // document.body.addEventListener('touchmove', onMove, { passive: false });

    // document.addEventListener('mouseup', onUp);
    // document.body.addEventListener('touchend', onUp);
  }

  /**
   * Returns the direction of dragging rule based on delta
   * Negative delta means rule is moving to the left
   * Positive - to the right
   * For zero delta the direction is not defined
   * @param {number} delta Drag offset
   * @returns {string|null} Direction 'left', 'right' or null
   */
  _getDragDirection(delta) {
    if (delta < 0) {
      return 'left';
    }
    if (delta > 0) {
      return 'right';
    }
    return null;
  }

  isValidEffect(effect: AudioEditEffect): boolean {
    return effect.start !== null && effect.end !== null;
  }

  private updatePosition = (timing: Interval) => {
    if (!timing) return;
    const pps = this.editorUtil.getPixelsPerSecond();
    const left = timing.start * pps;
    const width = (timing.end - timing.start) * pps;
    this.style(this.selectorEl, { left: `${left}px`, width: `${width}px` });
  };

  updateSelector = (timing: Interval) => {
    this.timing = timing;
  };

  renderPreview(timing: Interval) {
    this.updatePosition(timing);
  }

  render = () => {
    this.updatePosition(this.timing);
  };

  show = () => {
    this.visible = true;
    this.style(this.selectorEl, { display: 'block' });
  };

  hide = () => {
    this.visible = false;
    this.timing = null;
    this.style(this.selectorEl, { display: 'none' });
  };

  isVisible = () => this.visible;
}
