export enum UploadObjectStatus {
  /** raw file was added, some preprocessing might be going on. The file is not ready yet to be uploaded */
  Pending = 'Pending',
  /** file is ready to be uploaded */
  Ready = 'Ready',
  /** file was enqueued for upload */
  Requested = 'Requested',
  /** file upload has started */
  Started = 'Started',
  /** file could not be uploaded for some reason */
  Failed = 'Failed',
  /** file was uploaded successfully */
  Completed = 'Completed',
}

export interface UploadProgress {
  uploaded: number;
  total: number;
}

export interface UploadObject {
  id: string;
  status: UploadObjectStatus;
  file: File;
  metadata: Record<string, unknown> | null;
  uploaded: number | null;
  error: string | null;
}
