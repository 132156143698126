<div fxLayoutAlign="space-between stretch" fxLayoutGap="4px">
  <div class="context-actions" fxLayoutAlign="start stretch">
    <ng-container *ngFor="let menuItem of items">
      <mat-divider *ngIf="menuItem.id === CTX_SEPARATOR" [vertical]="true"></mat-divider>
      <ng-container
        *ngIf="menuItem.id !== CTX_SEPARATOR && (!menuItem.isVisible || menuItem.isVisible(activeRow, selectedRows, allRows))">

        <button *ngIf="showLabels && menuItem.label" mat-button
          [disabled]="menuItem.isDisabled && menuItem.isDisabled(activeRow, selectedRows, allRows)"
          (click)="triggerMenuAction(menuItem)" [matTooltip]="menuItem.tooltip" [matTooltipDisabled]="!menuItem.tooltip">
          <mat-icon *ngIf="menuItem.icon" matTooltipShowDelay="300">{{ menuItem.icon }}</mat-icon>&nbsp;
          <span>{{menuItem.label}}</span>
        </button>

        <button *ngIf="!showLabels || !menuItem.label" mat-icon-button
          [disabled]="menuItem.isDisabled && menuItem.isDisabled(activeRow, selectedRows, allRows)"
          (click)="triggerMenuAction(menuItem)" [matTooltip]="menuItem.tooltip || menuItem.label" [matTooltipDisabled]="!menuItem.tooltip && !menuItem.label">
          <mat-icon *ngIf="menuItem.icon" matTooltipShowDelay="300">{{ menuItem.icon }}
          </mat-icon>
        </button>
      </ng-container>
    </ng-container>
  </div>

</div>
