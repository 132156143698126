import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

interface IconCellRendererParams extends ICellRendererParams {
  icons: Record<string, string>;
  labels: Record<string, string>;
  colors?: Record<string, string>;
}

@Component({
  selector: 'hdis-icon-renderer',
  templateUrl: './icon-renderer.component.html',
  styleUrls: ['./icon-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule],
})
export class IconCellRendererComponent implements AgRendererComponent {
  label: string;

  icon: string;

  color: string;

  agInit(params: IconCellRendererParams): void {
    this.label = params.labels[params.value];
    this.icon = params.icons[params.value];
    this.color = params.colors?.[params.value] || 'inherit';
  }

  refresh(params: any): boolean {
    return false;
  }
}
