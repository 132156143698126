/* eslint-disable max-len */
import { AudioEditEffect, AudioEditEffectType, AudioEditRule } from '@heardis/api-contracts';
import { Icons } from '@heardis/hdis-ui';
import '@material/mwc-icon-button';
import { Observer } from 'wavesurfer.js/src/util';
import { EditorRuleEvent, EditorRuleEventType, EditorRuleHandleType, EditorRuleInitParams, Interval } from './interfaces';

const roundMs = (amount: number): number => Math.round(amount * 1000) / 1000;

type RuleTiming = { rule: Interval; preEffect?: Interval; effect?: Interval; postEffect?: Interval };
type ResizeTargetEffect = { effectType: 'preEffect' | 'effect' | 'postEffect'; handleType: 'start' | 'end' };

export class EditorRule extends Observer {
  wavesurfer: WaveSurfer;

  wrapper: any;

  util: any;

  style: any;

  editorUtil: any;

  id: any;

  resize: boolean;

  drag: boolean;

  ruleTiming: RuleTiming;

  isResizing: boolean;

  dragStartTime: number;

  resizeStartTime: number;

  resizeTarget: ResizeTargetEffect;

  draggingStartPosition: number;

  loop: boolean;

  color: any;

  attributes: any;

  maxLength: any;

  minLength: any;

  scroll: any;

  scrollSpeed: any;

  scrollThreshold: any;

  preventContextMenu: boolean;

  ruleHeight: string;

  marginTop: string;

  edgeScrollWidth: any;

  ruleEl: any;

  firedIn: boolean;

  firedOut: boolean;

  firedEvent: AudioEditEffectType;

  preButtonEl: HTMLButtonElement;

  effectButtonEl: HTMLButtonElement;

  postButtonEl: HTMLButtonElement;

  dragButtonEl: HTMLElement;

  playButtonEl: HTMLElement;

  deleteButtonEl: HTMLElement;

  lockButtonEl: HTMLElement;

  loopButtonEl: HTMLElement;

  rule: AudioEditRule;

  firstEffect: AudioEditEffect;

  lastEffect: AudioEditEffect;

  preEffectEl: HTMLElement;

  effectEl: HTMLElement;

  postEffectEl: HTMLElement;

  actionButtonsEl: HTMLElement;

  mouseDownTime: number;

  constructor(rule: AudioEditRule, params: EditorRuleInitParams, editorUtils, ws) {
    super();

    this.wavesurfer = ws;
    this.wrapper = ws.drawer.wrapper;
    this.util = ws.util;
    this.style = this.util.style;
    this.editorUtil = editorUtils;

    this.parseRule(rule);

    this.id = ws.util.getId();
    this.drag = params.drag === undefined ? true : Boolean(params.drag);
    // reflect resize and drag state of rule for rule-updated listener
    this.isResizing = false;
    this.loop = Boolean(params.loop);
    this.color = rule.color || '#808080';
    this.attributes = params.attributes || {};

    this.maxLength = params.maxLength;
    // It assumes the minLength parameter value, or the rulesMinLength parameter value, if the first one not provided
    this.minLength = params.minLength;

    this.scroll = params.scroll !== false && ws.params.scrollParent;
    this.scrollSpeed = params.scrollSpeed || 1;
    this.scrollThreshold = params.scrollThreshold || 10;
    // Determines whether the context menu is prevented from being opened.
    this.preventContextMenu = params.preventContextMenu === undefined ? false : Boolean(params.preventContextMenu);

    this.ruleHeight = '100%';
    this.marginTop = '0px';

    this.edgeScrollWidth = params.edgeScrollWidth;
    this.initRule();
    this.bindInOut();
    this.wavesurfer.on('zoom', this.render);
    this.wavesurfer.on('redraw', this.render);
    this.dispatchEvent('created');
  }

  get preEffect(): AudioEditEffect {
    return this.isValidEffect(this.rule.preEffect) ? this.rule.preEffect : undefined;
  }

  set preEffect(newValues: Partial<AudioEditEffect>) {
    this.rule.preEffect = { ...this.rule.preEffect, ...newValues };
  }

  get effect(): AudioEditEffect {
    return this.isValidEffect(this.rule.effect) ? this.rule.effect : undefined;
  }

  set effect(newValues: Partial<AudioEditEffect>) {
    this.rule.effect = { ...this.rule.effect, ...newValues };
  }

  get postEffect(): AudioEditEffect {
    return this.isValidEffect(this.rule.postEffect) ? this.rule.postEffect : undefined;
  }

  set postEffect(newValues: Partial<AudioEditEffect>) {
    this.rule.postEffect = { ...this.rule.postEffect, ...newValues };
  }

  private extractRuleTiming = (): RuleTiming => {
    if (!this.firstEffect || !this.lastEffect) return null;

    const timing: RuleTiming = { rule: { start: this.firstEffect.start, end: this.lastEffect.end } };
    if (this.preEffect) timing.preEffect = { start: this.preEffect.start, end: this.preEffect.end };
    if (this.effect) timing.effect = { start: this.effect.start, end: this.effect.end };
    if (this.postEffect) timing.postEffect = { start: this.postEffect.start, end: this.postEffect.end };

    return timing;
  };

  parseRule(rule: AudioEditRule) {
    this.rule = rule;
    this.firstEffect = this.preEffect ?? this.effect ?? this.postEffect ?? null;
    this.lastEffect = this.postEffect ?? this.effect ?? this.preEffect ?? null;
    this.ruleTiming = this.extractRuleTiming();
  }

  dispatchEvent(type: EditorRuleEventType, props?: any) {
    this.fireEvent(type, props);
    const event: EditorRuleEvent = { ctx: 'rule', type, rule: this, props };
    this.wavesurfer.fireEvent('editor', event);
  }

  /* Update rule params. */
  update(params, eventParams) {
    if (params.loop != null) {
      this.loop = Boolean(params.loop);
    }
    if (params.color != null) {
      this.color = params.color;
    }
    if (params.drag != null) {
      this.drag = Boolean(params.drag);
    }
    if (params.maxLength != null) {
      this.maxLength = Number(params.maxLength);
    }
    if (params.minLength != null) {
      this.minLength = Number(params.minLength);
    }
    if (params.attributes != null) {
      this.attributes = params.attributes;
    }
  }

  /* Remove a single rule. */
  remove() {
    if (this.ruleEl) {
      this.wrapper.removeChild(this.ruleEl);
      this.ruleEl = null;
      this.wavesurfer.un('zoom', this.render);
      this.wavesurfer.un('redraw', this.render);
      this.dispatchEvent('removed');
    }
  }

  /**
   * Play the audio rule.
   * @param {number} start Optional offset to start playing at
   */
  play(start) {
    if (!this.firstEffect || !this.lastEffect) return;
    this.setLoop(false);
    const s = start || this.firstEffect.start;
    this.wavesurfer.play(s, this.lastEffect.end);
    this.dispatchEvent('play');
  }

  fadein(currentTime, startTime, endTime) {
    const startVolume = 0;
    const endVolume = 1;
    const progress = (currentTime - startTime) / (endTime - startTime);
    const volume = startVolume + (endVolume - startVolume) * progress;
    this.wavesurfer.setVolume(Math.min(Math.max(volume, 0), 1));
  }

  fadeout(currentTime, startTime, endTime) {
    const startVolume = 1;
    const endVolume = 0;
    const progress = (currentTime - startTime) / (endTime - startTime);
    const volume = startVolume - (startVolume - endVolume) * progress;
    this.wavesurfer.setVolume(Math.min(Math.max(volume, 0), 1));
  }

  /**
   * Play the audio rule in a loop.
   * @param {number} start Optional offset to start playing at
   * */
  playLoop(start) {
    this.play(start);
    this.setLoop(true);
  }

  /**
   * Set looping on/off.
   * @param {boolean} loop True if should play in loop
   */
  setLoop(loop) {
    this.loop = loop;
  }

  onResizing = (e) => {
    // you may think we could just use the target time, as we are addressing only one timestamp and not ranges like in drag
    // however the issue is that the mouse position is still slightly offset from the effect border
    const { time: targetTime } = this.editorUtil.getCursorTime(e);
    const targetDelta = targetTime - this.resizeStartTime;
    const newTiming = this.resizeRule(this.ruleTiming, targetDelta);
    console.debug(`[rule] resizing ${this.resizeTarget} ${this.resizeTarget.handleType} for a delta of ${targetDelta}s`, newTiming);
    this.renderPreview(newTiming);
  };

  resizeStart = (e) => {
    const { time: targetTime } = this.editorUtil.getCursorTime(e);
    this.resizeStartTime = targetTime;
    this.resizeTarget = { effectType: e.target.getAttribute('effect-type'), handleType: e.target.getAttribute('handle-type') };
    console.debug(`[rule] start resizing ${this.resizeTarget.effectType} ${this.resizeTarget.handleType}`, this.ruleTiming);
  };

  onResizeStart = (e) => {
    e.target.addEventListener('pointermove', this.onResizing);
    e.target.setPointerCapture(e.pointerId);
    this.resizeStart(e);
  };

  resizeEnd = (e) => {
    const { time: targetTime } = this.editorUtil.getCursorTime(e);
    const targetDelta = targetTime - this.resizeStartTime;
    const newTiming = this.resizeRule(this.ruleTiming, targetDelta);
    this.updateRule(newTiming);
    this.render();
    this.dispatchEvent('updated');
    console.debug(`[rule] stop resizing ${this.resizeTarget.effectType} ${this.resizeTarget.handleType}`, this.ruleTiming);
    this.resizeStartTime = null;
    this.resizeTarget = null;
  };

  onResizeEnd = (e) => {
    e.target.removeEventListener('pointermove', this.onResizing);
    e.target.releasePointerCapture(e.pointerId);
    this.resizeEnd(e);
  };

  private resizeRule = (previousTiming: RuleTiming, targetDeltaTime: number): RuleTiming => {
    const deltaTime = targetDeltaTime;
    const { effectType, handleType } = this.resizeTarget;
    const newTiming: RuleTiming = structuredClone(previousTiming);

    // ensure that resizing the effect does not push the rule outside of the track
    // and that an effect cant be resized to lower than 0
    let minTimestamp;
    let maxTimestamp;
    let targetTimestamp;

    if (handleType === 'start') {
      minTimestamp = newTiming[effectType].start - newTiming.rule.start;
      maxTimestamp = newTiming[effectType].end;
    }

    if (handleType === 'end') {
      minTimestamp = newTiming[effectType].start;
      maxTimestamp = this.wavesurfer.getDuration() - (newTiming.rule.end - newTiming[effectType].end);
    }

    targetTimestamp = Math.max(minTimestamp, Math.min(this.resizeStartTime + deltaTime, maxTimestamp));

    // and that it sticks to the beatgrid if enabled
    targetTimestamp = this.editorUtil.getValidTimestamp(this.resizeStartTime + deltaTime);

    newTiming[this.resizeTarget.effectType][this.resizeTarget.handleType] = targetTimestamp;

    // if the user is resizing the effect, it might be necessary to push preEffect or postEffect up to the track limits
    if (this.resizeTarget.effectType === 'effect') {
      if (this.resizeTarget.handleType === 'start' && newTiming.preEffect) {
        const preEffectDuration = newTiming.preEffect.end - newTiming.preEffect.start;
        newTiming.preEffect.start = this.editorUtil.getValidTimestamp(targetTimestamp - preEffectDuration);
        newTiming.preEffect.end = targetTimestamp; // already stick to the grid
      }
      if (this.resizeTarget.handleType === 'end' && newTiming.postEffect) {
        const postEffectDuration = newTiming.postEffect.end - newTiming.postEffect.start;
        newTiming.postEffect.start = targetTimestamp;
        newTiming.postEffect.end = this.editorUtil.getValidTimestamp(targetTimestamp + postEffectDuration);
      }
    }

    // update the size of the rule a whole
    newTiming.rule.start = newTiming.preEffect?.start ?? newTiming.effect?.start ?? newTiming.postEffect?.start;
    newTiming.rule.end = newTiming.postEffect?.end ?? newTiming.effect?.end ?? newTiming.preEffect?.end;

    return newTiming;
  };

  private createResizeHandle = (effectType: 'preEffect' | 'effect' | 'postEffect', position: 'start' | 'end') => {
    const handle = document.createElement('handle');
    handle.setAttribute('effect-type', effectType);
    handle.setAttribute('handle-type', position);
    handle.addEventListener('pointerdown', this.onResizeStart);
    handle.addEventListener('pointerup', this.onResizeEnd);
    return handle;
  };

  private createAddEffectButton = (effectType: 'preEffect' | 'effect' | 'postEffect', position: 'start' | 'end', icon: Icons) => {
    const button = document.createElement('add-effect');
    button.appendChild(this.editorUtil.createIconButton(icon));
    button.setAttribute('effect-type', effectType);
    button.setAttribute('button-type', position);
    button.addEventListener('click', (e) => this.addEffect(e, effectType, position));
    return button;
  };

  private createEffect(effectType: 'preEffect' | 'effect' | 'postEffect') {
    const effect = document.createElement('effect');
    const preHandle = this.createResizeHandle(effectType, 'start');
    effect.appendChild(preHandle);
    const postHandle = this.createResizeHandle(effectType, 'end');
    effect.appendChild(postHandle);

    if (effectType === 'preEffect') {
      const createEffectHandle = this.createAddEffectButton(effectType, 'end', Icons.EDITOR_EFFECT_CUT);
      effect.appendChild(createEffectHandle);
    }
    if (effectType === 'postEffect') {
      const createEffectHandle = this.createAddEffectButton(effectType, 'start', Icons.EDITOR_EFFECT_CUT);
      effect.appendChild(createEffectHandle);
    }
    if (effectType === 'effect') {
      const createPreEffectHandle = this.createAddEffectButton(effectType, 'start', Icons.EDITOR_EFFECT_FADEOUT);
      effect.appendChild(createPreEffectHandle);
      const createPostEffectHandle = this.createAddEffectButton(effectType, 'end', Icons.EDITOR_EFFECT_FADEIN);
      effect.appendChild(createPostEffectHandle);
    }

    return effect;
  }

  /* Render a rule as a DOM element. */
  private initRule() {
    if (!this.preEffect && !this.effect && !this.postEffect) return;
    this.ruleEl = this.wrapper.appendChild(document.createElement('rule'));
    /** This tabIndex is necessary to make the HTML Element focusable */
    this.ruleEl.tabIndex = 0;
    this.ruleEl.setAttribute('data-id', this.id);
    if (this.resize) this.ruleEl.setAttribute('is-resizable', '');
    if (this.isReadonly()) this.ruleEl.setAttribute('is-readonly', '');
    if (this.wavesurfer.editor.readonly) this.ruleEl.setAttribute('edit-is-readonly', '');

    Object.entries(this.attributes).forEach(([name, value]) => {
      this.ruleEl.setAttribute(`data-rule-${name}`, value);
    });

    this.style(this.ruleEl, {
      position: 'absolute',
      zIndex: 3,
      height: this.ruleHeight,
      top: this.marginTop,
    });

    if (this.preEffect) {
      this.ruleEl.setAttribute('has-pre-effect', '');
      this.preEffectEl = this.ruleEl.appendChild(this.createEffect('preEffect'));
      this.preEffectEl.setAttribute('effect-type', 'preEffect');
      this.style(this.preEffectEl, { color: this.color, background: `linear-gradient(to top right, ${this.color}44 50%, ${this.color}99 0%)` });
    }

    if (this.effect) {
      this.ruleEl.setAttribute('has-effect', '');
      this.effectEl = this.ruleEl.appendChild(this.createEffect('effect'));
      this.effectEl.setAttribute('effect-type', 'effect');
      this.style(this.effectEl, { color: this.color, backgroundColor: `${this.color}99` });
    }

    if (this.postEffect) {
      this.ruleEl.setAttribute('has-post-effect', '');
      this.postEffectEl = this.ruleEl.appendChild(this.createEffect('postEffect'));
      this.postEffectEl.setAttribute('effect-type', 'postEffect');
      this.style(this.postEffectEl, { color: this.color, background: `linear-gradient(to top left, ${this.color}44 50%, ${this.color}99 0%)` });
    }

    /* Action buttons to play, loop, lock and delete the rule */
    this.actionButtonsEl = this.ruleEl.appendChild(this.createActionButtonsElements());

    this.render();
    this.bindEvents();
  }

  onDragging = (e) => {
    const { time: targetTime } = this.editorUtil.getCursorTime(e);
    const targetDelta = targetTime - this.dragStartTime;

    console.debug(`[rule] keep dragging from ${this.dragStartTime} to ${targetTime} for a delta of ${targetDelta}s`);

    const newTiming = this.moveRule(this.ruleTiming, targetDelta);

    this.renderPreview(newTiming);
  };

  onDragStart = (e) => {
    // e.target is this.dragButtonEl
    console.debug('[rule] start dragging in ', e.clientX);
    e.target.addEventListener('pointermove', this.onDragging);
    e.target.setPointerCapture(e.pointerId);
    const { time: targetTime } = this.editorUtil.getCursorTime(e);
    this.dragStartTime = targetTime;
  };

  onDragEnd = (e) => {
    console.debug('[rule] stop dragging in ', e.clientX);
    e.target.removeEventListener('pointermove', this.onDragging);
    e.target.releasePointerCapture(e.pointerId);
    const { time: targetTime } = this.editorUtil.getCursorTime(e);
    const targetDelta = targetTime - this.dragStartTime;

    const newTiming = this.moveRule(this.ruleTiming, targetDelta);
    this.updateRule(newTiming);
    this.render();
    this.dispatchEvent('updated');
    this.dragStartTime = null;
  };

  private moveInterval = ({ start, end }: Interval, deltaTime: number): Interval => ({
    start: this.editorUtil.getValidTimestamp(start + deltaTime),
    end: this.editorUtil.getValidTimestamp(end + deltaTime),
  });

  private moveRule = (previousTiming: RuleTiming, targetDeltaTime: number): RuleTiming => {
    console.debug(`[rule] attempt to move rule of ${targetDeltaTime}s from ${previousTiming.rule.start}-${previousTiming.rule.end}`);
    let deltaTime = targetDeltaTime;
    const maxEnd = this.wavesurfer.getDuration();

    // ensure that the rule cant move outside of track duration
    if (previousTiming.rule.end + deltaTime > maxEnd) deltaTime = maxEnd - previousTiming.rule.end;
    if (previousTiming.rule.start + deltaTime < 0) deltaTime = previousTiming.rule.start * -1;
    console.debug(`[rule] move rule of ${deltaTime}s from ${previousTiming.rule.start}-${previousTiming.rule.end}`);

    const newTiming: RuleTiming = { rule: this.moveInterval(previousTiming.rule, deltaTime) };

    if (previousTiming.preEffect) newTiming.preEffect = this.moveInterval(previousTiming.preEffect, deltaTime);
    if (previousTiming.effect) newTiming.effect = this.moveInterval(previousTiming.effect, deltaTime);
    if (previousTiming.postEffect) newTiming.postEffect = this.moveInterval(previousTiming.postEffect, deltaTime);

    return newTiming;
  };

  private updateRule = (timing: RuleTiming) => {
    this.ruleTiming = this.extractRuleTiming();
    if (timing.preEffect) this.preEffect = timing.preEffect;
    if (timing.effect) this.effect = timing.effect;
    if (timing.postEffect) this.postEffect = timing.postEffect;
  };

  createActionButtonsElements() {
    const actionButtonsElement = document.createElement('action-buttons');

    const dragButton = this.editorUtil.createIconButton(Icons.EDITOR_ACTION_DRAG, [['action', 'drag']]);
    this.dragButtonEl = actionButtonsElement.appendChild(dragButton);
    this.dragButtonEl.addEventListener('pointerdown', this.onDragStart);
    this.dragButtonEl.addEventListener('pointerup', this.onDragEnd);

    this.playButtonEl = actionButtonsElement.appendChild(this.editorUtil.createIconButton(Icons.EDITOR_ACTION_PLAY, [['action', 'play']]));
    this.playButtonEl.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.playLoop(this.firstEffect.start);
      this.dispatchEvent('click', e);
    });

    this.loopButtonEl = actionButtonsElement.appendChild(this.editorUtil.createIconButton(Icons.EDITOR_ACTION_REPEAT, [['action', 'loop']]));
    this.loopButtonEl.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.loop = !this.loop;
      this.dispatchEvent('click', e);
    });

    const lockButton = this.editorUtil.createIconButton(Icons.EDITOR_ACTION_LOCK, [['action', 'lock']]);
    if (this.isReadonly()) lockButton.setAttribute('active', '');
    this.lockButtonEl = actionButtonsElement.appendChild(lockButton);
    this.lockButtonEl.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.rule.readonly = !this.rule.readonly;
      this.dispatchEvent('updated');
    });

    this.deleteButtonEl = actionButtonsElement.appendChild(this.editorUtil.createIconButton(Icons.EDITOR_ACTION_REMOVE, [['action', 'delete']]));
    this.deleteButtonEl.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.dispatchEvent('remove', e);
      this.remove();
    });

    return actionButtonsElement;
  }

  addEffect(e: MouseEvent, originEffect: 'preEffect' | 'effect' | 'postEffect', originHandle: 'start' | 'end') {
    e.stopPropagation();

    const defaultEffectSize = 3;
    const trackDuration = this.wavesurfer.getDuration();
    const gapToDefaultEffectSize = (startTime: number) => {
      if ((startTime - defaultEffectSize) < 0) return defaultEffectSize - startTime;
      if ((startTime + defaultEffectSize) > trackDuration) return trackDuration - startTime - defaultEffectSize;
      return 0;
    };

    if (originEffect === 'preEffect' && originHandle === 'end') {
      const gap = gapToDefaultEffectSize(this.preEffect.end);
      if (gap) {
        this.preEffect = {
          start: this.editorUtil.getValidTimestamp(this.preEffect.start + gap),
          end: this.editorUtil.getValidTimestamp(this.preEffect.end + gap),
        };
      }
      this.effect = {
        type: AudioEditEffectType.CUT,
        end: this.editorUtil.getValidTimestamp(this.preEffect.end + defaultEffectSize),
        start: this.preEffect.end,
      };
    } else if (originEffect === 'effect' && originHandle === 'start') {
      const gap = gapToDefaultEffectSize(this.effect.start);
      if (gap) {
        this.effect = {
          start: this.editorUtil.getValidTimestamp(this.effect.start + gap),
          end: this.editorUtil.getValidTimestamp(this.effect.end + gap),
        };
      }
      if (gap && this.postEffect) {
        this.postEffect = {
          start: this.editorUtil.getValidTimestamp(this.postEffect.start + gap),
          end: this.editorUtil.getValidTimestamp(this.postEffect.end + gap),
        };
      }
      this.preEffect = {
        type: AudioEditEffectType.FADEOUT,
        start: this.editorUtil.getValidTimestamp(this.effect.start - defaultEffectSize),
        end: this.effect.start,
      };
    } else if (originEffect === 'effect' && originHandle === 'end') {
      const gap = gapToDefaultEffectSize(this.effect.end);
      if (gap) {
        this.effect = {
          start: this.editorUtil.getValidTimestamp(this.effect.start + gap),
          end: this.editorUtil.getValidTimestamp(this.effect.end + gap),
        };
      }
      if (gap && this.preEffect) {
        this.preEffect = {
          start: this.editorUtil.getValidTimestamp(this.preEffect.start + gap),
          end: this.editorUtil.getValidTimestamp(this.preEffect.end + gap),
        };
      }
      this.postEffect = {
        type: AudioEditEffectType.FADEIN,
        start: this.effect.end,
        end: this.editorUtil.getValidTimestamp(this.effect.end + defaultEffectSize),
      };
    } else if (originEffect === 'postEffect' && originHandle === 'start') {
      const gap = gapToDefaultEffectSize(this.postEffect.start);
      if (gap) {
        this.postEffect = {
          start: this.editorUtil.getValidTimestamp(this.postEffect.start + gap),
          end: this.editorUtil.getValidTimestamp(this.postEffect.end + gap),
        };
      }
      this.effect = {
        type: AudioEditEffectType.CUT,
        start: this.editorUtil.getValidTimestamp(this.postEffect.start - defaultEffectSize),
        end: this.postEffect.start,
      };
    } else {
      console.debug('Unhandled effect type', originEffect);
      return;
    }
    this.dispatchEvent('updated');
  }

  /* Bind audio events. */
  bindInOut() {
    if (!this.preEffect && !this.effect && !this.postEffect) return;
    this.firedEvent = null;

    const onProcess = (currentTime: number) => {
      const time = Math.round(currentTime * 10) / 10;

      const handleEffectInAndOut = (effect: AudioEditEffect) => {
        const { start, end, type } = effect;
        const effectStart = Math.round(start * 10) / 10;
        const effectEnd = Math.round(end * 10) / 10;

        if (!this.firedEvent && effectStart <= time && effectEnd > time) {
          this.firedEvent = type;
          this.dispatchEvent('play-in', { effect, currentTime: time });
        }
        if (this.firedEvent === type && (effectStart > time || effectEnd <= time)) {
          this.firedEvent = null;
          this.dispatchEvent('play-out', { effect, currentTime: time });
        }
        if (effectStart <= time && effectEnd > time) {
          this.dispatchEvent('playing', { effect, currentTime: time });
        }
      };

      if (this.preEffect) handleEffectInAndOut(this.preEffect);
      if (this.effect) handleEffectInAndOut(this.effect);
      if (this.postEffect) handleEffectInAndOut(this.postEffect);
    };

    this.wavesurfer.backend.on('audioprocess', onProcess);

    this.on('removed', () => {
      this.wavesurfer.backend.un('audioprocess', onProcess);
      this.remove();
    });

    /* Loop playback. */
    this.on('play-out', ({ effect }: { effect: AudioEditEffect }) => {
      const { type } = effect;

      /** @TODO @Lukas the loop could potentiatly conflict with the prelistening. Need to be checked. */
      if (this.loop && type === this.lastEffect.type) {
        this.wavesurfer.play(this.firstEffect.start);
      }

      switch (type) {
        case AudioEditEffectType.FADEOUT:
          console.debug('play-out', AudioEditEffectType.FADEOUT);
          this.wavesurfer.setVolume(1);
          break;
        case AudioEditEffectType.CUT:
          console.debug('play-out', AudioEditEffectType.CUT);
          break;
        case AudioEditEffectType.FADEIN:
          console.debug('play-out', AudioEditEffectType.FADEIN);
          this.wavesurfer.setVolume(1);
          break;
        default: console.debug('unhandled play-out type', type);
      }
    });

    /** @TODO @Lukas Handle cut / fadeout / fadein. */
    this.on('play-in', ({ effect }: { effect: AudioEditEffect }) => {
      const { type } = effect;

      switch (type) {
        case AudioEditEffectType.FADEOUT:
          console.debug('play-in', AudioEditEffectType.FADEOUT);
          break;
        case AudioEditEffectType.CUT:
          console.debug('play-in', AudioEditEffectType.CUT);
          /** @TODO @Lukas here need to be another check if "preview" is enabled */
          if (this.wavesurfer.editor.prelistening) this.wavesurfer.play(this.effect.end);
          break;
        case AudioEditEffectType.FADEIN:
          console.debug('play-in', AudioEditEffectType.FADEIN);
          break;
        default: console.debug('unhandled play-in type', type);
      }
    });

    this.on('playing', ({ effect, currentTime }: { effect: AudioEditEffect; currentTime: number }) => {
      // quit asap if the main condition is not fullfilled
      if (!this.wavesurfer.editor.prelistening) return;

      switch (effect.type) {
        case AudioEditEffectType.FADEOUT:
          this.fadeout(currentTime, effect.start, effect.end);
          break;
        case AudioEditEffectType.FADEIN:
          this.fadein(currentTime, effect.start, effect.end);
          break;
        default:
          // console.log('unhandled playing type', type);
          break;
      }
    });

    this.on('click', () => {
      /** @TODO @Lukas here I would add all action types like lock, loop etc. */
    });
  }
  // on(arg0: string, arg1: () => void) {
  //   throw new Error("Method not implemented.");
  // }

  /**
   * @TODO @TOCHECK These keyboard event codes should come from the user preferences and have a default.
   * Add shortcuts for adding the effects. Should share the triggered events with the buttons. */
  onKeyDown = (event: KeyboardEvent) => {
    switch (event.code) {
      case 'Delete':
        this.dispatchEvent('remove', event);
        this.remove();
        break;
      case 'Space':
        if (event.shiftKey) {
          this.playLoop(this.firstEffect.start);
        } else {
          this.play(this.firstEffect.start);
        }
        break;
      default:
    }
  };

  /* Bind DOM events. */
  bindEvents() {
    const { preventContextMenu } = this;

    this.ruleEl.addEventListener('mouseenter', (e) => {
      this.dispatchEvent('mouse-enter', e);
    });

    this.ruleEl.addEventListener('mouseleave', (e) => {
      this.dispatchEvent('mouse-leave', e);
    });

    this.ruleEl.addEventListener('click', (e) => {
      console.debug('[rule] click', this);
      e.preventDefault();
      this.dispatchEvent('click', e);
    });

    this.ruleEl.addEventListener('dblclick', (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.dispatchEvent('double-click', e);
    });

    this.ruleEl.addEventListener('contextmenu', (e) => {
      if (preventContextMenu) {
        e.preventDefault();
      }
      this.dispatchEvent('context-menu', e);
    });

    this.ruleEl.addEventListener('keydown', this.onKeyDown);

    /* Drag or resize on mousemove. */
    if (this.drag || this.resize) {
      this.bindDragEvents();
    }
  }

  bindDragEvents() {
    const { container } = this.wavesurfer.drawer;
    const { scrollSpeed } = this;
    const { scrollThreshold } = this;
    let startTime;
    let touchId;
    let drag;
    let maxScroll;
    let resize: EditorRuleHandleType;
    let updated = false;
    let scrollDirection;
    let wrapperRect;
    let ruleLeftHalfTime;
    let ruleRightHalfTime;

    // Scroll when the user is dragging within the threshold
    const edgeScroll = (e) => {
      const duration = this.wavesurfer.getDuration();
      if (!scrollDirection || (!drag && !resize)) {
        return;
      }

      const x = e.clientX;
      let distanceBetweenCursorAndWrapperEdge = 0;
      let ruleHalfTimeWidth = 0;
      const adjustment = 0;

      // Get the currently selected time according to the mouse position
      const { time: targetTime } = this.editorUtil.getCursorTime(e);
      const time = this.editorUtil.getRuleSnapToGridValue(targetTime);

      if (drag) {
        // Considering the point of contact with the rule while edgescrolling
        if (scrollDirection === -1) {
          ruleHalfTimeWidth = ruleLeftHalfTime * this.wavesurfer.params.minPxPerSec;
          distanceBetweenCursorAndWrapperEdge = x - wrapperRect.left;
        } else {
          ruleHalfTimeWidth = ruleRightHalfTime * this.wavesurfer.params.minPxPerSec;
          distanceBetweenCursorAndWrapperEdge = wrapperRect.right - x;
        }
      } else {
        // Considering minLength while edgescroll
        let { minLength } = this;
        if (!minLength) {
          minLength = 0;
        }

        // if (resize === 'start') {
        //   if (time > this.lastEffect.end - minLength) {
        //     time = this.lastEffect.end - minLength;
        //     adjustment = scrollSpeed * scrollDirection;
        //   }

        //   if (time < 0) {
        //     time = 0;
        //   }
        // } else if (resize === 'end') {
        //   if (time < this.firstEffect.start + minLength) {
        //     time = this.firstEffect.start + minLength;
        //     adjustment = scrollSpeed * scrollDirection;
        //   }

        //   if (time > duration) {
        //     time = duration;
        //   }
        // }
      }

      // Don't edgescroll if rule has reached min or max limit
      const wrapperScrollLeft = this.wrapper.scrollLeft;

      if (scrollDirection === -1) {
        if (Math.round(wrapperScrollLeft) === 0) {
          return;
        }

        if (Math.round(wrapperScrollLeft - ruleHalfTimeWidth + distanceBetweenCursorAndWrapperEdge) <= 0) {
          return;
        }
      } else {
        if (Math.round(wrapperScrollLeft) === maxScroll) {
          return;
        }

        if (Math.round(wrapperScrollLeft + ruleHalfTimeWidth - distanceBetweenCursorAndWrapperEdge) >= maxScroll) {
          return;
        }
      }

      // Update scroll position
      let scrollLeft = wrapperScrollLeft - adjustment + scrollSpeed * scrollDirection;

      if (scrollDirection === -1) {
        const calculatedLeft = Math.max(0 + ruleHalfTimeWidth - distanceBetweenCursorAndWrapperEdge, scrollLeft);
        scrollLeft = calculatedLeft;
        this.wrapper.scrollLeft = scrollLeft;
      } else {
        const calculatedRight = Math.min(maxScroll - ruleHalfTimeWidth + distanceBetweenCursorAndWrapperEdge, scrollLeft);
        scrollLeft = calculatedRight;
        this.wrapper.scrollLeft = scrollLeft;
      }
      const delta = time - this.mouseDownTime;

      // Continue dragging or resizing
      this.onResize(delta, resize);

      // Repeat
      window.requestAnimationFrame(() => {
        edgeScroll(e);
      });
    };

    const onDown = (event) => {
      const duration = this.wavesurfer.getDuration();
      if (event.touches && event.touches.length > 1) {
        return;
      }
      touchId = event.targetTouches ? event.targetTouches[0].identifier : null;

      // stop the event propagation, if this rule is resizable or draggable
      // and the event is therefore handled here.
      if (this.drag || this.resize) {
        event.stopPropagation();
      }

      // Store the selected startTime we begun dragging or resizing
      const { time: targetTime } = this.editorUtil.getCursorTime(event);
      startTime = this.editorUtil.getRuleSnapToGridValue(targetTime);
      this.mouseDownTime = startTime;

      // Store the selected point of contact when we begin dragging
      ruleLeftHalfTime = startTime - this.firstEffect.start;
      ruleRightHalfTime = this.lastEffect.end - startTime;

      // Store for scroll calculations
      maxScroll = this.wrapper.scrollWidth - this.wrapper.clientWidth;

      wrapperRect = this.wrapper.getBoundingClientRect();

      this.isResizing = false;
      if (event.target.tagName.toLowerCase() === 'handle') {
        this.isResizing = true;
        resize = (event.target as HTMLElement).dataset.type as EditorRuleHandleType;
      } else {
        resize = undefined;
      }
    };
    const onUp = (event) => {
      if (event.touches && event.touches.length > 1) {
        return;
      }

      if (drag || resize) {
        this.isResizing = false;
        drag = false;
        scrollDirection = null;
        resize = undefined;
      }

      if (updated) {
        updated = false;
        this.util.preventClick();
        this.dispatchEvent('updated', event);
      }
    };
    const onMove = (event) => {
      const duration = this.wavesurfer.getDuration();

      if (event.touches && event.touches.length > 1) {
        return;
      }
      if (event.targetTouches && event.targetTouches[0].identifier !== touchId) {
        return;
      }
      if (!drag && !resize) {
        return;
      }

      const oldTime = startTime;
      const { time: targetTime } = this.editorUtil.getCursorTime(event);
      let time = this.editorUtil.getRuleSnapToGridValue(targetTime);

      if (drag) {
        // To maintain relative cursor start point while dragging
        const maxEnd = this.wavesurfer.getDuration();
        if (time > maxEnd - ruleRightHalfTime) {
          time = maxEnd - ruleRightHalfTime;
        }

        if (time - ruleLeftHalfTime < 0) {
          time = ruleLeftHalfTime;
        }
      }

      if (resize) {
        // To maintain relative cursor start point while resizing
        // we have to handle for minLength
        if (!this.minLength) {
          this.minLength = 0;
        }

        switch (resize) {
          case 'pre-start':
            if (time > this.preEffect.end - this.minLength) {
              time = this.preEffect.end - this.minLength;
            }
            break;
          case 'start':
            if (this.preEffect && time < this.preEffect.start + this.minLength) {
              time = this.preEffect.start + this.minLength;
            }
            if (this.effect && time > this.effect.end - this.minLength) {
              time = this.effect.end - this.minLength;
            }
            break;
          case 'end':
            if (this.effect && time < this.effect.start + this.minLength) {
              time = this.effect.start + this.minLength;
            }
            if (this.postEffect && time > this.postEffect.end - this.minLength) {
              time = this.postEffect.end - this.minLength;
            }
            break;
          case 'post-end':
            if (time < this.postEffect.start + this.minLength) {
              time = this.postEffect.start + this.minLength;
            }
            break;
          default:
        }

        if (time < 0) {
          time = 0;
        }

        if (time > duration) {
          time = duration;
        }
      }

      const delta = time - this.mouseDownTime;

      // Resize
      if (this.resize && resize) {
        updated = updated || !!delta;
        this.onResize(delta, resize);
      }

      if (
        this.scroll && container.clientWidth < this.wrapper.scrollWidth
      ) {
        // Triggering edgescroll from within edgeScrollWidth
        const x = event.clientX;

        // Check direction
        if (x < wrapperRect.left + this.edgeScrollWidth) {
          scrollDirection = -1;
        } else if (x > wrapperRect.right - this.edgeScrollWidth) {
          scrollDirection = 1;
        } else {
          scrollDirection = null;
        }

        if (scrollDirection) {
          edgeScroll(event);
        }
      }
    };

    // this.ruleEl.addEventListener('mousedown', onDown);
    // this.ruleEl.addEventListener('touchstart', onDown);
    // this.ruleEl.addEventListener('mousemove', this.dragRule);
    // this.ruleEl.addEventListener('touchmove', onMove, { passive: false });

    // document.body.addEventListener('mousemove', onMove);
    // document.body.addEventListener('touchmove', onMove, { passive: false });

    // document.addEventListener('mouseup', onUp);
    // document.body.addEventListener('touchend', onUp);
  }

  /**
   * Returns the direction of dragging rule based on delta
   * Negative delta means rule is moving to the left
   * Positive - to the right
   * For zero delta the direction is not defined
   * @param {number} delta Drag offset
   * @returns {string|null} Direction 'left', 'right' or null
   */
  _getDragDirection(delta) {
    if (delta < 0) {
      return 'left';
    }
    if (delta > 0) {
      return 'right';
    }
    return null;
  }

  /**
   * @example
   * onResize(-5, 'start') // Moves the start point 5 seconds back
   * onResize(0.5, 'end') // Moves the end point 0.5 seconds forward
   *
   * @param {number} delta How much to add or subtract, given in seconds
   * @param {EditorRuleHandleType} type 'start 'or 'end'
   */
  onResize(deltaTime: number, type: EditorRuleHandleType) {
    const eventParams = { action: 'resize' };
    const duration = this.wavesurfer.getDuration();
    let delta = deltaTime;

    const preEffectStart = this.preEffect?.start;
    const preEffectEnd = this.preEffect?.end;
    const effectStart = this.effect?.start;
    const effectEnd = this.effect?.end;
    const postEffectStart = this.postEffect?.start;
    const postEffectEnd = this.postEffect?.end;

    const getDeltaBasedOnConditions = (effStart: number, effEnd: number) => {
      // Check if changing the start by the given delta would result in the rule being smaller than minLength
      if (delta > 0 && effEnd - (effStart + delta) < this.minLength) return effEnd - this.minLength - effStart;
      // Check if changing the start by the given delta would result in the rule being larger than maxLength
      if (delta < 0 && effEnd - (effStart + delta) > this.maxLength) return effEnd - effStart - this.maxLength;
      if (delta < 0 && (effStart + delta) < 0) return effStart * -1;
      return undefined;
    };

    /** @TODO @Lukas this has become quite confusing as it is grown in progress but I'm sure it could be handled more easy. */
    switch (type) {
      case 'pre-start': {
        delta = getDeltaBasedOnConditions(preEffectStart, preEffectEnd) || delta;
        if (this.wavesurfer.editor.snapToGrid && this.wavesurfer.getBeatgrid().length) {
          const closestBeat = this.editorUtil.getValidTimestamp(preEffectStart + delta);
          this.preEffect.start = roundMs(Math.min(closestBeat, preEffectEnd));
          this.mouseDownTime = closestBeat;
        } else {
          this.preEffect.start = roundMs(Math.max(Math.min(preEffectStart + delta, preEffectEnd), 0));
          this.mouseDownTime = preEffectStart + delta;
        }
        break; }
      case 'start': {
        delta = getDeltaBasedOnConditions(effectStart, effectEnd) || delta;
        if (this.effect) {
          if (this.wavesurfer.editor.snapToGrid && this.wavesurfer.getBeatgrid().length) {
            const closestBeat = this.editorUtil.getValidTimestamp(effectStart + delta);
            this.effect.start = roundMs(Math.min(closestBeat, effectEnd));
            this.mouseDownTime = closestBeat;
            delta = closestBeat - effectStart;
          } else {
            this.effect.start = roundMs(Math.max(Math.min(effectStart + delta, effectEnd), 0));
            this.mouseDownTime = effectStart + delta;
          }
        }
        if (this.effect && this.preEffect) {
          this.preEffect.start = roundMs(Math.max((preEffectStart + delta), 0));
          this.preEffect.end = roundMs((preEffectEnd + delta));
        }
        if (!this.effect && this.preEffect) {
          if (this.wavesurfer.editor.snapToGrid && this.wavesurfer.getBeatgrid().length) {
            const closestBeat = this.editorUtil.getValidTimestamp(preEffectEnd + delta);
            this.preEffect.end = roundMs(Math.max(closestBeat, preEffectStart));
            this.mouseDownTime = closestBeat;
          } else {
            this.preEffect.end = roundMs(Math.max(preEffectEnd + delta, preEffectStart));
            this.mouseDownTime = preEffectEnd + delta;
          }
        }
        break; }
      case 'end': {
        delta = getDeltaBasedOnConditions(effectStart, effectEnd) || delta;
        if (this.effect) {
          if (this.wavesurfer.editor.snapToGrid && this.wavesurfer.getBeatgrid().length) {
            const closestBeat = this.editorUtil.getValidTimestamp(effectEnd + delta);
            this.effect.end = roundMs(Math.max(closestBeat, effectStart));
            this.mouseDownTime = closestBeat;
            delta = closestBeat - effectEnd;
          } else {
            this.effect.end = roundMs(Math.max(effectEnd + delta, effectStart));
            this.mouseDownTime = effectEnd + delta;
          }
        }
        if (this.effect && this.postEffect) {
          this.postEffect.start = roundMs((postEffectStart + delta));
          this.postEffect.end = roundMs((postEffectEnd + delta));
        }
        if (!this.effect && this.postEffect) {
          if (this.wavesurfer.editor.snapToGrid && this.wavesurfer.getBeatgrid().length) {
            const closestBeat = this.editorUtil.getValidTimestamp(postEffectStart + delta);
            this.postEffect.start = roundMs(Math.min(closestBeat, postEffectEnd));
            this.mouseDownTime = closestBeat;
          } else {
            this.postEffect.start = roundMs(Math.min(postEffectStart + delta, postEffectEnd));
            this.mouseDownTime = postEffectStart + delta;
          }
        }
        break; }
      case 'post-end': {
        delta = getDeltaBasedOnConditions(postEffectStart, postEffectEnd) || delta;
        if (this.wavesurfer.editor.snapToGrid && this.wavesurfer.getBeatgrid().length) {
          const closestBeat = this.editorUtil.getValidTimestamp(postEffectEnd + delta);
          this.postEffect.end = roundMs(Math.max(closestBeat, postEffectStart));
          this.mouseDownTime = closestBeat;
        } else {
          this.postEffect.end = roundMs(Math.min(Math.max(postEffectEnd + delta, postEffectStart), duration));
          this.mouseDownTime = postEffectEnd + delta;
        }
        break; }
      default: console.debug('unhandled handle type', type);
    }

    this.render();
  }

  isValidEffect(effect: AudioEditEffect): boolean {
    return effect.start !== null && effect.end !== null;
  }

  private updatePosition = (timing: RuleTiming) => {
    if (!timing) return;

    const pps = this.editorUtil.getPixelsPerSecond();
    this.style(this.ruleEl, { left: `${timing.rule.start * pps}px`, width: `${(timing.rule.end - timing.rule.start) * pps}px` });
    if (timing.preEffect) this.style(this.preEffectEl, { width: `${(timing.preEffect.end - timing.preEffect.start) * pps}px` });
    if (timing.effect) this.style(this.effectEl, { width: `${(timing.effect.end - timing.effect.start) * pps}px` });
    if (timing.postEffect) this.style(this.postEffectEl, { width: `${(timing.postEffect.end - timing.postEffect.start) * pps}px` });
  };

  renderPreview(timing: RuleTiming) {
    this.updatePosition(timing);
  }

  render = () => {
    this.updatePosition(this.ruleTiming);
  };

  private isReadonly = () => this.rule.readonly || this.wavesurfer.editor.readonly;
}
