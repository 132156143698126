import { createAction, props } from '@ngrx/store';

export const openCollectionItem = createAction(
  '[Navigation] Open collection item',
  props<{ collectionIds: string[], currentId: string }>()
);

export const resetCollection = createAction(
  '[Navigation] Reset collection'
)
