import { createReducer, on } from '@ngrx/store';
import { openCollectionItem, resetCollection } from './actions';
import { initialState, State } from './state';

export const reducer = createReducer(
  initialState,
  on(openCollectionItem, (state: State, { collectionIds, currentId }) => ({
    ...state,
    collection: collectionIds,
    currentValue: currentId
  })),
  on(resetCollection, (state: State) => ({
    ...state,
    collection: []
  })),
);
