
<div class="flex-column" [formGroup]="form">

  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="with sliders">
      <div class="flex-column">
        <h3>Duration</h3>
        <div>
          <mat-button-toggle-group formControlName="cutStrictness">
            <mat-button-toggle value="must">Only</mat-button-toggle>
            <mat-button-toggle value="should">Prefer</mat-button-toggle>
          </mat-button-toggle-group>
          &nbsp;
          <span>{{cutLevels[form.get('cutThreshold').value].label}}</span>
        </div>
        <mat-slider class="flex-auto" min="0" [max]="cutLevels.length - 1" step="1" showTickMarks [displayWith]="cutLabel">
          <input matSliderThumb formControlName="cutThreshold">
        </mat-slider>

        <h3>Explicitness</h3>
        <div>
          <mat-button-toggle-group formControlName="explicitStrictness">
            <mat-button-toggle value="must">Only</mat-button-toggle>
            <mat-button-toggle value="should">Prefer</mat-button-toggle>
          </mat-button-toggle-group>
          &nbsp;
          <span>{{explicitLevels[form.get('explicitThreshold').value].label}}</span>
        </div>
        <mat-slider class="flex-auto" min="0" [max]="explicitLevels.length - 1" step="1" showTickMarks [displayWith]="explicitLabel">
          <input matSliderThumb formControlName="explicitThreshold">
        </mat-slider>
      </div>
    </mat-tab>
    <mat-tab label="with radio buttons">
      <div class="flex-column">
        <h3>Duration</h3>
        <mat-radio-group formControlName="cutStrictness">
          <mat-radio-button value="must">Only</mat-radio-button>
          <mat-radio-button value="should">Prefer</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group formControlName="cutThreshold">
          <mat-radio-button [value]="0">standard</mat-radio-button>
          <mat-radio-button [value]="1">short</mat-radio-button>
          <mat-radio-button [value]="2">radio</mat-radio-button>
        </mat-radio-group>

        <h3>Explicitness</h3>
        <mat-radio-group formControlName="explicitStrictness">
          <mat-radio-button value="must">Only</mat-radio-button>
          <mat-radio-button value="should">Prefer</mat-radio-button>
        </mat-radio-group>
        <mat-radio-group formControlName="explicitThreshold">
          <mat-radio-button [value]="0">not relevant</mat-radio-button>
          <mat-radio-button [value]="1">mild</mat-radio-button>
          <mat-radio-button [value]="2">clean</mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
