export const key = 'user';

export interface State {
  username: string | null;
  roles: string[];
  infoStatus: 'init' | 'success' | 'failed';
}

export const initialState: State = {
  username: null,
  roles: [],
  infoStatus: 'init'
};
