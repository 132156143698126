/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { Audio, AudioAction, AudioActionRequest, AudioActionResponse, AudioBeatgrid, AudioBeatgridConfig, AudioBeats, AudioCulturalAffiliation, AudioEdit, AudioEditExplicitType, AudioEditType, AudioExpression, AudioFormatConvertRequest, AudioGenre, AudioInstrumentation, AudioLanguage, AudioMood, AudioPreset, AudioPresetConfig, AudioStatus, AudioStyle, AudioTimbre, AudioTimeReference, AudioVocals, AutotaggingAnnotateActionRequest, AutotaggingFeature, FacetId, FacetRequestProps, FacetResponse, Field, ImagePreset, ImagePresetConfig, LyricfindFetchActionRequest, Query, Resource, S3Location, TrackAsset, TrackAssetType, TrackEBUR128AnalysisActionRequest, TrackEditAsset, TrackExportRequest, TrackImageAsset, TrackImageAssetType, UploadResult } from '@heardis/api-contracts';
import { FieldValue, FileUploadResult, Icons, LocalizedFieldCondition, MetadataProvider, MultiUploadService, NotImplementedError, ThumbnailCellRendererParams, groupBy, roundMs } from '@heardis/hdis-ui';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { concatAll, map, mergeMap, publishReplay, refCount, shareReplay, switchMap, take, toArray } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AudioPlayerTrack, AudioPlayerTrackAsset, AudioPlayerTrackAssetType, AudioPlayerTrackInfo, AudioPlayerTrackSource, AudioPlayerTrackStatus } from '../audio-player/audio-player.interfaces';
import { EntityService } from './entity.service';

export const TRACK_METADATA_PROVIDER = new InjectionToken<MetadataProvider>('TRACK_METADATA_PROVIDER');

export interface FieldIntersectionStatsProps {
  primaryField: string;
  secondaryField: string;
  primaryWhitelist?: unknown[];
  secondaryWhitelist?: unknown[];
  filter?: Query;
  concurrency?: number;
}

@Injectable({ providedIn: 'root' })
export class SongService extends EntityService<Audio> {
  baseUrl = environment.apiBaseUrl + environment.endpoints.audio;

  resource = Resource.TRACK;

  entityId = 'track';

  localizedFields = ['metadata.style', 'metadata.mainStyle', 'metadata.subStyles', 'metadata.genre', 'metadata.moods', 'metadata.mood', 'metadata.instrumentation', 'metadata.vocals', 'metadata.timbre', 'metadata.culture', 'metadata.expression', 'metadata.timeReference'];

  fieldStats: { [field: string]: Observable<any> } = {};

  cachedTracks = new Map<string, Observable<Audio>>();

  uploadService: MultiUploadService;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(injector: Injector) {
    super();
    this.uploadService = injector.get(MultiUploadService);
  }

  getEntityRoute(id: string): string[] {
    return ['/library/songs', id];
  }

  cloneEntity() {
    return throwError(() => new NotImplementedError('track entity has no clone feature'));
  }

  getTermFilter(term: string): Query {
    const termLowerCase = term.toLowerCase();
    // FIXME: Separate server-endpoint to call for this case?
    return {
      or: [
        { regex: { _id: termLowerCase } },
        { regex: { 'metadata.title': termLowerCase } },
        { regex: { 'metadata.artist': termLowerCase } },
        { regex: { 'metadata.album': termLowerCase } },
        { regex: { 'metadata.genre': termLowerCase } },
        { regex: { 'metadata.style': termLowerCase } },
      ],
    } as Query;
  }

  private mapEditTypeToPlayerAssetType = (cut: AudioEditType, explicit: AudioEditExplicitType) => {
    if (cut === AudioEditType.STANDARD && explicit === null) return AudioPlayerTrackAssetType.STANDARD;
    if (cut === AudioEditType.RADIO && explicit === null) return AudioPlayerTrackAssetType.RADIO;
    if (cut === AudioEditType.SHORT && explicit === null) return AudioPlayerTrackAssetType.SHORT;
    return AudioPlayerTrackAssetType.UNKNOWN;
  };

  private mapTrackEditsToPlayerAssets = (assets: TrackAsset[]): AudioPlayerTrackAsset[] => assets
    .filter(({ type }) => type === TrackAssetType.EDIT)
    .map(({ id, cut, explicit }: TrackEditAsset) => ({
      id,
      type: this.mapEditTypeToPlayerAssetType(cut, explicit),
      beatgrid: [],
    }));

  private mapTrackToPlayerTrackInfo = (track: Audio): AudioPlayerTrackInfo => {
    const trackBeatgridConfig = this.getTrackBeatgridConfig(track);
    const trackBeatgrid = this.getBeatgridFromConfigs(trackBeatgridConfig);
    /**
     * beatgrid management stinks a bit because it's currently handled at track, not edit level.
     * however, since it's strictly related with the audio signal of the file, it should be part of the edit (or original).
     * In the future we should fix this on the backend, for the moment we just force it for the original asset here
     */
    const assets = [
      { id: null, type: AudioPlayerTrackAssetType.ORIGINAL, beatgrid: trackBeatgrid },
      ...this.mapTrackEditsToPlayerAssets(track.assets),
    ];

    const trackInfo: AudioPlayerTrackInfo = {
      source: AudioPlayerTrackSource.TRACK,
      id: track._id,
      title: track.metadata?.title,
      artist: track.metadata?.artist,
      route: ['/library/songs', track._id],
      assets,
    };
    return trackInfo;
  };

  loadPlayerTrack(trackId: string): Observable<AudioPlayerTrackInfo> {
    return this.getEntity(trackId, { projection: 'player' }).pipe(
      map(this.mapTrackToPlayerTrackInfo),
    );
  }

  private getTrackSourceUrl(id: string, format: AudioPreset = AudioPreset.SOURCE): string {
    if (!AudioPresetConfig[format]) throw Error(`invalid audio format ${format}`);
    const { name, extension } = AudioPresetConfig[format];
    return `${environment.assetsBaseUrl}/${id}/${name}.${extension}`;
  }

  private getTrackEditUrl(trackId: string, editId: string, format: AudioPreset = AudioPreset.EDIT): string {
    if (!AudioPresetConfig[format]) throw Error(`invalid audio format ${format}`);
    const { name, extension } = AudioPresetConfig[format];
    return `${environment.assetsBaseUrl}/${trackId}/${editId}/${name}.${extension}`;
  }

  getTrackAudioUrl(trackId: string, editId: string, format: AudioPreset): string {
    if (editId) return this.getTrackEditUrl(trackId, editId, format);
    return this.getTrackSourceUrl(trackId, format);
  }

  getTrackUrlWithAuth(trackId: string, editId: string, format: AudioPreset = AudioPreset.EDIT): Observable<string> {
    const trackUrl = this.getTrackAudioUrl(trackId, editId, format);
    return this.authService.wrapUrlWithAuth(trackUrl);
  }

  getTrackArtworkAssetId(track: Audio): string {
    const standardArtwork = track.assets?.filter(({ type }) => TrackAssetType.IMAGE === type)
      .find(({ role }: TrackImageAsset) => role === TrackImageAssetType.COVER);
    return standardArtwork?.id || null;
  }

  /**
   * Get the URL to a tracks artwork. In case no artwork exists for the given track,
   * a default one is returned by the backend.
   *
   * @param trackId the id of the track
   * @param format the optional dimension (e.g. thumbnail) for the artwork to be returned.
   */
  getTrackArtworkUrl(trackId: string, imageId: string, format = ImagePreset.THUMBNAIL): string {
    if (format && !ImagePresetConfig[format]) throw Error(`invalid image format ${format}`);
    const filename = format ? `artwork-${format}.jpg` : 'artwork.jpg';
    if (imageId) return `${environment.assetsBaseUrl}/${trackId}/${imageId}/${filename}`;
    return `${environment.assetsBaseUrl}/${trackId}/${filename}`;
  }

  getArtworkUrlWithAuth(trackId: string, imageId: string, format = ImagePreset.THUMBNAIL): Observable<string> {
    const trackUrl = this.getTrackArtworkUrl(trackId, imageId, format);
    return this.authService.wrapUrlWithAuth(trackUrl);
  }

  convertTracks(trackIds: string[], formatIds: AudioPreset[], force = false): Observable<any> {
    const url = `${this.baseUrl}/_convert`;
    return this.http.post(url, {
      trackIds,
      formatIds,
      force,
    } as AudioFormatConvertRequest)
      .pipe(take(1));
  }

  getAvaibleProfiles(): Observable<string[]> {
    // FIXME: maintenance/export/availableProfiles endpoint
    return this.http.get<string[]>(`${environment.apiBaseUrl}/maintenance/export/availableProfiles`);
  }

  getRelatedTracks(groupId: string): Observable<Audio[]> {
    return groupId ? this.searchList({ 'metadata.relTracks': groupId }, { offset: 0, limit: 1000 }).pipe(map((res) => res.content)) :
      of([]);
  }

  partialUpdateEntities(payload: Partial<Audio>[]): Observable<(Audio | any)[]> {
    return this.http.patch<Audio[]>(`${this.baseUrl}`, payload).pipe(map((res) => res.map(this.handleResponse)), take(1));
  }

  /**
   * Overrides the parent #getFields method to apply sort by groups
   */
  getEntityFields(): Observable<Field[]> {
    return super.getEntityFields()
      .pipe(
        map((fields) => {
          const groupedFields = groupBy(fields, (field) => {
            if (field.name.startsWith('metadata')) return 'metadata';
            if (field.name.startsWith('source')) return 'source';
            if (field.name.includes('.')) return 'otherGroups';
            return 'basic';
          });
          return [
            ...groupedFields.basic || [],
            ...groupedFields.metadata || [],
            ...groupedFields.source || [],
            ...groupedFields.otherGroups || [],
          ];
        }),
      );
  }

  getFieldValues(fieldName: string, keyword?: string, withInvalid?: boolean): Observable<FieldValue[]> {
    let localizedValues$: Observable<FieldValue[]>;
    let rawValues$: Observable<string[]>;

    if (fieldName === 'metadata.allStyles') {
      return forkJoin({
        // new meta field that include both main and sub styles
        main: this.getFieldValues('metadata.mainStyle', keyword, withInvalid),
        sub: this.getFieldValues('metadata.subStyles', keyword, withInvalid),
        // old field from foobar
        old: this.getFieldValues('metadata.style', keyword, withInvalid),
      }).pipe(
        map((values) => ([...values.main, ...values.sub, ...values.old])),
        publishReplay(1),
        refCount(),
      );
    } if (fieldName === 'metadata.styles') {
      return forkJoin({
        main: this.getFieldValues('metadata.mainStyle', keyword, withInvalid),
        sub: this.getFieldValues('metadata.subStyles', keyword, withInvalid),
      }).pipe(
        map((values) => ([...values.main, ...values.sub])),
        publishReplay(1),
        refCount(),
      );
    } if (fieldName === 'metadata.allMoods') {
      return forkJoin({
        // new field
        new: this.getFieldValues('metadata.moods', keyword, withInvalid),
        // old field from foobar
        old: this.getFieldValues('metadata.mood', keyword, withInvalid),
      }).pipe(
        map((values) => ([...values.new, ...values.old])),
        publishReplay(1),
        refCount(),
      );
    }
    switch (fieldName) {
      case 'metadata.mainStyle':
      case 'metadata.subStyles':
      case 'metadata.styles':
        rawValues$ = of(Object.values(AudioStyle));
        break;
      case 'metadata.genre':
        rawValues$ = of(Object.keys(AudioGenre));
        break;
      case 'metadata.instrumentation':
        rawValues$ = of(Object.keys(AudioInstrumentation));
        break;
      case 'metadata.vocals':
        rawValues$ = of(Object.keys(AudioVocals));
        break;
      case 'metadata.language':
        rawValues$ = of(Object.keys(AudioLanguage));
        break;
      case 'metadata.timbre':
        rawValues$ = of(Object.keys(AudioTimbre));
        break;
      case 'metadata.expression':
        rawValues$ = of(Object.keys(AudioExpression));
        break;
      case 'metadata.culture':
        rawValues$ = of(Object.keys(AudioCulturalAffiliation));
        break;
      case 'metadata.timeReference':
        rawValues$ = of(Object.values(AudioTimeReference));
        break;
      case 'metadata.moods':
        rawValues$ = of(Object.values(AudioMood));
        break;
      default:
        break;
    }
    if (rawValues$) {
      localizedValues$ = rawValues$.pipe(
        map((fieldValues: string[]) => {
          const localizable = this.isFieldLocalized(fieldName);
          console.debug(`fetch values for field ${fieldName}: ${localizable ? 'localize' : 'do not localize'}`);
          return fieldValues.map((fieldValue) => ({
            value: fieldValue,
            label: localizable ? this.i18n.translate(`entities.${this.entityId}.${fieldName}.values.${fieldValue}`) : String(fieldValue),
          }));
        }),
        map((values) => values.sort((a, b) => a.label.localeCompare(b.label))),
        publishReplay(1),
        refCount(),
      );
    } else {
      localizedValues$ = super.getFieldValues(fieldName, keyword);
    }

    if (withInvalid) {
      localizedValues$ = forkJoin({
        valid: localizedValues$,
        // old field from foobar
        invalid: super.getFieldValues(fieldName, keyword, withInvalid),
      }).pipe(
        map((values) => {
          const merged = new Map<string, string>();
          values.invalid.forEach((fieldValue) => merged.set(fieldValue.value, fieldValue.label));
          values.valid.forEach((fieldValue) => merged.set(fieldValue.value, fieldValue.label));
          const fieldValues: FieldValue[] = [];
          merged.forEach((value, key) => fieldValues.push({ value: key, label: value }));
          return fieldValues;
        }),
        map((values) => values.sort((a, b) => a.label.localeCompare(b.label))),
        publishReplay(1),
        refCount(),
      );
    }
    return localizedValues$;
  }

  getTableColumns() {
    return super.getTableColumns().pipe(
      map((columns) => columns.map((column) => {
        const colDef = { ...column };
        const [metadataType, ...subProps] = colDef.field.split('.');
        if (metadataType === 'autotagging' && subProps.at(-1) === 'predicted') {
          colDef.headerTooltip = colDef.headerName;
          colDef.headerName = `${this.i18n.translate(`entities.track.${metadataType}.${subProps.slice(0, -1).join('.')}`)}: autotagging`;
        } else if (['source', 'embedded', 'manual', 'discogs', 'spotify', 'lyricfind', 'comprehend', 'essentia', 'autotagging', 'soundcharts'].includes(metadataType)) {
          colDef.headerTooltip = `${colDef.headerName}`;
          colDef.headerName = `${colDef.headerName}: ${metadataType}`;
        }

        if (colDef.field === 'status') {
          colDef.headerValueGetter = () => '';
          colDef.type = [...colDef.type, 'iconColumn'];
          colDef.cellRendererParams = {
            icons: {
              [AudioStatus.IMPORTED]: Icons.STATUS_IMPORTED,
              [AudioStatus.DRAFT]: Icons.STATUS_DRAFT,
              [AudioStatus.DELETED]: Icons.STATUS_DELETED,
              [AudioStatus.PUBLISHED]: Icons.STATUS_PUBLISHED,
            },
            labels: {
              [AudioStatus.IMPORTED]: this.i18n.translate('entities.track.status.values.imported'),
              [AudioStatus.DRAFT]: this.i18n.translate('entities.track.status.values.draft'),
              [AudioStatus.DELETED]: this.i18n.translate('entities.track.status.values.deleted'),
              [AudioStatus.PUBLISHED]: this.i18n.translate('entities.track.status.values.published'),
            },
          };
        }
        if (colDef.field === 'metadata.artwork') {
          colDef.headerValueGetter = () => '';
          colDef.type = 'thumbnailColumn';
          colDef.cellRendererParams = {
            label: 'artwork',
            hasImage: (row: Audio) => row.metadata?.artwork,
            getImageUrl: (row: Audio): Observable<string> => this.getEntity(row._id, { projection: 'player' }).pipe(
              map((track: Audio) => {
                const trackId = track._id;
                const artworkId = this.getTrackArtworkAssetId(track);
                const artworkUrl = this.getTrackArtworkUrl(trackId, artworkId, ImagePreset.THUMBNAIL);
                return artworkUrl;
              }),
            ),
            placeholderUrl: 'assets/img/no-artwork.png',
          } as ThumbnailCellRendererParams;
        }
        if (colDef.field === 'metadata.popTrend') {
          colDef.valueFormatter = ({ value }) => (value ? this.i18n.translate(`entities.track.${colDef.field}.values.${value}`) : '');
        }
        if (colDef.field === 'soundcharts.data.spotify.popularity') {
          colDef.type = 'trendColumn';
          // colDef.valueFormatter = ({ value }) => value.map(([day, score]) => score);
        }
        if (colDef.field === 'source.path') {
          colDef.flex = 1;
        }
        if (colDef.field.startsWith('metadata.ids.') || colDef.field === 'createdBy' || colDef.field === 'updatedBy') {
          colDef.initialWidth = 110;
        }
        if (colDef.field === 'comprehend.data.entities.Text') {
          colDef.valueGetter = (params) => params.data?.comprehend?.data?.entities?.map((entity) => entity.Text) || [];
        }
        return colDef;
      })),
    );
  }

  getQueryConditions(): Observable<LocalizedFieldCondition[]> {
    return super.getQueryConditions().pipe(
      map((entityConditions) => ([
        { id: 'trackEdits', type: 'asset', criteria: [{ name: 'edits' }], label: this.i18n.translate('query.condition.labels.assets') },
        ...entityConditions,
      ])),
    );
  }

  parseFacetId = (id: FacetId) => ((typeof id === 'string') ? id : `${id.min}-${id.max}`);

  getFieldIntersectionStats<PrimaryValueType, SecondaryValueType>(props: FieldIntersectionStatsProps): Observable<{ primary: string; secondary: string; count: number }[]> {
    const { primaryField, secondaryField, filter, primaryWhitelist, secondaryWhitelist, concurrency = 5 } = props;

    const safePrimaryField = primaryField.replace(/\./g, '_');
    const safeSecondaryField = secondaryField.replace(/\./g, '_');
    const stats$ = this.getTracksFacets(filter, [{ field: primaryField, label: safePrimaryField }]).pipe(
      switchMap((primaryFieldStats) => {
        const primaryFieldValues = primaryFieldStats[safePrimaryField].map(({ _id }) => this.parseFacetId(_id));
        const primaryFieldValueStats = primaryFieldValues
          .filter((value) => (primaryWhitelist?.length ? primaryWhitelist.includes(value) : true))
          .map((primaryFieldValue) => {
            const subFilters: Query[] = [{ terms: { [primaryField]: [primaryFieldValue] } }];
            if (filter) subFilters.push(filter);
            return this.getTracksFacets({ and: subFilters }, [{ field: secondaryField, label: safeSecondaryField }])
              .pipe(
                map((secondaryFieldStats) => ({
                  primary: primaryFieldValue,
                  stats: secondaryFieldStats[safeSecondaryField]
                    .filter(({ _id }) => (secondaryWhitelist?.length ? secondaryWhitelist.includes(_id) : true)),
                })),
              );
          });
        return primaryFieldValueStats;
      }),
      concatAll(),
      mergeMap(
        ({ primary, stats }) => stats.map(({ _id: secondary, count }) => ({ primary, secondary: this.parseFacetId(secondary), count })),
        concurrency,
      ),
      toArray(),
    );

    return stats$;
  }

  getTracksFacets(filter: Query, facets: FacetRequestProps[]): Observable<FacetResponse> {
    return this.http.post<FacetResponse>(`${this.baseUrl}${environment.endpoints.fieldStats}`, { filter, facets });
  }

  /**
   * Map an input track to the model used by the audio player.
   * @param track Audio object
   * @returns AudioPlayerTrack
   */
  mapToAudioPlayerTrack = (track: Partial<Audio>): AudioPlayerTrack => ({
    status: AudioPlayerTrackStatus.INIT,
    source: AudioPlayerTrackSource.TRACK,
    id: track._id,
    artist: track.metadata?.artist,
    title: track.metadata?.title,
    route: this.getEntityRoute(track._id),
    assets: [],
  });

  getAutotaggingBeats(track: Audio): number[] {
    // if (track.autotagging?.data?.essentia?.beats) return [0, ...track.autotagging.data.essentia.beats];
    if (track.essentia?.features?.beats) return [0, ...track.essentia.features.beats];
    return [];
  }

  getAutotaggingBPM(track: Audio): number {
    // if (track.autotagging?.data?.essentia?.bpm) return track.autotagging.data.essentia.bpm;
    if (track.essentia?.features?.bpm) return Math.round(track.essentia.features.bpm * 100) / 100;
    return null;
  }

  /** returns the beatgrid configuration based on autotagging beats and default values */
  getTrackDefaultBeatgridConfig(track: Audio): AudioBeatgridConfig[] {
    const autotaggingBeats = this.getAutotaggingBeats(track);
    const autotaggingBPM = this.getAutotaggingBPM(track);
    const isAuto = autotaggingBeats.length > 0;
    const defaultBeatgridSection: AudioBeatgridConfig = {
      auto: isAuto,
      beats: isAuto ? autotaggingBeats : [],
      bpm: isAuto ? autotaggingBPM : track.metadata.bpm,
      referenceDownbeat: 0,
      timeSignature: 4,
      startTime: 0,
      endTime: track.source.length,
    };
    return [defaultBeatgridSection];
  }

  getTrackBeatgridConfig(track: Audio): AudioBeatgridConfig[] {
    return track.editor?.beatgrid ? track.editor.beatgrid : this.getTrackDefaultBeatgridConfig(track);
  }

  /**
   * @todo migrate to cached selectors in ngrx
   * @param entityId
   * @param cache
   * @returns
   */
  getTrack(entityId: string, cache = false): Observable<Audio> {
    if (!cache || !this.cachedTracks.has(entityId)) {
      this.cachedTracks.set(entityId, this.getEntity(entityId).pipe(shareReplay()));
    }
    return this.cachedTracks.get(entityId);
  }

  requestAction(payload: AudioActionRequest): Observable<AudioActionResponse> {
    return this.http.post<AudioActionResponse>(`${this.baseUrl}/_action`, payload).pipe(take(1));
  }

  requestExportAction(payload: TrackExportRequest): Observable<string> {
    return this.http.post<{ url: string }>(`${this.baseUrl}/_action`, payload).pipe(
      map((data) => data.url),
      take(1),
    );
  }

  generateEdit(trackId: string, editConfig: AudioEdit): Observable<PromiseSettledResult<S3Location>> {
    return this.http.post<PromiseSettledResult<S3Location>>(`${this.baseUrl}/${trackId}/edits/_preview`, editConfig).pipe(
      // map((data) => data.url),
      take(1),
    );
  }

  publishEdit(trackId: string, editId: string): Observable<Audio> {
    return this.http.post<Audio>(`${this.baseUrl}/${trackId}/edits/${editId}/_publish`, null).pipe(
      // map((data) => data.url),
      take(1),
    );
  }

  private getBeatsFromBPM(start: number, end: number, bpm: number): AudioBeats {
    if (!bpm) return [];
    const beatDuration = 60 / bpm;
    const totalBeats = Math.floor((end - start) / beatDuration);
    const beats = new Array(totalBeats)
      .fill([0, false])
      .map((value, index) => start + (index * beatDuration));
    return beats;
  }

  private getBeatgridFromBeats(beats: AudioBeats, referenceDownbeat: number, timeSignature: number): AudioBeatgrid {
    // 0-based index of the beat in a bar that should be considered downbeat (shifts the bar)
    const whichBeatIsDownbeat = referenceDownbeat % timeSignature;
    const beatgrid: AudioBeatgrid = beats.map((beat, index) => [roundMs(beat), index % timeSignature === whichBeatIsDownbeat]);
    return beatgrid;
  }

  /** return the track beatgrid based on the following priority:
   * 1) user edit (or approved autobeatgrid from the next choices)
   * 2) beats as detected by the new autotagging service
   * 3) beats as detected by the deprecated essentia service
   */
  getBeatgridFromConfig(segment: AudioBeatgridConfig): AudioBeatgrid {
    const beats = (segment.auto === true) ? segment.beats : this.getBeatsFromBPM(segment.startTime, segment.endTime, segment.bpm);
    const beatgrid = this.getBeatgridFromBeats(beats, segment.referenceDownbeat, segment.timeSignature);
    return beatgrid;
  }

  getBeatgridFromConfigs(segments: AudioBeatgridConfig[]): AudioBeatgrid {
    return (segments || [])
      .map((segment) => this.getBeatgridFromConfig(segment))
      .flat();
  }

  uploadArtwork(trackId: string, image:File): Observable<FileUploadResult<UploadResult>> {
    const endpoint = `${this.baseUrl}/${trackId}/artwork/_upload`;
    return this.uploadService.uploadFile<UploadResult>(endpoint, image);
  }

  importArtwork(trackId: string, url: string): Observable<PromiseSettledResult<UploadResult>> {
    const endpoint = `${this.baseUrl}/${trackId}/artwork/_import`;
    return this.http.post<PromiseSettledResult<UploadResult>>(endpoint, { url });
  }
}
