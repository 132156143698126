import { NgIf } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacySliderModule } from '@angular/material/legacy-slider';
import { ChordChart, ChordChartEntry } from '@heardis/charts';
import { ComponentResizeEvent } from '../../component-resize';
import { ComponentResizeDirective } from '../../component-resize/component-resize.directive';

@Component({
  selector: 'hdis-chord-chart',
  templateUrl: 'chord-chart.component.html',
  styleUrls: ['chord-chart.component.scss'],
  standalone: true,
  imports: [ComponentResizeDirective, MatLegacySliderModule, ReactiveFormsModule, FormsModule, MatLegacyButtonModule, MatIconModule, NgIf],
})
export class ChordChartComponent {
  loading = false;

  error = false;

  percentThreshold = 0.2;

  nativeChart: ChordChart;

  canRender = false;

  @Input()
  get data(): ChordChartEntry[] { return this._data; }

  set data(data: ChordChartEntry[]) { this.onData(data); }

  private _data: ChordChartEntry[];

  @ViewChild('chart', { static: true }) private chartElement: ElementRef;

  @ViewChild('wrapper', { static: true }) private chartContainer: ElementRef;

  private width: number;

  private height: number;

  setSize(width, height) {
    this.width = width;
    this.height = height;
    console.log('new chord chart area', this.width, this.height);
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   // first time we wait for afterviewinit to ensure viewchild is bound
  //   if (changes.data && !changes.data.firstChange) {
  //     this.renderChart('data');
  //   }
  // }

  ngAfterViewInit() {
    this.setSize(this.chartContainer.nativeElement.clientWidth, this.chartContainer.nativeElement.clientHeight);
    this.nativeChart = new ChordChart(this.chartElement.nativeElement);
    this.nativeChart.setConfig({ width: this.width, height: this.height });
    this.nativeChart.setFilter(this.percentThreshold);
    this.nativeChart.setData(this.data);
    this.canRender = true;
    this.renderChart('init');
  }

  onResize(event: ComponentResizeEvent) {
    this.setSize(event.current.width, event.current.height);
    this.renderChart('size');
  }

  onData(data: ChordChartEntry[]) {
    this._data = data;
    this.nativeChart?.setData(this.data);
    this.renderChart('data');
  }

  onFilter(event) {
    this.nativeChart?.setFilter(this.percentThreshold);
    this.renderChart('filter');
  }

  renderChart(reason: string) {
    console.log(`render chart after ${reason} change`);
    if (!this.canRender || !this.data || !this.width || !this.height) return;
    this.nativeChart.render();
  }
}
