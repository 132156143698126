import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { AudioPlayerProgressCounterComponent } from '../audio-player-progress-counter/audio-player-progress-counter.component';
import { AudioPlayerWaveformComponent } from '../audio-player-waveform/audio-player-waveform.component';
import { AudioPlayerMode, AudioPlayerWaveformViewMode } from '../audio-player.interfaces';
import { AudioPlayerService } from '../audio-player.service';
import { showExtendedWaveform, showStandardWaveform } from '../store/audio-player.actions';
import { selectPlayerViewModel } from '../store/audio-player.selectors';

@Component({
  selector: 'hdis-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, AudioPlayerProgressCounterComponent, AudioPlayerWaveformComponent],
})
export class AudioPlayerComponent {
  AudioPlayerMode = AudioPlayerMode;

  AudioPlayerWaveformViewMode = AudioPlayerWaveformViewMode;

  playerService = inject(AudioPlayerService);

  store = inject(Store);

  config$ = this.store.select(selectPlayerViewModel);

  setExtendedWaveform() {
    this.store.dispatch(showExtendedWaveform());
  }

  setStandardWaveform() {
    this.store.dispatch(showStandardWaveform());
  }
}
