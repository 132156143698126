import { formatDate } from '@angular/common';
import { Injectable, LOCALE_ID, NgZone, inject } from '@angular/core';
import { AudioBeatgrid, AudioEditRule, ImagePreset } from '@heardis/api-contracts';
import { Store } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import WaveSurfer from 'wavesurfer.js';
import CursorPlugin from 'wavesurfer.js/src/plugin/cursor';
import TimelinePlugin from 'wavesurfer.js/src/plugin/timeline';
import { SongService } from '../_services/song.service';
import { InboxService } from '../library/inbox/_services/inbox.service';
import AudioPlayerEventTarget from './audio-player-event-target';
import { AudioPlayerEditorEvent, AudioPlayerEditorNewRuleEvent, AudioPlayerEditorRemoveRuleEvent, AudioPlayerEditorUpdateRuleEvent, AudioPlayerTrack, AudioPlayerTrackFileViewModel, AudioPlayerTrackInfo, AudioPlayerTrackSource, AudioPlayerWaveformViewMode } from './audio-player.interfaces';
import { loadTrackFile, loadTrackFileFailure, loadTrackFileSuccess, playbackPaused, playbackStarted, playbackStopped, waveformLoaded } from './store/audio-player.actions';
import BeatgridPlugin from './wavesurfer-plugins/beatgrid';
import EditorPlugin from './wavesurfer-plugins/editor';
import { EditorEvent, EditorPluginParams } from './wavesurfer-plugins/editor/interfaces';
import MinimapPlugin from './wavesurfer-plugins/minimap/original';
// import MinimapPlugin from './wavesurfer-plugins/minimap';

const TIMELINE_SIZE = 12;
const BEATGRID_SIZE = 12;
const MINIMAP_SIZE = 50;
const TOOLBAR_SIZE = 48;

const CONTAINER_STANDARD_SIZE = 80;
const CONTAINER_EXTENDED_SIZE = 290;

@Injectable({
  providedIn: 'root',
})
export class AudioPlayerService {
  nativeElement: HTMLAudioElement;

  target = new AudioPlayerEventTarget();

  wsPlayer: WaveSurfer;

  track: AudioPlayerTrack;

  currTrack: AudioPlayerTrack;

  // autoplay: boolean;

  private sService = inject(SongService);

  private iService = inject(InboxService);

  private editorEvents$: Subject<AudioPlayerEditorEvent> = new Subject();

  get editorEvents(): Observable<AudioPlayerEditorEvent> {
    return this.editorEvents$.asObservable();
  }

  public locale = inject(LOCALE_ID);

  private readonly zone = inject(NgZone);

  private store = inject(Store);

  private formatTimelineTime = (time: number) => formatDate(time * 1000, 'mm:ss', this.locale);

  initWaveform(container: string | HTMLElement, timelineContainer: string | HTMLElement, beatgridContainer: string | HTMLElement, minimapContainer: string | HTMLElement, size: AudioPlayerWaveformViewMode) {
    this.zone.runOutsideAngular(() => {
      // clean waveform that was previously initialized
      this.destroyWaveform();
      const height = this.getWaveformHeight(size);
      this.wsPlayer = WaveSurfer.create({
        container,
        height,
        waveColor: '#ffb8b0',
        progressColor: '#ff7060',
        cursorColor: '#ff7060',
        barWidth: 0,
        barRadius: 1,
        cursorWidth: 1,
        barGap: 0,
        /**
         * with medialement backend, pro: the track start playing immediately, con: may not finish to download before auth token expires
         * with backend webaudio, pro: ensure that the track is fully loaded, con: user needs to wait longer until play
         */
        // backend: 'MediaElement',
        mediaControls: true,
        normalize: true,
        responsive: true, // redraw on window resize
        fillParent: true, // fill the container by default
        minPxPerSec: 5, // allow for very small waveforms just in case
        pixelRatio: 1, // Enforce the ratio 1 as otherwise window.devicePixelRatio that can change because of the OS screen settings or browser zoom settings
        autoCenter: true,
        autoCenterImmediately: true,
        plugins: [
          CursorPlugin.create({
            showTime: true,
            opacity: '1',
            customShowTimeStyle: {
              'background-color': '#ff7060',
              color: '#fff',
              padding: '2px',
              'font-size': '12px',
            },
          }),
          TimelinePlugin.create({
            container: timelineContainer,
            formatTimeCallback: this.formatTimelineTime,
            height: TIMELINE_SIZE,
            fontSize: TIMELINE_SIZE,
          }),
          MinimapPlugin.create({
            container: minimapContainer,
            isVisible: false,
            waveColor: '#ffb8b0',
            progressColor: '#ff7060',
            showOverview: true,
            height: MINIMAP_SIZE,
          }),
          BeatgridPlugin.create({
            container: beatgridContainer,
            beatHeight: Math.round(BEATGRID_SIZE * 0.6),
            downbeatHeight: BEATGRID_SIZE,
          }),
          EditorPlugin.create({
            dragSelection: true,
          }),
        ],
      });

      this.wsPlayer.on('ready', this.onPlayerReady);
      this.wsPlayer.on('waveform-ready', this.onPlayerWaveformReady);
      this.wsPlayer.on('error', this.onError);
      this.wsPlayer.on('play', this.onPlay);
      this.wsPlayer.on('pause', this.onPause);
      this.wsPlayer.on('seek', this.onSeek);
      this.wsPlayer.on('finish', this.onStop);
      this.wsPlayer.on('audioprocess', this.onProgress);

      this.wsPlayer.on('editor', (e: EditorEvent) => {
        switch (e.type) {
          case 'create': {
            if (e.ctx === 'selector') {
              const { preEffect, effect, postEffect } = e.props;
              const message: AudioPlayerEditorNewRuleEvent = { type: 'newRule', preEffect, effect, postEffect };
              this.editorEvents$.next(message);
            }
            break;
          }
          case 'remove': {
            if (e.ctx === 'rule') {
              const message: AudioPlayerEditorRemoveRuleEvent = { type: 'removeRule', rule: e.rule.rule };
              this.editorEvents$.next(message);
            }
            break;
          }
          case 'updated': {
            if (e.ctx === 'rule') {
              const message: AudioPlayerEditorUpdateRuleEvent = { type: 'updateRule', rule: e.rule.rule };
              this.editorEvents$.next(message);
            }
            break;
          }
          default:
            // console.log('ignore editor event', e);
            break;
        }
      });
    });
  }

  destroyWaveform() {
    this.wsPlayer?.destroy();
  }
  // setAutoplay(autoplay: boolean) {
  //   this.autoplay = autoplay;
  // }

  setPlaybackRate(rate: number) {
    this.wsPlayer.setPlaybackRate(rate);
  }

  setWaveformMinimap(isVisible: boolean) {
    if (isVisible && !this.wsPlayer.isMinimapEnabled()) this.wsPlayer.enableMinimap();
    if (!isVisible && this.wsPlayer.isMinimapEnabled()) this.wsPlayer.disableMinimap();
  }

  setWaveformViewMode(mode: AudioPlayerWaveformViewMode) {
    if (!this.wsPlayer) return;
    const height = this.getWaveformHeight(mode);
    this.wsPlayer.setHeight(height);
  }

  setWaveformZoom(zoom: number) {
    /**
     * the user expect a zoom in terms of magnification, so a 2x would mean that half the waveform is shown in the same space.
     * But wavesurfer sets the zoom in terms of pixels per second. So first figure out how many pixels per second if the waveform
     * was filling the space, then multiply for the magnification ratio specified by the user
     */
    if (zoom > 1) {
      const basePxRatio = this.wsPlayer.drawer.wrapper.clientWidth / this.wsPlayer.getDuration();
      this.wsPlayer.zoom(zoom * basePxRatio);
    } else {
      this.wsPlayer.zoom(null);
    }
  }

  setWaveformAutoscroll(autoscroll: boolean) {
    this.wsPlayer.drawer.params.autoCenter = autoscroll;
  }

  enableEditor(params: Partial<Pick<EditorPluginParams, 'snapToGrid' | 'prelistening'>>) {
    this.wsPlayer.enableEditor();
    this.setEditorParams(params);
    this.setWaveformMinimap(true);
  }

  disableEditor() {
    this.wsPlayer.disableEditor();
    this.setWaveformMinimap(false);
  }

  setEditorParams(params: Partial<Pick<EditorPluginParams, 'snapToGrid' | 'prelistening'>>) {
    this.wsPlayer.updateEditorParams(params);
  }

  loadTrack(url: string) {
    // // clean up from previous track
    // if (this.wsPlayer.isPlaying()) {
    //   this.wsPlayer.stop();
    //   this.wsPlayer.empty();
    // }
    // this.wsPlayer.cancelAjax();

    // lead new track
    // this.nativeElement?.removeAllListeners();
    // this.nativeElement = new Audio();
    // // this.nativeElement.addEventListener('play', this.onPlay);
    // // this.nativeElement.addEventListener('pause', this.onPause);
    // // this.nativeElement.addEventListener('ended', this.onStop);
    // // this.nativeElement.addEventListener('timeupdate', this.onProgress);
    // this.nativeElement.src = url;
    // this.nativeElement.load();
    // this.wsPlayer.load(this.nativeElement);
    this.wsPlayer.load(url);
    this.store.dispatch(loadTrackFile());
    this.target.dispatchProgress(null, null);
  }

  loadBeatgrid(beatgrid: AudioBeatgrid) {
    (beatgrid) ? this.wsPlayer?.loadBeatgrid(beatgrid) : this.wsPlayer?.clearBeatgrid();
  }

  private getWaveformHeight(waveformSize: AudioPlayerWaveformViewMode) {
    switch (waveformSize) {
      case AudioPlayerWaveformViewMode.EXTENDED:
        return CONTAINER_EXTENDED_SIZE - TOOLBAR_SIZE - TIMELINE_SIZE - BEATGRID_SIZE;
      case AudioPlayerWaveformViewMode.STANDARD:
      default:
        return CONTAINER_STANDARD_SIZE;
    }
  }

  onPlayerReady = () => {
    console.debug('[audio-player] file ready');
    this.store.dispatch(loadTrackFileSuccess());
    // if (this.autoplay) this.play();
  };

  onPlayerWaveformReady = () => {
    console.debug('[audio-player] rendered waveform');
    this.store.dispatch(waveformLoaded());
  };

  onError = (err) => {
    console.debug('[audio-player] error', err);
    this.store.dispatch(loadTrackFileFailure());
  };

  // onProgress = () => this.target.dispatchProgress(this.nativeElement.currentTime, this.nativeElement.duration);
  onProgress = () => {
    // console.debug('[audio-player] progress', this.wsPlayer.getCurrentTime());
    this.target.dispatchProgress(this.wsPlayer.getCurrentTime(), this.wsPlayer.getDuration());
  };

  onPlay = () => {
    console.debug('[audio-player] play');
    this.store.dispatch(playbackStarted());
  };

  onPause = () => {
    console.debug('[audio-player] pause');
    this.store.dispatch(playbackPaused());
  };

  onSeek = () => {
    console.debug('[audio-player] seek');
    this.target.dispatchProgress(this.wsPlayer.getCurrentTime(), this.wsPlayer.getDuration());
    // this.store.dispatch(playbackPaused());
  };

  onStop = () => {
    console.debug('[audio-player] stopped');
    this.store.dispatch(playbackStopped());
  };

  refreshWaveform() {
    this.wsPlayer.drawer.fireEvent('redraw');
    // this.wsPlayer.drawer.updateSize();
  }

  play() {
    this.wsPlayer.play();
  }

  pause() {
    this.wsPlayer.pause();
  }

  seekPercent(percent: number) {
    console.debug(`[player] seek to ${percent * 100}%`);
    this.wsPlayer.seekTo(percent);
  }

  seekTime(newTime: number) {
    console.debug(`[player] seek to ${newTime}s`);
    this.wsPlayer.seekTo(newTime / this.wsPlayer.getDuration());
  }

  getTarget() {
    return this.target;
  }

  getCurrentTime() {
    return this.wsPlayer.getCurrentTime();
  }

  updateEditorReadonly(readonly: boolean) {
    console.log('update editor readonly', readonly);
    this.wsPlayer?.setEditorReadonly(readonly);
  }

  updateEditorRules(rules?: AudioEditRule[]) {
    console.log('update editor rules', rules);
    this.wsPlayer?.setEditorRules(rules);
  }

  getPlayerTrackArtworkUrl(track: AudioPlayerTrack, format: ImagePreset): Observable<string> {
    if (!track) return of(null);
    if (track.source === AudioPlayerTrackSource.INBOX) {
      return this.iService.getInboxTrackArtworkUrl(track.id, true);
    } if (track.source === AudioPlayerTrackSource.TRACK) {
      return this.sService.getArtworkUrlWithAuth(track.id, null, format);
    }
    throw Error(`unknown track source: ${track?.source}`);
  }

  getTrackUrl(vm: AudioPlayerTrackFileViewModel): Observable<string> {
    if (!vm || !vm.source || !vm.trackId || !vm.format) return of(null);

    if (vm.source === AudioPlayerTrackSource.INBOX) {
      return this.iService.getTrackFileUrl(vm.trackId);
    } if (vm.source === AudioPlayerTrackSource.TRACK) {
      return this.sService.getTrackUrlWithAuth(vm.trackId, vm.fileId, vm.format);
    }
    return of(null);
  }

  getTrackInfo(track: AudioPlayerTrackInfo): Observable<AudioPlayerTrackInfo> {
    switch (track.source) {
      case AudioPlayerTrackSource.TRACK: return this.sService.loadPlayerTrack(track.id);
      case AudioPlayerTrackSource.INBOX: return of(track);
      default: throw new Error(`invalid track source ${track.source}`);
    }
  }
}
