import { ViewPreferences } from '@heardis/api-contracts';

export const key = 'viewPreferences';

export type State = ViewPreferences;

export const initialState: State = {
  language: 'en',
  tables: {},
  sidebar: {
    bookmarks: true,
  },
};
