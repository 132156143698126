import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UploadResponse } from '@heardis/api-contracts';
import { Observable, tap } from 'rxjs';
import { BaseComponent } from '../../common';
import { MultiFileUploadResult } from '../multi-upload.interfaces';
import { MultiUploadService } from '../multi-upload.service';

@Component({
  standalone: true,
  selector: 'hdis-multi-upload',
  templateUrl: './multi-upload.component.html',
  styleUrls: ['./multi-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,

    FlexLayoutModule,
    MatProgressBarModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
  ],
})
export class MultiUploadComponent extends BaseComponent {
  @Input() title: string;

  @Input() endpoint: string;

  @ViewChild('file') fileInput;

  @Output() uploadCompleted = new EventEmitter<MultiFileUploadResult<UploadResponse>>();

  uploading = false;

  files: File[] = [];

  progresses: number[] = [];

  statuses: string[] = [];

  uploadInfos$: Observable<MultiFileUploadResult<UploadResponse>>;

  uploadInfos: MultiFileUploadResult<UploadResponse>;

  private uploadService = inject(MultiUploadService);

  addFiles() {
    this.fileInput.nativeElement.click();
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  onFilesAdded() {
    const { files } = this.fileInput.nativeElement;
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.push(files[key]);
      }
    }
  }

  uploadFiles() {
    // set the component state to "uploading"
    this.uploading = true;

    this.uploadInfos$ = this.uploadService.uploadFiles(this.endpoint, this.files).pipe(
      tap({
        next: (data) => {
          console.log('progress', data.total.progress, data.total.loaded, data.total.total);
          this.uploadInfos = data;
        },
        complete: () => { this.uploading = false; },
      }),
    );
  }

  uploadFile(index: number) {
    // // set the component state to "uploading"
    // this.uploading = true;

    // this.uploadService.uploadFile(this.endpoint, this.files[index]).pipe(
    //   takeUntil(this.unsubscribe)
    // ).subscribe(fileInfo => {
    //   // ... and the component is no longer uploading
    //   this.uploading = false;
    //   this.uploadInfos[index] = fileInfo
    // })
  }
}
