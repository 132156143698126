import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { format, parse } from 'date-fns';

@Component({
  selector: 'hdis-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatetimepickerComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DatetimepickerComponent), multi: true },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, MatAutocompleteModule, MatFormFieldModule, MatInputModule, MatDatepickerModule],
})
export class DatetimepickerComponent implements ControlValueAccessor {
  @Input() minDate: Date;

  @Input() maxDate: Date;

  @Input() dateLabel = 'date';

  @Input() timeLabel = 'time';

  @Input()
  get hideTime(): boolean { return this._hideTime; }

  set hideTime(value) { this._hideTime = coerceBooleanProperty(value); }

  _hideTime = false;

  currentDate: Date;

  currentTime: string;

  readonly = false;

  propagateFn: (newValue: Date) => void;

  writeValue(obj: Date): void {
    if (obj) {
      this.currentDate = obj;
      this.currentTime = format(obj, 'HH:mm');
    }
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  onChange() {
    if (this.hideTime) {
      this.propagateFn(this.currentDate);
    } else {
      const resultDate = parse(this.currentTime, 'HH:mm', this.currentDate);
      this.propagateFn(resultDate);
    }
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }

  setDisabledState(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
