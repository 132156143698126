import { KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

export enum InfoDialogType {
  CHOOSE = 'choose',
  ACKNOWLEDGE = 'ack',
  CONFIRM = 'confirm',
  INFO = 'info',
}

export interface InfoDialogData {
  type?: InfoDialogType;
  title?: string;
  message?: string;
  subMessage?: string;
  acknowledge?: string;
  confirm?: string;
  cancel?: string;
  options?: Record<string, string>;
}

export type InfoDialogResult = boolean | string;

@Component({
    selector: 'hdis-info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, MatLegacyButtonModule, MatIconModule, MatDialogModule, NgFor, KeyValuePipe]
})
export class InfoDialogComponent {
  TYPE = InfoDialogType;

  data: InfoDialogData;

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: InfoDialogData,
  ) {
    this.data = {
      type: InfoDialogType.CONFIRM,
      confirm: 'confirm',
      cancel: 'cancel',
      acknowledge: 'ok',
      ...data,
    };
  }
}
