<div class="hidden-trigger" [style]="rowContextMenuPosition" [matMenuTriggerFor]="contextMenuDropdown"
  [matMenuTriggerData]="{items}" #contextMenuDropdownTrigger>
</div>
<mat-menu #contextMenuDropdown="matMenu">
  <ng-template matMenuContent let-items="items">
    <ng-container *ngFor="let item of items; trackBy: trackById">
      <button *ngIf="item.id !== CTX_SEPARATOR && isVisible(item)" mat-menu-item [disabled]="isDisabled(item)" (click)="triggerMenuAction(item)">
        <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
        <span>{{ item.label }}</span>
      </button>
      <mat-divider *ngIf="item.id === CTX_SEPARATOR"></mat-divider>
    </ng-container>
  </ng-template>
</mat-menu>
