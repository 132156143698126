import { Observable } from 'rxjs';
import { ColumnState } from '@ag-grid-community/core';

export abstract class UserPreferenceProvider {

  // abstract syncUiPreferences();

  // cant import TableView interface at the moment, need to restructure the modules in this project
  abstract getTableViewPreferences(tableId: string): Observable<ColumnState[]>;
  abstract updateTableViewPreferences(tableId: string, state: ColumnState[]);
  abstract removeTableViewPreferences(tableId: string);

  abstract resetCollection();
}
