import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AudioPlayerTrackFileViewModel, AudioPlayerFileStatus, AudioPlayerMode, AudioPlayerStatus, AudioPlayerTrackAssetType, AudioPlayerTrackStatus, AudioPlayerTracklist, AudioPlayerWaveformViewMode } from '../audio-player.interfaces';
import { AudioPlayerState, adapter, featureKey } from './audio-player.state';

// get the selectors
const { selectEntities } = adapter.getSelectors();

export const selectFeatureState = createFeatureSelector<AudioPlayerState>(featureKey);

export const selectTracks = createSelector(
  selectFeatureState,
  selectEntities,
);

export const selectCurrentTrackId = createSelector(
  selectFeatureState,
  (state) => state.currentId,
);

export const selectCurrentTrack = createSelector(
  selectTracks,
  selectCurrentTrackId,
  (tracks, currentId) => tracks[currentId],
);

export const selectTrack = (trackId: string) => createSelector(
  selectTracks,
  (tracks) => tracks[trackId],
);

export const selectHistoryIds = createSelector(
  selectFeatureState,
  (state) => state.history,
);

export const selectHistoryTracks = createSelector(
  selectTracks,
  selectHistoryIds,
  (tracks, history) => history.map((id) => tracks[id]),
);

export const selectQueueIds = createSelector(
  selectFeatureState,
  (state) => state.queue,
);

export const selectQueueTracks = createSelector(
  selectTracks,
  selectQueueIds,
  (tracks, queue) => queue.map((id) => tracks[id]),
);

export const selectTrackFromQueue = (index: number) => createSelector(
  selectTracks,
  selectQueueIds,
  (tracks, queue) => tracks[queue[index]],
);

export const selectNextIds = createSelector(
  selectFeatureState,
  (state) => state.next,
);

export const selectNextTracks = createSelector(
  selectTracks,
  selectNextIds,
  (tracks, next) => next.map((id) => tracks[id]),
);

export const selectTrackFromNext = (index: number) => createSelector(
  selectTracks,
  selectNextIds,
  (tracks, next) => tracks[next[index]],
);

export const selectPlayerTracklist = createSelector(
  selectFeatureState,
  (state) => state.tracklist,
);

export const selectNextTrack = createSelector(
  selectQueueTracks,
  selectNextTracks,
  ([inQueue], [inNext]) => inQueue || inNext,
);

export const getPlayerNavigation = createSelector(
  selectPlayerTracklist,
  selectHistoryTracks,
  selectQueueTracks,
  selectNextTracks,
  (tracklist, history, queue, next): AudioPlayerTracklist => ({
    isVisible: tracklist.isVisible,
    mode: tracklist.mode,
    hasPrevious: !!history.length,
    hasNext: !!queue.length || !!next.length,
    history,
    next,
    queue,
  }),
);

export const selectPlayerEditor = createSelector(
  selectFeatureState,
  (state) => state.editor,
);
export const selectPlayerEditorVisibility = createSelector(
  selectPlayerEditor,
  (editor) => editor.isVisible,
);

export const selectCurrentTrackStatus = createSelector(
  selectCurrentTrack,
  (track) => track?.status || AudioPlayerTrackStatus.EMPTY,
);

export const selectCurrentFileStatus = createSelector(
  selectFeatureState,
  (state) => state.file.status,
);

export const selectCurrentFormat = createSelector(
  selectFeatureState,
  (state) => state.format,
);

export const selectCurrentEdit = createSelector(
  selectFeatureState,
  selectPlayerEditorVisibility,
  (state, editorVisible) => (editorVisible ? AudioPlayerTrackAssetType.ORIGINAL : state.edit),
);

export const selectCurrentAsset = createSelector(
  selectCurrentTrack,
  selectCurrentEdit,
  (track, editToUse) => {
    if (!track || track.assets.length === 0) return null;
    // first try to get the asset that match the desired type
    let selectedAsset = track.assets.find((asset) => asset.type === editToUse);
    // then fallback to the standard
    if (!selectedAsset) selectedAsset = track.assets.find((asset) => asset.type === AudioPlayerTrackAssetType.STANDARD);
    // otherwise fallback to the default
    if (!selectedAsset) selectedAsset = track.assets.find((asset) => asset.type === AudioPlayerTrackAssetType.ORIGINAL);
    return selectedAsset;
  },
);

export const selectPlayerWaveform = createSelector(
  selectFeatureState,
  selectPlayerEditorVisibility,
  (state, editorVisible) => (editorVisible ?
    { ...state.waveform,
      isVisible: true,
      mode: AudioPlayerWaveformViewMode.EXTENDED,
      minimap: true } :
    state.waveform),
);
export const selectPlayerWaveformVisibility = createSelector(
  selectPlayerWaveform,
  (waveform) => waveform.isVisible,
);
export const selectPlayerWaveformMode = createSelector(
  selectPlayerWaveform,
  (waveform) => waveform.mode,
);

export const selectPlayerPlayback = createSelector(
  selectFeatureState,
  (state) => state.playback,
);

export const selectPlayerPlaybackRate = createSelector(
  selectPlayerPlayback,
  (playback) => playback.rate,
);

export const selectPlayerPlaybackAutoplay = createSelector(
  selectPlayerPlayback,
  (playback) => playback.autoplay,
);

export const selectPlayerPlaybackAutonext = createSelector(
  selectPlayerPlayback,
  selectPlayerEditorVisibility,
  (playback, editorVisible) => (editorVisible ? false : playback.autonext),
);

export const selectPlayerPlaybackStatus = createSelector(
  selectPlayerPlayback,
  (playback) => playback.status,
);

export const selectPlayerMode = createSelector(
  selectPlayerWaveformVisibility,
  selectPlayerWaveformMode,
  selectPlayerEditorVisibility,
  (waveformVisible, waveformMode, editorVisible) => {
    if (editorVisible) return AudioPlayerMode.EDITOR;
    if (waveformVisible && waveformMode === AudioPlayerWaveformViewMode.STANDARD) return AudioPlayerMode.STANDARD;
    if (waveformVisible && waveformMode === AudioPlayerWaveformViewMode.EXTENDED) return AudioPlayerMode.EXTENDED;
    return AudioPlayerMode.WIDGET;
  },
);
export const selectPlayerControlsStatus = createSelector(
  selectCurrentTrackStatus,
  selectCurrentFileStatus,
  selectPlayerPlaybackStatus,
  (trackStatus, fileStatus, playbackStatus) => {
    switch (trackStatus) {
      case AudioPlayerTrackStatus.INIT: return AudioPlayerStatus.LOADING;
      case AudioPlayerTrackStatus.READY: {
        switch (fileStatus) {
          case AudioPlayerFileStatus.LOADED: {
            switch (playbackStatus) {
              case 'playing': return AudioPlayerStatus.PLAYING;
              case 'paused':
              case 'stopped':
              case 'init':
              default:
                return AudioPlayerStatus.NOT_PLAYING;
            }
          }
          case AudioPlayerFileStatus.ERROR: return AudioPlayerStatus.ERROR;
          case AudioPlayerFileStatus.EMPTY:
          case AudioPlayerFileStatus.LOADING:
          default:
            return AudioPlayerStatus.LOADING;
        }
      }
      case AudioPlayerTrackStatus.ERROR: return AudioPlayerStatus.ERROR;
      case AudioPlayerTrackStatus.EMPTY:
      default:
        return AudioPlayerStatus.EMPTY;
    }
  },
);

/** used by service to load track file */
export const selectPlayerCurrentTrackViewModel = createSelector(
  selectCurrentTrack,
  selectCurrentAsset,
  selectCurrentFormat,
  selectPlayerPlaybackAutoplay,
  (track, asset, format, autoplay) => {
    if (!track || !asset) return null;
    const vm: AudioPlayerTrackFileViewModel = {
      source: track.source,
      status: track.status,
      trackId: track.id,
      type: asset.type,
      fileId: asset.id,
      format,
      beatgrid: asset.beatgrid,
      autoplay,
    };
    return vm;
  },
);

/** used by player visualization component to render waveform */
export const selectPlayerViewModel = createSelector(
  selectPlayerPlaybackRate,
  selectPlayerWaveform,
  selectPlayerEditor,
  selectCurrentTrackStatus,
  selectPlayerMode,
  (playbackRate, waveform, editor, trackStatus, mode) => ({ playbackRate, waveform, editor, trackStatus, mode }),
);

/** used by player component to render controls */
export const selectPlayerWidgetViewModel = createSelector(
  getPlayerNavigation,
  selectCurrentTrack,
  selectCurrentAsset,
  selectPlayerControlsStatus,
  selectPlayerMode,
  (tracklist, currentTrack, currentAsset, status, mode) => ({
    tracklist,
    currentTrack,
    currentAsset,
    status,
    mode,
  }),
);
