import { Pipe, PipeTransform } from '@angular/core';
import { ImagePreset } from '@heardis/api-contracts';
import { Observable } from 'rxjs';
import { InboxService } from '../../library/inbox/_services/inbox.service';

@Pipe({
  standalone: true,
  name: 'inboxArtwork',
})
export class InboxArtworkPipe implements PipeTransform {
  constructor(private service: InboxService) { }

  transform(path: string, ...[format = ImagePreset.THUMBNAIL]): Observable<string> {
    return this.service.getInboxTrackArtworkUrl(path, true);
  }
}
