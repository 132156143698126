import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ColorPickerModule } from 'ngx-color-picker';

@Component({
  selector: 'hdis-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ColorPickerComponent), multi: true },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,

    MatFormFieldModule,
    MatIconModule,
    MatInputModule,

    ColorPickerModule,
  ],
})
export class ColorPickerComponent implements ControlValueAccessor {
  color = '';

  propagateFn: (newValue: string) => void;

  @Input() floatLabel = 'auto';

  @Input() label = 'color picker';

  @Input() placeholder: string;

  @Input() isDisabled = false;

  writeValue(obj: any): void {
    this.color = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  inputChange(value) {
    this.color = value;
    this.propagateFn(this.color);
  }

  colorPickerChange(value) {
    this.color = value;
    this.propagateFn(this.color);
  }
}
