<h4 mat-dialog-title *ngIf="data.title">{{data.title}}</h4>
<button class="close-drawer-button" mat-icon-button (click)="close(false)" tabindex="-1">
  <mat-icon>close</mat-icon>
</button>

<mat-dialog-content>
  <p *ngIf="data.message" style="font-size: large; font-weight:bold">{{data.message}}</p>
  <p *ngIf="data.subMessage" [innerHtml]="data.subMessage"></p>

  <mat-radio-group *ngIf="data.type === 'single'" fxLayout="row wrap" [(ngModel)]="localSingleValue">
    <mat-radio-button *ngFor="let option of data.options" [fxFlex]="data.options.length > 6 ? 33 : 50" [value]="option.value" color="primary">
      {{option.label}}
    </mat-radio-button>
  </mat-radio-group>

  <div *ngIf="data.type === 'multiple'" fxLayout="row wrap">
    <mat-checkbox *ngFor="let option of data.options;let i=index" [fxFlex]="data.options.length > 6 ? 33 : 50" [(ngModel)]="option.checked" [checked]="option.checked" name="opt-{{i}}" color="primary">
      {{option.label}}
    </mat-checkbox>
  </div>
  &nbsp;
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button (click)="close(false)">{{data.cancel}}</button>
  <button mat-flat-button color="primary" (click)="close(true)">{{data.confirm}}</button>
</mat-dialog-actions>
