<div class="error-page" *transloco="let t">
  <div class="error-wrapper">
    <div class="error-message">
      <h1>Noooooo!</h1>
      <h2>{{ t('errors.notAuthorized.title') }}</h2>
    </div>
    <button mat-flat-button color="primary" class="abcdj-button" routerLink="/">{{ t('errors.common.toHome') }}</button>
    <button mat-flat-button color="primary" class="abcdj-button" (click)="location.back()">{{ t('errors.common.back')
      }}</button>
  </div>
</div>

<!-- <hdis-error-by-quote [cite]="'Cat Stevens'"
  [explanation]="'- or -<br/><br/>please review mandatory fields in the schedule info'">
  It's not time to make a change<br>
  Just relax, take it easy
</hdis-error-by-quote> -->
