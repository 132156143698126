<div class="error-page" *transloco="let t">
  <div class="error-wrapper">
    <div class="error-message">
      <h1>Noooooo!</h1>
      <h2>{{ t('errors.serviceUnavailable.title') }}</h2>
    </div>
    <a mat-flat-button color="primary" class="abcdj-button"
      href="mailto:incoming+heardis-abcdj-services-13758266-issue-@incoming.gitlab.com?subject=service+unavailable">
      {{ t('errors.serviceUnavailable.support') }}
    </a>
    <!-- <button mat-flat-button color="primary" class="abcdj-button" (click)="window.close()">give up</button> -->
  </div>
</div>
