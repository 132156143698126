<ng-container [formGroup]="formGroup">
  <mat-form-field fxFlex="50" class="abcdj-mat-form-field" floatLabel="always">
    <mat-label>timezone</mat-label>
    <input type="text" placeholder="choose timezone" matInput formControlName="code" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field fxFlex="50" class="abcdj-mat-form-field" floatLabel="always">
    <mat-label>slug</mat-label>
    <input matInput formControlName="slug" placeholder="default">
  </mat-form-field>
</ng-container>
