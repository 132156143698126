<ng-template #nowPlayingPlaceholder>
  <mat-list-item>
    <span matListItemTitle>Not available</span>
  </mat-list-item>
</ng-template>

<mat-action-list class="playlist-section" (click)="$event.stopPropagation();">
  <h3 matSubheader>
    <span>Now playing</span>
  </h3>
  <button mat-list-item *ngIf="current else nowPlayingPlaceholder">
    <span matListItemTitle>{{track.artist}} - {{track.title}}</span>
  </button>

  <ng-container *ngIf="queue.length">
    <h3 matSubheader>
      Next in Queue
      <button mat-button (click)="cleanQueue()">clear</button>
    </h3>
    <ng-container *ngFor="let track of queue; let i = index; let last = last; trackBy: getTrackId">
      <button mat-list-item [disabled]="track.error" (click)="loadFromQueue(i)">
        <!-- <span matListItemAvatar>{{i + 1}})</span> -->
        <span matListItemTitle>{{i + 1}}) {{track.artist}} - {{track.title}}</span>
        <!-- <span matListItemLine>{{track.artist}}</span> -->
      </button>
      <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="next.length">
    <h3 matSubheader>
      Next Up
      <button mat-button (click)="cleanNext()">clear</button>
    </h3>
    <ng-container *ngFor="let track of next; let i = index; let last = last; trackBy: getTrackId">
      <button mat-list-item [disabled]="track.error" (click)="loadFromNext(i)">
        <!-- <span matListItemAvatar>{{i + 1}})</span> -->
        <span matListItemTitle>{{queue.length + i + 1}}) {{track.artist}} - {{track.title}}</span>
        <!-- <span matListItemLine>{{track.artist}}</span> -->
      </button>
      <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>
  </ng-container>
</mat-action-list>
