import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Observable, of, take, takeUntil } from 'rxjs';
import { BaseComponent, Identifiable } from '../../common';
import { CTX_SEPARATOR, ContextMenuActionEvent, ContextMenuItem } from '../context-menu.interfaces';

@Component({
  standalone: true,
  selector: 'hdis-context-menu-dropdown',
  templateUrl: './context-menu-dropdown.component.html',
  styleUrls: ['./context-menu-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
  ],
})
export class ContextMenuDropdownComponent<T extends Identifiable> extends BaseComponent {
  CTX_SEPARATOR = CTX_SEPARATOR;

  @Input() activeRow: T;

  @Input() selectedRows: T[] = [];

  @Input() allRows: T[] = [];

  @Input() items: ContextMenuItem<T>[];

  @Output() onAction = new EventEmitter<ContextMenuActionEvent>();

  @ViewChild('contextMenuDropdownTrigger', { read: MatMenuTrigger }) contextMenuTrigger: MatMenuTrigger;

  rowContextMenuPosition = { left: '0px', top: '0px' };

  constructor() {
    super();
  }

  isVisible = (menuItem: ContextMenuItem<T>) => (menuItem.isVisible ? menuItem.isVisible(this.activeRow, this.selectedRows, this.allRows) : true);

  isDisabled = (menuItem: ContextMenuItem<T>) => (menuItem.isDisabled ? menuItem.isDisabled(this.activeRow, this.selectedRows, this.allRows) : false);

  triggerMenuAction = (menuItem: ContextMenuItem<T>) => {
    const actionReturnValue = menuItem.handleAction(this.activeRow, this.selectedRows, this.allRows);
    const action$ = actionReturnValue instanceof Observable ? actionReturnValue : of(actionReturnValue);

    action$.pipe(
      take(1),
      takeUntil(this.unsubscribe),
    ).subscribe((retValue) => {
      const eventData = { id: menuItem.id, data: retValue };
      this.onAction.emit(eventData);
    });
  };

  open(state: { active: T; selected: T[]; all: T[] }, position: { x: number; y: number }) {
    this.activeRow = state.active;
    this.selectedRows = state.selected;
    this.allRows = state.all;
    this.rowContextMenuPosition = { left: `${position.x}px`, top: `${position.y}px` };
    this.contextMenuTrigger.openMenu();
  }
}
