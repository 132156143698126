import { PlaylistTrack } from "@heardis/api-contracts";

export interface ChartType {
  id: string;
  name: string;
  thumbnailUrl: string;
  domain: Set<ChartTypeDomain>;
}
export interface Dimension {
  width: number;
  height: number;
}
export interface MappingSelectorConfig {
  id: string;
  chartTypeId: string;
  validator: (mappings: DimensionMapping[]) => boolean;
  mappingDimensionDescriptors: MappingDimensionDescriptor[];
}

export interface MappingDimensionDescriptor {
  id: string;
  dimensionName: string;
  dimensionIdentifier: string;
  dimensionType: string;
  allowedValueTypes: MappingFieldType[];
}

export interface DimensionMapping {
  dimensionIdentifier: string;
  mappedField: MappingFieldDescriptor;
}

export interface ChartConfig {
  mappings: DimensionMapping[];
  isValid: () => boolean;
}
export enum MappingFieldType {
  NUMBER,
  STRING,
  STRING_MULTI,
  DATE
}
export interface MappingFieldDescriptor {
  id: string;
  name: string;
  fieldIdentifier: string;
  type: MappingFieldType;
}
export enum ChartTypeDomain {
  TRACK,
  SCHEDULED_TRACK
}
export interface DimensionSelection {
  dimensionIdentifierId: string;
  dimensionSelectionId: string;
}

export interface ChartModel {
  id?: number;
  name: string;
  description?: string;
  manualId?: number;
  type: string;
  chartConfig?: {
    dimensionSelections?: DimensionSelection[];
  }
}

export interface DimensionsMapperSelection {
  dimensionDescriptor: MappingDimensionDescriptor;
  fieldDescriptor: MappingFieldDescriptor;
}

export interface TreemapChartData {
  name: string;
  value?: number;
  children?: TreemapChartData[];
}

export interface AreaChartData {
  data: PlaylistTrack[];
  x: () => {};
  y: () => {};
}

