import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ViewPreferencesStoreEffects } from './view-preferences.effects';
import { reducer } from './view-preferences.reducer';
import { key } from './view-preferences.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(key, reducer),
    EffectsModule.forFeature([ViewPreferencesStoreEffects]),
  ],
  providers: [ViewPreferencesStoreEffects],
})
export class ViewPreferencesStoreModule { }
