import { Pipe, PipeTransform } from '@angular/core';
import { Audio, ImagePreset } from '@heardis/api-contracts';
import { Observable, map } from 'rxjs';
import { SongService } from '../../_services/song.service';

@Pipe({
  standalone: true,
  name: 'trackArtworkUrlById',
})
export class TrackArtworkByIdPipe implements PipeTransform {
  constructor(private songService: SongService) { }

  transform(trackId: string, ...[format = ImagePreset.THUMBNAIL]): Observable<string> {
    return this.songService.getEntity(trackId, { projection: 'player' }).pipe(
      map((track: Audio) => {
        const artworkId = this.songService.getTrackArtworkAssetId(track);
        const artworkUrl = this.songService.getTrackArtworkUrl(trackId, artworkId, format);
        return artworkUrl;
      }),
    );
  }
}
