import { Pipe, PipeTransform } from '@angular/core';
import { Audio, ImagePreset } from '@heardis/api-contracts';
import { SongService } from '../../_services/song.service';

@Pipe({
  standalone: true,
  name: 'trackArtworkUrlByEntity',
})
export class TrackArtworkPipe implements PipeTransform {
  constructor(private songService: SongService) { }

  transform(track: Audio, ...[format = ImagePreset.THUMBNAIL]): string {
    const trackId = track._id;
    const artworkId = this.songService.getTrackArtworkAssetId(track);
    return this.songService.getTrackArtworkUrl(trackId, artworkId, format);
  }
}
