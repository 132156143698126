import { createAction, props } from '@ngrx/store';
import { UploadObjectStatus } from './upload.interfaces';

/** actions inherited from @ngrx/entity */
export const addFile = createAction('[Upload/form] Add file', props<{ id: string; file: File; status?: UploadObjectStatus }>());
export const annotateFile = createAction('[Upload/form] Annotate file', props<{ id: string; metadata: Record<string, unknown> }>());
export const removeFile = createAction('[Upload/form] Remove file', props<{ id: string }>());
export const removeAllFiles = createAction('[Upload/form] Remove all files');
export const removeUploadedFiles = createAction('[Upload/form] Remove uploaded files');
export const uploadFile = createAction('[Upload/form] Upload file', props<{ id: string }>());
export const uploadAllFiles = createAction('[Upload/form] Upload all files');

export const uploadFileProgress = createAction('[Upload/API] Upload file progress', props<{ id: string; uploaded: number }>());
export const uploadFileSuccess = createAction('[Upload/API] Upload file success', props<{ id: string }>());
export const uploadFileFailure = createAction('[Upload/API] Upload file failure', props<{ id: string; error: string }>());
