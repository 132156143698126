import { createAction, props } from '@ngrx/store';

export const initUser = createAction(
  '[User] Init user',
  props<{
    username: string,
    roles: string[]
  }>()
);
export const resetUser = createAction(
  '[User] Clear user info'
);

export const setUserInfo = createAction(
  '[User] Set user info',
  props<{
    success: boolean
  }>()
);
