import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  selector: 'hdis-trend',
  templateUrl: './trend-renderer.component.html',
  styleUrls: ['./trend-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
})
export class TrendCellRendererComponent implements AgRendererComponent {
  series: [string | number, number][] = [];

  latestValue: number;

  trendLabel: string;

  label: string;

  agInit(params: ICellRendererParams<any, any>): void {
    if (!params.value || !params.value.length) return;

    this.series = [...params.value] as [string | number, number][];
    [, this.latestValue] = this.series.at(-1);
    this.label = `${this.series.map(([key, value]) => `${key}: ${value}%`).join('\n')}`;

    // trend represents the expected popularity difference in a day
    const trend = this.getTrend(this.series);
    // You can interpret the slope to determine the overall trend.
    // If slope > 0, it indicates an increasing trend.
    // If slope < 0, it indicates a decreasing trend.
    // If slope ≈ 0, it indicates a relatively flat trend.
    if (trend > 0.05) {
      this.trendLabel = 'increasing';
    } else if (trend < -0.05) {
      this.trendLabel = 'decreasing';
    } else {
      this.trendLabel = 'stable';
    }
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    throw new Error('Method not implemented.');
  }

  getTrend = (series: [string | number, number][]) => {
    const n = series.length;

    // get linear regression and then evaluate it's slope to define the trend
    const { sumX, sumY, sumXY, sumXX } = series
      .sort(([keyA], [keyB]) => { // sort ascending
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      })
      .map(([_key, datapoint]) => datapoint)
      .reduce(
        (acc, value, index) => {
          const x = index + 1;
          return {
            sumX: acc.sumX + x,
            sumY: acc.sumY + value,
            sumXY: acc.sumXY + x * value,
            sumXX: acc.sumXX + x * x,
          };
        },
        { sumX: 0, sumY: 0, sumXY: 0, sumXX: 0 },
      );

    const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);

    // slope represents the change in value per unit of time. We know that values go from 0 to 100 and the unit of time is a day.
    // This means that slope represents the expected popularity difference in a day.
    return slope;
  };
}
