import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs';
import { AudioPlayerTrackStatus } from '../audio-player.interfaces';
import { AudioPlayerService } from '../audio-player.service';
import { addTracksToNextAndPlay, currentTrackInfoRequested, currentTrackInfoLoaded, playNextTrack, playPreviousTrack, playTrackFromNext, playTrackFromQueue, playbackStopped, prelisteningDisabled, prelisteningEnabled, reloadCurrentTrack, setPreferredEdit, setPlaybackRate, setWaveformAutoscroll, setWaveformZoom, showExtendedWaveform, showStandardWaveform, snapToGridDisabled, snapToGridEnabled, trackEditorClosed, trackEditorOpened, currentTrackInfoReady, currentTrackInfoCached, loadTrackFileSuccess, currentTrackEditApproved } from './audio-player.actions';
import { selectCurrentTrack, selectPlayerCurrentTrackViewModel, selectNextTrack, selectPlayerEditor, selectPlayerPlaybackAutonext, selectPlayerPlaybackAutoplay, selectPlayerWaveformMode } from './audio-player.selectors';

@Injectable()
export class AudioPlayerStoreEffects {
  private store = inject(Store);

  private actions$ = inject(Actions);

  private playerService = inject(AudioPlayerService);

  requestTrack$ = createEffect(() => this.actions$.pipe(
    ofType(
      addTracksToNextAndPlay,
      playNextTrack,
      playPreviousTrack,
      playTrackFromNext,
      playTrackFromQueue,
      trackEditorOpened,
      reloadCurrentTrack,
    ),
    concatLatestFrom(() => this.store.select(selectCurrentTrack)),
    map(([, track]) => {
      if (track.status === AudioPlayerTrackStatus.READY) return currentTrackInfoCached();
      return currentTrackInfoRequested();
    }),
  ));

  apiLoadTrack$ = createEffect(() => this.actions$.pipe(
    ofType(
      currentTrackInfoRequested,
      currentTrackEditApproved,
    ),
    concatLatestFrom(() => this.store.select(selectCurrentTrack)),
    switchMap(([, track]) => this.playerService.getTrackInfo(track)),
    map((trackInfo) => currentTrackInfoLoaded({ track: trackInfo })),
    // catchError((err) => of(currentTrackInfoFailed({ track: {id: trackInfo.id, error: err} }))),
  ));

  trackLoaded$ = createEffect(() => this.actions$.pipe(
    ofType(
      currentTrackInfoLoaded,
      currentTrackInfoCached,
    ),
    map(() => currentTrackInfoReady()),
  ));

  playTrack$ = createEffect(() => this.actions$.pipe(
    ofType(
      currentTrackInfoReady,
      setPreferredEdit,
    ),
    concatLatestFrom(() => [
      this.store.select(selectPlayerCurrentTrackViewModel),
    ]),
    filter(([, current]) => !!current),
    switchMap(([,current]) => this.playerService.getTrackUrl(current).pipe(map((audioUrl) => ({ audioUrl, beatgrid: current.beatgrid, autoplay: current.autoplay })))),
    /**
     * Return false when the emission should considered changed.
     * Since the focus here is to have valid url to download the file to play,
     * the relevant props are the ones that affect the url, namely trackId, edit (if any) and format
     */
    // temprarily disabled as we first need to implement the retry when failed, otherwise tracks would be unrecoverable after jwt expired
    // distinctUntilChanged(([,prevPlaying], [,nowPlaying]) => (prevPlaying.track.id === nowPlaying.track.id && prevPlaying.format === nowPlaying.format)),
    // looks bad, but we know for sure it's a loaded track from filter above, just typescript cant understand.
    // Need to come up with better solution...
    tap(({ audioUrl, beatgrid, autoplay }) => {
      this.playerService.loadTrack(audioUrl);
      this.playerService.loadBeatgrid(beatgrid);
    }),
  ), { dispatch: false });

  fileLoaded$ = createEffect(() => this.actions$.pipe(
    ofType(loadTrackFileSuccess),
    concatLatestFrom(() => [
      this.store.select(selectPlayerPlaybackAutoplay),
    ]),
    filter(([, autoplay]) => autoplay),
    tap(() => this.playerService.play()),
  ), { dispatch: false });

  playbackStopped$ = createEffect(() => this.actions$.pipe(
    ofType(playbackStopped),
    concatLatestFrom(() => [
      this.store.select(selectPlayerPlaybackAutonext),
      this.store.select(selectNextTrack),
    ]),
    filter(([, autonext, next]) => autonext === true && !!next),
    map(() => playNextTrack()),
  ));

  playbackRateChanged$ = createEffect(() => this.actions$.pipe(
    ofType(setPlaybackRate),
    tap(({ rate }) => this.playerService.setPlaybackRate(rate)),
  ), { dispatch: false });

  waveformZoomChanged$ = createEffect(() => this.actions$.pipe(
    ofType(setWaveformZoom),
    tap(({ zoom }) => this.playerService.setWaveformZoom(zoom)),
  ), { dispatch: false });

  waveformAutoscrollChanged$ = createEffect(() => this.actions$.pipe(
    ofType(setWaveformAutoscroll),
    tap(({ autoscroll }) => this.playerService.setWaveformAutoscroll(autoscroll)),
  ), { dispatch: false });

  waveformViewModeChanged$ = createEffect(() => this.actions$.pipe(
    ofType(
      showStandardWaveform,
      showExtendedWaveform,
      trackEditorOpened,
      trackEditorClosed,
    ),
    concatLatestFrom(() => this.store.select(selectPlayerWaveformMode)),
    tap(([, mode]) => this.playerService.setWaveformViewMode(mode)),
  ), { dispatch: false });

  editorConfigChanged$ = createEffect(() => this.actions$.pipe(
    ofType(
      prelisteningEnabled,
      prelisteningDisabled,
      snapToGridEnabled,
      snapToGridDisabled,
    ),
    concatLatestFrom(() => this.store.select(selectPlayerEditor)),
    tap(([, { snapToGrid, prelistening }]) => {
      this.playerService.setEditorParams({ snapToGrid, prelistening });
    }),
  ), { dispatch: false });

  editorEnabled$ = createEffect(() => this.actions$.pipe(
    ofType(
      trackEditorOpened,
    ),
    concatLatestFrom(() => this.store.select(selectPlayerEditor)),
    tap(([, { snapToGrid, prelistening }]) => {
      this.playerService.enableEditor({ snapToGrid, prelistening });
    }),
  ), { dispatch: false });

  editorDisabled$ = createEffect(() => this.actions$.pipe(
    ofType(
      trackEditorClosed,
    ),
    tap(() => this.playerService.disableEditor()),
  ), { dispatch: false });
}
