import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bookmark, Bookmarks, User, ViewPreferences } from '@heardis/api-contracts';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserPreferenceProvider } from '@heardis/hdis-ui';
import { environment } from '../../environments/environment';
import { resetCollection } from '../_state/navigation/actions';
import { State } from '../_state/state';
import { resetTableViewPreferences, updateTableViewPreferences } from '../_state/view-preferences/view-preferences.actions';
import { getTableViewPreference } from '../_state/view-preferences/view-preferences.selectors';

@Injectable({
  providedIn: 'root',
})
export class UserService extends UserPreferenceProvider {
  private _uiPreferences: BehaviorSubject<any> = new BehaviorSubject({});

  public readonly uiPreferences: Observable<any> = this._uiPreferences.asObservable();

  constructor(
    private http: HttpClient,
    private langService: TranslocoService,
    private store: Store<State>,
  ) {
    super();
  }

  fetchUserData(): Observable<User> {
    console.debug('[user] fetch user info');
    return this.http.get<User>(`${environment.apiBaseUrl}${environment.endpoints.user}`).pipe(take(1));
  }

  updateUserPreferences(data: Partial<ViewPreferences>): Observable<any> {
    console.debug('[user] update user preferences', data);
    return this.http.post<ViewPreferences>(`${environment.apiBaseUrl}${environment.endpoints.uiPreferences}`, data).pipe(take(1));
  }

  /**
   * @deprecated backward compatibility with table module
   * @param tableId
   */
  getTableViewPreferences(tableId: string): Observable<any> {
    return this.store.select(getTableViewPreference(tableId));
  }

  /**
   * @deprecated backward compatibility with table module
   * @param tableId
   */
  updateTableViewPreferences(tableId: string, tableState: any) {
    this.store.dispatch(updateTableViewPreferences({ tableId, tableState }));
  }

  /**
   * @deprecated backward compatibility with table module
   * @param tableId
   */
  removeTableViewPreferences(tableId: string) {
    this.store.dispatch(resetTableViewPreferences({ tableId }));
  }

  resetCollection() {
    this.store.dispatch(resetCollection());
  }

  getBookmarks(): Observable<Bookmarks> {
    console.debug('[user] get bookmarks');
    return this.http.get<Bookmarks>(`${environment.apiBaseUrl}${environment.endpoints.bookmarks}`).pipe(
      take(1),
    );
  }

  addBookmark(bookmark: Bookmark): Observable<Bookmarks> {
    console.debug('[user] add bookmark', { bookmark });
    return this.http.post<Bookmarks>(`${environment.apiBaseUrl}${environment.endpoints.bookmarks}`, bookmark).pipe(
      take(1),
    );
  }

  removeBookmark(bookmarkId: string): Observable<Bookmarks> {
    console.debug('[user] remove bookmark', { bookmarkId });
    return this.http.delete<Bookmarks>(`${environment.apiBaseUrl}${environment.endpoints.bookmarks}/${bookmarkId}`).pipe(
      take(1),
    );
  }

  getAvailableLangs() {
    return this.langService.getAvailableLangs();
  }

  preloadAvailableLangs() {
    const results = (this.langService.getAvailableLangs() as any[]).map((lang) => this.langService.load(lang.id).toPromise());
    return Promise.all(results);
  }
}
