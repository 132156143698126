<mat-form-field>
  <mat-chip-list #chipList [required]="required">
    <ng-container *ngFor="let selectedValue of selectedValues; let last = last;">
      <mat-basic-chip [selectable]="!isDisabled && isSelectable" [removable]="!isDisabled && isRemovable"
        (removed)="remove(selectedValue)">
        {{selectedValue}}
        <mat-icon inline matChipRemove *ngIf="!isDisabled && isRemovable">cancel</mat-icon>
      </mat-basic-chip>
      <span *ngIf="!last" class="selection-divider">, </span>
    </ng-container>
    <input #autocompleteInput matInput [placeholder]="placeholder" [formControl]="inputControl"
      [matAutocomplete]="autocomplete" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
    <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event)">
      <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option.label}}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-chip-list>
  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
</mat-form-field>
