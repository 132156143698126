import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { WithAuthPipe } from 'libs/hdis-ui/src/lib/pipes/with-auth/with-auth.pipe';
import { ImagePreset } from '@heardis/api-contracts';
import { InboxArtworkPipe } from '../../shared/pipes/inbox-artwork.pipe';
import { TrackArtworkComponent } from '../../shared/track/track-artwork/track-artwork.component';
import { AudioPlayerTrack, AudioPlayerTrackSource } from '../audio-player.interfaces';

@Component({
  selector: 'abcdj-audio-player-artwork',
  templateUrl: './audio-player-artwork.component.html',
  styleUrls: ['./audio-player-artwork.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, AsyncPipe, WithAuthPipe, InboxArtworkPipe, JsonPipe, NgIf, TrackArtworkComponent],
})
export class AudioPlayerArtworkComponent {
  ImagePreset = ImagePreset;

  AudioPlayerTrackSource = AudioPlayerTrackSource;

  @Input() track: AudioPlayerTrack;
}
