import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams, ValueGetterFunc } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { take } from 'rxjs/operators';
import { FieldValue, MetadataProvider } from '../../../_models';

export interface FilterSelectItem {
  id: string;
  label: string;
}

export interface CheckboxElement extends FieldValue {
  checked?: boolean;
}

export interface ElementSelectedChange {
  element: CheckboxElement;
  selected: boolean;
}

interface MultivalueFilterModel {
  filterType: 'multivalue';
  selected: string[];
}

@Component({
  selector: 'hdis-multivalue-filter',
  templateUrl: './multivalue-filter.component.html',
  styleUrls: ['./multivalue-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FlexModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatButtonModule, MatIconModule, MatMenuModule, MatPseudoCheckboxModule],
})
export class MultivalueFilterComponent implements IFilterAngularComp {
  filterInput: string;

  elements: CheckboxElement[] = [];

  stopClickPropagation?: boolean;

  private valueGetter: ValueGetterFunc;

  private params: IFilterParams;

  constructor(
    private cdr: ChangeDetectorRef,
    private provider: MetadataProvider,
  ) {
  }

  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;

    if (params.colDef.field) {
      this.provider.getFieldValues(params.colDef.field)
        .pipe(take(1))
        .subscribe((fieldValues) => this.setElements(fieldValues));
    }
  }

  isFilterActive(): boolean {
    return !!this.getModel();
  }

  // doesFilterPass(params: IDoesFilterPassParams): boolean {
  //   const selectedValues = this.elements.reduce((acc, item) => { if (item.checked) acc.push(item.value); return acc; }, [] as any[]);
  //   return !!selectedValues.includes(this.valueGetter({ node: params.node }));
  // }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const { api, colDef, column, columnApi, context } = this.params;
    const { node, data } = params;
    const value = this.params.valueGetter({
      api,
      colDef,
      column,
      columnApi,
      context,
      data: node.data,
      getValue: (field) => node.data[field],
      node,
    });

    const selectedValues = this.elements.reduce((acc, item) => { if (item.checked) acc.push(item.value); return acc; }, [] as any[]);
    return value && selectedValues && selectedValues.includes(value);
  }

  getModel(): MultivalueFilterModel | undefined {
    const selectedElementIds = this.elements
      .filter((currentElement) => currentElement.checked)
      .map((currentElement) => currentElement.value);

    if (!selectedElementIds.length) return null;

    return { filterType: 'multivalue', selected: selectedElementIds };
  }

  getModelAsString() {
    const selected = this.elements
      .filter((element) => element.checked)
      .map((element) => element.label);

    return selected.length ? `(${selected.length}) ${selected.join(',')}` : '';
  }

  setModel(model: MultivalueFilterModel): void {
    const elements: CheckboxElement[] = this.elements.map((element) => ({
      ...element,
      checked: model ? model.selected.indexOf(element.value) > -1 : false,
    }));
    this.setElements(elements);
  }

  private setElements(elements: CheckboxElement[]) {
    this.elements = [...elements];
    this.cdr.markForCheck();
  }

  toggle(index: number) {
    const elements = [...this.elements];
    elements[index].checked = !elements[index].checked;
    this.elements = elements;

    this.params.filterChangedCallback();
  }

  reset() {
    this.filterInput = '';
    this.elements = [...this.elements.map((element) => ({ ...element, checked: false }))];
    this.params.filterChangedCallback();
  }

  trackBy(index: number, element: CheckboxElement) {
    return element.value;
  }

  onMenuItemClicked(event: MouseEvent, index: number) {
    event.stopPropagation();
    this.toggle(index);
  }

  isVisible(element: CheckboxElement, keyword: string) {
    return element.checked || !keyword || !!element.label.match(new RegExp(keyword, 'i'));
  }
}
