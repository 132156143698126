import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ChangesStatusService } from '../_services/changes-status.service';

@Injectable()
export class StatusChangesGuard implements CanDeactivate<EditableComponent> {
  constructor(private changeStatusService: ChangesStatusService) { }

  canDeactivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.changeStatusService.confirmDialog();
  }
}

export interface EditableComponent {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
