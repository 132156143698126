import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Inject, Input, Optional, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { UntypedFormBuilder } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { FieldValue, MetadataProvider } from '../../_models';
import { BaseComponent, EDIT_CONTEXT, EditContextType, I18N_CONTEXT, MessageSeverity } from '../../common';

export interface PairItem {
  leftValue: any;
  leftLabel: string;
  rightValue: any;
  rightLabel: string;
  currentValue: any;
}
@Component({
  selector: 'hdis-pair-picker',
  templateUrl: './pair-picker.component.html',
  styleUrls: ['./pair-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, FlexModule, MatButtonModule, MatIconModule, NgFor, MatButtonToggleModule, AsyncPipe],
})
export class PairPickerComponent extends BaseComponent {
  isOpen: boolean;

  fieldsLoading: boolean;

  selected$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  options$: Observable<PairItem[]>;

  @Input() fieldName: string;

  @Input() options: [FieldValue, FieldValue][] = [];

  @Input()
  get showCloseButton(): boolean { return this._showCloseButton; }

  set showCloseButton(value) { this._showCloseButton = coerceBooleanProperty(value); }

  _showCloseButton = false;

  @Input() title: string;

  @Input('max')
  get maxSelection(): number { return this._maxSelection; }

  set maxSelection(value) { this._maxSelection = coerceNumberProperty(value); }

  _maxSelection: number;

  @Input()
  get readonly(): boolean { return this._readonly; }

  set readonly(value) { this._readonly = coerceBooleanProperty(value); }

  _readonly = false;

  @Output() readonly selectionChange = new EventEmitter<any>();

  @Output() readonly opened = new EventEmitter<void>();

  @Output() readonly closed = new EventEmitter<void>();

  private snackbar = inject(MatSnackBar);

  constructor(
    private service: MetadataProvider,
    @Optional() @Inject(I18N_CONTEXT) public i18nContext: string,
    @Optional() @Inject(EDIT_CONTEXT) public editContext: EditContextType,
    private i18n: TranslocoService,
    private builder: UntypedFormBuilder,
  ) {
    super();
    this.showCloseButton = editContext === 'panel';
  }

  ngOnInit() {
    this.options$ = this.selected$.pipe(
      takeUntil(this.unsubscribe),
      map((selected): PairItem[] => this.options.map((pair, index) => ({
        leftValue: pair[0].value,
        leftLabel: pair[0].label,
        rightValue: pair[1].value,
        rightLabel: pair[1].label,
        currentValue: selected[index],
      }))),
    );
  }

  ngOnDestroy() {
    console.log('destroy pair picker');
    super.ngOnDestroy();
    this.closed.emit();
  }

  open(fieldName: string, selected: string[]) {
    // first clean any possible dirty state by closing the previous picker
    this.close();

    // then updated the component props
    this.initPairs(fieldName, selected);
    this.isOpen = true;

    // finally emit the `opened` event to let subscribers (usually the directive) to properly subscribe
    this.opened.emit();
  }

  close() {
    this.isOpen = false;
    this.closed.emit();
  }

  setSelected(selected: string[] = []) {
    this.selected$.next(this.mapSelected(selected));
  }

  private initPairs(name: string, selected: string[]) {
    this.fieldName = name;
    this.fieldsLoading = true;
    this.service.getFieldValues(name).pipe(
      take(1),
      map((values) => this.mapFieldValuesToForm(values)),
    ).subscribe((newValues: any[]) => {
      this.setSelected(selected);
      this.fieldsLoading = false;
    });
  }

  private mapSelected(selected: string[]) {
    const newSelection = Array(this.options.length).fill('');
    this.options.forEach((pair, index) => {
      if (selected?.includes(pair[0].value)) newSelection[index] = pair[0].value;
      if (selected?.includes(pair[1].value)) newSelection[index] = pair[1].value;
    });

    return newSelection;
  }

  onSelectionChange(event: MatButtonToggleChange, index: number) {
    const currentValues = this.selected$.value;
    let newValues = [...currentValues];
    if (this.maxSelection === 1) {
      // single selection: turn everything off and select the current
      newValues = Array(currentValues.length).fill('');
      newValues[index] = event.value;
    } else if (this.maxSelection) {
      // multiple selection with a max number of selected items: check if selectable, revert otherwise
      newValues[index] = event.value;
      const selectedCount = newValues.filter((value) => value).length;
      if (selectedCount > this.maxSelection) {
        this.selected$.next([...currentValues]);
        this.snackbar.open(`${this.fieldName} allows max ${this.maxSelection} items`, '', { panelClass: [MessageSeverity.WARNING] });
        // avoid propagate changes since there no real change
        return;
      }
    } else {
      // multi selection no limit: just select
      newValues[index] = event.value;
    }

    // filter selected and propagate the new value
    this.selected$.next(newValues);
    this.selectionChange.emit(this.selected$.value.filter((value) => value));
  }

  private mapFieldValuesToForm(values: FieldValue[]) {
    return values.reduce((result, value, index, arr) => {
      if (index % 2 === 0) { result.push(arr.slice(index, index + 2)); }
      return result;
    }, [] as any);
  }
}
