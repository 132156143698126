import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { CdkOverlayOrigin, ConnectedPosition, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TextFieldModule } from '@angular/cdk/text-field';
import { NgIf } from '@angular/common';
import { Component, ComponentRef, Inject, Injector, Input, OnChanges, Optional, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyFormField as MatFormField, MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { TrackEditQuery } from '@heardis/api-contracts';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';
import { I18N_CONTEXT } from '../../common';
import { AssetPickerComponent } from '../picker/asset-picker.component';

@Component({
  selector: 'hdis-asset-picker-form-field',
  templateUrl: './asset-picker-form-field.component.html',
  styleUrls: ['./asset-picker-form-field.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssetPickerFormFieldComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [MatLegacyFormFieldModule, NgIf, MatLegacyInputModule, TextFieldModule, CdkOverlayOrigin],
})
export class AssetPickerFormFieldComponent implements OnChanges, ControlValueAccessor {
  @Input() fieldName: string;

  @Input() separator = ', ';

  @Input() label = 'value';

  @Input()
  get inline(): boolean { return this._inline; }

  set inline(value) { this._inline = coerceBooleanProperty(value); }

  _inline = false;

  @Input('max')
  get maxSelection(): number { return this._maxSelection; }

  set maxSelection(value) { this._maxSelection = coerceNumberProperty(value); }

  _maxSelection: number;

  @Input()
  get canShowInvalidValues(): boolean { return this._canShowInvalidValues; }

  set canShowInvalidValues(value) { this._canShowInvalidValues = coerceBooleanProperty(value); }

  _canShowInvalidValues = false;

  @ViewChild('trigger') trigger: MatFormField;

  readonly = false;

  isActive = false;

  value: TrackEditQuery['trackEdits'];

  formattedValue: string;

  propagateFn: any;

  picker: AssetPickerComponent;

  release$: Observable<any>;

  overlayPositions: ConnectedPosition[] = [
    // { originY: "bottom", overlayY: "top", originX: "center", overlayX: "center" },
    // { originY: "top", overlayY: "bottom", originX: "center", overlayX: "center" },
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
    { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
    { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
    { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
    // { originX: "start", originY: "center", overlayX: "end", overlayY: "center", },
    // { originY: "bottom", overlayY: "top", originX: "center", overlayX: "end" },
    // { originY: "top", overlayY: "bottom", originX: "center", overlayX: "end" },
  ];

  options$: Observable<{ value: string; visible: boolean }[]>;

  constructor(
    private injector: Injector,
    private overlay: Overlay,
    private i18n: TranslocoService,
    @Optional() @Inject(I18N_CONTEXT) public i18nContext: string,
  ) {
  }

  writeValue(value: any): void {
    console.debug(`[asset-picker-form-field] for ${this.fieldName} `, { active: this.isActive }, value);
    this.value = value;
    this.formatValue();

    if (this.isActive && this.picker) { this.picker.setSelected(this.value); }
  }

  registerOnChange(fn: any): void {
    this.propagateFn = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean) {
    this.readonly = isDisabled;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fieldName) {
      // const values$ = .pipe(shareReplay(1))
    }
  }

  private formatValue() {
    const { cut: { threshold: cutThreshold, strictness: cutStrictness } = {}, explicit: { threshold: explicitThreshold, strictness: explicitStrictness } = {} } = this.value;
    const messages = [];
    if (cutThreshold) messages.push(`${cutStrictness === 'must' ? 'only' : 'prefer'} tracks with ${cutThreshold} duration`);
    if (explicitThreshold) messages.push(`${explicitStrictness === 'must' ? 'only' : 'prefer'} tracks with ${explicitThreshold} version`);

    // If it's used within a `MatFormField`, we should set it through the property so it can go
    // through change detection.
    this.formattedValue = messages.join(' and ');
    if (this.trigger) this.trigger._control.value = this.formattedValue;
  }

  openOverlay() {
    console.debug(`open overlay for ${this.fieldName}`);

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: 'hdis-asset-picker-form-field-panel',

      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.trigger.getConnectedOverlayOrigin())
        .withPositions(this.overlayPositions)
        .withFlexibleDimensions()
        .withGrowAfterOpen()
        .withPush(),
    });

    // Returns an OverlayRef (which is a PortalHost)
    const overlayRef = this.overlay.create(overlayConfig);

    // Subscribe to a stream that emits when the backdrop was clicked
    overlayRef.backdropClick().subscribe((_) => overlayRef.dispose());

    // Create ComponentPortal that can be attached to a PortalHost
    const filePreviewPortal = new ComponentPortal(AssetPickerComponent, null, this.injector);

    // Attach ComponentPortal to PortalHost
    const containerRef: ComponentRef<AssetPickerComponent> = overlayRef.attach(filePreviewPortal);
    this.picker = containerRef.instance;
    this.picker.showCloseButton = false;
    this.picker.isReadonly = this.readonly;
    this.picker.maxSelection = this.maxSelection;
    this.picker.canShowInvalidValues = this.canShowInvalidValues;

    this.picker.opened.pipe(first())
      .subscribe(() => {
        console.debug(`[asset-picker-form-field] subscribe for ${this.fieldName}`);
        this.isActive = true;
        this.release$ = containerRef.instance.closed.pipe(tap((lastValue) => {
          console.debug(`[asset-picker-form-field] unsubscribe for ${this.fieldName}`);
          this.propagateFn(lastValue);
          this.writeValue(lastValue);
          this.isActive = false;
        }));
        this.picker.selectionChange.pipe(takeUntil(this.release$))
          .subscribe((newValue) => {
            console.debug(`[asset-picker-form-field] new value for ${this.fieldName}`, newValue);
            this.propagateFn(newValue);
            this.writeValue(newValue);
          });
      });

    this.picker.open(this.value);
  }
}
