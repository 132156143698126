import { TranslocoMissingHandler, TranslocoConfig } from '@ngneat/transloco';
import { InjectionToken } from '@angular/core';

export const I18N_CONTEXT = new InjectionToken<string>('i18n key prefix');

export type EditContextType = 'page' | 'panel';

export const EDIT_CONTEXT = new InjectionToken<EditContextType>('where the editing is happening');

export class MissingTranslationHandler implements TranslocoMissingHandler {
  handle(key: string, config: TranslocoConfig) {
    console.warn(`missing translation for ${key}`);
    return key.split('.').pop();
  }
}
