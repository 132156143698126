import { createFeatureSelector, createSelector } from '@ngrx/store';
import { key, State } from './user.state';

export const getUser = createFeatureSelector<State>(key);

export const getUserInfo = createSelector(
  getUser,
  (state: State) => state
);

export const getUsername = createSelector(
  getUserInfo,
  (userInfo) => userInfo.username
)

export const isAuthenticated = createSelector(
  getUser,
  (state: State) => !!state?.username
)

export const isLoaded = createSelector(
  getUser,
  (state: State) => state.infoStatus !== 'init'
)
