export const key = 'navigation';

export interface State {
  collection: string[];
  currentValue: string;
  currentIndex: number;
}

export const initialState: State = {
  collection: [],
  currentValue: null,
  currentIndex: null,
};

export interface NavigationPagination {
  current: string;
  previous: string;
  next: string;
}
