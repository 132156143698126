import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, ValidationErrors, Validator, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, Observable, startWith, takeUntil } from 'rxjs';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { NgIf, NgFor } from '@angular/common';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { HdisFormFieldBulkOperations } from '../form-fields.interfaces';
import { BaseComponent } from '../../common';

/**
 * @todo migrate to custom form field to properly manage validation, styles and so on
 * https://material.angular.io/guide/creating-a-custom-form-field-control
 */
@Component({
  selector: 'hdis-string-field',
  templateUrl: './string-field.component.html',
  styleUrls: ['./string-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StringFieldComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => StringFieldComponent), multi: true },
  ],
  standalone: true,
  imports: [MatLegacyFormFieldModule, NgIf, MatLegacyInputModule, ReactiveFormsModule, MatLegacyAutocompleteModule, NgFor, MatLegacyOptionModule],
})
export class StringFieldComponent extends BaseComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() label: string;

  @Input() placeholder: string;

  @Input()
  get readonly(): boolean { return this._readonly; }

  set readonly(value) { this._readonly = coerceBooleanProperty(value); }

  _readonly: boolean;

  @Input()
  get isBulk(): boolean { return this._isBulk; }

  set isBulk(value) { this._isBulk = coerceBooleanProperty(value); }

  _isBulk: boolean;

  @Input() options: string[] = [];

  bulkDefaultOptions = [
    { value: HdisFormFieldBulkOperations.Clean, label: '-- discard values --' },
    { value: HdisFormFieldBulkOperations.Keep, label: '-- keep values --' },
  ];

  filteredOptions$: Observable<{ value: string; label: string }[]>;

  ctrl = new UntypedFormControl();

  onChangeFn: (value: string) => void;

  bulkOps = HdisFormFieldBulkOperations;

  ngOnInit(): void {
    this.ctrl.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe),
      )
      .subscribe((value) => {
        this.onChangeFn?.(value);
      });

    this.filteredOptions$ = this.ctrl.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      map((value: string) => {
        const filterValue = value.toLowerCase();
        const matchingOptions = [...this.options].filter((option) => option.toLowerCase().includes(filterValue));

        return [
          { value: HdisFormFieldBulkOperations.Clean, label: 'clean' },
          { value: HdisFormFieldBulkOperations.Keep, label: 'keep' },
          { value, label: value },
          ...matchingOptions.map((option) => ({ value: option, label: option })),
        ];
        // /*
        // when user writes in autocomplete input incoming value is string.
        // when user selects an item from the dropdown the incoming value is FieldValue.
        // */
        // if (value) {
        //   return this.fetchValuesFn(value) as Observable<FieldValue[]>;
        // } else {
        //   // this.addValue(value);
        //   return (this.fetchValuesFn() as Observable<FieldValue[]>).pipe(tap(values => this.currentOptions = values));
        // }
      }),
    );
  }

  registerOnValidatorChange?(fn: () => void): void {
    // throw new Error('Method not implemented.');
  }

  writeValue(obj: any): void {
    this.ctrl.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.ctrl.disable() : this.ctrl.enable();
  }

  validate(control: AbstractControl): ValidationErrors {
    return;

    throw new Error('Method not implemented.');
  }

  pickValue(value: string) {
    this.ctrl.patchValue(value);
  }

  /** arrow function to bind this to string-field and not mat-autocomplete */
  optionLabel = (value: string) => {
    if (value === this.bulkOps.Keep) return this.options.length === 1 ? this.options[0] : `-- keep ${this.options.length} values --`;
    if (value === this.bulkOps.Clean) return `-- discard ${this.options.length} values --`;
    if (value) return value;
    return '';
  };
}
