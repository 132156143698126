import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { HdisFormFieldBulkOperations } from '../form-fields.interfaces';

export const numericBulkInputValidator = (): ValidatorFn => (control: UntypedFormControl) : ValidationErrors | null => {
  const { value } = control;

  if (!value) return null;

  if (/^\d+$/.test(value)) return null;

  if (value === HdisFormFieldBulkOperations.Clean || value === HdisFormFieldBulkOperations.Keep) return null;

  return { numericBulk: true };
};

export const regexBulkInputValidator = (pattern: RegExp): ValidatorFn => (control: UntypedFormControl) : ValidationErrors | null => {
  const { value } = control;

  if (!value) return null;

  if (pattern.test(value)) return null;

  if (value === HdisFormFieldBulkOperations.Clean || value === HdisFormFieldBulkOperations.Keep) return null;

  return { regexBulk: true };
};

export const optionsBulkInputValidator = (allowedValues: string[]): ValidatorFn => (control: UntypedFormControl) : ValidationErrors | null => {
  const { value } = control;

  if (!value) return null;

  if (allowedValues.includes(value)) return null;

  if (value === HdisFormFieldBulkOperations.Clean || value === HdisFormFieldBulkOperations.Keep) return null;

  return { optionsBulk: { allowed: allowedValues } };
};
