import { ColumnApi, IDatasource, IGetRowsParams } from '@ag-grid-community/core';
import { Query, RequestProps, RequestRange, RequestSort } from '@heardis/api-contracts';
import { EventEmitter } from 'events';
import { Observable, of } from 'rxjs';
import { Identifiable, NotImplementedError } from '../../common';
import { GetRowsResult, StatsConfig } from '../table.interfaces';
import { DataSourceEvents } from './datasource.interfaces';

/**
 * Maps ui range operator names to backend operators.
 */
const RANGE_OPERATOR_MAP = {
  lessThan: 'lt',
  lessThanOrEqual: 'lte',
  greaterThan: 'gt',
  greaterThanOrEqual: 'gte',
};

/**
 * Abstract implementation of a TableDatasource which can be easily adapted to specific usecases, e.g.
 * different query-executors.
 *
 * Datasources that extend this class and describe "first-class" entities (i.e. tracks, pools, profiles)
 * are expected to support and optionally provide the baseQuery, meant as criteria that apply to any query,
 * regardless of pagination and further filtering. This feature is useful for example to define preset criteria
 * at table level, such as "latest tracks" being tracks where status != deleted
 *
 * Datasources that describe "second-class" entities (i.e. pool tracks, profile tracks, ...) do not support
 * the baseQuery capability. This basic filtering is transparently taken care on the backend side to avoid
 * ignoring relevant business rules by accident on the frontend (e.g. profile must include only tracks with
 * status === published and type === music to avoid production music or pending tracks to sneak into the list of
 * profile tracks). On the other hand, these "second-class" datasources may provide an overwriteQuery, meant as
 * temporary filter to show the results of the filter that is being edited.
 */
export abstract class AbstractDatasource<T extends Identifiable> extends EventEmitter implements IDatasource, DataSourceEvents {
  protected constructor(protected baseQuery?: Query, public statsEnabled?: boolean) {
    super();
  }

  protected getFields(columnApi: ColumnApi): string[] {
    return null;
  }

  getRows(params: IGetRowsParams): void {
    let tmpFilter: any;
    const conditions: Query[] = [];

    const hasBaseQuery = !!this.baseQuery;
    const hasColumnFilterQuery = Object.keys(params.filterModel).length > 0;
    const hasQuickFilterQuery = !!params?.context?.parent?.quickSearchQuery;

    // add basic query if any
    if (hasBaseQuery) {
      conditions.push(this.baseQuery);
    }

    // add column filters if any
    if (hasColumnFilterQuery) {
      Object.entries(params.filterModel).forEach(([fieldId, filterDefinition]) => {
        const sanitizedFieldId = fieldId;
        conditions.push(this.translateColumnFilter(sanitizedFieldId, filterDefinition));
      });
    }

    // add quick search filter if any
    if (hasQuickFilterQuery) {
      conditions.push(this.getTermFilter(params.context.parent.quickSearchQuery));
    }

    // merge all possible queries
    if (conditions.length) {
      tmpFilter = { and: [...conditions] };
    }

    // add pagination
    const range: RequestRange = {
      offset: params.startRow,
      limit: params.endRow - params.startRow,
    };

    // add column sorting
    const sort: RequestSort[] = [];
    params.sortModel.forEach((sortCol) => {
      sort.push({ field: sortCol.colId, order: sortCol.sort });
    });

    // add visible fields
    const fields = this.getFields(params.context.parent.getColumnApi());
    const props: RequestProps = fields?.length > 0 ? { fields } : null;

    this.emit('fetch', params);
    // run the query
    this.doQuery(tmpFilter, range, sort, props).subscribe({
      next: (response) => {
        this.emit('data', response, params);
        params.successCallback(response.rows, response.totalCount);
      },
      error: (error) => {
        this.emit('error', error, params);
        params.failCallback();
      },
    });
  }

  getStats(filter: Query, config: StatsConfig): Observable<any> {
    return of([]);
  }

  /**
   * Build the term-filter for the given query.
   * @param query
   */
  protected abstract getTermFilter(query: string): any;

  /**
   * @param filter transient query provided by the table component describing additional criteria to be applied
   * @param page range of results to be returned
   * @param sort order of the results
   * @param props defined which object props to request to the backend
   */
  protected abstract doQuery(filter: Query, page: RequestRange, sort: RequestSort[], props: RequestProps): Observable<GetRowsResult<T>>;

  private translateColumnFilter(sanitizedFieldId: string, filterDefinition: any): Query {
    switch (filterDefinition.filterType) {
      case 'text':
        return this.translateTextTypeColumnFilter(sanitizedFieldId, filterDefinition) as Query;
      case 'number':
        return this.translateNumberTypeColumnFilter(sanitizedFieldId, filterDefinition) as Query;
      case 'date':
        return this.translateDateTypeColumnFilter(sanitizedFieldId, filterDefinition) as Query;
      case 'multivalue':
        return this.translateMultivalueTypeColumnFilter(sanitizedFieldId, filterDefinition) as Query;
      default:
        throw new NotImplementedError(`Filter type [${filterDefinition.type}] is not implemented yet`);
    }
  }

  private translateTextTypeColumnFilter(sanitizedFieldId: string, filterDefinition: any) {
    switch (filterDefinition.type) {
      case 'contains':
        return { regex: { [sanitizedFieldId]: filterDefinition.filter } };
      case 'notContains':
        return { not: { regex: { [sanitizedFieldId]: filterDefinition.filter } } };
      case 'equals':
        return { equals: { [sanitizedFieldId]: filterDefinition.filter } };
      case 'notEquals':
        return { notEquals: { [sanitizedFieldId]: filterDefinition.filter } };
      case 'startsWith':
        return { regex: { [sanitizedFieldId]: `^${filterDefinition.filter}` } };
      case 'endsWith':
        return { regex: { [sanitizedFieldId]: `${filterDefinition.filter}$` } };
      default:
        throw new NotImplementedError(`Filter type [${filterDefinition.type}] for ${filterDefinition.filterType}-columns is not implemented yet`);
    }
  }

  private translateNumberTypeColumnFilter(sanitizedFieldId: string, filterDefinition: any) {
    switch (filterDefinition.type) {
      case 'equals':
        return { equals: { [sanitizedFieldId]: filterDefinition.filter } };
      case 'notEqual':
        return { notEquals: { [sanitizedFieldId]: filterDefinition.filter } };
      case 'lessThan':
      case 'lessThanOrEqual':
      case 'greaterThan':
      case 'greaterThanOrEqual': {
        const mappedOperator = RANGE_OPERATOR_MAP[filterDefinition.type];
        return { range: { [sanitizedFieldId]: { [mappedOperator]: filterDefinition.filter } } };
      }
      case 'inRange':
        return { range: { [sanitizedFieldId]: { lte: filterDefinition.filterTo, gte: filterDefinition.filter } } };
      default:
        throw new NotImplementedError(`Filter type [${filterDefinition.type}] for ${filterDefinition.filterType}-columns is not implemented yet`);
    }
  }

  private translateDateTypeColumnFilter(sanitizedFieldId: string, filterDefinition: any) {
    const startDay = '00:00:00.000Z';
    const endDay = '23:59:59.999Z';
    const dateFrom = filterDefinition.dateFrom?.slice(0, 10);
    const dateTo = filterDefinition.dateTo?.slice(0, 10);
    switch (filterDefinition.type) {
      case 'equals':
        return { range: { [sanitizedFieldId]: { gte: `${dateFrom}T${startDay}`, lte: `${dateFrom}T${endDay}` } } };
      case 'lessThan':
        return { range: { [sanitizedFieldId]: { lt: `${dateFrom}T${startDay}` } } };
      case 'lessThanOrEqual':
        return { range: { [sanitizedFieldId]: { lte: `${dateFrom}T${endDay}` } } };
      case 'greaterThan':
        return { range: { [sanitizedFieldId]: { gt: `${dateFrom}T${endDay}` } } };
      case 'greaterThanOrEqual':
        return { range: { [sanitizedFieldId]: { gte: `${dateFrom}T${startDay}` } } };
      case 'inRange':
        return { range: { [sanitizedFieldId]: { gte: `${dateFrom}T${startDay}`, lte: `${dateTo}T${endDay}` } } };
      default:
        throw new NotImplementedError(`Filter type [${filterDefinition.type}] for ${filterDefinition.filterType}-columns is not implemented yet`);
    }
  }

  private translateMultivalueTypeColumnFilter(sanitizedFieldId: string, multivalueFilterDefinition: any) {
    return { terms: { [sanitizedFieldId]: multivalueFilterDefinition.selected } };
  }
}
