import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent, InfoDialogData } from '@heardis/hdis-ui';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export enum ChangesStatus {
  TRACK_INFO = 'track-info',
  TRACK_CUEPOINTS = 'track-info',
  POOL_INFO = 'pool-info',
  POOL_FILTER = 'pool-filter',
  PROFILE_INFO = 'profile-info',
  PROFILE_FILTER = 'profile-filter',
  SCHEDULE = 'schedule',
  RADIO_STATION = 'radio-station',
  RADIO_STATION_CONFIGURATION = 'station-config',
  RADIO_STATION_WEB_CLIENT = 'station-web-client',
  COMPILATION = 'compilation',
  PLAYER = 'player',
  DEVICE = 'device',
}

@Injectable({ providedIn: 'root' })
export class ChangesStatusService {

  private hold: { [key: string]: boolean };

  constructor(private dialog: MatDialog) {
    this.reset()
  }

  setDirty(component: ChangesStatus, dirty: boolean) {
    this.hold[component] = dirty;
  }

  isDirty(component?: ChangesStatus) {
    if (component) {
      return this.hold[component] || false;
    } else {
      // if any component is dirty, return true
      return Object.entries(this.hold).some(([component, status]) => status === true);
    }
  }

  reset() {
    this.hold = {}
  }

  confirmDialog(component?: ChangesStatus): Observable<boolean> {

    // if no dirt changes continue
    if (!this.isDirty(component)) return of(true);

    // otherwise open the confirm modal
    return this.dialog.open<InfoDialogComponent, InfoDialogData>(InfoDialogComponent, {
      data: {
        subMessage: 'The changes will be lost if you continue. Are you sure?',
        title: 'confirm',
      }
    }).afterClosed().pipe(map(confirm => {
      if (confirm === true) this.reset()
      return confirm;
    }));
  }
}
